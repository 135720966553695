import React, { Component } from 'react';
import axios from "axios";
import ModalSaving from "../../../ModalSaving";

class ModalDisableMembership extends Component {
    constructor(){
        super();
        this.state = {
            picode : false,
            isActive : false,
            saved : false,
            error: false
        };
        this.activeMembership=this.activeMembership.bind(this);
    }
    componentDidMount(){
    }

    activeMembership = async () => {
        const country =  this.props.currCountry;
        await this.setState({saved : false, error: false});
        let send=true;
        if(send) {
            document.getElementById("ModalDisableMembershipCancelBtn").click();
            document.getElementById("modalSaveDisableMembership").classList.add("show");
            const formData = new FormData();
            formData.append("idModel",this.props.model);
            const saved = await axios.put("/api/membership/disable/"+this.props.membership, formData, {params : { country : country }}).then(resp => {
                return resp.data;
            });
            if (saved.Error === undefined) {
                await this.setState({saved : true})
                await this.props.reloadMemberships();
            } else {
                await this.setState({error : true});
            }
        }
    }
    render(){
        return (
            <div>
                <div className="modal" id="ModalDisableMembership" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header-section">
                                <h5 className="modal-title sectionTitle">Desctivar Membresia</h5>
                                <button type="button" className="close modalClose" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body modelBody">
                                <p>¿Realmente desea desactivar esta membresia?</p>
                                {(parseInt(this.props.main) === parseInt(this.props.membership)) ?
                                <p>Esta es la membres&iacute;a principal, si continua, la membres&iacute;a restante sera configurada como principal</p> : "" }
                            </div>
                            <div className="modal-footer modalFooter">
                                <button type="button" className="btn btn-modalClose" id="ModalDisableMembershipCancelBtn" data-dismiss="modal">Cancelar</button>
                                <button type="button" className="btn btn-modalSuccess" onClick={this.activeMembership}>Continuar</button>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalSaving saved={this.state.saved} error={this.state.error} idModal="modalSaveDisableMembership"/>
            </div>
        )
    }
}
export default ModalDisableMembership;