import React from 'react';

const PlayIcon= ({color, width, height, className = null, onClickFunction = null})=>{
    return(
        <svg   
            xmlns="http://www.w3.org/2000/svg" 
            width={width} 
            height={height}
            viewBox="0 0 360.000000 360.000000"
            className={className}
            preserveAspectRatio="xMidYMid meet"
            onClick={onClickFunction}
        >
        <g 
            transform="translate(0.000000,360.000000) scale(0.100000,-0.100000)"
            fill={color}
            stroke="none"
        >
            <path 
                d="M1571 3124 c-167 -29 -375 -112 -515 -206 -299 -200 -500 -500 -576
                -858 -32 -147 -32 -381 -1 -528 100 -478 437 -862 892 -1016 126 -43 216 -58
                381 -63 194 -6 312 9 474 62 453 149 805 546 899 1015 25 125 30 328 11 456
                -43 289 -173 550 -376 754 -189 190 -382 300 -645 367 -85 21 -128 26 -280 29
                -127 2 -205 -1 -264 -12z m-35 -755 c49 -28 177 -102 284 -164 107 -61 283
                -163 390 -225 107 -63 216 -125 242 -139 86 -46 151 -1 -754 -523 -142 -81
                -264 -148 -273 -148 -13 0 -15 58 -15 488 0 555 7 762 25 762 7 0 52 -23 101
                -51z"
            />
        </g>
    </svg>
    )
}
export default PlayIcon;
