import React, { Component } from 'react';
import axios from "axios";
import ModalSaving from "../../../ModalSaving";

class ModalDisableWebsite extends Component {
    constructor(){
        super();
        this.state = {
            picode : false,
            isActive : false,
            id: null,
            saved : false,
            error: false
        };
        this.disableWebsite=this.disableWebsite.bind(this);
    }
    // componentDidMount(){

    // }
    disableWebsite = async () => {
        await this.setState({saved : false, error: false});
            document.getElementById("ModalDisableWebsiteCancelBtn").click();
            document.getElementById("modalSaveActivePage").classList.add("show");
            const data ={
                id: this.props.id,
                status: 0
            }
            const changeStatus = await axios.post("/api/model/statusWebsite/", data)
            .then(resp => {
                return resp.data;
            });
            if (changeStatus) {
                await this.setState({saved : true});
                window.location.reload();

            } else {
                await this.setState({error : true});
            }
    }
    render(){
        return (
            <div>
                <div className="modal" id="ModalDisableWebsite" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header-section">
                                <h6 className="modal-title sectionTitle">Deshabilitar Sitio Web</h6>
                                <button type="button" className="close modalClose" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body modelBody">
                                <p className='textModalDisable'>¿Desea deshabilitar el sitio de esta modelo?</p>
                            </div>
                            <div className="modal-footer modalFooter">
                                <button type="button" className="btn btn-modalClose" id="ModalDisableWebsiteCancelBtn" data-dismiss="modal">Cancelar</button>
                                <button type="button" className="btn btn-modalSuccess" onClick={this.disableWebsite}>Aceptar</button>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalSaving saved={this.state.saved} error={this.state.error} idModal="modalSaveActivePage"/>
            </div>
        )
    }
}
export default ModalDisableWebsite;
