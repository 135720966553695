import React, { Component } from 'react';
import axios from "axios";
import moment from "moment";
import Promise from "bluebird";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import Carousel from "./Carousel";
import images from '../../Images';
import ModalSaving from "../../ModalSaving";
import UploadFiles from "../../UploadFiles/";
import "react-datepicker/dist/react-datepicker.css";

class ModalNewNotification extends Component{
    constructor(){
        super();
        this.state = {
            files : [],
            idModel: '',
            modelos: [],
            saved: true,
            error: false,
            progress : 0,
            mediaNews: [],
            allModels: null,
            uploading : false,
            progressSlice : 0,
            totalModelos: null,
            nameEspInput: null,
            nameEngInput: null,
            modelsSelected: [],
            filesUploading : 0,
            descriptionEspInput: null,
            descriptionEngInput: null,
            fileEncodingMessage: null,
            startDate: moment().add(1, 'days').utc().format("YYYY-MM-DDTHH:00")
        };
        this.axiosMedia = this.axiosMedia.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.saveNotification=this.saveNotification.bind(this);
        this.handleChangeStart = this.handleChangeStart.bind(this);
        this.fileChangedHandler = this.fileChangedHandler.bind(this);
        this.changeHandlerMultiple = this.changeHandlerMultiple.bind(this);
    }
    componentDidMount(){
        this.getModels();
    }

    getModels = async () => {
        await axios.get("/api/models/")
        .then(async resp => {
            let activeModelsCount = 0;
            let activeModels = [];
            for(var i = 0; i < resp.data.length; ++i){
                if(resp.data[i].status_active === 1 ){
                    activeModelsCount = i + 1;
                    activeModels.push(resp.data[i]);
                }
            }
            await this.setState({
                modelos : activeModels,
                totalModelos : activeModelsCount
            })
        });
    };

    handleChangeStart = async(date) =>{
        await this.setState({
            startDate: date
        });
    }

    changeHandler(e){
        this.setState({ [e.target.name]: e.target.value});
    }

    changeHandlerMultiple(value){
        let getAll = null;
        let valueAll = null;
        for (var i = 0; i < value.length; ++i) {
            if(value[i].label === "Todos"){
                getAll = value[i].value
                valueAll = value[i];
                break;
            }
        }
        if(getAll){
            this.setState({
                allModels: getAll,
                modelsSelected: valueAll
            });
        } else {
            this.setState({
                modelsSelected: value,
                allModels: null
            });
        }
    }

    drawCanvas(size){
        let canvas = [];
        for(let i = 0; i < size; i++){
            canvas.push(<canvas key={i} id={"canvasToRotate" + i} className="hide"></canvas>)
        }
        return canvas;
    }

    fileChangedHandler = async (e) => {
        const files = e.target.files;
        await this.setState({filesUploading: files.length})
        let currFiles = this.state.files;
        await new Promise.map(files, async (file, i) => {
            let typeFormat = file ? images.validateFormat(file.type) : '';
            if (typeFormat === 'image') {
                if (file.type.match('image/jpeg.*')) {
                    let blob = await images.rotateImage(file, i);
                    currFiles.push({file : blob, type : typeFormat});
                } else {
                    currFiles.push({file : file, type : typeFormat});
                }
            }
            else if(typeFormat === 'video'){
                currFiles.push({file : file, type : typeFormat});
            }
        }).then( async () => {
            this.setState({
                files : currFiles
            })
        })
    }

    axiosMedia = (url,file, option) => {
        try {
            return axios.put(url, file, {
                onUploadProgress: progressEvent =>
                {
                    let storageImages = [...this.state.files];
                    let progress= Math.floor((progressEvent.loaded / progressEvent.total)*100);
                    storageImages[option.index]["progress"]=progress;
                    this.setState({files : storageImages});
                },
                headers: {
                    'Content-Type': file.type
                }

            })
                .then(async (resp)=>{
                    if(resp){
                        let filesUploaded = [];
                        this.state.files.forEach(item=>{
                            if (item.progress && item.progress===100){
                                filesUploaded.push(item);
                            }
                            if (filesUploaded.length === this.state.files.length)
                            {
                                this.setState({
                                    saved : true,
                                    uploading: false
                                })
                                this.props.reloadNews();
                            }
                        })
                        return true;
                    }
                })
                .catch((err)=>{});
        }
        catch (e) { console.error(e); }
    }

    saveNotification = async () => {
        await this.setState({
            saved : false, 
            error: false
        });
        let send  = true;
        var today = new Date();
        let dateExpire     = document.getElementById("dateExpire");
        let nameEsp        = document.getElementById("nameEspInput");
        let nameEng        = document.getElementById("nameEngInput");
        let modelsStelect  = document.getElementById("modelsStelect");
        let descriptionEsp = document.getElementById("descriptionEspInput");
        let descriptionEng = document.getElementById("descriptionEngInput");
        if(!this.state.nameEspInput || this.state.nameEspInput.length < 3){
            nameEsp.classList.add("is-invalid");
            send = false;
        }else{
            nameEsp.classList.remove("is-invalid");
        }
        if(!this.state.descriptionEspInput || this.state.descriptionEspInput.length < 3){
            descriptionEsp.classList.add("is-invalid");
            send = false;
        }else{
            descriptionEsp.classList.remove("is-invalid");
        }
        if(!this.state.nameEngInput || this.state.nameEngInput.length < 3){
            nameEng.classList.add("is-invalid");
            send = false;
        }else{
            nameEng.classList.remove("is-invalid");
        }
        if(!this.state.descriptionEngInput || this.state.descriptionEngInput.length < 3){
            descriptionEng.classList.add("is-invalid");
            send = false;
        }else{
            descriptionEng.classList.remove("is-invalid");
        }
        if(!this.state.startDate || this.state.startDate < today){
            dateExpire.classList.add("is-invalid");
            send = false;
        }else{
            dateExpire.classList.remove("is-invalid");
        }
        if(this.state.modelsSelected.length === 0 && !this.state.allModels){
            modelsStelect.classList.add("error-input");
            send = false;
        }else{
            modelsStelect.classList.remove("error-input");
        }
        let copyFiles = [...this.state.files];
        let lengFiles = copyFiles.length;
        if(send) {
            var formData = new FormData();
            formData.append("lengFiles", lengFiles);
            formData.append("allModels", this.state.allModels);
            formData.append("nameEsp"  , this.state.nameEspInput);
            formData.append("nameEng"  , this.state.nameEngInput);
            formData.append("descriptionEsp",this.state.descriptionEspInput);
            formData.append("descriptionEng", this.state.descriptionEngInput);
            formData.append("modelsSelected", JSON.stringify(this.state.modelsSelected));
            formData.append("startDate", moment(this.state.startDate).format("YYYY-MM-DD HH:mm:ss"));
            const saved = await axios({
                method: 'post',
                url   : '/api/notifications/addNotification/',
                data  : formData,
                config: { headers: { 'Content-Type': 'multipart/form-data' } }
            })
            .then(async resp => {
                return resp.data;
            });
            if (!saved || !saved.notificationId) {
                await this.setState({
                    error: true
                })
            }
            else if (lengFiles > 0) {
                this.setState({ uploading: true });
                copyFiles.forEach((item,i)=>{
                    UploadFiles.uploadFiles(item.file, {
                        index: i,
                        type: "news",
                        totalFiles: lengFiles,
                        folder: '/News/',
                        idInserted: saved.notificationId
                    }, this.axiosMedia);
                });
            }
            else{
                this.setState({
                    saved: true,
                    uploading: false
                })
                this.props.reloadNews();
            }
            document.getElementById("ModalNewNotificationCancelBtn").click();
            document.getElementById("ModalSaving").classList.add("show");
        }
    }

    removeMedia = (index) => {
        let files = [...this.state.files];
        files.splice(index, 1);
        this.setState({files : files})
    }

    renderCarousel(){
        if(this.state.files && this.state.files.length){
            let files = [...this.state.files];
            return (
                <Carousel
                    media={files}
                    remove={this.removeMedia}
                />
            )
        }
        return null;
    }

    render(){
        const all = {
            id_model: this.state.totalModelos,
            username: 'Todos'
        };
        const allModels = [all, ...this.state.modelos];
        const modelos = allModels.map((item, index) => ({
            key: index ,
            value: item.id_model,
            label: item.username
        }));

        return(
            <div>
                <div className="modal" id="ModalNewNotification" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header-section">
                                <h6
                                    className="modal-title sectionTitle"
                                >
                                    Nueva Notificaci&oacute;n
                                </h6>
                                <button
                                    type="button"
                                    className="close modalClose modal-closeSection"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            {this.drawCanvas(this.state.filesUploading)}
                            <div className="modal-body referralBody publish">
                                <form id="newNotification">
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            placeholder="Título en español"
                                            className="form-control"
                                            id="nameEspInput"
                                            name="nameEspInput"
                                            defaultValue={this.state.nameEspInput}
                                            onChange={this.changeHandler}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <textarea
                                            placeholder="Añadir descripción en español"
                                            className="text-description form-control"
                                            id="descriptionEspInput"
                                            name="descriptionEspInput"
                                            defaultValue={this.state.descriptionEspInput}
                                            onChange={this.changeHandler}

                                        >
                                        </textarea>
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            placeholder="Título en inglés"
                                            className="form-control"
                                            id="nameEngInput"
                                            name="nameEngInput"
                                            defaultValue={this.state.nameEngInput}
                                            onChange={this.changeHandler}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <textarea
                                            placeholder="Añadir descripción en inglés"
                                            className="text-description form-control"
                                            id="descriptionEngInput"
                                            name="descriptionEngInput"
                                            defaultValue={this.state.descriptionEngInput}
                                            onChange={this.changeHandler}
                                        >
                                        </textarea>
                                    </div>
                                    <div className="form-group date-news">
                                        <label>Fecha de expiración</label>
                                        <DatePicker
                                            id="dateExpire"
                                            minDate={new Date()}
                                            className="form-control form-control-sm dateInput"
                                            showTimeSelect
                                            timeFormat="HH:mm"
                                            dateFormat="MMMM d, yyyy h:mm a"
                                            timeIntervals={15}
                                            selected={new Date(this.state.startDate)}
                                            onChange={this.handleChangeStart}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Enviar a</label>
                                        <Select
                                            id="modelsStelect"
                                            closeMenuOnSelect={false}
                                            hideSelectedOptions={false}
                                            isMulti
                                            name="modelsStelect"
                                            options={modelos}
                                            className="form-multiselect basic-multi-select"
                                            classNamePrefix="selec"
                                            value={this.state.modelsSelected}
                                            onChange={this.changeHandlerMultiple}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <div
                                            className="custom-file addFile-adjust files-alignment"
                                        >
                                                <input
                                                    type="file"
                                                    id="fileStorage"
                                                    name="fileStorage"
                                                    accept="image/*, video/*"
                                                    className="custom-file-input pointer"
                                                    onChange={(e) => {
                                                        this.fileChangedHandler(e)
                                                    }}
                                                    multiple
                                                />
                                            <label className="custom-upload">
                                                <i className="fas fa-cloud-upload-alt"></i>
                                                A&ntilde;adir Media
                                            </label>
                                        </div>
                                        {this.renderCarousel()}
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer modalFooter">
                                <button
                                    type="button"
                                    id="ModalNewNotificationCancelBtn"
                                    className="btn btn-modalClose"
                                    data-dismiss="modal"
                                >
                                    Cancelar
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-modalSuccess"
                                    onClick={this.saveNotification}
                                >
                                    Guardar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalSaving
                    saved={this.state.saved}
                    error={this.state.error}
                />
            </div>
        )
    }
}
export default ModalNewNotification;