import React, { Component } from 'react';
import axios from "axios";
import ModalSaving from "../../../ModalSaving";

class ModalDisableMembershipAll extends Component {
    constructor(){
        super();
        this.state = {
            picode : false,
            isActive : false,
            saved : false,
            error: false
        };
        this.websiteMemberships=this.websiteMemberships.bind(this);
    }
    componentDidMount(){
    }

    websiteMemberships = async () => {
            document.getElementById("ModalDisableMembershipAllCancelBtn").click();
            const data ={
                id: this.props.id,
                status: 0,
            }
            const changeStatus = await axios.post("/api/model/statusMembership/", data)
            .then(resp => {
                return resp.data;
            });
            if (changeStatus) {
                document.getElementById('ModalDisableMembershipAll').classList.remove('show');
                window.location.reload();
            } else {
                console.log('Memberships Error')
            }
    }
    render(){
        return (
            <div>
                <div className="modal" id="ModalDisableMembershipAll" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header-section">
                                <h5 className="modal-title sectionTitle">Desctivar todas las membresias</h5>
                                <button type="button" className="close modalClose" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body modelBody">
                                <p className='textModalDisable'>¿Realmente desea desactivar todas las membresias de esta modelo?</p>
                            </div>
                            <div className="modal-footer modalFooter">
                                <button type="button" className="btn btn-modalClose" id="ModalDisableMembershipAllCancelBtn" data-dismiss="modal">Cancelar</button>
                                <button type="button" className="btn btn-modalSuccess" onClick={this.websiteMemberships}>Continuar</button>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalSaving saved={this.state.saved} error={this.state.error} idModal="modalSaveDisableMembership"/>
            </div>
        )
    }
}
export default ModalDisableMembershipAll;