import React, { Component } from 'react';
import axios from "axios";
import ModalSaving from "../../../ModalSaving";

class ModalChangeBonus extends Component {
    constructor() {
        super();
        this.pct = [];
        this.usd = [];
        this.state = {
            saved: false,
            error: false,
            addBonusAllModels: false,
            catalog: [{
                pct: 0,
                usd: 0
            }]
        }
        this.addBonusAllModels = this.addBonusAllModels.bind(this);
    }
    changeInput = async (e, index) => {
        let name = e.target.name;
        let value = e.target.value;
        const catalog = [...this.state.catalog];
        catalog[index][name] = value;
        this.setState({ catalog: catalog })
    }
    changeBonus = async () => {
        await this.setState({ saved: false, error: false });
        document.getElementById("ModalAcceptChangeBonusCancelBtn").click();
        const regexInput = new RegExp(/[0-9]+$/);
        let send = true;
        const catalog = [...this.state.catalog];
        let percentage = 0;
        let dollarSpent = 0;
        catalog.map((item, index) => {
            percentage = catalog[index].pct;
            dollarSpent = catalog[index].usd;
            let pctModels = this.pct[index].current;
            let usdModels = this.usd[index].current;
            if (percentage === "" || isNaN(percentage) || percentage <= 0 || percentage >= 100 || !regexInput.test(percentage)) {
                pctModels.classList.add("is-invalid");
                send = false;
            }
            else {
                pctModels.classList.remove("is-invalid");
            }
            if (dollarSpent === "" || isNaN(dollarSpent) || dollarSpent <= 0 || !regexInput.test(dollarSpent)
                || dollarSpent >= 16777215) {
                usdModels.classList.add("is-invalid");
                send = false;
            }
            else {
                usdModels.classList.remove("is-invalid");
            }
        })
        if (send) {
            document.getElementById("ModalChangeBonusCancelBtn").click();
            document.getElementById("ModalSaveChangeBonus").classList.add("show");
            let data = "";
            let reloadBonusModel = false;
            if (this.props.allModels) {
                data = {
                    bonusCoins: [],
                    replaceAllBonus: true,
                    allModels: true
                }
                catalog.map((item)=>{
                    data.bonusCoins.push({
                        "new": {
                            "percentage": item.pct,
                            "dollarSpent": item.usd
                        }
                    });              
                })
            } else {
                data = {
                    "bonusCoins": [{
                        "new": {
                            "percentage": percentage,
                            "dollarSpent": dollarSpent
                        }
                    }],
                    "replaceAllBonus": true,
                    "modelsSelected": [
                        { "value": this.props.model }
                    ],
                    "allModels": false
                }
                reloadBonusModel = true;
            }
            const saved = await axios.put('/api/bonus/selfupdate', data)
                .then(async resp => {
                    return resp.data;
                });
            if (saved.Error === undefined) {
                await this.setState({ saved: true })
                if (reloadBonusModel) {
                    await this.props.reloadBonus();
                }
            } else {
                await this.setState({ error: true });
            }
        }
    }
    drawBonus() {
        const catalog = [...this.state.catalog];
        return catalog.map((item, index) => {
            this.pct[index] = React.createRef();
            this.usd[index] = React.createRef();
            return (
                <div id="changeBonusModels" className="modal-body">
                    <form id="catalogBonus">
                        <div className="form-group">
                            <input ref={this.pct[index]} type="number" placeholder="Porcentaje" className="form-control" id={index} name="pct" value={item.pct} onChange={(e) => this.changeInput(e, index)} />
                        </div>
                        <div className="form-group">
                            <input ref={this.usd[index]} type="number" placeholder="Dolar Gastado" className="form-control" id={index} name="usd" value={item.usd} onChange={(e) => this.changeInput(e, index)} />
                        </div>
                    </form>
                </div>
            )
        })
    }
    addBonusAllModels() {
        const catalog = [...this.state.catalog];
        this.usd.push([]);
        this.pct.push([]);
        catalog.push({
            pct: 0,
            usd: 0
        });
        this.setState({
            catalog: catalog
        })
    }
    render() {
        return (
            <div>
                <div className="modal" id="ModalChangeBonus" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Cambiar Cat&aacute;logo de Bonus</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div id="changeBonusModels" className="modal-body">
                                <form id="catalogBonus">
                                    {this.drawBonus()}
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger" id="ModalChangeBonusCancelBtn" data-dismiss="modal">Cancelar&nbsp;<i className="fas fa-times"></i></button>
                                <button type="button" className="btn btn-success" data-toggle="modal" data-target="#ModalAcceptChangeBonus">Aceptar</button>
                                {(this.props.allModels) ?
                                    <button type="button" className="btn btn-primary" data-toggle="modal" onClick={this.addBonusAllModels}>Agregar</button>
                                    : ""
                                }

                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal" id="ModalAcceptChangeBonus" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header-section">
                                <h5 className="modal-title sectionTitle">Cambiar Cat&aacute;logo de Bonus</h5>
                                <button type="button" className="close modalClose" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body modelBody">
                                <p>¿Realmente desea cambiar todo el Cat&aacute;logo de Bonus?</p>
                            </div>
                            <div className="modal-footer modalFooter">
                                <button type="button" className="btn btn-modalClose" id="ModalAcceptChangeBonusCancelBtn" data-dismiss="modal">Cancelar&nbsp;<i className="fas fa-times"></i></button>
                                <button type="button" className="btn btn-modalSuccess" onClick={this.changeBonus}>Guardar&nbsp;<i className="fas fa-save"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalSaving saved={this.state.saved} error={this.state.error} idModal="ModalSaveChangeBonus" />
            </div >
        )
    }
}
export default ModalChangeBonus;