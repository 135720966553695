import React, { Component } from 'react';

class GeneralReferral extends Component {
    constructor(){
        super();
        this.state = {
            referrals : []
        }
    }
    async componentDidMount(){
    }
    render(){
        return (
            <div className="">
                <div className="text-white head-table">
                    General
                </div>
                <div id='generalReferralDetail' className="container-fluid">
                    <div className="divCard row">
                        <div className="col-12 col-sm-6 col-lg-3 fontdetail">
                            <label>Nombre</label>
                            {this.props.name}
                        </div>
                        <div className="col-12 col-sm-6 col-lg-3 fontdetail" >
                            <label>Correo Electrónico</label>
                            {this.props.email}
                        </div>
                        <div className="col-12 col-sm-6 col-lg-3 fontdetail">
                            <label>Dirección</label>
                            { (this.props.address === undefined) ? "" : this.props.address }
                        </div>                       
                    </div>
                </div>
            </div>
        )
    }
}
export default GeneralReferral;
