import React, { Component } from 'react';
import axios from "axios";
import moment from 'moment';

class CoinsCustomer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            usercoins: [],
            usercoinsamount: [],
            usercoinstransaction: [],
            amountcoins: '',
            usercoinsbuy: []
        };
    }
    async componentDidMount() {
        await this.getUserCoinsSpent();
        await this.showUserCoinsSpent();
        await this.getUserCoinsAmount();
        await this.showUserCoinsAmount();
        await this.getUserCoinsTransaction();
        await this.showUserCoinsTransaction();
        await this.getUserCoinsBuy();
        await this.showUserCoinsBuy();
    }
    
    getUserCoinsAmount = async () => {
        const data = {
            model: this.props.model
        }
        await axios.get('/api/usercoinsamount/' + this.props.username, {params: data}).then(async resp => {
            await this.setState({ usercoinsamount: resp.data })
        });
    };
    getUserCoinsBuy = async () => {       
        const data = {
            model: this.props.model           
        }
        await axios.get('/api/usercoinsbuy/' + this.props.username, {params: data}).then(async resp => {
            await this.setState({ usercoinsbuy: resp.data })
        });
    };
    getUserCoinsSpent = async () => {        
        const data = {
            model: this.props.model           
        }
        await axios.get('/api/usercoins/' + this.props.username, {params: data}).then(async resp => {
            await this.setState({ usercoins: resp.data })
        });
    };

    getUserCoinsTransaction = async () => {       
        const data = {
            model: this.props.model           
        }
        await axios.get('/api/usercoinstransaction/' + this.props.username, {params: data}).then(async resp => {
            await this.setState({ usercoinstransaction: resp.data })
        });
    }
    showUserCoinsBuy() {
        if (this.state.usercoinsbuy.length === 0) {
            return (<tr><td colSpan={5} className="text-center">No hay Informaci&oacute;n de Monedas</td></tr>)
        }
        const usercoinsbuy = [...this.state.usercoinsbuy];
        return usercoinsbuy.map((item, index) => {
            let datetime = moment(item.dateTransaction).format("DD/MMM/YYYY HH:mm:ss");
            return (
                <tr key={index}>
                    <td>{item.buyCoins}</td>
                    <td>{datetime}</td>
                </tr>
            )
        })
    }
    showUserCoinsSpent() {
        if (this.state.usercoins.length === 0) {
            return (<tr><td colSpan={5} className="text-center">No hay Informaci&oacute;n de Monedas</td></tr>)
        }
        const usercoins = [...this.state.usercoins];
        return usercoins.map((item, index) => {
            return (
                <tr key={index}>
                    <td></td>
                    <td>{item.spentcoins}</td>
                    {item.namemodule === 'media-messages' ?
                        <td>Envio de multimedia</td>
                        : ""
                    }
                    {item.namemodule === 'audio-message-req' ?
                        <td>Petici&oacute;n de Audio</td>
                        : ""
                    }
                    {item.namemodule === 'image-message-req' ?
                        <td>Petici&oacute;n de Imagen</td>
                        : ""
                    }
                    {item.namemodule === 'video-message-req' ?
                        <td>Petici&oacute;n de Video</td>
                        : ""
                    }
                    {item.namemodule === 'donation' ?
                        <td>Donaci&oacute;n </td>
                        : ""
                    }
                    {item.namemodule === 'massive-message' ?
                        <td>Mensaje Masivo</td>
                        : ""
                    }
                </tr>
            )
        })
    }
    showUserCoinsAmount() {
        const usercoinsamount = [...this.state.usercoinsamount];
        return usercoinsamount.map((item, index) => {
            return (
                this.setState({ amountcoins: item.amountcoins })
            )
        })
    }
    showUserCoinsTransaction() {
        if (this.state.usercoinstransaction.length === 0) {
            return (<tr><td colSpan={5} className="text-center">No hay Informaci&oacute;n de Monedas</td></tr>)
        }
        const usercoinstransaction = [...this.state.usercoinstransaction];
        return usercoinstransaction.map((item, index) => {
            let datetime = moment(item.createdtime).format("DD/MMM/YYYY HH:mm:ss");
            return (
                <tr key={index}>
                    <td>
                        {item.namemodule === 'media-messages' ?
                            <td>Envio de multimedia</td>
                            : ""
                        }
                        {item.namemodule === 'audio-message-req' ?
                            <td>Petici&oacute;n de Audio</td>
                            : ""
                        }
                        {item.namemodule === 'image-message-req' ?
                            <td>Petici&oacute;n de Imagen</td>
                            : ""
                        }
                        {item.namemodule === 'video-message-req' ?
                            <td>Petici&oacute;n de Video</td>
                            : ""
                        }
                        {item.namemodule === 'donation' ?
                            <td>Donaci&oacute;n </td>
                            : ""
                        }
                        {item.namemodule === 'massive-message' ?
                            <td>Mensaje Masivo</td>
                            : ""
                        }
                    </td>
                    <td>{item.spentamount}</td>
                    <td>{datetime}</td>
                </tr>
            )
        })
    }
    render() {
        return (
            <div className="row">
                <div className="col-12">
                    <div className="text-white head-table-coin">
                        Informaci&oacute;n de Monedas del Usuario
                    </div>
                    <div id='' className="container-fluid">
                        <div className="divCard row">
                            <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                                <label>Monedas Disponibles</label>
                                {this.state.amountcoins === '' || this.state.amountcoins === 0 ?
                                    <input className="form-control" type="text" value=" No hay monedas" disabled />
                                    : <input className="form-control" type="text" value={this.state.amountcoins} disabled />
                                }
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div className="table-responsive tableDiv tableScoll-lg">  
                                        <caption className="table-responsive caption-coins">Historial de compra de monedas</caption>           
                                        <table className="table tableDataSection">                                           
                                            <thead>                                                                                     
                                                <tr className="attr-table coins-user">
                                                    <th>Monto de la Transacci&oacute;n</th>
                                                    <th>Fecha de transacci&oacute;n</th>
                                                </tr>
                                            </thead>
                                            {this.showUserCoinsBuy()}
                                        </table>
                                    </div>
                                    <div className="table-responsive tableDiv tableScoll-lg">
                                        <caption className="table-responsive caption-coins">Historial de monedas gastadas</caption>
                                        <table className="table tableDataSection">                                       
                                            <thead>
                                                <tr className="attr-table coins-user">
                                                    <th></th>
                                                    <th>Total de Monedas Gastadas</th>
                                                    <th>Nombre del M&oacute;dulo</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            {this.showUserCoinsSpent()}
                                        </table>
                                    </div>
                                    <div className="table-responsive tableDiv tableScoll-lg">
                                        <caption className="table-responsive caption-coins">Historial de transacciones en monedas gastadas</caption>
                                        <table className="table tableDataSection">                      
                                            <thead>
                                                <tr className="attr-table coins-user">
                                                    <th>Nombre del M&oacute;dulo</th>
                                                    <th>Monedas Gastadas</th>
                                                    <th>Fecha de transacci&oacute;n</th>
                                                </tr>
                                            </thead>
                                            {this.showUserCoinsTransaction()}
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default CoinsCustomer;