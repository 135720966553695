import React, { Component } from 'react';
import './style.css';

class Post extends Component {

    async componentDidMount(){

    }
    render(){
        return (
            <div
                className="screen-example screen-post"
                id="examplePost"
                style={{backgroundColor : this.props.config.color.navbar}}
            >
                <button
                    type="button"
                    className="menu-example-head left"
                    onClick={this.props.openSideBar}
                >
                    <i className=" fas fa-bars"></i>
                </button>
                <button
                    type="button"
                    className="menu-example-head right"
                >
                    <i className=" fas fa-user"></i>
                </button>
                <div
                    className="logo"
                >
                    {(this.props.config.logo) ?
                        <img
                            src={this.props.config.storageURL + this.props.config.logo}
                            alt="LOGO"
                        />
                        :
                        "LOGO"
                    }
                </div>
                <div
                    className="sidebar-example open"
                    style={{
                        backgroundColor : this.props.config.color.navbar
                    }}
                >
                    <button
                        type="button"
                        className="menu-example-head left"
                        onClick={this.props.closeSideBar}
                    >
                        <i className=" fas fa-bars"></i>
                    </button>
                    <ul>
                        <li
                            style={{
                                color : "hsla(0,0%,100%,.57)"
                            }}
                            data-window={0}
                            onClick={this.props.openExampleWindow}
                        >
                            INICIO
                        </li>
                        <li
                            style={{
                                backgroundColor:this.props.config.color.secondary,
                                color: "#FFF"
                            }}
                        >
                            POSTS
                        </li>
                        <li
                            style={{
                                color : "hsla(0,0%,100%,.57)"
                            }}
                            data-window={4}
                            onClick={this.props.openExampleWindow}
                        >
                            MENU CHAT
                        </li>
                        <li
                            style={{
                                color : "hsla(0,0%,100%,.57)"
                            }}
                            data-window={2}
                            onClick={this.props.openExampleWindow}
                        >
                            CHAT
                        </li>
                        <li
                            style={{
                                color : "hsla(0,0%,100%,.57)"
                            }}
                            data-window={3}
                            onClick={this.props.openExampleWindow}
                        >
                            MODAL
                        </li>
                    </ul>
                </div>
                <div
                    className="screen-body"
                    style={{backgroundColor:this.props.config.color.principal}}
                >
                    <ul
                        className="sections"
                        style={{
                            color : this.props.config.color.principalText,
                            borderColor : this.props.config.color.principalText
                        }}
                    >
                        <li
                            style={{color : this.props.config.color.secondary}}
                        >
                            <i className="fas fa-camera"></i>
                        </li>
                        <li>
                            <i className="fas fa-plane"></i>
                        </li>
                        <li>
                            <i className="fas fa-video"></i>
                        </li>
                    </ul>
                    <div
                        className="post"
                        style={{
                            borderColor : this.props.config.color.principalText
                        }}
                    >
                        <img
                            src={this.props.config.storageURL+this.props.mainImage}
                            alt=""
                            className="post-user-icon"
                        />
                        <p
                            style={{color : this.props.config.color.principalText}}
                            className="post-user-name"
                        >
                            {this.props.config.nameTitle}
                        </p>
                        <div
                            style={{color : this.props.config.color.principalText}}
                            className="post-content"
                        >
                            <i className="fas fa-clone post-content-many"></i>
                            <i className="fas fa-ellipsis-v post-content-menu"></i>
                        </div>
                        <span
                            className="post-foot-like"
                            style={{color : this.props.config.color.principalText}}
                        >
                            <i className="fas fa-thumbs-up" style={{color : this.props.config.color.secondary}}></i>
                            200
                        </span>
                        <span
                            style={{color : this.props.config.color.principalText}}
                            className="post-foot-coins"
                        >
                            <i className="fas fa-coins" style={{color : this.props.config.color.secondary}}></i>
                            10
                        </span>
                        <p
                            className="post-description"
                            style={{color : this.props.config.color.principalText}}
                        >
                            Sed ut perspiciatis unde omnis iste natus error.
                        </p>
                    </div>
                    <div
                        className="post"
                    >
                        <img
                            src={this.props.config.storageURL+this.props.mainImage}
                            alt=""
                            className="post-user-icon"
                        />
                        <p
                            style={{color : this.props.config.color.principalText}}
                            className="post-user-name"
                        >
                            {this.props.config.nameTitle}
                        </p>
                        <div
                            style={{color : this.props.config.color.principalText}}
                            className="post-content"
                        >
                            <i className="fas fa-clone post-content-many"></i>
                            <i className="fas fa-ellipsis-v post-content-menu"></i>
                        </div>
                        <span
                            className="post-foot-like"
                            style={{color : this.props.config.color.principalText}}
                        >
                            <i className="fas fa-thumbs-up" style={{color : this.props.config.color.secondary}}></i>
                            200
                        </span>
                        <span
                            style={{color : this.props.config.color.principalText}}
                            className="post-foot-coins"
                        >
                            <i className="fas fa-coins" style={{color : this.props.config.color.secondary}}></i>
                            10
                        </span>
                        <p
                            className="post-description"
                            style={{color : this.props.config.color.principalText}}
                        >
                            Sed ut perspiciatis unde.
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}
export default Post;
