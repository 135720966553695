import React, { Component } from 'react';
import axios from "axios";
import ModalSaving from "../../ModalSaving";
import './style.css';
class ModalNewRegion extends Component {
    constructor(){
        super();
        this.state={
            saved : false,
            error : false,
            selected : [],
            searchCountry : "",
            searchSelected : "",
            regionName : ""
        };
        this.saveRegion=this.saveRegion.bind(this);
        this.addCountry=this.addCountry.bind(this);
        this.removeCountry=this.removeCountry.bind(this);
        this.handleInputChange=this.handleInputChange.bind(this);
    }
    componentDidMount() {

    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.regionIndex !== prevProps.regionIndex){
            document.getElementById("regionName").value = this.props.currentName;
            this.setState({regionName : this.props.currentName, selected : this.props.currentCountries})
        }
    }

    handleInputChange = async (e) => {
        let name = e.target.id;
        let value = e.target.value;
        this.setState({[name] : value});
    }
    addCountry = async (e) => {
        let countries = document.getElementById("country").selectedOptions;
        let selected = [...this.state.selected];
        for( let option of countries){
            selected.push(option.value)
        }
        this.setState({selected : selected})
    }
    removeCountry = async () => {
        let countries = document.getElementById("selectedCountry").selectedOptions;
        let selected = [...this.state.selected];
        for( let option of countries){
            selected.splice(selected.indexOf(option.value),1)
        }
        this.setState({selected : selected})
    }
    saveRegion = async () => {
        await this.setState({saved : false, error: false});
        let send=true;
        let name = this.state.regionName;
        let region = this.props.regionIndex;
        let countries = this.state.selected;
        if(name === ""){
            send = false;
            document.getElementById("regionName").classList.add("is-invalid");
        }
        if(countries.length < 1){
            send = false;
            document.getElementById("selectedCountry").classList.add("is-invalid");
        }
        if(send) {
            document.getElementById("ModalNewRegionCancelBtn").click();
            document.getElementById("ModalSaving").classList.add("show");
            let data = {
                name : name,
                index : region,
                countries : countries
            }
            const saved = await axios.post("/api/region/", data).then(resp => {
                if(resp.data.error){
                    return false;
                }
                return true;
            }).catch(err => {
                return false;
            });
            if (saved) {
                await this.setState({saved : true});
                this.props.reloadRegions()
            } else {
                await this.setState({error : true});
            }
        }
    }
    printSelect(selected = false) {
        const paises = [...this.props.paises];
        const selectedCountries = [...this.state.selected];
        if(paises.length === 0){
            return (<option>No Data</option>)
        }
        let searchCountry = this.state.searchCountry.toLowerCase();
        let searchSelected = this.state.searchSelected.toLowerCase();
         // eslint-disable-next-line
        return paises.map((item) => {
            if(!selectedCountries.includes(item.code) && !selected){
                if(item.code.toLowerCase().includes(searchCountry) || item.name.toLowerCase().includes(searchCountry)) {
                    return (
                        <option value={item.code}>{item.name}</option>
                    )
                }
            }
            else if(selectedCountries.includes(item.code) && selected){
                if(item.code.toLowerCase().includes(searchSelected) || item.name.toLowerCase().includes(searchSelected)) {
                    return (
                        <option value={item.code}>{item.name}</option>
                    )
                }
            }
            else{
                return false
            }
        })
    }
    render(){
        return (
            <div>
                <div className="modal" id="ModalNewRegion" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header-section">
                                <h6 className="modal-title sectionTitle">Nueva Region</h6>
                                <button type="button" className="close modalClose" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body modelBody">
                                <form id="newModel">
                                    <div className="form-group">
                                        <input type="text" placeholder="Nombre" className="form-control" id="regionName" onChange={this.handleInputChange}/>
                                    </div>
                                    <div className="form-group select-vs ">
                                        <div className="select-vs-left">
                                            <p>All countries</p>
                                            <input type="text" placeholder="search..." className="form-control" id="searchCountry" onChange={this.handleInputChange}/>
                                            <select multiple id="country">
                                                {this.printSelect()}
                                            </select>
                                        </div>
                                        <div className="select-vs-btns">
                                            <button type="button" className="btn add btn-xs btn-modalClose" onClick={this.addCountry}>--&gt;</button>
                                            <button type="button" className="btn remove btn-xs btn-modalClose" onClick={this.removeCountry}>&lt;--</button>
                                        </div>
                                        <div className="select-vs-right">
                                            <p>Region</p>
                                            <input type="text" placeholder="search..." className="form-control" id="searchSelected" onChange={this.handleInputChange}/>
                                            <select multiple id="selectedCountry">
                                                {this.printSelect(true)}
                                            </select>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer modalFooter">
                                <button type="button" className="btn btn-modalClose" id="ModalNewRegionCancelBtn" data-dismiss="modal">Cancelar</button>
                                <button type="button" className="btn btn-modalSuccess" onClick={this.saveRegion}>Guardar</button>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalSaving saved={this.state.saved} error={this.state.error}/>
            </div>
        )
    }
}
export default ModalNewRegion;