import React, { Component } from 'react';
import './style.css'

class Main extends Component {

    async componentDidMount(){
    }
    render(){
        return (
            <div
                className="screen-example screen-main open"
                id="exampleLogin"
                style={{backgroundColor : this.props.config.color.navbar}}
            >
                <button
                    type="button"
                    className="menu-example-head left"
                    onClick={this.props.openSideBar}
                >
                    <i className=" fas fa-bars"></i>
                </button>
                <button
                    type="button"
                    className="menu-example-head right"
                >
                    <i className=" fas fa-user"></i>
                </button>
                <div
                    className="logo"
                >
                    {(this.props.config.logo) ?
                        <img
                            src={this.props.config.storageURL + this.props.config.logo}
                            alt="LOGO"
                        />
                        :
                        "LOGO"
                    }
                </div>
                <div className="sidebar-example open" style={{backgroundColor : this.props.config.color.navbar}}>
                    <button
                        type="button"
                        className="menu-example-head left"
                        onClick={this.props.closeSideBar}
                    >
                        <i className=" fas fa-bars"></i>
                    </button>
                    <p className="title">
                        Menu
                    </p>
                    <ul style={{color : this.props.config.color.sideBarText}}>
                        <li
                            style={{
                                backgroundColor:this.props.config.color.secondary,
                                color: "#FFF"
                            }}
                        >
                            INICIO
                        </li>
                        <li
                            style={{
                                color : "hsla(0,0%,100%,.57)"
                            }}
                            data-window={1}
                            onClick={this.props.openExampleWindow}
                        >
                            POSTS
                        </li>
                        <li
                            style={{
                                color : "hsla(0,0%,100%,.57)"
                            }}
                            data-window={4}
                            onClick={this.props.openExampleWindow}
                        >
                            MENU CHAT
                        </li>
                        <li
                            style={{
                                color : "hsla(0,0%,100%,.57)"
                            }}
                            data-window={2}
                            onClick={this.props.openExampleWindow}
                        >
                            CHAT
                        </li>
                        <li
                            style={{
                                color : "hsla(0,0%,100%,.57)"
                            }}
                            data-window={3}
                            onClick={this.props.openExampleWindow}
                        >
                            MODAL
                        </li>
                    </ul>
                </div>
                <div className="user-image">
                    <img src={this.props.config.storageURL+this.props.mainImage} alt=""/>
                </div>
                <div
                    className="screen-body"
                    style={{
                        backgroundColor : this.props.config.color.principal
                    }}
                >
                    <h6 className="model-name"
                        style={{color : this.props.config.color.principalText}}>
                        {this.props.config.nameTitle}
                    </h6>
                    <ul
                        className="model-actions"
                    >
                        <li>
                            <button
                                className="model-actions-button"
                                type="button"
                                style={{
                                    backgroundColor: this.props.config.color.third,
                                    color : this.props.config.color.secondaryText
                                }}
                            >
                                Log In
                            </button>
                        </li>
                        <li>
                            <button
                                className="model-actions-button"
                                type="button"
                                style={{
                                    backgroundColor: this.props.config.color.secondary,
                                    color: this.props.config.color.secondaryText
                                }}
                            >
                                Suscribe now
                            </button>
                        </li>
                    </ul>
                    <p
                        className="join"
                        style={{color : this.props.config.color.principalText}}
                    >
                        Join my exclusive community!
                    </p>
                    <h5
                        className="benefits-title"
                        style={{color : this.props.config.color.principalText}}
                    >
                        Benefits
                    </h5>
                    <p
                        className="benefits-element"
                        style={{color : this.props.config.color.principalText}}
                    >
                        <i className="fas fa-check" style={{color : this.props.config.color.principalText}}></i>
                        Exclusive content.
                    </p>
                    <p
                        className="benefits-element"
                        style={{color : this.props.config.color.principalText}}
                    >
                        <i className="fas fa-check" style={{color : this.props.config.color.principalText}}></i>
                        Private chat with {this.props.config.nameTitle}.
                    </p>
                    <p
                        className="benefits-element"
                        style={{color : this.props.config.color.principalText}}
                    >
                        <i className="fas fa-check" style={{color : this.props.config.color.principalText}}></i>
                        Cancel your membership at anytime.
                    </p>
                </div>
            </div>
        )
    }
}
export default Main;
