import React, { Component } from 'react';
import axios from "axios";
import Header from '../../../components/Layout/Header';
import Sidebar from '../../../components/Layout/Sidebar';
import Footer from '../../../components/Layout/Footer';
import GeneralReferral from '../../../components/Referral/Detail/GeneralReferral';
import ModelReferral from '../../../components/Referral/Detail/ModelReferral';
import ModalUpdateReferral from '../../../components/Referral/ModalUpdateReferral';
import ModalNewModelReferral from '../../../components/Referral/Detail/ModalNewModelReferral';
import ModalActivateReferral from '../../../components/Referral/ModalActivateReferral';
import ModalWarning from '../../../components/Layout/ModalWarning';
import ModalSaving from "../../../components/ModalSaving";

class ReferralDetail extends Component {
    constructor() {
        super();
        this.state = {
            id: null,
            name: null,
            mail: null,
            address: null,
            referral_status: null,
            saved: false,
            id_model: null,
            username: "",
            email: null,
            hostname: null,
            path: "Home / Referidos / "
        }
        this.reloadReferral = this.reloadReferral.bind(this);
    }
    async componentDidMount() {
        await this.props.isAbleToAccess(3, 1, 1);
        await this.setState({ id: this.props.referral });
        if (this.state.id === "") {
            window.location = "/";
        }
        await this.getAllDataReferral();
        this.props.changeView("referral");

        const name = this.state.name;
        this.setState({ view: name });
    }
    async cancelChanges(e) {
        this.getAllDataReferral();
    }
    reloadReferral = async () => {
        this.getAllDataReferral();
    }
    getAllDataReferral = async () => {
        await axios.get('/api/referral/detail/' + this.state.id)
            .then(async resp => {
                if (!resp.data) {
                    window.location = "/";
                }
                else {
                    await this.setState({
                        name: resp.data.name,
                        email: resp.data.email,
                        address: resp.data.address,
                        referral_status: resp.data.referral_status
                    })
                }
            });
    }
    updateReferral(idReferral) {
        this.setState({ id_referral: idReferral });
    }
    render() {
        return (
            <div className="container">
                <Header name={this.props.name} path={this.state.path} view={this.state.view} />
                <div className="content">
                    <Sidebar permisos={this.props.permisos} view={this.props.view} />
                    <div className="content-padding main-content" id="page">
                        <div className="row head-padding">
                            <div className="col-12 col-sm-2 col-md-2 col-lg-2">
                                <h4>Referidos</h4>
                            </div>
                            <div className="col-12 col-sm-10 col-md-10 col-lg-10 alignButtons">
                                <div className="inline-buttons">
                                    <ul style={{ marginBottom: 0, paddingLeft: 0 }}>
                                        {(this.props.accessLevel["c"] !== undefined && this.props.accessLevel["u"] >= 3) ?
                                            <li className="list-inline-item">
                                                {this.state.referral_status === 1 ?
                                                    <button className="btn btn-reactivate" type="button" data-toggle="modal" data-target="#ModalDesReferral">
                                                        Inhabilitar
                                                    </button>
                                                    :
                                                    <button className="btn btn-reactivate" type="button" data-toggle="modal" data-target="#ModalActReferral">
                                                        Reactivar
                                                    </button>
                                                }
                                            </li> : ""
                                        }
                                        {(this.props.accessLevel["c"] !== undefined && this.props.accessLevel["u"] >= 1) ?
                                            <li className="list-inline-item extra-btn">
                                                <button type="button" className="btn btn-updateReferral" data-toggle="modal" data-target="#ModalUpdateReferral">Actualizar</button>
                                            </li> : ""}
                                        {(this.props.accessLevel["c"] !== undefined && this.props.accessLevel["u"] >= 1) ?
                                            <li className="list-inline-item extra-btn">
                                                <button type="button" className="btn btn-newModelReferral" data-toggle="modal" data-target="#ModalNewModelReferral">Agregar Modelo</button>
                                            </li> : ""}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <canvas id="canvasToRotate"></canvas>
                            <div className="col-12">
                                <form id="DataReferral" encType="multipart/form-data" method="post">
                                    {(this.state.name !== null) ?
                                        <GeneralReferral accessLevel={this.props.accessLevel} name={this.state.name} email={this.state.email} address={this.state.address} />
                                        : ""}
                                    <ModelReferral accessLevel={this.props.accessLevel} id_referral={this.state.id} id_model={this.state.id_model}
                                        name={this.state.name} hostname={this.state.hostname} split={this.state.split}
                                        coins={this.state.coins}
                                        updateReferral={this.updateReferral}
                                    />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
                <ModalWarning title={"Algo salio mal"} message={"No se pudo guardar el referral"} />
                {(this.state.referral_status === 1 || this.state.referral_status === 0) ?
                    <ModalActivateReferral reloadReferral={this.reloadReferral} referral={this.state.id} referral_status={this.state.referral_status}/>
                    : ""
                }
                <ModalNewModelReferral reloadReferral={this.reloadReferral} referral={this.state.id} />
                <ModalUpdateReferral reloadReferral={this.reloadReferral} referral={this.state.id} name={this.state.name} email={this.state.email}/>
                <ModalSaving saved={this.state.saved} error={this.state.error} />
            </div>
        )
    }
}
export default ReferralDetail;
