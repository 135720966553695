import React, { Component } from 'react';
import ModalContentRequestRefund from "../../../../components/Customers/Detail/ModalContentRequestRefund";

class ModalDisableContentRequest extends Component {
    constructor() {
        super();
        this.state = {
            saved: false,
            error: false
        };
    }
    render() {
        return (
            <div>
                <div className="modal" id="ModalDisableContentRequest" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header-section">
                                <h5 className="modal-title sectionTitle">Desactivar Solicitud de Contenido</h5>
                                <button type="button" className="close modalClose" id="refundCloseBtn" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body modelBody">
                                <p>¿Desea reembolsar las monedas al usuario?</p>
                            </div>
                            <div className="modal-footer modalFooter">
                                <button type="button" id="refundBtn" className="btn btn-modalSuccess" onClick={(e) => this.props.refundCoinsContentRequest(e)} data-toggle="modal" data-target="#ModalRefundDiContentRequest">Sí</button>
                                <button type="button" className="btn btn-modalClose" onClick={(e) => this.props.refundContentRequestC(e)} data-toggle="modal" data-target="#ModalOnlyDisableContentRequest">No</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default ModalDisableContentRequest;