import React, { Component } from 'react';
import axios from "axios";
import ModalNewModelReferral from '../../../../components/Referral/Detail/ModalNewModelReferral';
import ModalSaving from '../../../ModalSaving';

class ModelReferral extends Component {
    constructor(props) {
        super(props);
        this.state = {
            messageError: null,
            saved: false,
            error: false,
            id_referral: null,
            name: null,
            split: null,
            coins: null,
            modelos: [],
            status: 1
        }
        this.saveSplitCoins = this.saveSplitCoins.bind(this);
    }
    async componentDidMount() {
        await this.setState({ id_referral: this.props.id_referral });
    }
    reloadReferral = async () => {
        //this.getAllDataReferral();
    }
    saveSplitCoins = async (e, index) => {  
        await this.setState({ saved: false, error: false });
        let send = true;
        let id_referral = this.props.id_referral;
        let id_model = this.state.modelos[index].id_model;
        let split = this.state.modelos[index].split;
        let coins = this.state.modelos[index].split_coins;
        if (this.state.modelos && this.state.modelos.lenght && this.state.modelos[index].split) {
            split = this.state.modelos[index].split;
        }
        if (this.state.modelos && this.state.modelos.lenght && this.state.modelos[index].split) {
            coins = this.state.modelos[index].split_coins;
        }
        if (split === "" || isNaN(split) || split < 0 || split > 100) {
            send = false;
        }
        else {
            document.getElementById("currentSplitInput").classList.remove("is-invalid");
        }
        if (coins === "" || isNaN(coins) || coins < 0 || coins > 100) {
            send = false;
        }
        else {
            document.getElementById("currentCoinsInput").classList.remove("is-invalid");
        }   
        if (send) {
            document.getElementById("modalSavingReferral").classList.add("show");
            const data = {
                id_referral: id_referral,
                id_model: id_model,
                split: split,
                coins: coins
            }
            const saved = await axios.post('/api/referral/modelsInsert', data).then(resp => {
                return resp.data;            
            });
            if (saved === false) {
                await this.setState({ saved: true, error : false })               
            } else {
                await this.setState({ error: true, saved : false, messageError: `La suma de los porcentajes excede el 100%, 
                en caso de que la suma de los referidos no sea mayor que 100 consulta los porcentajes en el detalle de la modelo.`})
            }
        }
    }
    getModelReferral = async () => {
        await axios.get("/api/referral/models/" + this.props.id_referral).then(async resp => {
            await this.setState({ modelos: resp.data })
            if (this.state.modelos.length === 0) {
                return (<tr><td colSpan={6} className="text-center">No hay modelos</td></tr>)
            }
        });
    };
    changeHandler(e, index) {
        let value = e.target.value;
        let modelos = [...this.state.modelos];
        modelos[index].split = value;
        modelos[index].error = true;
        this.setState({ modelos: modelos });
    }
    changeHandlerCoins(e, index) {
        let value = e.target.value;
        let modelos = [...this.state.modelos];
        modelos[index].split_coins = value;
        modelos[index].error = true;
        this.setState({ modelos: modelos });
    }

    showModel() {
        if (this.state.modelos.length === 0) {
            return (<tr><td colSpan={6} className="text-center">No hay modelos</td></tr>)
        }
        const modelos = [...this.state.modelos];
        return modelos.map((item, index) => {
            return (
                <tr key={index}>
                    <td> {/*eslint-disable-next-line */}
                        <a id="currentIdModel" href="#">
                            {item.id_model}
                        </a>
                    </td>
                    <td>
                        <a id="currentUsername" href={"/models/model/ " + item.id_model}>
                            {item.username}
                        </a>
                    </td>
                    <td><a id="currentHostname" href={"/models/model/" + item.id_model}>{item.hostname}</a></td>
                    {(modelos[index].split === "" || isNaN(modelos[index].split) || modelos[index].split < 0 || modelos[index].split >= 101)
                        ? <td><input id="currentSplitInput" value={item.split} onChange={(e) => this.changeHandler(e, index)} placeholder="Split" className="form-control" type="number" step="0.1" />
                            <span className="text-danger text-small"> Inválido</span></td>
                        :
                        <td><input id="currentSplitInput" value={item.split} onChange={(e) => this.changeHandler(e, index)} placeholder="Split" className="form-control" type="number" step="0.1" /></td>
                    }
                    {(modelos[index].split_coins === "" || isNaN(modelos[index].split_coins) || modelos[index].split_coins < 0 || modelos[index].split_coins >= 101)
                        ? <td><input id="currentCoinsInput" value={item.split_coins} onChange={(e) => this.changeHandlerCoins(e, index)} placeholder="Coins" className="form-control" type="number" step="0.1" />
                            <span className="text-danger text-small"> Inválido</span></td>
                        :
                        <td><input id="currentCoinsInput" value={item.split_coins} onChange={(e) => this.changeHandlerCoins(e, index)} placeholder="Coins" className="form-control" type="number" step="0.1" /></td>
                    }
                    <td>
                        <ul className="list-inline">
                            <li className="list-inline-item">
                                <button type="button" className="btn btn-newModel btn-sm btn-icon" onClick={(e) => this.saveSplitCoins(e, index)}>Guardar <span
                                    className="fas fa-save"></span>
                                </button>
                            </li>
                        </ul>
                    </td>
                </tr>
            )
        })
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.id_referral !== this.props.id_referral) {
            this.getModelReferral();
        }
    }
    render() {
        return (
            <div className="">
                <div className="text-white head-table">
                    Modelos del referido
                </div>
                <div className="card">
                    <div className="card-body">
                        <div className="table-responsive-xl">
                            <table className="table table-stripped table-bordered">
                                <thead>
                                    <tr className="table-danger">
                                        <th>ID</th>
                                        <th>Nombre</th>
                                        <th>Hostname</th>
                                        <th>Split</th>
                                        <th>Monedas</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.showModel()}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <ModalNewModelReferral reloadReferrals={this.reloadReferrals}
                    getModelReferral={this.getModelReferral}
                    updateReferral={this.props.updateReferral.bind(this)}
                    updateModel={this.props.updateModel}
                    id_referral={this.props.id_referral}
                />
                <ModalSaving idModal="modalSavingReferral" saved={this.state.saved} 
                    error={this.state.error} messageError={this.state.messageError} 
                />
            </div>

        )
    }
}
export default ModelReferral;