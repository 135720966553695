import React, { Component } from 'react';
import axios from "axios";
import ModalSaving from "../../../ModalSaving";
import DatePicker from "react-datepicker/es";
import moment from "moment";

class ModalResetPromo extends Component {
    constructor(){
        super();
        this.state = {
            picode : false,
            isActive : false,
            saved : false,
            error: false,
            startDate : moment().add(1, 'hours').utc().format("YYYY-MM-DDTHH:00"),
            endDate : moment().add(1, 'hours').add(1,'days').utc().format("YYYY-MM-DDTHH:00")
        };
        this.resetPromo=this.resetPromo.bind(this);
    }
    componentDidMount(){
    }
    setDate = async (date, end = false) => {
        console.log(date.UTC, moment(date));
        if(end){
            this.setState({endDate : moment(date).format("YYYY-MM-DDTHH:mm")});
        }
        else{
            this.setState({startDate : moment(date).format("YYYY-MM-DDTHH:mm")});
            if(moment(this.state.endDate).isBefore(moment(date))){
                this.setState({endDate : moment(date).add(1, 'days').format("YYYY-MM-DDTHH:mm")});
            }
        }
    }
    resetPromo = async () => {
        await this.setState({saved : false, error: false});
        let send = true;
        if(send){
            document.getElementById("ModalResetPromoCancelBtn").click();
            document.getElementById("modalSaveResetPromo").classList.add("show");
            const formData = new FormData();
            formData.append("idModel",this.props.model);
            formData.append("promo",this.props.promo);
            formData.append("start", this.state.startDate);
            formData.append("end", this.state.endDate);
            const saved = await axios.put("/api/membership/promo/reset/"+this.props.membership.id, formData).then(resp => {
                return resp.data;
            });
            if (saved.Error === undefined) {
                await this.setState({saved : true})
                await this.props.reloadMemberships();
            } else {
                await this.setState({error : true});
            }
        }
    }
    render(){
        return (
            <div>
                <div className="modal" id="ModalResetPromo" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header-section">
                                <h5 className="modal-title sectionTitle">Ativar Promocion</h5>
                                <button type="button" className="close modalClose" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body modelBody">
                                <p>¿Realmente quiere activar esta promoci&oacute;n?</p>
                                <form id="setPromoForm">
                                    <p className="text-info text-sm"><i className="fa fa-info"></i>&nbsp;Recuerda insertar las horas en tiempo UTC</p>
                                    <div className="form-group">
                                        <label htmlFor="" className="control-label">
                                            Fecha de inicio
                                        </label>
                                        <DatePicker selected={new Date(this.state.startDate)}
                                                    onChange={date => this.setDate(date)}
                                                    showTimeSelect
                                                    timeFormat="HH:mm"
                                                    timeIntervals={15}
                                                    minDate={new Date()}
                                                    timeCaption="time"
                                                    dateFormat="MMMM d, yyyy h:mm aa"
                                                    className="form-control"/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="" className="control-label">
                                            Fecha de termino
                                        </label>
                                        <DatePicker selected={new Date(this.state.endDate)}
                                                    onChange={date => this.setDate(date, true)}
                                                    showTimeSelect
                                                    timeFormat="HH:mm"
                                                    timeIntervals={15}
                                                    minDate={new Date(this.state.startDate)}
                                                    timeCaption="time"
                                                    dateFormat="MMMM d, yyyy h:mm aa"
                                                    className="form-control"/>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer modalFooter">
                                <button type="button" className="btn btn-modalClose" id="ModalResetPromoCancelBtn" data-dismiss="modal">Cancelar</button>
                                <button type="button" className="btn btn-modalSuccess" onClick={this.resetPromo}>Continuar</button>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalSaving saved={this.state.saved} error={this.state.error} idModal="modalSaveResetPromo"/>
            </div>
        )
    }
}
export default ModalResetPromo;