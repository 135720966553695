import React, { Component } from 'react';
import axios from "axios";

import Header from '../../../components/Layout/Header';
import Sidebar from '../../../components/Layout/Sidebar';
import Footer from '../../../components/Layout/Footer';
import ModalWarning from '../../../components/Layout/ModalWarning';
import ModalUpdateManager from '../../../components/Manager/ModalUpdateManager';
import ModalBanManager from '../../../components/Manager/ModalBanManager';
import ModalActivateManager from "../../../components/Manager/ModalActivateManager";

class ManagerDetail extends Component {
    constructor(){
        super();
        this.state = {
            id : null,
            name : null,
            mail : null,
            username : null,
            modelos : [],
            status : 1
        }
        this.reloadManager = this.reloadManager.bind(this);
    }
    async componentDidMount() {
        //console.log("inicio");
        await this.props.isAbleToAccess(3,1,3);
        await this.setState({id : this.props.manager});
        this.props.changeView("manager");
        await this.getManager();
        await this.getModels();
    }
    reloadManager = async () => {
        this.getManager();
    }
    getManager = async () => {
        await axios.get('/api/manager/'+this.state.id)
            .then( async resp => {
                await this.setState({
                    name : resp.data[0].name,
                    mail : resp.data[0].email,
                    username : resp.data[0].username,
                    status : resp.data[0].status
                })
            });
    };
    getModels = async () => {
        await axios.get("/api/manager/models/"+this.state.id).then(async resp => {
            await this.setState({modelos : resp.data})
        });
    };
    pintarModelos (){
        if(this.state.modelos.length === 0){
            return (<tr><td colSpan={5} className="text-center">No hay modelos</td></tr>)
        }
        const modelos = [...this.state.modelos];
        return modelos.map((item,index) => {
            return (
                <tr key={index}>
                    <td>{item.id_model}</td>
                    <td><a href={"/models/model/"+item.id_model}>{item.alias}</a></td>
                    <td><a href={"/models/model/"+item.id_model}>{item.username}</a></td>
                    <td>{item.email}</td>
                    <td>{item.hostname}</td>
                </tr>
            )
        })
    }
    render () {
        return(
            <div className="container">
                <Header username={this.props.username}/>
                <div className="content">
                    <Sidebar permisos={this.props.permisos} view={this.props.view}/>
                    <div className="main-content" id="page">
                        <h2>Managers</h2>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="/Dashboard">Home</a>
                                </li>
                                <li className="breadcrumb-item">
                                    <a href="/Managers">Managers</a>
                                </li>
                                <li className="breadcrumb-item active">
                                    {(this.state.name === null) ? "" : this.state.name}
                                </li>
                            </ol>
                        </nav>
                        <div className="row">
                            <div className="col-12">

                                    <div className="text-right spacer">
                                        <ul className="list-inline">
                                            {(this.props.accessLevel["u"] !== undefined && this.props.accessLevel["u"] >= 1) ?
                                                <li className="list-inline-item"><button className="btn btn-primary" data-toggle="modal" data-target="#ModalUpdateManager" type="button" >Cambiar Nombre</button></li> : ""}
                                            {(this.props.accessLevel["d"] !== undefined && this.props.accessLevel["d"] >= 3) ?
                                                <li className="list-inline-item"><button className="btn btn-danger" data-toggle="modal" data-target={ (this.state.status === 1) ? "#ModalBanManager" : "#ModalActivateManager"} type="button" >{(this.state.status === 1) ? "Banear Manager" : "Activar Manager" }</button></li>  : ""}
                                        </ul>

                                    </div>
                                <div className="card">
                                    <div className="card-header bg-danger text-white">
                                        Modelos
                                    </div>
                                    <div className="card-body">
                                        <div className="table-responsive-xl">
                                            <table className="table table-stripped table-bordered">
                                                <thead>
                                                <tr className="table-danger">
                                                    <th>ID</th>
                                                    <th>Nombre</th>
                                                    <th>Usuario</th>
                                                    <th>Mail</th>
                                                    <th>Host</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {this.pintarModelos()}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
                <ModalWarning title={"Algo salio mal"} message={"No se pudo guardar el manager"} />
                <ModalUpdateManager reloadManager={this.reloadManager} manager={this.state.id} name={this.state.name}/>
                {(this.state.status === 1) ?
                <ModalBanManager reloadManager={this.reloadManager} manager={this.state.id}/> :
                    <ModalActivateManager reloadManager={this.reloadManager} manager={this.state.id}/> }
            </div>
        )
    }
}
export default ManagerDetail;