import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';
import MailPromoTemplate from '../MailPromoTemplate';
import ModalNewPromo from '../ModalNewPromo';

class PromoMail extends Component{
    constructor(props){
        super(props);
        this.state={
            sentPromo: false,
            uploadProgress: 0,
            selectedFile: [],
            promoDetails: [],
            messageUpload: '',
            apikeyTest: '',
            codeTest: '',
            hostTest: '',
            imgPromoTest: '',
            logoTest: '',
            nameTest: '',
            nameModelTest: '',
            storageTest: '',
            storageUrlTest: '',
            subject: '',
            titleMail: '',
            fisrtLine: '',
            secondLine: '',
            thirdLine: '',
            showPreview: false
        }
    }
    componentDidMount() {
        this.getPromoImage();
    }
    getPromoImage = async () => {
         const promo = await axios.post('/api/get/mail/promo',{idModel:this.props.idModel})
            .then(({data})=>{return data});
         if(promo[0].createdTime && promo[0].promoStatus){
            const addDate = moment(promo[0].createdTime).add('5', 'days');
            if(addDate.format('L')<=moment().format('L')){
                this.setState({sentPromo : false});
            }
            else{
                const message ='Se han enviado: '+ promo[0].totalSent+ ' correos, podras enviar correos de promoción hasta '+addDate.format('L');
                this.setState({sentPromo : true, messageUpload:message});
            }
            console.log(addDate.format('L'));
         }
         this.setState({promoDetails: promo});
         // console.log(this.state.promoDetails)
    };
    previewPromo = async ()=>
    {   
        this.setState({
            imgPromoTest: '',
            showPreview: false
        });
        const subject = document.getElementById('subjectMail').value;
        const titleMail = document.getElementById('titleMail').value;
        const fisrtLine = document.getElementById('contentOne').value;
        const secondLine = document.getElementById('contentTwo').value;
        const thirdLine = document.getElementById('contentThree').value;
        const promoDetails = await axios.post('/api/send/promo/preview', {
            idModel: this.props.idModel,
            promoDetails: this.state.promoDetails
        })
        .then(resp=>{
            return resp.data;
        })
        .catch((err)=>{
            console.log(err);
        })
        await this.setState({
            hostTest: promoDetails.host,
            imgPromoTest: promoDetails.imgPromo,
            logoTest: promoDetails.logo,
            nameTest: promoDetails.name,
            storageTest: promoDetails.storage,
            storageUrlTest: promoDetails.storageUrl,
            subject: subject,
            fisrtLine: fisrtLine,
            secondLine: secondLine,
            thirdLine: thirdLine,
            titleMail: titleMail,
            showPreview: true
        });
    }
    sendMailTest = async ()=>
    {
        const subject = document.getElementById('subjectMail').value;
        const titleMail = document.getElementById('titleMail').value;
        const fisrtLine = document.getElementById('contentOne').value;
        const secondLine = document.getElementById('contentTwo').value;
        const thirdLine = document.getElementById('contentThree').value;
        const offsetUsers = document.getElementById('quantityMail').value;
        this.setState({sentPromo : false });
        await axios.post('/api/send/promo/mail', {
            idModel: this.props.idModel,
            promoDetails: this.state.promoDetails,
            receiver: 'test',
            subject: subject,
            titleMail: titleMail,
            fisrtLine: fisrtLine,
            secondLine: secondLine,
            thirdLine: thirdLine,
            offsetUsers: offsetUsers,
        })
        .then(resp=>{
            return resp.data
        });
    };
    sendMailPromo = async ()=>
    {
        const subject = document.getElementById('subjectMail').value;
        const titleMail = document.getElementById('titleMail').value;
        const fisrtLine = document.getElementById('contentOne').value;
        const secondLine = document.getElementById('contentTwo').value;
        const thirdLine = document.getElementById('contentThree').value;
        this.setState({sentPromo : true });
        await axios.post('/api/send/promo/mail', {
            idModel: this.props.idModel,
            promoDetails: this.state.promoDetails,
            receiver: 'user',
            subject: subject,
            titleMail: titleMail,
            fisrtLine: fisrtLine,
            secondLine: secondLine,
            thirdLine: thirdLine
        })
        .then(resp=>{
            return resp.data
        });
    };
    saveImagePromo = async (e) =>{
        e.preventDefault();
        // console.log(e.target.files[0]);
        const file = e.target.files[0];
        if (file !== undefined) {
            const dataURL = await URL.createObjectURL(file);
            const selectedFile=[{dataUrl: dataURL, file: file}];
            this.setState({selectedFile: selectedFile});
            const formData = new FormData();
            formData.append('file', file, file.name);
            formData.append('storage', this.state.promoDetails[0].storage);
            formData.append('idModel', this.props.idModel);
            axios.post('/api/save/image/promo', formData, {
                onUploadProgress: progressEvent => {
                    let progress= ((progressEvent.loaded / progressEvent.total)*100).toFixed(2);
                    this.setState({uploadProgress: progress});
                }
            })
            .then((resp)=>{
                return resp;
            })
            .catch((err)=>{
                console.log(err);
            })
        }
    };

    renderObject (){
        if(!this.state.sentPromo){
            return(
                <div className='divCard'>
                    {/*<div className="form-group col-12 col-sm-10 col-lg-7">
                                            <label>Cantidad de usuarios a enviar</label>
                                            <input className="form-control" type="number" id='quantityMail' placeholder='Número de correos'/>
                                        </div>*/}
                    <div className="form-group col-12 col-sm-10 col-lg-7">
                        <label>Asunto</label>
                        <input className="form-control" type="text" id='subjectMail' placeholder='Asunto del correo'/>
                    </div>
                    <div className="form-group col-12 col-sm-10 col-lg-7">
                        <label>Contenido del mensaje</label>
                        <div className="input-group">
                            <div className="lineSt">T&iacute;tulo</div>
                            <input className="form-control" type="text" id='titleMail' defaultValue={'Aprovecha la Oferta'} placeholder='Ej. Aprovecha la Oferta'/>
                        </div>
                        <div className="input-group">
                            <div className="lineSt">L&iacute;nea 1</div>
                            <input className="form-control" type="text" id='contentOne' defaultValue={'Chatea en privado'} placeholder='Ej. Chatea en privado con...'/>
                        </div>
                        <div className="input-group">
                            <div className="lineSt">L&iacute;nea 2</div>
                            <input className="form-control" type="text" id='contentTwo' defaultValue={'Contenido exclusivo que no econtrarás en otro lugar'} placeholder='Ej. Contenido exclusivo que no econtrarás en otro lugar'/>
                        </div>
                        <div className="input-group">
                            <div className="lineSt">L&iacute;nea 3</div>
                            <input className="form-control" type="text" id='contentThree' defaultValue={'1 dólar por 24 horas'} placeholder='Ej. 1 dólar por 24 horas'/>
                        </div>                        
                    </div>
                    {this.state.selectedFile.length===1
                        ?
                        <div className='text-center' style={{'fontSize': 12,'color': '#c4c4c4'}}>
                            <img style={{maxWidth: "80px"}}  className="rounded mx-auto img-fluid" src={this.state.selectedFile[0].dataUrl} alt="promo"/> imagen seleccionada
                        </div>
                    : null }
                    <div className="form-group">
                        <div className="custom-file addFile-adjust">
                            <input onChange={(e)=>this.saveImagePromo(e)} className="custom-file-input" type="file" name="imagePromo"/>
                            <label className="custom-upload-promo" htmlFor="imagePromo">
                                <i className="fas fa-cloud-upload-alt"></i>&ensp;Add image file
                            </label>
                        </div>
                    </div>
                    {this.state.showPreview ?
                        <MailPromoTemplate subject={this.state.subject} hostTest={this.state.hostTest} 
                            logoTest={this.state.logoTest} nameTest={this.state.nameTest} 
                            storageTest={this.state.storageTest} storageUrlTest={this.state.storageUrlTest} 
                            imgPromoTest={this.state.imgPromoTest} titleMail={this.state.titleMail}
                            fisrtLine={this.state.fisrtLine} secondLine={this.state.secondLine} 
                            thirdLine={this.state.thirdLine} />
                    : null }
                    <button type="button" className='btn btn-modalSuccess btnSpace' onClick={this.previewPromo}>
                        Vista Previa
                    </button>
                    <button type="button" className='btn btn-modalSuccess btnSpace' onClick={this.sendMailTest}>
                        Enviar Test
                    </button>
                    <button type="button" className='btn btn-modalSuccess btnSpace' onClick={this.sendMailPromo}>
                        Enviar Promo Mail
                    </button>
                    {/*<button type="button" className='btn btn-newPromo btnSpace' data-toggle="modal" data-target="#ModalNewPromo">
                        Crear nueva promoción
                    </button>*/}
                    <ModalNewPromo />
                </div>
            )
        }
        else{
            return (
                this.state.messageUpload
            )
        }
    }
    render() {
        return(
            <div className="">
                <div className="text-white head-table">
                    Promo Mail
                </div>
                {this.renderObject()}
            </div>
        )
    }
}
export default PromoMail;