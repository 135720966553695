import React, { Component } from 'react';
import axios from "axios";
import ModalSaving from "../../../ModalSaving";

class ModalDisableBonus extends Component {
    constructor(){
        super();
        this.state = {
            saved : false,
            error : false
        };
        this.disableBonus=this.disableBonus.bind(this);
    }
    disableBonus = async () => {
        await this.setState({saved : false, error: false});
        document.getElementById("ModalDisableBonusCancelBtn").click();
        document.getElementById("modalSaveDisableBonus").classList.add("show");
        const data = {
            id: this.props.disableBonus,
            model: this.props.model
        }
        const saved = await axios.put('/api/bonus/disable', data)
            .then( async resp => {
                return resp.data;
            });
        if (saved.Error === undefined) {
            await this.setState({saved : true})
            await this.props.reloadBonus();
        } else {
            await this.setState({error : true});
        }
    }
    render(){
        return (
            <div>
                <div className="modal" id="ModalDisableBonus" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header-section">
                                <h5 className="modal-title sectionTitle">Inhabilitar Bonus</h5>
                                <button type="button" className="close modalClose" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body modelBody">
                                <p>¿Realmente desea desactivar este bonus?</p>
                            </div>
                            <div className="modal-footer modalFooter">
                                <button type="button" className="btn btn-modalClose" id="ModalDisableBonusCancelBtn" data-dismiss="modal">Cancelar</button>
                                <button type="button" className="btn btn-modalSuccess" onClick={this.disableBonus}>Guardar</button>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalSaving saved={this.state.saved} error={this.state.error} idModal="modalSaveDisableBonus"/>
            </div>
        )
    }
}
export default ModalDisableBonus;