import React, { Component } from 'react';
import axios from "axios";
import Header from '../../components/Layout/Header';
import Sidebar from '../../components/Layout/Sidebar';
import Footer from '../../components/Layout/Footer';
import ModalWarning from '../../components/Layout/ModalWarning';
import ModalNewManager from "../../components/Manager/ModalNewManager";
import './Managers.css';
class Managers extends Component {
    constructor(){
        super();
        this.state={
            managers: [],
            path: "Home / ",
            view: "Managers"
        };
        this.reloadManagers = this.reloadManagers.bind(this);
    }
    async componentDidMount() {
        //console.log("inicio");
        await this.props.isAbleToAccess(3,1,1);
        await this.getManagers();
        this.props.changeView("manager");
    }
    getManagers = async () => {
        await axios.get("/api/managers/").then(async resp => {
            await this.setState({managers : resp.data})
        });
    };
    reloadManagers = async () => {
        this.getManagers();
    }
    pintarManagers (){
        if(this.state.managers.length === 0){
            return (<tr><td colSpan={4} className="text-center">No hay managers</td></tr>)
        }
        const modelos = [...this.state.managers];
        return modelos.map((item,index) => {
            return (
                <tr key={index}>
                    <td>{item.id}</td>
                    <td><a href={"/managers/manager/"+item.id}>{item.name}</a></td>
                    <td><a href={"/managers/manager/"+item.id}>{item.username}</a></td>
                    <td>{item.email}</td>
                </tr>
            )
        })
    }

    render () {
        return(
            <div className="container">
                <Header username={this.props.username} path={this.state.path} view={this.state.view}/>
                <div className="content">
                    <Sidebar permisos={this.props.permisos} view={this.props.view}/>
                    <div className="content-padding main-content" id="page">
                        <div className="row head-padding">
                            <div className="col-6">
                                <h4>Managers</h4>
                            </div>
                            <div className="col-6">
                                {(this.props.accessLevel["c"] !== undefined && this.props.accessLevel["c"] >= 3) ?
                                <div className="text-right spacer">
                                    <button className="btn btn-newModel" data-toggle="modal" data-target="#ModalNewManager">Nuevo Manager</button>
                                </div> 
                                : "" }
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                            <div className="text-white managerColor head-table">
                                    Managers
                                </div>
                                <div className="tableDiv table-responsive-xl tableScoll-lg">
                                    <table className="table tableDataSection">
                                        <thead>
                                        <tr className="table-primary">
                                            <th>ID</th>
                                            <th>Nombre</th>
                                            <th>Usuario</th>
                                            <th>Mail</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.pintarManagers()}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
                <ModalWarning title={"Algo salio mal"} message={"No se pudo guardar el manager"} />
                <ModalNewManager reloadManagers={this.reloadManagers}/>
            </div>
        )
    }
}
export default Managers;