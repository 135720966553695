import React, { Component } from 'react';
import "./CarouselElement";
import Icon from '../../../../iconos/Icon';

class CarouselElement extends Component{

    componentDidMount() {
        const video = document.querySelector('video');
        if(video){
            video.controls = "";
            video.addEventListener('pause', e => {
                video.controls = "";
            });
        }
    }

    renderMedia(){
        let media   = this.props.media;
        let index   = this.props.index;
        let strgURL = "https://tpcontent.com/private-social-media.appspot.com/News/";
        let img     = strgURL + media.imageName;
        let video   = strgURL + media.videoName;
        if(media.videoName !== null) {
            this.props.clicVideo[index] = React.createRef();
            return(
                <video
                    onClick={this.props.playVideo}
                    preload="metadata"
                    poster={img}
                    className="responsive-video carouselVideo no-select"
                    controlsList="nodownload"
                    playsInline
                >
                    <source
                        src={video}
                        type="video/mp4"
                    />
                    Your browser does not support the video tag.
                </video>
            )
        }
        else if(media.imageName !== null && media.videoName === null) {
            return(
                <img
                    alt="news media"
                    data-lazy={img}
                    src={img}
                    className="responsive-img"
                />
            )   
        }
    }

    render(){
        let media = this.props.media;
        return(
            <div>
                {this.renderMedia()}
                {media.videoName !== null ?
                    <Icon
                        width="5em"
                        height="5em"
                        iconName="PlayIcon"
                        className="play-video"
                        color="rgba(255,255,255,0.5)"
                        onClickFunction={this.props.playVideo}
                    />
                : null }
            </div>
        )
    }
}

export default CarouselElement;
