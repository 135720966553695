import React, { Component } from 'react';
import axios from "axios";

import Header from '../../../components/Layout/Header';
import Sidebar from '../../../components/Layout/Sidebar';
import Footer from '../../../components/Layout/Footer';
import AccessModules from '../../../components/Configuration/AccessModules';
class Access extends Component {
    constructor(){
        super();
        this.state={
            permisos: [],
            path: "Home / Configuración / ",
            view: "Permisos"
        };
        this.reloadPermisos = this.reloadPermisos.bind(this);
    }
    async componentDidMount() {
        //console.log("inicio");
        await this.props.isAbleToAccess(2,3,1);

        await this.getPermisos();
        this.props.changeView("config");
    }
    reloadPermisos = async () => {
        this.getPermisos();
    }
    getPermisos = async () => {
        await axios.get("/api/access/").then(async resp => {
            await this.setState({permisos : resp.data})
        });
    };
    drawCards (){
        if(this.state.permisos.length === 0){
            return (
                <div>
                </div>
            )
        }
        const permisos = [...this.state.permisos];
        return permisos.map((item,index) => {
            return (
                <div key={index}>
                    <div className="text-white head-table">
                        Permisos {item.name}
                    </div>
                    <div className='divCard'>
                        <AccessModules reloadPermisos={this.reloadPermisos} access={item}/>
                    </div>
                </div>
            )
        })
    }

    render () {
        return(
            <div className="container">
                <Header username={this.props.username}  path={this.state.path} view={this.state.view}/>
                <div className="content">
                    <Sidebar permisos={this.props.permisos} view={this.state.view}/>
                    <div className="content-padding main-content" id="page">
                        <div className="head-padding">
                            <h4>Permisos</h4>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                {this.drawCards()}
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        )
    }
}
export default Access;