import React, { Component } from 'react';
import axios from "axios";
import Header from '../../../components/Layout/Header';
import Sidebar from '../../../components/Layout/Sidebar';
import Footer from '../../../components/Layout/Footer';
import General from '../../../components/Model/Detail/General';
import StorageAnalytics from '../../../components/Model/Detail/StorageAnalytics';
import Contenido from '../../../components/Model/Detail/Contenido';
import Metadata from '../../../components/Model/Detail/Metadata';
import Secciones from '../../../components/Model/Detail/Secciones';
import Design from '../../../components/Model/Detail/Design';
import Crop from '../../../components/Model/Detail/Cropper';
import piexif from 'piexifjs';
import Promise from 'bluebird';
import './modelDetail.css';
import getCroppedImg from "../../../components/Model/Detail/Cropper/cropImage";
import PromoMail from "../../../components/Model/Detail/PromoMail";
import ModalDisableWebsite from "../../../components/Model/Detail/ModalDisableWebsite";
import ModalReactiveWebsite from "../../../components/Model/Detail/ModalReactiveWebsite";
import ModalError from "../../../components/Model/Detail/ModalError";
import ModalSaving from "../../../components/ModalSaving";

class ModelDetail extends Component {
    constructor(){
        super();
        this.state= {
            id: null,
            name: null,
            hostname: null,
            username: null,
            mail: null,
            status: null,
            config: {
                "color": {
                    "chat": {
                        "userChat": "#404040",
                        "modelChat": "#1e88e5",
                        "background": "#292929",
                        "bottonText": "#fff",
                        "tittleText": "#fff",
                        "userChatText": "#fff",
                        "modelChatText": "#000",
                        "titleAndBotton": "#404040"
                    },
                    "modal": "#282828",
                    "third": "#404040",
                    "navbar": "#232323",
                    "sideBar": "#282828",
                    "modalText": "#d8d3d3",
                    "principal": "#151515",
                    "secondary": "#1e88e5",
                    "thirdText": "#fff",
                    "navbarText": "#c4c4c4",
                    "navbarHover": "#1565c0",
                    "sideBarText": "#c4c4c4",
                    "principalText": "#d8d3d3",
                    "secondaryText": "#000"
                }
            },
            meta: {"theme_color": "#000"},
            section: null,
            section_images: null,
            language: "es",
            languageName : "Spanish",
            imageSrc: "",
            shapeCrop: 'round',
            aspect: 23 / 28,
            zoom: 1,
            crop: {x: 0, y: 0},
            setZoom: 1,
            croppedAreaPixels: null,
            croppedImage: null,
            croppedArea: null,
            nameImputFile: null,
            updloadedFiles: [],
            sendGrid : null,
            manager : null,
            managerName : "",
            saved : false,
            error : false,
            path: "Home / Modelos / ",
            view: "",
            fee : 0,
            manager_fee : 0,
            wire_fee : 0
        };

        this.getImage = this.getImage.bind(this);
        this.cancelChanges = this.cancelChanges.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
        this.changeColorState = this.changeColorState.bind(this);
        this.onCropChange = this.onCropChange.bind(this);
        this.onCropComplete = this.onCropComplete.bind(this);
        this.onZoomChange = this.onZoomChange.bind(this);
        this.showCroppedImage = this.showCroppedImage.bind(this);
        this.onZoomChangeBar = this.onZoomChangeBar.bind(this);
        this.viewMemberships = this.viewMemberships.bind(this);
        this.reactiveWeb = this.reactiveWeb.bind(this);
        this.setupCoins = this.setupCoins.bind(this);
    }
    async componentDidMount() {
        await this.props.isAbleToAccess(1,1,1);
        await this.setState({id : this.props.model});
        if(this.state.id===""){
            window.location = "/";
        }
        await this.getAllDataModel();
        this.props.changeView("model");

        const name = this.state.name;
        this.setState({view : name});
    }
    async changeColorState(data,color){
        if(data==="meta"){
            await this.setState({meta : color});
        }
        else{
            await this.setState({config : color});
        }
    }
    async cancelChanges(e){
        this.getAllDataModel();
    }
    async saveChanges(e) {
        await this.setState({saved : false, error: false});
        document.getElementById("ModalSaving").classList.add("show");
        let saved = false;
        const form = document.getElementById("DataModel");
        let formData = new FormData();
        let doUpdate = true;
        if(this.props.accessLevel["u"] >= 3) {
            let modelFee = document.getElementById("EarningFee").value;
            let managerFee = document.getElementById("ManagerFee").value;
            let totalFee = parseInt(modelFee) + parseInt(managerFee);
            if (totalFee > 100 || totalFee < 0) {
                doUpdate = false
            }
        }
        let video = null;
        let storageInput = document.getElementById("StorageName");
        let nameBucket = "";
        if (storageInput !== null) {
            nameBucket = storageInput.value;
        }
        let urlBucket = "";
        if (nameBucket !== "" && doUpdate){
            doUpdate = await axios({
                method: 'post',
                url: '/api/model/beforeUpdate',
                data: "StorageName=" + nameBucket
            }).then(resp => {
                return resp.data.resp;
            });
        }
        else{
            nameBucket = (this.state.config.storage === undefined) ? "" : this.state.config.storage;
            urlBucket = (this.state.config.storageURL === undefined) ? "" : this.state.config.storageURL;
            formData.append("StorageName",nameBucket);
            formData.append("StorageURL",urlBucket);
            //doUpdate = true;
        }
        if(doUpdate) {
            formData.append("idModel",this.state.id);
            await Promise.map(form, async (element,k) => {
                if(element.tagName.toUpperCase() === "INPUT"){
                    if(element.type === "file" && nameBucket !== ""){
                        if(element.files.length>0){
                            let fileType = element.files[0].type;
                            fileType = fileType.split("/");
                            if(fileType[0] === "video"){
                                const formDataVideo = new FormData();
                                formDataVideo.append("file", element.files[0], element.value);
                                formDataVideo.append("routeBucket", "public");
                                formDataVideo.append("videoId", 0);
                                formDataVideo.append("nameBucketStorage", nameBucket);
                                video = await axios.post("https://socialtechapps.com/encoding/v3/video/model/", formDataVideo)
                                    .then( resp => {
                                        return resp.data;
                                    });
                                let currentConfig = this.state.config;
                                currentConfig["videoEntrada"] = video.videoName;
                                currentConfig["videoPreview"] = video.screenshotName;
                                formData.append("updateVideo", true);
                                this.setState({config : currentConfig});
                            }
                            else if(fileType[0] === "image"){
                                //let file = new File([this.state.updloadedFiles[element.name]],element.name + ".png", {type: "image/png", lastModified: Date.now()});
                                formData.append(element.name, this.state.updloadedFiles[element.name]);
                            }
                        }
                    }
                    else if(element.type === "checkbox"){
                        if(element.checked) {
                            formData.append(element.name, element.value);
                        }
                    }
                    else{

                        if(element.name !== "") {
                            formData.append(element.name, element.value);
                        }

                    }


                }
                if(element.tagName.toUpperCase() === "SELECT"){
                    formData.append(element.name, element.value);
                }
            }).then(async () => {
                const config = this.state.config;
                const metas = this.state.meta;
                const section_images = this.state.section_images;
                formData.append("config", JSON.stringify(config));
                formData.append("metas", JSON.stringify(metas));
                formData.append("sectionimgs", (section_images === null) ? section_images : JSON.stringify(section_images));
                await axios({
                    method: 'post',
                    url: '/api/model/update',
                    data: formData,
                    config: { headers: {'Content-Type': 'multipart/form-data' }}
                }).then(resp=>{
                    saved = true;
                });

            });
        }
        if (saved) {
            await this.setState({saved : true})
            await this.getAllDataModel();
        } else {
            await this.setState({error : true})
        }
    }

    async getImage(e){
        const file =e.target.files[0];
        const name = e.target.name;
        const img = e.target.closest(".card-body").previousSibling;
        let dataURL;

        await this.setState({
            nameImputFile : name,
            zoom: 1,
            crop: { x: 0, y: 0 }
        });

        let typeFormat = this.validateFormat(file.type);
        if (file.type.match('image/jpeg.*')) {

            let blob = await this.rotateImage(file);
            console.log(blob, 2);
            dataURL = await URL.createObjectURL(blob)
            if(name==="logo" || name==="secondLogo"){
                img.setAttribute("src",dataURL);
                this.state.updloadedFiles[name]=blob;
            }
            else {
                if (name === "principalImage") {
                    this.setState({
                        shapeCrop: 'round',
                        aspect: 3 / 3,
                    })
                } else if (name.search("icon") >= 0) {
                    this.setState({
                        shapeCrop: 'rect',
                        aspect: 3 / 3,
                    })
                } else {
                    this.setState({
                        shapeCrop: 'rect',
                        aspect: 23 / 28,
                    })
                }
                await this.setState({imageSrc: dataURL})
                const cropper = document.getElementById("cropperModal")
                cropper.style.display = "block";
            }
        }
        else{
            if(typeFormat==="video") {
                dataURL = await URL.createObjectURL(file);
                //await this.setState({imageSrc : dataURL})
                img.querySelector("source").setAttribute("src",dataURL);
                img.setAttribute("poster","");
                img.load();
                //img.play();
            }
            else if(typeFormat === "image"){
                dataURL = await URL.createObjectURL(file);
                console.log(dataURL);
                if(name==="logo" || name==="secondLogo"){
                    img.setAttribute("src",dataURL);
                    this.state.updloadedFiles[name]=file;
                }
                else {
                    if (name === "principalImage") {
                        this.setState({
                            shapeCrop: 'round',
                            aspect: 3 / 3,
                        })
                    } else if (name.search("icon") >= 0) {
                        this.setState({
                            shapeCrop: 'rect',
                            aspect: 3 / 3,
                        })
                    } else {
                        this.setState({
                            shapeCrop: 'rect',
                            aspect: 23 / 28,
                        })
                    }
                    await this.setState({imageSrc: dataURL})
                    const cropper = document.getElementById("cropperModal");
                    cropper.style.display = "block";
                }
                //img.setAttribute("src",dataURL);
                //document.getElementById("showModal").click();
            }
        }

    }
    validateFormat(format){
        const mediaType = format.split('/')[0];
        if(mediaType === 'video' || mediaType === 'image')
            return mediaType;
        return false;
    }
    async rotateImage(file) {
        let reader = new FileReader();
        reader.onload = async (e) => {
            let exif = piexif.load(e.target.result);
            let image = new Image();
            image.onload = async () => {
                var orientation = exif["0th"][piexif.ImageIFD.Orientation];

                var canvas = document.getElementById("canvasToRotate");
                canvas.width = image.width;
                canvas.height = image.height;
                var ctx = canvas.getContext("2d");
                var x = 0;
                var y = 0;
                ctx.save();
                if (orientation === 2) {
                    x = -canvas.width;
                    ctx.scale(-1, 1);
                } else if (orientation === 3) {
                    x = -canvas.width;
                    y = -canvas.height;
                    ctx.scale(-1, -1);
                } else if (orientation === 4) {
                    y = -canvas.height;
                    ctx.scale(1, -1);
                } else if (orientation === 5) {
                    canvas.width = image.height;
                    canvas.height = image.width;
                    ctx.translate(canvas.width, canvas.height / canvas.width);
                    ctx.rotate(Math.PI / 2);
                    y = -canvas.width;
                    ctx.scale(1, -1);
                } else if (orientation === 6) {
                    canvas.width = image.height;
                    canvas.height = image.width;
                    ctx.translate(canvas.width, canvas.height / canvas.width);
                    ctx.rotate(Math.PI / 2);
                } else if (orientation === 7) {
                    canvas.width = image.height;
                    canvas.height = image.width;
                    ctx.translate(canvas.width, canvas.height / canvas.width);
                    ctx.rotate(Math.PI / 2);
                    x = -canvas.height;
                    ctx.scale(-1, 1);
                } else if (orientation === 8) {
                    canvas.width = image.height;
                    canvas.height = image.width;
                    ctx.translate(canvas.width, canvas.height / canvas.width);
                    ctx.rotate(Math.PI / 2);
                    x = -canvas.height;
                    y = -canvas.width;
                    ctx.scale(-1, -1);
                }
                await new Promise(resolve => {ctx.drawImage(image, x, y);});
                ctx.restore();


            };

            await new Promise( resolve => {image.src = e.target.result;});
        };
        return await new Promise( resolve => {
            reader.readAsDataURL(file);
            setTimeout(()=>{
                const canvas = document.getElementById("canvasToRotate");

                canvas.toBlob( async (blob) => {
                    return  resolve(blob)
                });
            },1200);
        });

    }
    onCropChange = crop => {
        this.setState({ crop })
    }
    onCropComplete = (croppedArea, croppedAreaPixels) => {
        //console.log(croppedArea, croppedAreaPixels,'cropped pixeles');
        this.setState({ croppedAreaPixels })
    }
    onZoomChange = zoom => {
        this.setState({ zoom })
    }
    showCroppedImage = async () => {
        const croppedImage = await getCroppedImg(
            this.state.imageSrc,
            this.state.croppedAreaPixels
        );
        const inputFile=document.getElementsByName(this.state.nameImputFile)[0];
        const img = inputFile.closest(".card-body").previousSibling;
        //const reader = new FileReader();
        let file = new File([croppedImage],this.state.nameImputFile+".png",{type: "image/png", lastModified: Date.now()});
        //file = URL.createObjectURL(file);
        this.state.updloadedFiles[this.state.nameImputFile]=file;

        img.setAttribute("src",URL.createObjectURL(croppedImage));
        document.getElementById("cropperModal").style.display="none";

    }
    onZoomChangeBar = (e, zoom)=>{
        e.preventDefault();
        this.setState({ zoom:e.target.value })
    }
    viewMemberships = () => {
        window.location="/Models/model/" + this.state.id + "/memberships";
    }
    disable = () => {
        window.location="/Models/model/" + this.state.id + "/memberships";
    }
    setupCoins = () => {
        window.location="/Models/model/" + this.state.id + "/coins";
    }
    reactiveWeb = async () => {
        const hostnameVal = document.getElementById('Hostname').value;
        const hostnameInput = document.getElementById('Hostname');
        const modalReactive = document.getElementById("ModalReactiveWebsite");
        const modalError = document.getElementById("ModalError");
        if (hostnameVal === null || hostnameVal === "") {
            hostnameInput.classList.add("is-invalid");
            modalError.classList.add("show");
            modalReactive.classList.remove("show");
        } else {
            this.setState({hostname: hostnameVal});
            hostnameInput.classList.remove("is-invalid");
            modalError.classList.remove("show");
            modalReactive.classList.add("show");


        }
    }
    getAllDataModel = async () => {
        await axios.get('/api/model/detail/'+this.state.id)
            .then( async resp => {
                if(!resp.data){
                    window.location = "/";
                }
                else{
                    await this.setState({
                        name: resp.data.name,
                        hostname: resp.data.hostname,
                        username: resp.data.username,
                        mail: resp.data.mail,
                        language: (resp.data.idioma === null) ? "es" : resp.data.idioma,
                        section : (resp.data.sections !== null) ? resp.data.sections.replace(/[ [\]]/g,"").split(",") : ["6"],
                        sendGrid: resp.data.sendGrid,
                        manager: resp.data.manager_id,
                        managerName: resp.data.manager_name,
                        languageName : (resp.data.idiomaName === null) ? "Spanish" : resp.data.idiomaName,
                        fee: resp.data.fee,
                        manager_fee: (resp.data.manager_fee === null) ? 0 : resp.data.manager_fee,
                        status: resp.data.status,
                        wire_fee: (resp.data.wire_fee === null) ? 0 : resp.data.wire_fee,
                        rating: resp.data.ratingId,
                        ratingName: resp.data.ratingName,
                        descRating: resp.data.descRating ? resp.data.descRating : null,
                        otherDesc: resp.data.descRating === null ? false : true
                    })
                    if(resp.data.config!==null) {
                        await this.setState({
                            config: JSON.parse(resp.data.config)
                        });
                    }
                    else{
                        let newConfig = await this.state.config;
                        newConfig["storage"] = "";
                        await this.setState({
                            config: newConfig
                        });
                    }
                    if(resp.data.metas!==null) {
                        await this.setState({
                            meta: JSON.parse(resp.data.metas)
                        });
                    }
                    else{
                        let newMetas = await this.state.meta;
                        newMetas["name"] = "";
                        await this.setState({
                            meta: newMetas
                        });
                    }
                    if(resp.data.section_images!==null) {
                        await this.setState({
                            section_images: JSON.parse(resp.data.section_images)
                        });
                    }
                }
            });
    }
    render(){
        return (
            <div className="container">
                <Header username={this.props.username} path={this.state.path} view={this.state.view}/>
                <div className="content">
                    <Sidebar permisos={this.props.permisos} view={this.props.view}/>
                    <div className="content-padding main-content" id="page">
                        <div className="row head-padding">
                            <div className="col-12 col-sm-2 col-md-2 col-lg-2">
                                <h4>Modelos</h4>
                            </div>
                            <div className="col-12 col-sm-10 col-md-10 col-lg-10 alignButtons">
                                <div className="inline-buttons">
                                    <ul style={{marginBottom:0,paddingLeft:0}}>
                                        {(this.props.accessLevel["c"] !== undefined && this.props.accessLevel["u"] >= 2) ?
                                            <li className="list-inline-item">
                                                {this.state.status === 1 ?
                                                <button className="btn btn-reactivate" type="button" data-toggle="modal" data-target="#ModalDisableWebsite">
                                                    Inhabilitar
                                                </button>
                                                :
                                                <button className="btn btn-reactivate" type="button" onClick={this.reactiveWeb}>
                                                    Reactivar
                                                </button>
                                                }
                                            </li> : ""}
                                        {(this.props.accessLevel["c"] !== undefined && this.props.accessLevel["u"] >= 2) ?
                                            <li className="list-inline-item extra-btn">
                                                <button className="btn btn-newMembership" type="button" onClick={this.viewMemberships}>Membresias</button>
                                            </li> : ""}
                                        {(this.props.accessLevel["c"] !== undefined && this.props.accessLevel["u"] >= 2) ?
                                            <li className="list-inline-item extra-btn">
                                                <button className="btn btn-coins" type="button" onClick={this.setupCoins}>Monedas</button>
                                            </li> : ""}
                                        {(this.props.accessLevel["c"] !== undefined && this.props.accessLevel["u"] >= 1) ?
                                            <li className="list-inline-item extra-btn">
                                                <button type="button" className="btn btn-cancel" onClick={this.cancelChanges}>Cancelar</button>
                                            </li> : "" }
                                        {(this.props.accessLevel["c"] !== undefined && this.props.accessLevel["u"] >= 1) ?
                                            <li className="list-inline-item extra-btn lastButton">
                                                <button type="button" className="btn btn-newModel" onClick={this.saveChanges}>Guardar</button>
                                            </li> : ""}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <canvas id="canvasToRotate"></canvas>
                            <div className="col-12">
                                <form id="DataModel" encType="multipart/form-data" method="post">
                                    {(this.state.username !== null) ? 
                                        <General 
                                            accessLevel={this.props.accessLevel} 
                                            username={this.state.username} 
                                            alias={this.state.name} 
                                            mail={this.state.mail}
                                            title={this.state.config.nameTitle} 
                                            language={this.state.language} 
                                            languageName={this.state.languageName} 
                                            manager={this.state.manager} 
                                            managerName={this.state.managerName}
                                            fee={this.state.fee} 
                                            managerfee={this.state.manager_fee} 
                                            wireFee={this.state.wire_fee}
                                            rating={this.state.rating} 
                                            ratingName={this.state.ratingName} 
                                            descRating={this.state.descRating} 
                                            otherDesc={this.state.otherDesc}
                                        /> 
                                    : ""}
                                    {(this.state.config.storage !== undefined && this.props.accessLevel["u"] >= 3) ? 
                                        <StorageAnalytics 
                                            accessLevel={this.props.accessLevel} 
                                            analytics={this.state.config.googleAnalytics} 
                                            storageName={this.state.config.storage} 
                                            storageURL={this.state.config.storageURL} 
                                            hostname={this.state.config.hostname} 
                                            sendGrid={this.state.sendGrid} 
                                            pixel={this.state.config.pixel} 
                                            model={this.state.id}
                                        />
                                    : ""}
                                    {(this.state.config.storage !== undefined) ? 
                                        <Contenido 
                                            accessLevel={this.props.accessLevel} 
                                            config={this.state.config} 
                                            getImage={this.getImage}
                                        /> 
                                    : ""}
                                    {(this.state.config.storage !== undefined) ? 
                                        <Design 
                                            accessLevel={this.props.accessLevel} 
                                            config={this.state.config} 
                                            allstate={this.state} 
                                            meta={this.state.meta} 
                                            changeColorState={this.changeColorState}
                                        /> 
                                    : ""}
                                    {(this.state.meta.name !== undefined) ? 
                                        <Metadata 
                                            accessLevel={this.props.accessLevel} 
                                            meta={this.state.meta} 
                                            getImage={this.getImage}
                                        /> 
                                    : ""}
                                    {(this.state.section !== null) ? 
                                        <Secciones 
                                            accessLevel={this.props.accessLevel} 
                                            section={this.state.section} 
                                            section_images={this.state.section_images} 
                                            getImage={this.getImage} 
                                        /> 
                                        : ""}
                                </form>
                                <div className="spacer alignButtons">
                                    <ul>
                                        {(this.props.accessLevel["c"] !== undefined && this.props.accessLevel["u"] >= 2) ?
                                            <li className="list-inline-item">
                                                <button className="btn btn-newMembership" type="button" onClick={this.viewMemberships}>Membresias</button>
                                            </li> : ""}
                                        {(this.props.accessLevel["c"] !== undefined && this.props.accessLevel["u"] >= 2) ?
                                            <li className="list-inline-item extra-btn">
                                                <button className="btn btn-coins" type="button" onClick={this.setupCoins}>Monedas</button>
                                            </li> : ""}
                                        {(this.props.accessLevel["c"] !== undefined && this.props.accessLevel["u"] >= 1) ?
                                            <li className="list-inline-item extra-btn">
                                                <button type="button" className="btn btn-cancel" onClick={this.cancelChanges}>Cancelar</button>
                                            </li> : "" }
                                        {(this.props.accessLevel["c"] !== undefined && this.props.accessLevel["u"] >= 1) ?
                                            <li className="list-inline-item extra-btn lastButton">
                                                <button type="button" className="btn btn-newModel" onClick={this.saveChanges}>Guardar</button>
                                            </li> : ""}
                                    </ul>
                                </div>
                                {(this.props.accessLevel["u"] !== undefined && this.props.accessLevel["u"] >= 3) ?
                                <PromoMail idModel={this.props.model}/> : ""}
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
                <ModalDisableWebsite 
                    id={this.state.id}
                />
                <ModalReactiveWebsite 
                    id={this.state.id} 
                    hostname={this.state.hostname}
                />
                <ModalSaving 
                    saved={this.state.saved} 
                    error={this.state.error}
                />
                <ModalError/>
                <Crop 
                    showCroppedImage={this.showCroppedImage} 
                    onZoomChangeBar={this.onZoomChangeBar} 
                    onCropChange={this.onCropChange} 
                    onCropComplete={this.onCropComplete} 
                    onZoomChange={this.onZoomChange} 
                    imageSrc={this.state.imageSrc} 
                    shapeCrop={this.state.shapeCrop} 
                    aspect={this.state.aspect} 
                    zoom={this.state.zoom} 
                    crop={this.state.crop} 
                    croppedAreaPixels={this.state.croppedAreaPixels} 
                    croppedImage={this.state.croppedImage} 
                    croppedArea={this.state.croppedArea}
                />
            </div>

        )
    }
}
export default  ModelDetail;
