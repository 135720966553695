import React, { Component } from 'react';
import axios from "axios";

class SetActiveMemberships extends Component {
    _isMounted = false;
    constructor(){
        super();
        this.state = {
            membresias: {Activas: [], Historial: []},
            activos : [ true ],
            principal : 0
        }
        this.changeActives=this.changeActives.bind(this);
        this.changeMain=this.changeMain.bind(this);
    }
    async componentDidMount(){
        this._isMounted = true;
        if(this._isMounted){
            await this.getMemberships();
            const membresias = [...this.state.membresias.Activas];
            let activos = this.state.activos;
            let principal = this.state.principal;
            membresias.map(async (item,index) => {
                if (item.principal_membership === 1) {
                    activos[item.id] = true;
                    principal = item.id;
                }
                else {
                    activos[item.id] = false
                }

            });
            await this.setState({
                activos: activos,
                principal: principal
            });
        }

    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    changeActives = (e) => {
        const value = parseInt(e.target.value);
        const checked = e.target.checked;
        let actives = this.state.activos;
        let principal = this.state.principal;
        actives.forEach(async (e,i) =>{
            if(i!==0){
                actives[i]=false;
                if(i === principal){
                    principal = 0
                }
            }
        })
        actives[value] = checked;

        if(principal === value){
            principal = 0;
        }
        this.setState({
            activos : actives,
            principal : principal
        })
        console.log(this.state);
    }
    changeMain = (e) =>{
        const value = parseInt(e.target.value);
        const checked = e.target.checked;
        let actives = this.state.activos;
        let principal = this.state.principal;
        if(checked){
            principal = value;
        }
        else if(actives[value]){
            actives[value] = false
        }
        this.setState({
            activos : actives,
            principal : principal
        })
        console.log(this.state);
    }
    getMemberships = async () => {
        const country =  this.props.currCountry;
        await axios.get("/api/memberships/"+this.props.model, {params : { country : country }}).then(async resp => {
            await this.setState({membresias : resp.data})
        });
    };
    pintarNuevaMembresia () {
        const item = this.props.new;

        return (
            <tr key={"-1"}>
                <td>{(item.id !== undefined) ? item.id : " - "}</td>
                <td>{item.picode}</td>
                <td>{item.precio}</td>
                <td>{item.moneda}</td>
                <td>{item.periodo}</td>
                <td>
                    <div className="form-check" key="all">
                        <input type="checkbox" className="form-check-input" name="activas" value="0" checked={this.state.activos[0]} readOnly={true}/>
                    </div>
                </td>
                <td>
                    <div className="form-check" key="all">
                        <input type="radio" className="form-check-input" name="principal" id="principalNew" value="0" checked={(this.state.principal === 0)} onChange={this.changeMain}/>
                    </div>
                </td>
            </tr>
        )
    }
    pintarMembresiasActivas (){
        const membresias = [...this.state.membresias.Activas];
        return membresias.map((item,index) => {

            return (
                <tr key={index}>
                    <td>{item.id}</td>
                    <td>{item.pi_code}</td>
                    <td>{item.price}</td>
                    <td>{item.currency}</td>
                    <td>{item.payment_period}</td>
                    <td>
                        <div className="form-check" key="all">
                            <input type="checkbox" className="form-check-input" name="activas" value={item.id} checked={this.state.activos[item.id]} onChange={this.changeActives}/>
                        </div>
                    </td>
                    <td>
                        <div className="form-check" key="all">
                            <input type="radio" className="form-check-input" name="principal" value={item.id} checked={( this.state.principal === item.id )} onChange={this.changeMain}/>
                        </div>
                    </td>
                </tr>
            )
        })
    }
    render(){
        return (
            <div className={"table-responsive"}>

                <table className="table tableDiv">
                    <thead>
                    <tr className="table-success">
                        <th>ID</th>
                        <th>PiCode</th>
                        <th>Precio</th>
                        <th>Moneda</th>
                        <th>Periodo de Pago</th>
                        <th>Activa</th>
                        <th>Principal</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.pintarNuevaMembresia()}
                    {this.pintarMembresiasActivas()}
                    </tbody>
                </table>
            </div>
        )
    }
}
export default SetActiveMemberships;