import React, { Component } from 'react';
import axios from "axios";
import ModalSaving from "../../../ModalSaving";

class ModalSetMainMembership extends Component {
    constructor(){
        super();
        this.state = {
            picode : false,
            isActive : false,
            saved : false,
            error: false
        };
        this.activeMembership=this.activeMembership.bind(this);
    }
    componentDidMount(){
    }

    activeMembership = async () => {
        const country =  this.props.currCountry;
        await this.setState({saved : false, error: false});
        let send=true;
        if(send) {
            document.getElementById("ModalMainMembershipCancelBtn").click();
            document.getElementById("modalSaveMainMembership").classList.add("show");
            const formData = new FormData();
            formData.append("idModel",this.props.model);
            const saved = await axios.put("/api/membership/setMain/"+this.props.membership, formData, {params : { country : country }}).then(resp => {
                return resp.data;
            });
            if (saved.Error === undefined) {
                await this.setState({saved : true})
                await this.props.reloadMemberships();
            } else {
                await this.setState({error : true});
            }
        }
    }
    render(){
        return (
            <div>
                <div className="modal" id="ModalSetMainMembership" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Activar Membresia</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>¿Realmente desea hacer esta la membresia principal?</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger" id="ModalMainMembershipCancelBtn" data-dismiss="modal">Cancelar&nbsp;<i className="fas fa-times"></i></button>
                                <button type="button" className="btn btn-success" onClick={this.activeMembership}>Continuar&nbsp;<i className="fas fa-save"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalSaving saved={this.state.saved} error={this.state.error} idModal="modalSaveMainMembership"/>
            </div>
        )
    }
}
export default ModalSetMainMembership;