import React, { Component } from 'react';
import axios from "axios";

class AllMembershipDisable extends Component {
    constructor(){
        super();
        this.state = {
            changed: false
        }
        this.disablePromo = this.disablePromo.bind(this);

    }
    async componentDidMount(){
        
    }
    disablePromo = async () => {
        const changeStatus = await axios.post("/api/model/disablePromo/")
        .then(resp => {
            return resp.data;
        });
        if (changeStatus) {
            await this.setState({changed: true});
        } else {
            console.log('Memberships Error')
        }
    }
    render(){
        return (
            <div className="container-fluid">
                <button onClick={this.disablePromo} className='btn btn-reactivate'>Desactivar Todas las Promociones de $1 USD</button>
                {this.state.changed === true ?
                        <span className="alertMsj" role="alert">
                            ¡Promociones desactivadas!
                        </span>
                : null }
            </div>
        )
    }
}
export default AllMembershipDisable;