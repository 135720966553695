import React, { Component } from 'react';
import axios from "axios";

class StorageAnalytics extends Component {
    constructor(){
        super();
        this.requestSendGridApiKey = this.requestSendGridApiKey.bind(this);
    }
    requestSendGridApiKey = async (e) => {
        const button = e.target;
        button.classList.add("disabled");
        button.disabled = true;
        const resp = await axios.post("/api/sendgrid/", {model : this.props.model}).then(resp => {
            button.classList.remove("disabled");
            button.disabled = false;
            return resp.data;
        });
        document.getElementById("SendGrid").value = resp.Apikey;

    }
    async componentDidMount(){
        if(this.props.accessLevel["u"] >= 3) {
            document.getElementById("AnalyticsKey").value = (this.props.analytics === undefined) ? "" : this.props.analytics;
            document.getElementById("StorageName").value = (this.props.storageName === undefined) ? "" : this.props.storageName;
            document.getElementById("StorageURL").value = (this.props.storageURL === undefined) ? "" : this.props.storageURL;
            document.getElementById("Hostname").value = (this.props.hostname === undefined) ? "" : this.props.hostname;
            document.getElementById("Pixel").value = (this.props.pixel === undefined) ? "" : this.props.pixel;
            document.getElementById("SendGrid").value = (this.props.sendGrid === undefined) ? "" : this.props.sendGrid;
        }
    }
    render(){
        return (
            <div className="">
                <div className="text-white head-table">
                    Storage & Analytics
                </div>
                <div className="container-fluid">
                    <div className="divCard row">
                        <div className="col-12 col-sm-6 col-lg-3">
                            <label>Analytics Key</label>
                            {(this.props.accessLevel["u"] >= 3) ?
                            <input name="AnalyticsKey" id="AnalyticsKey" className="form-control" type="text" /> : (this.props.analytics === undefined) ? "" : this.props.analytics }
                        </div>
                        <div className="col-12 col-sm-6 col-lg-3">
                            <label>Storage Name</label>
                            {(this.props.accessLevel["u"] >= 3) ?
                            <input name="StorageName" id="StorageName" className="form-control" type="text" /> : (this.props.storageName === undefined) ? "" : this.props.storageName }
                        </div>
                        <div className="col-12 col-sm-6 col-lg-3">
                            <label>Sotorage URL</label>
                            {(this.props.accessLevel["u"] >= 3) ?
                            <input name="StorageURL" id="StorageURL" className="form-control" type="text" /> : (this.props.storageURL === undefined) ? "" : this.props.storageURL }
                        </div>
                        <div className="col-12 col-sm-6 col-lg-3">
                            <label>Hostname</label>
                            {(this.props.accessLevel["u"] >= 3) ?
                            <input name="Hostname" id="Hostname" className="form-control" type="text" /> : (this.props.hostname === undefined) ? "" : this.props.hostname }
                            <div className="invalid-feedback" style={{'marginTop':'-10px','fontSize':11,'marginBottom':'5px'}} >
                                Campo requerido para activar
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-3">
                            <label>SendGrid API Key</label>
                            {(this.props.accessLevel["u"] >= 3) ?
                                <div className="input-group mb-3">
                                    <input name="SendGrid" id="SendGrid" className="form-control" type="text" />
                                    <div className="input-generar">
                                        <button className="btn btn-generar" type="button" onClick={this.requestSendGridApiKey}>Generar
                                        </button>
                                    </div>
                                </div> : (this.props.sendGrid === undefined) ? "" : this.props.sendGrid }
                        </div>
                        <div className="col-12 col-sm-6 col-lg-3">
                            <label>Pixel API Key</label>
                            {(this.props.accessLevel["u"] >= 3) ?
                            <input name="Pixel" id="Pixel" className="form-control" type="text" /> : (this.props.pixel === undefined) ? "" : this.props.pixel }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default StorageAnalytics;