import React, { Component, Fragment } from 'react';
import axios from "axios";
import ModalSaving from "../../../components/ModalSaving";
import Header from '../../../components/Layout/Header';
import Sidebar from '../../../components/Layout/Sidebar';
import Footer from '../../../components/Layout/Footer';
import GeneralCustomer from '../../../components/Customers/Detail/GeneralCustomer';
import ModalWarning from '../../../components/Layout/ModalWarning';
import ModalNewUser from '../../../components/Configuration/ModalNewUser';
import ModalDisableAccount from "../../../components/Model/Detail/ModalDisableAccount";
import ModalResetCustomerPassword from "../../../components/Customers/ModalResetCustomerPassword";
import ModalReactiveAccount from "../../../components/Model/Detail/ModalReactiveAccount";
import ModalDeleteAccount from "../../../components/Model/Detail/ModalDeleteAccount";
import CoinsCustomer from '../../../components/Customers/Detail/CoinsCustomer/CoinsCustomer';
import ContentRequest from '../../../components/Customers/Detail/ContentRequest/ContentRequest';
import Select from 'react-select';

class Customer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            usuarios: [],
            modelos: [],
            idUser: '',
            username: '',
            email: '',
            banned: 0,
            membership: '',
            modelName: '',
            canceled: 0,
            created: '',
            lastPay: '',
            payday: '',
            userSearch: '',
            idModel: '',
            saved: false,
            error: false,
            viewInfo: false,
            noInfo: false,
            noActive: false,
            insertedM: false,
            modelSelected: null,
            model: null,
            valorprueba: 'ejemplo',
            path: "Home / Usuario / ",
            view: "Clientes",
            paymentProcessor: null,
            subscriptionId: null
        };
        this.searchUser = this.searchUser.bind(this);
        this.activeMembership = this.activeMembership.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
    }
    async componentDidMount() {
        this.getModels();
        await this.props.isAbleToAccess(5, 3, 1);

        this.props.changeView("user");
    }
    getModels = async () => {
        await axios.get("/api/models/").then(async resp => {
            await this.setState({ modelos: resp.data, totalModelos: resp.data.length })
        });
    };
    searchUser = async (button = true) => {
        await this.setState({ saved: false, error: false, viewInfo: false, noInfo: false, noActive: false });
        const usuario = document.getElementById("userSelect").value;
        let modelo = 0;
        if(this.state.modelSelected === null  && button){
           await this.setState({ noInfo: true, userSearch: usuario });
        }else{
             modelo = this.state.model;
        }               
        document.getElementById("ModalSaving").classList.add("show");
        const data = {
            model: modelo,
            user: usuario
        }
        await this.setState({ userSearch: usuario, idModel: modelo });
        const account = await axios.post('/api/users/info', data)
            .then(async res => {
                document.getElementById("ModalSaving").classList.remove("show");
                return res.data;
            });
        if (!account) {
            await this.setState({ noInfo: true });
        } else {
            await this.setState({
                idUser: account.id_user,
                username: account.username,
                email: account.email,
                banned: account.ban,
                modelName: account.modelname,
                membership: account.statusMembership,
                canceled: account.membership_canceled,
                lastPay: account.lastPayment,
                payday: account.paydayLimit,
                created: account.createdTime,
                paymentProcessor: account.description,
                subscriptionId: account.subscriptionId,
                viewInfo: true,
                insertedM: false,
                error: false,
                noActive: false,
                noInfo: false
            });
        }

    }
    activeMembership = async () => {
        await this.setState({ saved: false, error: false, noActive: false, noInfo: false });
        document.getElementById("ModalSaving").classList.add("show");
        const data = {
            cancel: this.state.canceled,
            iduser: this.state.idUser,
            user: this.state.userSearch,
            idModel: this.state.idModel
        }
        const account = await axios.post('/api/users/activeMembership', data)
            .then(async res => {
                document.getElementById("ModalSaving").classList.remove("show");
                if (res.data.Success) {
                    await this.setState({ membership: 'Activa', insertedM: true, noActive: false });
                } else {
                    await this.setState({ insertedM: false, noActive: res.data.Error });
                }
            });
        if(account){
            return true;
        }

    }
    usermodel = async (user, model) => {
        this.setState({userSelected: user, model: model});
        this.searchUser(false);
    }
    changeHandler(value){
        this.setState({modelSelected: value, model: (value) ? value.value : null, idModel: (value) ? this.state.idModel : null})
    }
    render() {
        const modelos = [...this.state.modelos].map((item, index) => ({
            key: index,
            value: item.id_model,
            label: item.username
        }));
        return (
            <div className="container">
                <Header username={this.props.username} path={this.state.path} view={this.state.view} />
                <div className="content">
                    <Sidebar permisos={this.props.permisos} view={this.state.view} />
                    <div className="content-padding main-content" id="page">
                        <div className="row head-padding">
                            <div className="col-6">
                                <h4>Clientes</h4>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="text-white head-table">
                                    Buscador de usuario
                                </div>
                                <div id='' className="container-fluid">
                                    <div className="divCard row">
                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                                            <label>Usuario</label>
                                            <input id="userSelect" className="form-control" type="text" placeholder='Username o correo electronico' />
                                        </div>
                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                                            <label>Modelo</label>
                                            <Fragment>
                                                <Select
                                                    name="model"
                                                    id="modelSelect"
                                                    placeholder="Seleccionar"
                                                    className="from-control from-control-sm select-model"
                                                    defaultValue= '0'                                                   
                                                    value={this.state.modelSelected}
                                                    onChange={this.changeHandler}
                                                    isClearable={true}
                                                    options={modelos}
                                                >
                                                </Select>
                                            </Fragment>
                                        </div>
                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                                            <button type="button" className="btn btn-search" onClick={this.searchUser}>
                                                <i className="fas fa-search"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {this.state.viewInfo ?
                                <div className="col-12">
                                    <div className="text-white head-table-user">
                                        Informaci&oacute;n de la cuenta
                                    </div>
                                    <div id='' className="container-fluid">
                                        <div className="divCard row">

                                            <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                                                <label>ID</label>
                                                <input className="form-control" type="text" value={this.state.idUser} disabled />
                                            </div>
                                            <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                                                <label>Username</label>
                                                <input className="form-control" type="text" value={this.state.username} disabled />
                                            </div>
                                            <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                                                <label>Mail</label>
                                                <input className="form-control" type="text" value={this.state.email} disabled />
                                            </div>
                                            <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                                                <label>Modelo</label>
                                                <input className="form-control" type="text" value={this.state.modelName} disabled />
                                            </div>
                                            <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                                                <button type="button" className="btn btn-newMembership" data-toggle="modal" data-target="#ModalResetCustomerPassword">
                                                    Resetear Contrase&ntilde;a
                                            </button>
                                            </div>
                                            <p className="col-12 col-sm-12 col-lg-12 titleCell">
                                                Membresia / Suscripción
                                            </p>
                                            <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                                                <label>Estado</label>
                                                <input className="form-control" type="text" value={this.state.membership} disabled />
                                            </div>
                                            {this.state.membership !== 'Sin membresia' && this.state.created !== '' ?
                                                <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                                                    <label>Creación</label>
                                                    <input className="form-control" type="text" value={this.state.created ? this.state.created : ''} disabled />
                                                </div>
                                                : null}
                                            {this.state.membership !== 'Sin membresia' && this.state.lastPay !== '' ?
                                                <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                                                    <label>Último pago</label>
                                                    <input className="form-control" type="text" value={this.state.lastPay ? this.state.lastPay : ''} disabled />
                                                </div>
                                                : null}
                                            {this.state.membership !== 'Sin membresia' && this.state.payday !== '' ?
                                                <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                                                    <label>Fecha Límite</label>
                                                    <input className="form-control" type="text" value={this.state.payday ? this.state.payday : ''} disabled />
                                                </div>
                                                : null}
                                            <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                                                <label>Procesador de Pagos</label>
                                                <input className="form-control" type="text" value={this.state.paymentProcessor || "N/A"} disabled />
                                            </div>
                                            <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                                                <label>Subscripción ID</label>
                                                <input className="form-control" type="text" value={this.state.subscriptionId || "N/A"} disabled />
                                            </div>
                                            {//this.state.membership === 'Activa' ? 
                                                //     <div className="col-12 col-sm-3 col-lg-3">
                                                //         <button type="button" className="btn btn-newMembership ">
                                                //             Editar
                                                //         </button>
                                                //     </div>
                                                // : null
                                            }

                                            {//this.state.membership === 'Activa' ? 
                                                //     <div className="col-12 col-sm-3 col-lg-3">
                                                //         <button type="button" className="btn btn-cancel btn-client">
                                                //             Cancelar
                                                //         </button>
                                                //     </div>
                                                // : null
                                            }

                                            {this.state.membership === 'Sin membresia' ?
                                                <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                                                    <button type="button" className="btn btn-newModel  form-extra-margin" onClick={this.activeMembership}>
                                                        Activar
                                                </button>
                                                </div>
                                                : null
                                            }

                                            {this.state.membership === 'Cancelada' ?
                                                <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                                                    <button type="button" className="btn btn-newModel  form-extra-margin" onClick={this.activeMembership}>
                                                        Reactivar
                                                </button>
                                                </div>
                                                : null}

                                            {this.state.insertedM === true ?
                                                <div className="col-12 col-md-4 col-lg-3">
                                                    <div className="alertMsj form-extra-margin" role="alert">
                                                        ¡Membresia activada!
                                                </div>
                                                </div>
                                                : this.state.noActive ?
                                                    <div className="col-12 col-sm-4 col-lg-4">
                                                        {this.state.noActive}
                                                    </div>
                                                    : null}                                           
                                            <div className="row full-row">
                                                <div style={{ 'marginBottom': 20 }} className="col-12 col-sm-6 col-lg-6">
                                                    <div className='titleCell'>Inhabilitar</div>
                                                    {this.state.banned === 0 ?
                                                        <button type="button" className="btn btn-reactivate" data-toggle="modal" data-target="#ModalDisableAccount">
                                                            Inhabilitar cuenta
                                                    </button>
                                                        :
                                                        <button type="button" className="btn btn-reactivate" data-toggle="modal" data-target="#ModalReactiveAccount">
                                                            Habilitar cuenta
                                                    </button>
                                                    }
                                                </div>
                                                <div
                                                    style={{ 'marginBottom': 20 }}
                                                    className="col-12 col-sm-6 col-lg-6"
                                                >
                                                    <div
                                                        className='titleCell'
                                                    >
                                                        Eliminar
                                                </div>
                                                    <button
                                                        type="button"
                                                        className="btn btn-reactivate"
                                                        data-toggle="modal"
                                                        data-target="#ModalDeleteAccount"
                                                    >
                                                        Eliminar cuenta
                                                </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.username != null ?
                                        <CoinsCustomer username={this.state.userSearch} model={this.state.model}/>
                                        : null
                                    }  
                                    {this.state.username != null ?
                                        <ContentRequest username={this.state.userSearch} model={this.state.model} idUser={this.state.idUser}/>
                                        : null
                                    }                                  
                                </div>
                                : this.state.noInfo ?
                                    <GeneralCustomer username={this.state.userSearch} searchUser={this.searchUser}
                                     usermodel={this.usermodel}/>
                                    : null}
                        </div>
                        <ModalDisableAccount id={this.state.idUser} />
                        <ModalResetCustomerPassword id={this.state.idUser} />
                        <ModalReactiveAccount id={this.state.idUser} />
                        <ModalSaving saved={this.state.saved} error={this.state.error} message={"Cargando"} />
                        <ModalDeleteAccount id={this.state.idUser} name={this.state.username} model={this.state.idModel} />
                    </div>
                </div>
                <Footer />
                {(this.props.accessLevel["c"] !== undefined && this.props.accessLevel["c"] >= 3) ?
                    <ModalNewUser reloadUsers={this.reloadUsers} /> : ""}
                <ModalWarning title={"Algo salio mal"} message={"No se pudo guardar el usuario"} />
            </div>
        )
    }
}
export default Customer;