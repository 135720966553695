import React, { Component } from 'react';
import Main from './Main';
import Post from './Post';
import Chat from './Chat';
import MenuChat from './MenuChat';
import Modal from './Modal';
import './style.css';
const AColorPicker = require('a-color-picker');

class Design extends Component {
    constructor(){
        super();
        this.openExampleWindow=this.openExampleWindow.bind(this);
        this.closeSideBar=this.closeSideBar.bind(this);
        this.openSideBar=this.openSideBar.bind(this);
        this.openPicker = this.openPicker.bind(this);
    }
    async componentDidMount(){
        //console.log(this.props.config);
        AColorPicker.from('.picker').on('change', async (picker, color) => {
            const newColor = AColorPicker.parseColor(color,"hex");
            const pickerContent = picker.element.parentElement;
            let arr = pickerContent.getAttribute("data-config");
            let el = pickerContent.getAttribute("data-color");
            let arrElements=el.split("|");
            let arrSteps=arr.split(".");
            let steps=[...arrSteps];
            let newArr = this.props.allstate;
            let letArr = await this.travelConfig(newArr,arrSteps,arrElements,newColor);
            await this.props.changeColorState(arr,letArr[steps[0]]);

        });
    }
    async travelConfig(arr,way,elements,color){
        if(way.length===0){
            for(let i=0; i<elements.length; i++){
                arr[elements[i]]=color;
            }
        }
        else {
            let newArr = arr[way[0]];
            let auxWay=[...way];
            way.splice(0, 1);
            arr[auxWay[0]] = await this.travelConfig(newArr, way, elements, color);
        }
        //console.log(arr);
        return arr;
    }
    openPicker(e){
        const picker = e.target.parentElement.nextSibling;
        if(e.target.classList.contains("open")) {
            picker.style.display = "none";
            e.target.classList.remove("open")
        }
        else{
            e.target.classList.add("open")
            picker.style.display = "block";
        }
    }
    closeSideBar(e){
        let sidebar = document.querySelectorAll(".sidebar-example");
        const totalSideBar = sidebar.length;
        let k=0;
        while (k<totalSideBar){
            sidebar[k].classList.remove("open");
            k++
        }
    }
    openSideBar(e){
        let sidebar = document.querySelectorAll(".sidebar-example");
        const totalSideBar = sidebar.length;
        let k=0;
        while (k<totalSideBar){
            sidebar[k].classList.add("open");
            k++
        }
    }
    openExampleWindow(e){
        const window=e.target.getAttribute("data-window");
        let allPages=document.querySelectorAll(".screen-example");
        const totalPages=allPages.length;
        let k=0;
        while (k<totalPages){
            allPages[k].classList.remove("open");
            k++;
        }
        switch (window) {
            case "0":
                document.getElementById("exampleLogin").classList.add("open");
                break;
            case "1":
                document.getElementById("examplePost").classList.add("open");
                break;
            case "2":
                document.getElementById("exampleChat").classList.add("open");
                break;
            case "3":
                document.getElementById("exampleModal").classList.add("open");
                break;
            case "4":
                document.getElementById("exampleMenuChat").classList.add("open");
                break;
            default:
                document.getElementById("exampleLogin").classList.add("open");
                break;
        }

    }
    render(){
        return (
            <div className="">
                <div className="text-white head-table-red">
                    Diseño
                </div>
                <div className="divCardCellphone container-fluid">
                    <div className="row">
                        {(this.props.accessLevel["u"] >= 1) ?
                        <div className="col-12 col-md-6 col-lg-4">
                            <div className='titleCell'>Principales</div>
                            <div className="form-group color-form-group col-12 col-sm-6 col-lg-4 col-xl-4">
                                <label>Background</label>
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text color-picker-btn" onClick={this.openPicker} style={{backgroundColor : this.props.meta.theme_color}}>&nbsp;</span>
                                    </div>
                                    <div className="picker" data-color="theme_color|background_color" data-config="meta" acp-color={this.props.meta.theme_color} acp-show-rgb="no" acp-show-hsl="no" acp-show-hex="yes"></div>
                                    <input type="text" className="form-control" value={this.props.meta.theme_color} readOnly={true} />
                                </div>
                            </div>
                            <div className="form-group color-form-group col-12 col-sm-6 col-lg-4 col-xl-4">
                                <label>Principal</label>
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text color-picker-btn" onClick={this.openPicker} style={{backgroundColor : this.props.config.color.principal}}>&nbsp;</span>
                                    </div>
                                    <div className="picker" data-color="principal" data-config="config.color" acp-color={this.props.config.color.principal} acp-show-rgb="no" acp-show-hsl="no" acp-show-hex="yes"></div>
                                    <input type="text" className="form-control" value={this.props.config.color.principal} readOnly={true} />
                                </div>
                            </div>
                            <div className="form-group color-form-group col-12 col-sm-6 col-lg-4 col-xl-4">
                                <label>Texto Principal</label>
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text color-picker-btn" onClick={this.openPicker} style={{backgroundColor : this.props.config.color.principalText}}>&nbsp;</span>
                                    </div>
                                    <div className="picker" data-color="principalText" data-config="config.color" acp-color={this.props.config.color.principalText} acp-show-rgb="no" acp-show-hsl="no" acp-show-hex="yes"></div>
                                    <input type="text" className="form-control" value={this.props.config.color.principalText} readOnly={true} />
                                </div>
                            </div>
                            <div className="form-group color-form-group col-12 col-sm-6 col-lg-4 col-xl-4">
                                <label>Secundario</label>
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text color-picker-btn" onClick={this.openPicker} style={{backgroundColor : this.props.config.color.secondary}}>&nbsp;</span>
                                    </div>
                                    <div className="picker" data-color="secondary" data-config="config.color" acp-color={this.props.config.color.secondary} acp-show-rgb="no" acp-show-hsl="no" acp-show-hex="yes"></div>
                                    <input type="text" className="form-control" value={this.props.config.color.secondary} readOnly={true} />
                                </div>
                            </div>
                            <div className="form-group color-form-group col-12 col-sm-6 col-lg-4 col-xl-4">
                                <label>Texto Secundario</label>
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text color-picker-btn" onClick={this.openPicker} style={{backgroundColor : this.props.config.color.secondaryText}}>&nbsp;</span>
                                    </div>
                                    <div className="picker" data-color="secondaryText" data-config="config.color" acp-color={this.props.config.color.secondaryText} acp-show-rgb="no" acp-show-hsl="no" acp-show-hex="yes"></div>
                                    <input type="text" className="form-control" value={this.props.config.color.secondaryText} readOnly={true} />
                                </div>
                            </div>
                            <div className="form-group color-form-group col-12 col-sm-6 col-lg-4 col-xl-4">
                                <label>Terciario</label>
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text color-picker-btn" onClick={this.openPicker} style={{backgroundColor : this.props.config.color.third}}>&nbsp;</span>
                                    </div>
                                    <div className="picker" data-color="third" data-config="config.color" acp-color={this.props.config.color.third} acp-show-rgb="no" acp-show-hsl="no" acp-show-hex="yes"></div>
                                    <input type="text" className="form-control" value={this.props.config.color.third} readOnly={true} />
                                </div>
                            </div>
                            <div className="form-group color-form-group col-12 col-sm-6 col-lg-4 col-xl-4">
                                <label>Texto Terciario</label>
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text color-picker-btn" onClick={this.openPicker} style={{backgroundColor : this.props.config.color.thirdText}}>&nbsp;</span>
                                    </div>
                                    <div className="picker" data-color="thirdText" data-config="config.color" acp-color={this.props.config.color.thirdText} acp-show-rgb="no" acp-show-hsl="no" acp-show-hex="yes"></div>
                                    <input type="text" className="form-control" value={this.props.config.color.thirdText} readOnly={true} />
                                </div>
                            </div>
                            <div className="form-group color-form-group col-12 col-sm-6 col-lg-4 col-xl-4">
                                <label>Navbar</label>
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text color-picker-btn" onClick={this.openPicker} style={{backgroundColor : this.props.config.color.navbar}}>&nbsp;</span>
                                    </div>
                                    <div className="picker" data-color="navbar" data-config="config.color" acp-color={this.props.config.color.navbar} acp-show-rgb="no" acp-show-hsl="no" acp-show-hex="yes"></div>
                                    <input type="text" className="form-control" value={this.props.config.color.navbar} readOnly={true} />
                                </div>
                            </div>
                            <div className="form-group color-form-group col-12 col-sm-6 col-lg-4 col-xl-4">
                                <label>Navbar Text</label>
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text color-picker-btn" onClick={this.openPicker} style={{backgroundColor : this.props.config.color.navbarText}}>&nbsp;</span>
                                    </div>
                                    <div className="picker" data-color="navbarText" data-config="config.color" acp-color={this.props.config.color.navbarText} acp-show-rgb="no" acp-show-hsl="no" acp-show-hex="yes"></div>
                                    <input type="text" className="form-control" value={this.props.config.color.navbarText} readOnly={true} />
                                </div>
                            </div>
                        </div>
                        : ""}
                        <div className="col-12 col-md-6 col-lg-4">
                            <Main config={this.props.config} meta={this.props.meta} openSideBar={this.openSideBar} closeSideBar={this.closeSideBar} openExampleWindow={this.openExampleWindow} mainImage={this.props.config.principalImage} storageURL={this.props.config.storageURL} nameModel={this.props.config.nameModel}/>
                            <Post config={this.props.config} meta={this.props.meta} openSideBar={this.openSideBar} closeSideBar={this.closeSideBar} openExampleWindow={this.openExampleWindow} mainImage={this.props.config.principalImage} nameModel={this.props.config.nameModel}/>
                            <Chat config={this.props.config} meta={this.props.meta} openSideBar={this.openSideBar} closeSideBar={this.closeSideBar} openExampleWindow={this.openExampleWindow} mainImage={this.props.config.principalImage} nameModel={this.props.config.nameModel}/>
                            <Modal config={this.props.config} meta={this.props.meta} openSideBar={this.openSideBar} closeSideBar={this.closeSideBar} openExampleWindow={this.openExampleWindow} mainImage={this.props.config.principalImage} storageURL={this.props.config.storageURL}/>
                            <MenuChat config={this.props.config} meta={this.props.meta} openSideBar={this.openSideBar} closeSideBar={this.closeSideBar} openExampleWindow={this.openExampleWindow} mainImage={this.props.config.principalImage} nameModel={this.props.config.nameModel}/>
                            <div className="cellphone"></div>
                        </div>
                        {(this.props.accessLevel["u"] >= 1) ?
                        <div className="col-12 col-md-6 col-lg-4">
                            <div className='titleCell'>Modal</div>
                            <div className="form-group color-form-group col-12 col-sm-6 col-lg-4 col-xl-4">
                                <label>Background</label>
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text color-picker-btn" onClick={this.openPicker} style={{backgroundColor : this.props.config.color.modal}}>&nbsp;</span>
                                    </div>
                                    <div className="picker" data-color="modal" data-config="config.color" acp-color={this.props.config.color.modal} acp-show-rgb="no" acp-show-hsl="no" acp-show-hex="yes"></div>
                                    <input type="text" className="form-control" value={this.props.config.color.modal} readOnly={true} />
                                </div>
                            </div>
                            <div className="form-group color-form-group col-12 col-sm-6 col-lg-4 col-xl-4">
                                <label>Texto</label>
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text color-picker-btn" onClick={this.openPicker} style={{backgroundColor : this.props.config.color.modalText}}>&nbsp;</span>
                                    </div>
                                    <div className="picker" data-color="modalText" data-config="config.color" acp-color={this.props.config.color.modalText} acp-show-rgb="no" acp-show-hsl="no" acp-show-hex="yes"></div>
                                    <input type="text" className="form-control" value={this.props.config.color.modalText} readOnly={true} />
                                </div>
                            </div>
                            <div className='titleCell'>Chat</div>
                            <div className="form-group color-form-group col-12 col-sm-6 col-lg-4 col-xl-4">
                                <label>Background</label>
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text color-picker-btn" onClick={this.openPicker} style={{backgroundColor : this.props.config.color.chat.background}}>&nbsp;</span>
                                    </div>
                                    <div className="picker" data-color="background" data-config="config.color.chat" acp-color={this.props.config.color.chat.background} acp-show-rgb="no" acp-show-hsl="no" acp-show-hex="yes"></div>
                                    <input type="text" className="form-control" value={this.props.config.color.chat.background} readOnly={true} />
                                </div>
                            </div>
                            <div className="form-group color-form-group col-12 col-sm-6 col-lg-4 col-xl-4">
                                <label>Footer</label>
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text color-picker-btn" onClick={this.openPicker} style={{backgroundColor : this.props.config.color.chat.titleAndBotton}}>&nbsp;</span>
                                    </div>
                                    <div className="picker" data-color="titleAndBotton" data-config="config.color.chat" acp-color={this.props.config.color.chat.titleAndBotton} acp-show-rgb="no" acp-show-hsl="no" acp-show-hex="yes"></div>
                                    <input type="text" className="form-control" value={this.props.config.color.chat.titleAndBotton} readOnly={true} />
                                </div>
                            </div>
                            <div className="form-group color-form-group col-12 col-sm-6 col-lg-4 col-xl-4">
                                <label>Footer Text</label>
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text color-picker-btn" onClick={this.openPicker} style={{backgroundColor : this.props.config.color.chat.bottonText}}>&nbsp;</span>
                                    </div>
                                    <div className="picker" data-color="bottonText" data-config="config.color.chat" acp-color={this.props.config.color.chat.bottonText} acp-show-rgb="no" acp-show-hsl="no" acp-show-hex="yes"></div>
                                    <input type="text" className="form-control" value={this.props.config.color.chat.bottonText} readOnly={true} />
                                </div>
                            </div>
                            <div className="form-group color-form-group col-12 col-sm-6 col-lg-4 col-xl-4">
                                <label>User Background</label>
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text color-picker-btn" onClick={this.openPicker} style={{backgroundColor : this.props.config.color.chat.userChat}}>&nbsp;</span>
                                    </div>
                                    <div className="picker" data-color="userChat" data-config="config.color.chat" acp-color={this.props.config.color.chat.userChat} acp-show-rgb="no" acp-show-hsl="no" acp-show-hex="yes"></div>
                                    <input type="text" className="form-control" value={this.props.config.color.chat.userChat} readOnly={true} />
                                </div>
                            </div>
                            <div className="form-group color-form-group col-12 col-sm-6 col-lg-4 col-xl-4">
                                <label>User Text</label>
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text color-picker-btn" onClick={this.openPicker} style={{backgroundColor : this.props.config.color.chat.userChatText}}>&nbsp;</span>
                                    </div>
                                    <div className="picker" data-color="userChatText" data-config="config.color.chat" acp-color={this.props.config.color.chat.userChatText} acp-show-rgb="no" acp-show-hsl="no" acp-show-hex="yes"></div>
                                    <input type="text" className="form-control" value={this.props.config.color.chat.userChatText} readOnly={true} />
                                </div>
                            </div>
                            <div className="form-group color-form-group col-12 col-sm-6 col-lg-4 col-xl-4">
                                <label>Model Background</label>
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text color-picker-btn" onClick={this.openPicker} style={{backgroundColor : this.props.config.color.chat.modelChat}}>&nbsp;</span>
                                    </div>
                                    <div className="picker" data-color="modelChat" data-config="config.color.chat" acp-color={this.props.config.color.chat.modelChat} acp-show-rgb="no" acp-show-hsl="no" acp-show-hex="yes"></div>
                                    <input type="text" className="form-control" value={this.props.config.color.chat.modelChat} readOnly={true} />
                                </div>
                            </div>
                            <div className="form-group color-form-group col-12 col-sm-6 col-lg-4 col-xl-4">
                                <label>Model Text</label>
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text color-picker-btn" onClick={this.openPicker} style={{backgroundColor : this.props.config.color.chat.modelChatText}}>&nbsp;</span>
                                    </div>
                                    <div className="picker" data-color="modelChatText" data-config="config.color.chat" acp-color={this.props.config.color.chat.modelChatText} acp-show-rgb="no" acp-show-hsl="no" acp-show-hex="yes"></div>
                                    <input type="text" className="form-control" value={this.props.config.color.chat.modelChatText} readOnly={true} />
                                </div>
                            </div>
                        </div> : ""}
                    </div>
                </div>
            </div>
        )
    }
}
export default Design;
