import React, { Component } from 'react';

class Contenido extends Component {

    async componentDidMount(){
        const noimage="https://storage.googleapis.com/private-social-media.appspot.com/cms/noimage.png";
        document.getElementById("principalImg").setAttribute("src",(this.props.config.storageURL === undefined || this.props.config.principalImage===undefined) ? noimage : this.props.config.storageURL+this.props.config.principalImage);
        document.getElementById("logoImg").setAttribute("src",(this.props.config.storageURL === undefined || this.props.config.logo === undefined) ? noimage : this.props.config.storageURL+this.props.config.logo);
        document.getElementById("secondLogoImg").setAttribute("src",(this.props.config.storageURL === undefined || this.props.config.secondLogo === undefined) ? noimage : this.props.config.storageURL+this.props.config.secondLogo);
    }
    render(){
        return (
            <div className="modelContent">
                <div className="text-white head-table-membership">
                    Contenido
                </div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="cardPadd img-content col-12 col-sm-6 col-md-6 col-lg-4">
                            <div className="divImgCard card  mx-auto" style={{width: "100%"}}>
                                <img src="" className="img-selectImgModel card-img-top" alt="principal" id="principalImg" />
                                <div className="card-body" style={{paddingTop: "10px"}}>
                                    <div className="card-title imgTitle">Im&aacute;gen Principal</div>
                                    {(this.props.accessLevel["u"] >= 1) ?
                                    <div className="form-group" style={{marginBottom:'-2rem'}}>
                                        <div className="custom-file addFile-adjust">
                                                <input type="file" className="custom-file-input" onChange={this.props.getImage} name="principalImage" id="mainImage" data-img="principalImage" accept=".png,.jpg,.jpeg,.bmp,.heif,.heic" data-arr="config"/>
                                            <label className="custom-upload">
                                                <i className="fas fa-cloud-upload-alt"></i>&ensp;Add file
                                            </label>
                                        </div>
                                    </div> : "" }
                                </div>
                            </div>
                        </div>
                        <div className="cardPadd img-content col-12 col-sm-6 col-md-6 col-lg-4">
                            <div className="divImgCard card  mx-auto" style={{width: "100%"}}>
                                <img src="" className="img-selectImgModel card-img-top" alt="logo" id="logoImg"/>
                                <div className="card-body" style={{paddingTop: "10px"}}>
                                    <div className="card-title imgTitle">Logo</div>
                                    {(this.props.accessLevel["u"] >= 1) ?
                                        <div className="form-group" style={{marginBottom:'-2rem'}}>
                                            <div className="custom-file addFile-adjust">
                                                <input className="custom-file-input" type="file" onChange={this.props.getImage} name="logo" id="logoImage" accept=".png,.jpg,.jpeg,.bmp,.heif,.heic" data-arr="config"/>
                                                <label className="custom-upload">
                                                    <i className="fas fa-cloud-upload-alt"></i>&ensp;Add file
                                                </label>
                                            </div>
                                        </div> : "" }
                                </div>
                            </div>
                        </div>
                        <div className="cardPadd img-content col-12 col-sm-6 col-md-6 col-lg-4">
                            <div className="divImgCard card  mx-auto" style={{width: "100%"}}>
                                <img src="" className="img-selectImgModel card-img-top" alt="secondLogo" id="secondLogoImg"/>
                                <div className="card-body" style={{paddingTop: "10px"}}>
                                    <div className="card-title imgTitle">Logo Modal (Opcional)</div>
                                    {(this.props.accessLevel["u"] >= 1) ?
                                        <div className="form-group" style={{marginBottom:'-2rem'}}>
                                            <div className="custom-file addFile-adjust">
                                                <input className="custom-file-input" type="file" onChange={this.props.getImage} name="secondLogo" id="secondLogo" accept=".png,.jpg,.jpeg,.bmp,.heif,.heic" data-arr="config"/>
                                                <label className="custom-upload">
                                                    <i className="fas fa-cloud-upload-alt"></i>&ensp;Add file
                                                </label>
                                            </div>
                                        </div> : "" }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default Contenido;
