import React, { Component } from 'react';
import axios from "axios";
import ModalSaving from "../../../ModalSaving";

class ModalEnablePiCodeCoin extends Component {
    constructor(){
        super();
        this.state = {
            picode : false,
            isActive : false,
            nuevaCoin : {},
            saved : false,
            error : false
        };
        this.enablePiCodeCoin=this.enablePiCodeCoin.bind(this);
    }
    componentDidMount(){

    }
    enablePiCodeCoin = async () => {
        await this.setState({saved : false, error: false});
        document.getElementById("ModalEnablePiCodeCoinCancelBtn").click();
        document.getElementById("modalSaveEnablePiCode").classList.add("show");
        const formData = new FormData();
        formData.append("idModel",this.props.model);
        const extraCharge = this.props.enableMembership;
        const saved = await axios.put('/api/model/coin/enable/'+extraCharge, formData)
            .then( async resp => {
                return resp.data;
            });
        if (saved.Error === undefined) {
            await this.setState({saved : true})
            await this.props.reloadCoins();
        } else {
            await this.setState({error : true});
        }
    }
    render(){
        return (
            <div>
                <div className="modal" id="ModalEnablePiCodeCoin" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header-section">
                                <h5 className="modal-title sectionTitle">Activar PiCode</h5>
                                <button type="button" className="close modalClose" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body modelBody">
                                <p>¿Realmente desea desactivar este picode?</p>
                            </div>
                            <div className="modal-footer modalFooter">
                                <button type="button" className="btn btn-modalClose" id="ModalEnablePiCodeCoinCancelBtn" data-dismiss="modal">Cancelar</button>
                                <button type="button" className="btn btn-modalSuccess" onClick={this.enablePiCodeCoin}>Guardar</button>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalSaving saved={this.state.saved} error={this.state.error} idModal="modalSaveEnablePiCode"/>
            </div>
        )
    }
}
export default ModalEnablePiCodeCoin;