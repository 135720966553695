import React, { useState } from 'react';
import DatePicker from "react-datepicker";
import ModelsToActivate from "./ModelsToActivate";
import ModalGeneralConfirm from "../ModalGeneralConfirm";
import moment from 'moment';
import axios from 'axios';
const ActivatePromo = (props) => {
    const {
        modelosPromo
    } = props;

    const newDate = new Date();
    // eslint-disable-next-line
    Date.prototype.addDays = function (days) {
        var date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
    }
    const [allValues, setDates] = useState({
        date: newDate,
        dateFin: newDate.addDays(1)
    });
    const changeHandlerInit = e => {
        let dateFin = e.addDays(1);
        setDates({ ...allValues, date: e, dateFin: dateFin });
    }
    const changeHandlerFin = e => {
        setDates({ ...allValues, dateFin: e });
    }
    const [hour, setHour] = useState(
        moment().format('HH:mm')
    );
    function activatePromo(e, toggle){
        e.preventDefault();
        toggle();
        axios.post('/api/model/promo/activate', { modelos: modelosPromo, dates: allValues, hour: hour} )
        .then(resp=>{
            console.log(resp);
        })
    }
    return (
        <div>
            <h5>Activar Promo</h5>
            <p>Fecha Inicio: </p>
            <div className='row'>
                <div className={"form-group col-6 center"}>
                    <DatePicker
                        className="form-control form-control-sm dateInput"
                        dateFormat="yyyy-MM-dd"
                        name='date'
                        minDate={newDate}
                        selected={allValues.date}
                        onChange={changeHandlerInit}
                    />
                </div>
                <div className={"form-group col-6 center"}>
                    <input
                        className='form-control'
                        type="time"
                        value={hour}
                        onChange={(e) => setHour(e.target.value)}
                        required
                    />
                </div>
            </div>
            <p>Fecha Final: </p>
            <div className='row'>
                <div className={"form-group col-6 center"}>
                    <DatePicker
                        className="form-control form-control-sm dateInput"
                        dateFormat="yyyy-MM-dd"
                        minDate={allValues.date.addDays(1)}
                        selected={allValues.dateFin}
                        onChange={changeHandlerFin}
                    />
                </div>
                <div className={"col-6 "}>
                <ul>
                    <ModelsToActivate
                        modelosPromo={modelosPromo}
                    />
                </ul>
                </div>
            </div>
            {modelosPromo.length>0
            ?
                <ModalGeneralConfirm
                    title = 'Activar Promo'
                    body  = {
                        <>
                            <p>Se activaran las promos de :</p>
                            < ul >
                                <ModelsToActivate
                                    modelosPromo={modelosPromo}
                                />
                            </ul>
                        </>
                    }
                    buttonLabel = 'Activar'
                    actionConfirm={activatePromo}
                />
            : ''
            }

        </div>
    )
}
export default ActivatePromo;