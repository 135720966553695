import React, { Component } from 'react';
import axios from 'axios';
import ModalSaving from "../../ModalSaving";
import Promise from 'bluebird'
import './style.css'

class Multimedia extends Component{
    constructor(){
        super();
        this.state={
            coins : [],
            prices : [],
            modelos:[]
        }
    }
    componentDidMount(){
        this.getModels();
    }
    getModels = async () => {
        await this.getModelsCoinPrice();
        await this.getModelsServicesPrice();
        let coins = [...this.state.coins];
        let prices = [...this.state.prices];
        let modelos = [];
        await Promise.map(coins, (el) => {
            if(modelos[el.id_model] === undefined){
                modelos[el.id_model] = {coins : [], prices : [], username : el.username};
            }
            modelos[el.id_model]["coins"].push({ price : el.price, coins : el.num_coins, currency : el.currency});
        })
        await Promise.map(prices, (el) => {
            if(modelos[el.id_model] === undefined){
                modelos[el.id_model] = {coins : [], prices : [], username : el.username};
            }
            modelos[el.id_model]["prices"][el.module] = el.num_coins;
        })
        this.setState({modelos : modelos});
    };
    getModelsCoinPrice = async () => {
        await axios.get("/api/models/coins").then(async resp => {
            await this.setState({coins : resp.data})
        });
    }
    getModelsServicesPrice = async () => {
        await axios.get("/api/models/services").then(async resp => {
            await this.setState({prices : resp.data})
        });
    }
    drawCoins (coins){
        if(coins.length === 0){
            return (<i className="fa fa-times text-danger"></i>)
        }
        return coins.map( (el,i) => {
            return (
                <p key={i} className="text-success">{el.coins} por {el.price}{el.currency}</p>
            )
        })
    }
    drawServices () {
        if(this.state.modelos.length === 0){
            return (<tr><td colSpan={7} className="text-center">No hay información</td></tr>)
        }
        const modelos = [...this.state.modelos];
        // eslint-disable-next-line
        return modelos.map((item,index) => {
            if(item) {
                return (
                    <tr key={index}>
                        <td>{index}</td>
                        <td>{item.username}</td>
                        <td>{this.drawCoins(item.coins)}</td>
                        <td>
                            {(item.prices["media-messages"]) ?
                                <span className="text-success">{item.prices["media-messages"]}</span>
                                :
                                <i className="fa fa-times text-danger"></i>
                            }
                        </td>
                        <td>
                            {(item.prices["audio-message-req"]) ?
                                <span className="text-success">{item.prices["audio-message-req"]}</span>
                                :
                                <i className="fa fa-times text-danger"></i>
                            }
                        </td>
                        <td>
                            {(item.prices["image-message-req"]) ?
                                <span className="text-success">{item.prices["image-message-req"]}</span>
                                :
                                <i className="fa fa-times text-danger"></i>
                            }
                        </td>
                        <td>
                            {(item.prices["video-message-req"]) ?
                                <span className="text-success">{item.prices["video-message-req"]}</span>
                                :
                                <i className="fa fa-times text-danger"></i>
                            }
                        </td>
                    </tr>
                )
            }
        })
    }
    render(){
        return(
            <div className="row">
                <div className="col-12">
                    <div className="text-white head-table">
                        Servicios activos por Modelo
                    </div>
                    <div className="table-responsive tableDiv tableScoll-lg">
                        <table className="table tableDataSection table-services">
                            <thead>
                            <tr className="attr-table">
                                <th>ID</th>
                                <th>Modelo</th>
                                <th>Coins</th>
                                <th>Recibir Media</th>
                                <th>Enviar Audio</th>
                                <th>Enviar Imagen</th>
                                <th>Enviar Video</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.drawServices()}
                            </tbody>
                        </table>
                    </div>
                </div>

                <ModalSaving saved={this.state.saved} error={this.state.error} message={"Cargando"}/>

            </div>
        )
    }
}
export default Multimedia;
