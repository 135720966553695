import React from 'react';
import EyeIcon from './EyeIcon';
import PlayIcon from './PlayIcon';
import CloseIcon from './CloseIcon';
import FullscreenIcon from './FullscreenIcon';

const Icon= ({iconName, color, width, height, className = null, divStyle= null, onClickFunction = null})=>{
    return(
        <div className={divStyle}>
            { iconName === "EyeIcon" ?
                <EyeIcon
                    color={color}
                    width={width}
                    height={height}
                    className={className}
                />
            : iconName === "PlayIcon" ?
                <PlayIcon
                    color={color}
                    width={width}
                    height={height}
                    className={className}
                    onClickFunction={onClickFunction}
                />
            : iconName === "CloseIcon" ?
                <CloseIcon
                    color={color}
                    width={width}
                    height={height}
                    className={className}
                    onClickFunction={onClickFunction}
                />
            : iconName === "FullscreenIcon" ?
                <FullscreenIcon
                    color={color}
                    width={width}
                    height={height}
                    className={className}
                    onClickFunction={onClickFunction}
                />
            : null }
        </div>
    )
}
export default Icon;
