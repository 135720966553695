import React, { Component } from 'react';
import axios from "axios";
import ModalSaving from "../../../ModalSaving";

class ModalDeleteAccount extends Component {
    constructor(){
        super();
        this.state = {
            picode : false,
            isActive : false,
            id: null,
            saved : false,
            error: false,
            deleteMsg: false,
            deletedMessage: null,
            messageError: ''
        };
        this.deleteAccount=this.deleteAccount.bind(this);
    }

    deleteAccount = async () => {
        await this.setStateAsync({saved : false, error: false});
        document.getElementById("ModalDeleteAccountCancelBtn").click();

        document.getElementById("modalDeleteUserActivePage").classList.add("show");

        const data ={
             usersToDelete: [
            {
                username: this.props.name,
                model: this.props.model
            }]
        }
        await axios.post("/api/v2/delete/user/", data, {
            headers: {
                api_key: 'wPhmcTXUmWHK'
            }
        }).then(resp => {
            if(resp.data.length){
                if(resp.data[0].deleted){
                    this.setState({saved : true, deletedMessage: resp.data[0].details  });
                }
                else{
                    this.setStateAsync({ messageError: resp.data[0].details, error: true});
                }
                return resp.data[0];
            }
            return { deleted: false };
        }).catch((err)=>{
            console.log(err);
            this.setState({error : true});
        });

    }
    setStateAsync(state) {
        return new Promise((resolve) => {
            this.setState(state, resolve);
        });
    }
    render(){
        return (
            <div>
                <div className="modal" id="ModalDeleteAccount" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header-section">
                                <h6 className="modal-title sectionTitle">Eliminar cuenta</h6>
                                <button type="button" className="close modalClose" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body modelBody">
                                <p className='textModalDisable'>¿Desea <b>eliminar de forma permanente</b> la cuenta de este usuario?</p>
                            </div>
                            <div className="modal-footer modalFooter">
                                <button type="button" className="btn btn-modalClose" id="ModalDeleteAccountCancelBtn" data-dismiss="modal">Cancelar</button>
                                <button type="button" className="btn btn-modalSuccess" onClick={this.deleteAccount}>Aceptar</button>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalSaving
                    saved={this.state.saved}
                    error={this.state.error}
                    customMessage={this.state.deletedMessage || ''}
                    messageError={this.state.messageError}
                    idModal="modalDeleteUserActivePage"
                />
            </div>
        )
    }
}
export default ModalDeleteAccount;
