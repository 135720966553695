import React, { Component } from 'react';
import axios from "axios";
import ModalSaving from "../../ModalSaving";

class ModalActivateReferral extends Component {
    constructor() {
        super();
        this.state = {
            saved: false,
            error: false
        }
        this.activateReferral = this.activateReferral.bind(this);
        this.desactivateReferral = this.desactivateReferral.bind(this);
    }
    activateReferral = async () => {
        await this.setState({ saved: false, error: false });
        document.getElementById("ModalActivateReferralCancelBtn").click();
        document.getElementById("modalSaveActivateReferral").classList.add("show");
        const saved = await axios.put("/api/referral/activate/" + this.props.referral, {}).then(resp => {
            return resp.data;
        });
        if (saved) {
            await this.setState({ saved: true })
            await this.props.reloadReferral;
        } else {
            await this.setState({ error: true })
        }
    }
    desactivateReferral = async () => {
        await this.setState({ saved: false, error: false });
        document.getElementById("ModalReferralCancelBtn").click();
        document.getElementById("modalSaveActivateReferral").classList.add("show");
        const saved = await axios.put("/api/referral/desactivate/" + this.props.referral, {}).then(resp => {
            return resp.data;
        });
        if (saved) {
            await this.setState({ saved: true })
            await this.props.reloadReferral;
        } else {
            await this.setState({ error: true })
        }

    }
    render() {
        return (
            <div>
                {(this.props.referral_status === 1) ?
                    <div>
                        <div className="modal" id="ModalDesReferral" role="dialog">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">Inhabilitar Referido</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <p>¿Seguro que desea inhabilitar a este referido?</p>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-danger" id="ModalReferralCancelBtn" data-dismiss="modal">Cancelar&nbsp;<i className="fas fa-times"></i></button>
                                        <button type="button" className="btn btn-success" onClick={this.desactivateReferral}>Guardar&nbsp;<i className="fas fa-save"></i></button>
                                    </div>
                                </div>
                            </div>                         
                        </div>
                    </div>
                    :
                    <div>
                        <div className="modal" id="ModalActReferral" role="dialog">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">Activar Referido</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <p>¿Seguro que desea activar a este referido?</p>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-danger" id="ModalActivateReferralCancelBtn" data-dismiss="modal">Cancelar&nbsp;<i className="fas fa-times"></i></button>
                                        <button type="button" className="btn btn-success" onClick={this.activateReferral}>Guardar&nbsp;<i className="fas fa-save"></i></button>
                                    </div>
                                </div>
                            </div>                         
                        </div>
                    </div>
                }
                <ModalSaving saved={this.state.saved} error={this.state.error} idModal="modalSaveActivateReferral" />
            </div>
        )
    }
}
export default ModalActivateReferral;
