import React, { Component } from 'react';
import axios from "axios";

class ModalReactiveWebsite extends Component {
    constructor(){
        super();
        this.state = {
            picode : false,
            isActive : false,
            id: null
        };
        this.reactiveWebsite=this.reactiveWebsite.bind(this);
    }

    reactiveWebsite = async () => {
            document.getElementById("ModalReactiveWebsiteCancelBtn").click();
            // document.getElementById("modalSaveActivePage").classList.add("show");
            const data ={
                id: this.props.id,
                hostname: this.props.hostname,
                status: 1
            }
            const changeStatus = await axios.post("/api/model/statusWebsite/", data)
            .then(resp => {
                return resp.data;
            });
            if (changeStatus) {
                document.getElementById('ModalReactiveWebsite').classList.remove('show');
                window.location.reload();
            } else {
                console.log('Activate Error')
            }
    }
    closeModal(){
        document.getElementById('ModalReactiveWebsite').classList.remove('show');
    }
    render(){
        return (
            <div className="modal fade ModalReactiveWebsite" id="ModalReactiveWebsite" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header-section">
                            <h6 className="modal-title sectionTitle">Reactivar el Sitio Web</h6>
                            <button type="button" className="close modalClose" data-dismiss="modal" aria-label="Close" onClick={this.closeModal}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body modelBody">
                            <p className='textModalDisable'>¿Desea reactivas el sitio de esta modelo?</p>
                        </div>
                        <div className="modal-footer modalFooter">
                            <button type="button" className="btn btn-modalClose" id="ModalReactiveWebsiteCancelBtn" data-dismiss="modal" onClick={this.closeModal}>Cancelar</button>
                            <button type="button" className="btn btn-modalSuccess" onClick={this.reactiveWebsite}>Aceptar</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default ModalReactiveWebsite;
