import React, { Component } from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import CarouselElement from "./CarouselElement";
import './style.css'

class CarouselObj extends Component{

    playVideo = async (e) => {
        let select = e.target;
        let parent = e.target.parentNode;
        let video= (parent.tagName === "DIV") 
            ? parent.previousSibling 
            : (parent.tagName === "g") 
                ? parent.parentNode.parentNode.previousSibling
                : select;
        if(video.paused) {
            video.play();
            video.nextSibling.firstChild.classList.add("playing");
        }
        else{
            video.pause();
            video.nextSibling.firstChild.classList.remove("playing");
        }
    }

    drawMedia(){
        let media = [...this.props.media];
        let length = media.length;
        let id = this.props.id !== undefined ? this.props.id : null;
        return media.map((item, index) => {
            return (
                <CarouselElement
                    media={item}
                    length={length}
                    playVideo={this.playVideo}
                    key={index}
                    index={index}
                    remove={this.props.remove}
                    id={id}
                />
            )
        });
    }

    render(){
        let media = [...this.props.media];
        return(
            (media.length > 1) ?
                <Carousel
                    showStatus={false}
                    dynamicHeight={false}
                    emulateTouch={true}
                    showThumbs={false}
                >
                    {this.drawMedia()}
                </Carousel>
                :
                <div className="only">
                    {this.drawMedia()}
                </div>
        )
    }
}

export default CarouselObj;
