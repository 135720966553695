import React, { Component } from 'react';
import axios from "axios";
import ModalSaving from "../../../ModalSaving";

class ModalContentRequestRefund extends Component {
    constructor(props) {
        super(props);
        this.state = {
            saved: false,
            error: false,
        };
        this.disableContentRequest = this.disableContentRequest.bind(this);
        this.refundContentRequest = this.refundContentRequest.bind(this);
    }
    disableContentRequest = async () => {
        await this.setState({ saved: false, error: false });
        if (this.props.allContentRequest) {
            document.getElementById("modalDisableContentRequestCancelBtn").click();
            document.getElementById("modalSaveRDContentRequest").classList.add("show");
            let model = this.props.model;
            const data = {
                model: model
            }
            const saved = await axios.put('/api/contentrequest/alldisable/' + this.props.username, data).then(async resp => {
                return resp.data;
            });
            if (saved) {
                await this.setState({ saved: true });
                await this.props.reloadContentRequest();
            } else {
                await this.setState({ error: true });
            }
        } else {
            document.getElementById("modalDisableContentRequestCancelBtn").click();
            document.getElementById("modalSaveRDContentRequest").classList.add("show");
            let idmessage = this.props.idDisable;
            const saved = await axios.put('/api/contentrequest/disable/' + idmessage).then(async resp => {
                return resp.data;
            });
            if (saved) {
                await this.setState({ saved: true });
                await this.props.reloadContentRequest();
            } else {
                await this.setState({ error: true });
            }
        }
    }
    refundContentRequest = async () => {
        await this.setState({ saved: false, error: false });
        if (this.props.allContentRequest) {
            document.getElementById("modalRefundContentRequestCancelBtn").click();
            document.getElementById("modalSaveRDContentRequest").classList.add("show");
            let model = this.props.model;
            let idUser = this.props.idUser;
            const data = {
                model: model,
                idUser: idUser
            }
            const saved = await axios.put('/api/contentrequest/allrefund/' + this.props.username, data).then(async resp => {
                return resp.data;
            });
            if (saved) {
                await this.setState({ saved: true });
                await this.props.reloadContentRequest();
            } else {
                await this.setState({ error: true });
            }
        } else {
            document.getElementById("modalRefundContentRequestCancelBtn").click();
            document.getElementById("modalSaveRDContentRequest").classList.add("show");
            const data = {
                idUser: this.props.idUser
            }
            let idmessage = this.props.idDisable;
            const saved = await axios.get('/api/contentrequest/refund/' + idmessage, { params: data }).then(async resp => {
                return resp.data;
            });
            if (saved) {
                await this.setState({ saved: true });
                await this.props.reloadContentRequest();
            } else {
                await this.setState({ error: true });
            }
        }
    }
    render() {
        return (
            <div>
                <div className="modal" id="ModalRefundDiContentRequest" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header-section">
                                <h5 className="modal-title sectionTitle">Desactivar Solicitud de Contenido</h5>
                                <button type="button" className="close modalClose" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body modelBody">
                                <p>¿Desea continuar con la desactivaci&oacute;n? (SI) se le reembolsar&aacute; las monedas al usuario.</p>
                            </div>
                            <div className="modal-footer modalFooter">
                                <button type="button" className="btn btn-modalClose" id="modalRefundContentRequestCancelBtn" data-dismiss="modal">Cancelar</button>
                                <button type="button" className="btn btn-modalSuccess" onClick={this.refundContentRequest}>Guardar</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal" id="ModalOnlyDisableContentRequest" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header-section">
                                <h5 className="modal-title sectionTitle">Desactivar Solicitud de Contenido</h5>
                                <button type="button" className="close modalClose" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body modelBody">
                                <p>¿Desea continuar con la desactivaci&oacute;n? (NO) se le reembolsar&aacute; las monedas al usuario.</p>
                            </div>
                            <div className="modal-footer modalFooter">
                                <button type="button" className="btn btn-modalClose" id="modalDisableContentRequestCancelBtn" data-dismiss="modal">Cancelar</button>
                                <button type="button" className="btn btn-modalSuccess" onClick={this.disableContentRequest}>Guardar</button>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalSaving saved={this.state.saved} error={this.state.error} idModal="modalSaveRDContentRequest" />
            </div>
        )
    }
}
export default ModalContentRequestRefund;