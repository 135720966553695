import React from 'react';

const EyeIcon= ({color, width, height, className = null})=>{
    return(
        <svg   
            xmlns="http://www.w3.org/2000/svg" 
            width={width} 
            height={height}
            fill="none" 
            viewBox="0 0 18 10"
            className={className}
        >
            <g 
                fill={color}
            >
                <path 
                    d="M17.866 4.418c-.04-.045-1.008-1.12-2.575-2.203C13.196.765 11.021 0 9 0 6.979 0 4.804.766 2.709 2.215 1.142 3.299.175 4.373.134 4.418c-.179.2-.179.503 0 .703.04.046 1.008 1.12 2.575 2.204C4.804 8.774 6.979 9.54 9 9.54c2.021 0 4.196-.766 6.291-2.215 1.567-1.084 2.534-2.158 2.575-2.204.179-.2.179-.502 0-.703zM3.33 6.472C2.383 5.82 1.66 5.16 1.263 4.77c.681-.667 2.323-2.128 4.43-3.006C4.97 2.56 4.53 3.614 4.53 4.77c0 1.155.44 2.209 1.162 3.003-.917-.381-1.723-.861-2.36-1.3zM9 8.186c-1.884 0-3.416-1.532-3.416-3.416S7.116 1.354 9 1.354s3.416 1.532 3.416 3.416S10.884 8.186 9 8.186zm3.307-.41c.723-.795 1.164-1.85 1.164-3.006 0-1.155-.44-2.209-1.162-3.003.917.381 1.723.86 2.36 1.3.948.653 1.67 1.313 2.068 1.703-.681.667-2.322 2.128-4.43 3.006z"
                />
                <path 
                    d="M9 3.07c-.937 0-1.7.763-1.7 1.7 0 .937.763 1.7 1.7 1.7.291 0 .527-.237.527-.528 0-.145-.059-.277-.154-.373-.096-.095-.228-.154-.373-.154-.356 0-.645-.29-.645-.645 0-.356.29-.645.645-.645.355 0 .645.29.645.645 0 .146.059.277.154.373.096.095.228.154.373.154.291 0 .527-.236.527-.527 0-.937-.762-1.7-1.7-1.7z"
                />
            </g>
        </svg>
    )
}
export default EyeIcon;
