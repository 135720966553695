import React, { Component } from 'react';
import Icon from "../../../../../iconos/Icon";

class CarouselElement extends Component{

    renderMedia(){
        let media = this.props.media;
        if(media.type === "video") {
            return(
                <video
                    preload="yes"
                    poster={(media.method === "mediabox" || media.method === "saved") ?
                        media.poster
                        :
                        ""
                    }
                    className="responsive-video carouselVideo"
                    controlsList="nodownload"
                    onClick={(e) => {this.props.playVideo(e)} }
                >
                    <source
                        src={(media.method === "mediabox" || media.method === "saved") ?
                            media.file
                            :
                            URL.createObjectURL(media.file)
                        }
                        type="video/mp4"
                    />
                    Your browser does not support the video tag.
                </video>
            )
        }
        else{
            return(
                <img
                    alt="preview"
                    className="responsive-img"
                    src={(media.method === "mediabox" || media.method === "saved") ?
                        media.file
                        :
                        URL.createObjectURL(media.file)
                    }
                />
            )
        }
    }

    render(){
        let media = this.props.media;
        let index = this.props.index;
        let id = this.props.id;
        return(
            (media.file) ?
                <p>
                    {this.renderMedia()}
                    {media.type === "video" ?
                        <Icon
                            iconName="PlayIcon"
                            color="rgb(255 255 255 / 50%)"
                            width="5em"
                            height="5em"
                            className="playvideo-event play-video"
                            onClickFunction={(e) => {this.props.playVideo(e)} }
                        />
                        : null
                    }
                    <span
                        className="close"
                        onClick={() => (id !== null) ? this.props.remove(index, id) : this.props.remove(index)}
                    >
                        <Icon
                            iconName="CloseIcon"
                            color="rgb(222 3 3)"
                            width="2em"
                            height="2em"
                        />
                    </span>
                </p>
            : null
        )
    }
}

export default CarouselElement;
