import React, { Component } from 'react';
import axios from "axios";

class ModalReactiveAccount extends Component {
    constructor(){
        super();
        this.state = {
            picode : false,
            isActive : false,
            id: null
        };
        this.reactiveAccount=this.reactiveAccount.bind(this);
    }

    reactiveAccount = async () => {
            document.getElementById("ModalReactiveAccountCancelBtn").click();
            // document.getElementById("modalSaveActivePage").classList.add("show");
            const data ={
                id: this.props.id,
                status: 0
            }
            const changeStatus = await axios.post("/api/user/statusAccount/", data)
            .then(resp => {
                return resp.data;
            });
            if (changeStatus) {
                document.getElementById('ModalReactiveAccount').classList.remove('show');
                window.location.reload();
            } else {
                console.log('Activate Error')
            }
    }
    closeModal(){
        document.getElementById('ModalReactiveAccount').classList.remove('show');
    }
    render(){
        return (
            <div className="modal fade ModalReactiveAccount" id="ModalReactiveAccount" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header-section">
                            <h6 className="modal-title sectionTitle">Reactivar cuenta</h6>
                            <button type="button" className="close modalClose" data-dismiss="modal" aria-label="Close" onClick={this.closeModal}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body modelBody">
                            <p className='textModalDisable'>¿Desea reactivas la cuenta de este usuario?</p>
                        </div>
                        <div className="modal-footer modalFooter">
                            <button type="button" className="btn btn-modalClose" id="ModalReactiveAccountCancelBtn" data-dismiss="modal" onClick={this.closeModal}>Cancelar</button>
                            <button type="button" className="btn btn-modalSuccess" onClick={this.reactiveAccount}>Aceptar</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default ModalReactiveAccount;
