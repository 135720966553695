import React, { Component } from 'react';
import moment from 'moment';
import ModalSaving from '../../../ModalSaving';
import DatePicker from "react-datepicker";
import ModalStatusNotification from '../../ModalStatusNotification';

class NewsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            saved: false,
            error: false,
            idNews: null,
            idModel: null,
            logStatus: null,
            modelName: null,
            startDate: false,
            messageError: null
        }
        this.changeStatus = this.changeStatus.bind(this);
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.id !== this.props.id) {
            this.props.getNewsList();
        }
        if(this.props.realoadNewList){
            this.props.reloadModels();
        }
    }

    handleChangeStart= async(date) =>{
        await this.setState({
            startDate: date
        });
    }

    changeStatus(e, id, idModel, logStatus, modelName){
        e.preventDefault();
        this.setState({
            idNews: id , 
            idModel: idModel, 
            logStatus: logStatus,
            modelName: modelName
        });
    }

    showModel() {
        if (this.props.modelos.length === 0) {
            return (<tr><td colSpan={6} className="text-center">No hay modelos</td></tr>)
        }
        const modelos = [...this.props.modelos];
        return modelos.map((item, index) => {
            return (
                <tr key={index}>
                    <td>
                        {index + 1}
                    </td>
                    <td>
                        <a 
                            id="currentUsername" 
                            href={"/models/model/"+item.idModel}
                        >
                            {item.modelName}
                        </a>
                    </td>
                    <td>
                        {(this.props.modifyModelDate && this.props.selectedRow === index) ?
                            <div>
                                <div className="exp-date-detail">
                                    <DatePicker 
                                        showTimeSelect
                                        timeIntervals={15}
                                        minDate={new Date()}
                                        dateFormat="MMMM d, yyyy HH:mm"
                                        onChange={this.handleChangeStart}
                                        className="input-tiny form-control form-control-sm dateInput"
                                        selected={new Date(this.state.startDate 
                                            ? this.state.startDate 
                                            : moment(item.expiration).format("YYYY-MM-DD HH:mm"))
                                        }
                                    />
                                </div>
                                <button
                                    className="btn btn-saveEditModeNews"
                                    onClick={(e) => this.props.changeExpiration(e,item.id,this.state.startDate ? this.state.startDate : moment(item.expiration).format("YYYY-MM-DD HH:mm"), item.idModel)}
                                >
                                    <i className="fas fa-save"></i>
                                </button>
                            </div>
                        :
                            <div>
                                {moment(item.expiration).format("YYYY-MM-DD HH:mm")}
                                <button 
                                    type="button" 
                                    className="btn btn-editModeNews" 
                                    onClick={(e) => this.props.openModify(e, index)}
                                >
                                    <span className="fas fa-edit"></span>
                                </button>
                            </div>
                        }
                    </td>
                    <td>
                        {item.log_status === 1
                            ? "No leído"
                            :item.log_status === 2 
                                ? "Leído"
                                : "Desactivado"
                        }
                    </td>
                    <td>
                        {item.log_status !== 0 ?
                            <div className="checked-on onoff-style">
                                <button 
                                    id="diable"
                                    data-toggle="modal"
                                    className="btn btn-alignIcon"
                                    data-target="#ModalStatusNotification"
                                    onClick={(e) => this.changeStatus(e, item.id, item.idModel, item.log_status, item.modelName)}
                                >
                                    <i 
                                        className="fas fa-power-off icon-check-new"
                                    ></i>
                                </button>
                            </div>
                        :
                            <div className="checked-off onoff-style">
                                <button 
                                    id="able"
                                    data-toggle="modal"
                                    className="btn btn-alignIcon"
                                    data-target="#ModalStatusNotification"
                                    onClick={(e) => this.changeStatus(e, item.id, item.idModel, item.log_status, item.modelName)}
                                >
                                    <i className="fas fa-power-off icon-desactive-new"
                                    ></i>
                                </button>
                            </div>
                        }
                    </td>
                </tr>
            )
        })
    }
    render() {
        return (
            <div className="">
                <div className="text-white head-table">
                    Enviado a
                </div>
                <div className="tableDiv table-responsive-xl">
                    <table className="table tableDataSection middle-style">
                        <thead>
                            <tr className="table-primary">
                                <th>#</th>
                                <th>Nombre</th>
                                <th>Fecha de expiraci&oacute;n</th>
                                <th>Estatus</th>
                                <th>Desactivar / Activar</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.showModel()}
                        </tbody>
                    </table>
                </div>
                <ModalStatusNotification
                    idNews={this.state.idNews}
                    idModel={this.state.idModel}
                    logStatus={this.state.logStatus}
                    modelName={this.state.modelName}
                    reloadNews = {this.props.reloadModels}
                />
                <ModalSaving
                    saved={this.state.saved}
                    error={this.state.error}
                    idModal="modalSavingReferral"
                    messageError={this.state.messageError}
                />
            </div>
        )
    }
}
export default NewsList;