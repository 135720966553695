import React, { Component } from 'react';
import axios from "axios";
import ModalSaving from '../../../ModalSaving';

class ModalNewModelReferral extends Component{
    constructor(){
        super();
        this.state = {
            messageError: null,
            modelos: [],
            saved: true,
            error: false
        };
        this.saveModelReferral = this.saveModelReferral.bind(this);
    }
    componentDidMount(){
        this.showModelos();
        this.getModels();
    }
    getModels = async () => {
        await axios.get("/api/models/").then(async resp => {
            await this.setState({modelos : resp.data})
        });
    };
    showModelos(){
        if(this.state.modelos.length === 0){
            return ("No hay modelos")
        }
        const modelos = [...this.state.modelos];
        // eslint-disable-next-line
        return modelos.map((item,index) => {
            if(item.status_active === 1){
                return (
                    <option key={index} value={item.id_model}>{item.username}</option>
                )
            }
        })
    }
    saveModelReferral = async () => {  
        await this.setState({saved : false, error: false});
        let send = true;
        let id_referral = this.props.id_referral;
        const username = document.getElementById("nameSelect").value;
        const split = document.getElementById("splitInput").value;
        const coins = document.getElementById("coinsInput").value;
        if(username===""){
            document.getElementById("nameSelect").classList.add("is-invalid");
            send = false;
        }
        else{
            document.getElementById("nameSelect").classList.remove("is-invalid");
            
        }
        if(split==="" || isNaN(split) || split < 0 || split > 100){
            document.getElementById("splitInput").classList.add("is-invalid");
            send = false;
        }
        else{
            document.getElementById("splitInput").classList.remove("is-invalid");
        }
        if(coins==="" || isNaN(coins) || coins < 0 || coins > 100){ 
            document.getElementById("coinsInput").classList.add("is-invalid");
            send = false;
        }
        else{
            document.getElementById("coinsInput").classList.remove("is-invalid");
        }
        if(send) {
            document.getElementById("ModalNewReferralCancelBtn").click();
            document.getElementById("ModalSaving").classList.add("show");
            const data = {
                id_referral: id_referral,
                id_model: username,
                split: split,
                coins: coins
            }
            const saved = await axios.post("/api/referral/modelsInsert", data).then(resp => {
                return resp.data;
            });
            await this.props.getModelReferral();
            if (saved === false) {
                await this.setState({saved : true, error : false})             
            } else {
                await this.setState({error : true, saved : false, messageError: `La suma de los porcentajes excede el 100%, 
                en caso de que la suma de los referidos no sea mayor que 100 consulta los porcentajes en el detalle de la modelo.`})
            }
        }
    }
    updateModel(idModel){
        this.setState({id_model : idModel});
    }
    render(){
        return(
            <div>
                <div className="modal" id="ModalNewModelReferral" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header-section">
                                <h6 className="modal-title sectionTitle">Agregar Modelo</h6>
                                <button type="button" className="close modalClose modal-closeSection" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body referralBody">
                                <form id="newModelReferral">
                                    <div className="form-group">
                                        <label>Modelo</label>
                                        <select name="idmodel" id="nameSelect" className="form-control form-control-sm" required>                                  
                                            <option value="0">Seleccionar</option>
                                            {this.showModelos()}
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <input type="number" placeholder="Split" className="form-control" id="splitInput"/>
                                    </div>
                                    <div className="form-group">
                                        <input type="number" placeholder="Monedas" className="form-control" id="coinsInput"/>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer modalFooter">
                                <button type="button" className="btn btn-modalClose" id="ModalNewReferralCancelBtn" data-dismiss="modal">Cancelar</button>
                                <button type="button" className="btn btn-modalSuccess" onClick={this.saveModelReferral}>Referir</button>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalSaving saved={this.state.saved} error={this.state.error} messageError={this.state.messageError}/>               
            </div>
        )
    }
}
export default ModalNewModelReferral;