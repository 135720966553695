import React, { Component } from 'react';
import axios from "axios";
import ModalSaving from "../../../ModalSaving";

class ModalNewCoin extends Component {
    constructor(){
        super();
        this.state = {
            picode : false,
            isActive : false,
            nuevaCoin : {},
            saved : false,
            error : false
        };
        this.saveCoinPrice=this.saveCoinPrice.bind(this);
        this.changeInput = this.changeInput.bind(this);
    }
    componentDidMount(){

    }
    changeInput = function(e){
        const picode = document.getElementById("picode").value;
        const precio = document.getElementById("precio").value;
        const moneda = document.getElementById("moneda").value;
        const coins = document.getElementById("coins").value;
        const paymentPro = document.getElementById("paymentPro").value;
        const data = {
            picode: picode,
            precio: precio,
            moneda: moneda,
            coins: coins,
            paymentPro: paymentPro
        };
        this.setState({ nuevaCoin : data});
    }
    saveCoinPrice = async () => {
        await this.setState({saved : false, error: false});
        let send=true;
        const picode = document.getElementById("picode").value;
        const precio = document.getElementById("precio").value;
        const moneda = document.getElementById("moneda").value;
        const coins = document.getElementById("coins").value;
        const paymentPro = document.getElementById("paymentPro").value;
        if(picode===""){
            document.getElementById("picode").classList.add("is-invalid");
            send = false;
        }
        else{
            document.getElementById("picode").classList.remove("is-invalid");
        }
        if(precio==="" || isNaN(precio) || precio<=0){
            document.getElementById("precio").classList.add("is-invalid");
            send = false;
        }
        else{
            document.getElementById("precio").classList.remove("is-invalid");
        }
        if(moneda==="0"){
            document.getElementById("moneda").classList.add("is-invalid");
            send = false;
        }
        else{
            document.getElementById("moneda").classList.remove("is-invalid");
        }
        if(coins==="" || isNaN(coins) || coins<=0){
            document.getElementById("coins").classList.add("is-invalid");
            send = false;
        }
        else{
            document.getElementById("coins").classList.remove("is-invalid");
        }
        if(paymentPro==="0"){
            document.getElementById("paymentPro").classList.add("is-invalid");
            send = false;
        }
        else{
            document.getElementById("paymentPro").classList.remove("is-invalid");
        }
        if(send) {
            document.getElementById("ModalNewCoinCancelBtn").click();
            document.getElementById("ModalSaving").classList.add("show");
            const formData = new FormData();
            const form = document.getElementById("setupCoin");
            formData.append("idModel",this.props.model);
            for( let k = 0 ; k<form.length; k++){
                let element=form[k];
                if(element.tagName.toUpperCase() === "INPUT") {
                    if(element.type === "checkbox" || element.type === "radio"){
                        if(element.checked)
                            formData.append(element.name, element.value);
                    }
                    else{

                        if(element.name !== "")
                            formData.append(element.name, element.value);

                    }
                }
                if(element.tagName.toUpperCase() === "SELECT"){
                    formData.append(element.name, element.value)
                }
            }
            const saved = await axios.post("/api/model/coin/", formData).then(resp => {
                return resp.data;
            });
            if (saved.Error === undefined) {
                await this.setState({saved : true})
                await this.props.reloadCoins();
            } else {
                await this.setState({error : true});
                let picodeInput = document.getElementById("picode");
                if(picodeInput !== null)
                    picodeInput.classList.add("is-invalid");
            }
        }
    }
    render(){
        return (
            <div>
                <div className="modal" id="ModalNewCoin" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header-section">
                                <h5 className="modal-title sectionTitle">Cambiar precio de moneda</h5>
                                <button type="button" className="close modalClose" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body modelBody">
                                <form id="setupCoin">
                                    <div className="form-group">
                                        <input type="text" placeholder="PiCode" className="form-control" id="picode" name="picode" onChange={this.changeInput}/>
                                    </div>
                                    <div className="form-group">
                                        <input type="text" placeholder="Precio" className="form-control" id="precio" name="precio" onChange={this.changeInput}/>
                                    </div>
                                    <div className="form-group">
                                        <select name="moneda" className="form-control" id="moneda" defaultValue={0} onChange={this.changeInput}>
                                            <option value="0" disabled>Moneda</option>
                                            <option value="USD">USD</option>
                                            <option value="MXN">MXN</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <input type="number" placeholder="Monedas" className="form-control" id="coins" name="coins" onChange={this.changeInput}/>
                                    </div>
                                    <div className="form-group">
                                        <select name="paymentPro" id="paymentPro" className="form-control" defaultValue={0} onChange={this.changeInput}>
                                            <option value="0">Procesador de Pagos</option>
                                            <option value="1">EPOCH</option>
                                            <option value="2">CCBILL</option>                                           
                                        </select>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer modalFooter">
                                <button type="button" className="btn btn-modalClose" id="ModalNewCoinCancelBtn" data-dismiss="modal">Cancelar</button>
                                <button type="button" className="btn btn-modalSuccess" onClick={this.saveCoinPrice}>Guardar</button>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalSaving saved={this.state.saved} error={this.state.error}/>
            </div>
        )
    }
}
export default ModalNewCoin;