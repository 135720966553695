import React, { Component } from 'react';
import axios from "axios";
import ModalSaving from "../../ModalSaving";

class AccessModules extends Component {
    constructor(){
        super();
        this.state = {
            access : ["Ninguno","Propio","Hijos","Todos"],
            saved : false,
            error: false
        }
        this.savePermisos = this.savePermisos.bind(this);
    }
    componentDidMount(){

    }
    savePermisos = async () => {
        await this.setState({saved : false, error: false});
        document.getElementById("modalSave"+this.props.access.name).classList.add("show");
        const table = document.getElementById("tablePermisos"+this.props.access.name);
        const formData = new FormData();
        formData.set("permiso", this.props.access.id)
        for( let k = 0 ; k<table.length; k++){
            let element=table[k];
            if(element.tagName.toUpperCase() === "SELECT"){
                formData.set(element.name, element.value)
            }
        }
        const saved = await axios.put("/api/access/", formData).then(resp => {
            return resp.data;
        });
        if (saved.Error === undefined) {
            await this.setState({saved : true})
            await this.props.reloadPermisos();
        } else {
            await this.setState({error : true});
            let picodeInput = document.getElementById("picode");
            if(picodeInput !== null)
                picodeInput.classList.add("is-invalid");
        }
    }
    drawSelect(){
        const accessType=[...this.state.access];
        return accessType.map((item,index) => {
            return (<option key={index} value={index}>{item}</option>)
        })
    }
    pintarModulos(){
        if(this.props.access.permisos === 0){
            return (<div className={"text-center"}>Empty</div>)
        }
        const permisos = [...this.props.access.permisos];
        return permisos.map((item,index) => {
            return(
                <tr key={index}>
                    <td>
                        {item.module}
                    </td>
                    <td>
                        <select defaultValue={item.r} className="form-control" name={"access-"+item.id+"-r"} id={"access"+item.id+"r"}>
                            {this.drawSelect()}
                        </select>
                    </td>
                    <td>
                        <select defaultValue={item.c} className="form-control" name={"access-"+item.id+"-c"} id={"access"+item.id+"c"}>
                            {this.drawSelect()}
                        </select>
                    </td>
                    <td>
                        <select defaultValue={item.u} className="form-control" name={"access-"+item.id+"-u"} id={"access"+item.id+"u"}>
                            {this.drawSelect()}
                        </select>
                    </td>
                    <td>
                        <select defaultValue={item.d} className="form-control" name={"access-"+item.id+"-d"} id={"access"+item.id+"d"}>
                            {this.drawSelect()}
                        </select>
                    </td>
                </tr>


            )
        })
    }
    render(){
        return (
            <div>
                <div className="table-responsive">
                    <form id={"tablePermisos"+this.props.access.name}>
                        <table className="table tableDataSection">
                            <thead className='access-table'>
                                <tr>
                                    <th width="20%" className='tr-access'>Modulo</th>
                                    <th width="20%">Leer</th>
                                    <th width="20%">Crear</th>
                                    <th width="20%">Actualizar</th>
                                    <th width="20%">Borrar</th>
                                </tr>
                            </thead>
                            <tbody className='body-access'>
                                {this.pintarModulos()}
                            </tbody>
                        </table>
                    </form>
                </div>
                <div className="col-12 text-right">
                    <button className="btn btn-newModel" onClick={this.savePermisos}>Guardar</button>
                </div>
                <ModalSaving saved={this.state.saved} error={this.state.error} idModal={"modalSave"+this.props.access.name}/>
            </div>
        )
    }
}
export default AccessModules;