import React, { Component } from 'react';
import axios from "axios";
import moment from 'moment';
import ModalDisableContentRequest from "../../../../components/Customers/Detail/ModalDisableContentRequest";
import ModalContentRequestRefund from "../../../../components/Customers/Detail/ModalContentRequestRefund";

class ContentRequest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contentRequest: [],
            contentRequestAnswered: [],
            idmessage: null,
            allContentRequest: false,
            refund: false
        };
    }
    componentDidMount() {
        this.getContentRequest();
        this.getContentRequestAnswered();
        this.showContentRequest();
        this.showContentRequestAnswered();
    }
    refundCoinsContentRequest = async (e) => {
        if(e.target.click){
            this.setState({refund: true});
        }
        document.getElementById("refundCloseBtn").click(); 
    }
    refundContentRequestC = async (e) => {
        if(e.target.click){
            this.setState({refund: false});
        }
        document.getElementById("refundCloseBtn").click();   
    }
    reloadContentRequest = async () => {
        this.getContentRequest();
        this.getContentRequestAnswered();
    }
    getContentRequest = async () => {
        const data = {
            model: this.props.model
        }
        await axios.get("/api/usercontent/request/" + this.props.username, { params: data }).then(async resp => {
            await this.setState({ contentRequest: resp.data })
        });
    }
    getContentRequestAnswered = async () => {
        const data = {
            model: this.props.model
        }
        await axios.get("/api/usercontent/requestanswered/" + this.props.username, { params: data }).then(async resp => {
            await this.setState({ contentRequestAnswered: resp.data })
        });
    }
    showContentRequest() {
        if (this.state.contentRequest.length === 0) {
            return (<tr><td colSpan={5} className="text-center">No hay Informaci&oacute;n de solicitud de contenido</td></tr>)
        }
        const contentRequest = [...this.state.contentRequest];
        return contentRequest.map((item, index) => {
            let datetime = moment(item.timeCreated).format("DD/MMM/YYYY HH:mm:ss");
            let idmessage = item.idMessage;
            if(item.answered === 0){
                return (
                    <tr key={index}>
                        <td>{item.idMessage}</td>
                        {item.module === 'audio-message-req' ?
                            <td>Petici&oacute;n de Audio</td>
                            : ""
                        }
                        {item.module === 'image-message-req' ?
                            <td>Petici&oacute;n de Imagen</td>
                            : ""
                        }
                        {item.module === 'video-message-req' ?
                            <td>Petici&oacute;n de Video</td>
                            : ""
                        }
                        <td>{datetime}</td>
                        <td>
                            <ul className="list-inline">
                                <li className="list-inline-item">
                                    <button className="btn btn-sm btn-reactivate" data-toggle="modal" data-target="#ModalDisableContentRequest">
                                        <span onClick={() => this.contentRequestDisable(idmessage)}>
                                            Desactivar
                                        </span>
                                    </button>
                                </li>
                            </ul>
                        </td>
                    </tr>
                )
            }
        })
    }
    contentRequestDisable = async (idmessage) => {
        this.setState({idmessage: idmessage});
    }
    showContentRequestAnswered() {
        if (this.state.contentRequestAnswered.length === 0) {
            return (<tr><td colSpan={5} className="text-center">No hay Informaci&oacute;n de solicitud de contenido</td></tr>)
        }
        const contentRequestAnswered = [...this.state.contentRequestAnswered];
        return contentRequestAnswered.map((item, index) => {
            let datetime = moment(item.timeCreated).format("DD/MMM/YYYY HH:mm:ss");
            if(item.answered === 1){
                return (
                    <tr key={index}>
                        <td>{item.idMessage}</td>
                        {item.module === 'audio-message-req' ?
                            <td>Petici&oacute;n de Audio</td>
                            : ""
                        }
                        {item.module === 'image-message-req' ?
                            <td>Petici&oacute;n de Imagen</td>
                            : ""
                        }
                        {item.module === 'video-message-req' ?
                            <td>Petici&oacute;n de Video</td>
                            : ""
                        }
                        <td>{datetime}</td>
                    </tr>
                )
            }
        })
    }
    allContentRequest = async (e) => {
        if(e.target.click){
            await this.setState({allContentRequest: true});
        }
    }
    render() {
        return (
            <div className="row">
                <div className="col-12">
                    <div className="text-white head-table">
                        Solicitud de Contenido del Usuario
                    </div>
                    <div id='' className="container-fluid">
                        <div className="divCard row">
                            <div className="row">
                                <div className="col">
                                    <button className="btn btn-reactivate" onClick={(e) => this.allContentRequest(e)} data-toggle="modal" data-target="#ModalDisableContentRequest">
                                        Desactivar Todas
                                    </button>
                                    <br></br>
                                    <br></br>
                                    <div className="table-responsive tableDiv tableScoll-lg">
                                        <table className="table tableDataSection">
                                            <thead>
                                                <tr className="attr-table table-primary">
                                                    <th>ID Contenido</th>
                                                    <th>Nombre del M&oacute;dulo</th>
                                                    <th>Fecha de Creaci&oacute;n</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td colSpan='5' className='attr-title'>Solicitud de Contenido Activo</td>
                                                </tr>
                                                {this.showContentRequest()}
                                                <tr>
                                                    <td colSpan='5' className='attr-title'>Solicitud de Contenido Respondido</td>
                                                </tr>
                                                {this.showContentRequestAnswered()}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ModalDisableContentRequest
                        refundCoinsContentRequest={this.refundCoinsContentRequest}
                        refundContentRequestC={this.refundContentRequestC}
                    />
                    <ModalContentRequestRefund 
                        idDisable={this.state.idmessage} 
                        allContentRequest={this.state.allContentRequest} 
                        reloadContentRequest={this.reloadContentRequest} 
                        model={this.props.model} 
                        username={this.props.username}
                        idUser={this.props.idUser}  
                    />
                </div>
            </div>
        )
    }
}
export default ContentRequest;