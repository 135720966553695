import React, { Component } from 'react';
import axios from "axios";
import moment from "moment";
import Header from '../../../../components/Layout/Header';
import Sidebar from '../../../../components/Layout/Sidebar';
import Footer from '../../../../components/Layout/Footer';
import ModalWarning from '../../../../components/Layout/ModalWarning';
import ModalNewMemberships from '../../../../components/Model/Detail/ModalNewMemberships';
import ModalUpdateMembership from '../../../../components/Model/Detail/ModalUpdateMembership';
import ModalActiveMembership from "../../../../components/Model/Detail/ModalActiveMembership";
import ModalDisableMembership from "../../../../components/Model/Detail/ModalDisableMembership";
import ModalDisableMembershipAll from "../../../../components/Model/Detail/ModalDisableMembershipAll";
import ModalSetMainMembership from "../../../../components/Model/Detail/ModalSetMainMembership";
import ModalSetPromo from "../../../../components/Model/Detail/ModalSetPromo";
import ModalDeleteTrial from "../../../../components/Model/Detail/ModalDeleteTrial";
import ModalActivateTrial from "../../../../components/Model/Detail/ModalActivateTrial";
import ModalDeletePromo from "../../../../components/Model/Detail/ModalDeletePromo";
import ModalResetPromo from "../../../../components/Model/Detail/ModalResetPromo";
import ModalShowRegionMembership from "../../../../components/Model/Detail/ModalShowRegionMembership";

class Memberships extends Component {
    constructor() {
        super();
        this.state = {
            id: null,
            membresias: { Activas: [], Historial: [], Trials: [] },
            membresiasRegion: [],
            promos: [],
            updating: null,
            activating: null,
            promoting: null,
            untrial: null,
            toTrial: null,
            unpromo: null,
            promo: null,
            promoToUnpromo: null,
            promoToPromo: null,
            updatingMembership: {},
            activeMembership: {},
            promotingMembership: {},
            untrialMembership: {},
            toTrialMembership: {},
            unpromoMembership: {},
            promoMembership: {},
            disableMembership: null,
            settingMembership: null,
            totalActivates: 0,
            mainMembership: 0,
            countries: [],
            regiones: [],
            name: null,
            username: null,
            hostname: null,
            currCountry: 0,
            currRegion: 0,
            path: "Home / Modelos / ",
            view: "Membresias",
            searchByRegion: 0,
            regionMembership: 0,
            regionPicode: "",
            currTrial: null
        }
        this.setUpdating = this.setUpdating.bind(this);
        this.setActivating = this.setActivating.bind(this);
        this.setDisabling = this.setDisabling.bind(this);
        this.setSetting = this.setSetting.bind(this);
        this.updatePiCodeEdited = this.updatePiCodeEdited.bind(this);
        this.reloadMemberships = this.reloadMemberships.bind(this);
        this.changeCountry = this.changeCountry.bind(this);
        this.setPromoting = this.setPromoting.bind(this);
        this.disableTrial = this.disableTrial.bind(this);
        this.deletePromo = this.deletePromo.bind(this);
        this.resetPromo = this.resetPromo.bind(this);
        this.activateTrial = this.activateTrial.bind(this);
    }
    async componentDidMount() {
        await this.props.isAbleToAccess(1, 2, 3);
        await this.setState({ id: this.props.model });
        if (this.state.id === null) {
            window.location = "/";
        }
        await this.getAllDataModel();
        await this.getCountries();
        await this.getRegions();
        await this.setState({ currCountry: "US" });
        await this.getMemberships(this.state.currCountry);
        await this.getPromos(this.state.currCountry);
        this.props.changeView("model");
        const membresias = [...this.state.membresias.Activas];
        await this.setState({ totalActivates: membresias.length })

        membresias.map(async (item, index) => {
            if (item.principal_membership === 1) {
                await this.setState({ mainMembership: item.id });
            }
        });

        const name = this.state.name;
        this.setState({ path: "Home / Modelos / " + name + " / " });

    }
    changeCountry = async (e) => {
        const country = e.target.value;
        //console.log(country);
        await this.setState({ currCountry: country, currRegion: 0 });
        await this.getMemberships(this.state.currCountry);
        await this.getPromos(this.state.currCountry);
        const membresias = [...this.state.membresias.Activas];
        await this.setState({ totalActivates: membresias.length })

        membresias.map(async (item, index) => {
            if (item.principal_membership === 1) {
                await this.setState({ mainMembership: item.id });
            }
        });
    }
    changeRegion = async (e) => {
        const region = e.target.value;
        //console.log(country);
        await this.setState({ currRegion: region, currCountry: 0 });
        await this.getRegionMemberships(this.state.currRegion)
    }
    reloadMemberships = async () => {
        await this.getMemberships(this.state.currCountry, this.state.currRegion);
        await this.getPromos(this.state.currCountry);
        const membresias = [...this.state.membresias.Activas];
        await this.setState({ totalActivates: membresias.length });
        membresias.map(async (item) => {
            if (item.principal_membership === 1) {
                await this.setState({ mainMembership: item.id });
            }
        });
    }
    getMemberships = async (country) => {
        await axios.get("/api/memberships/" + this.state.id, { params: { country: country } }).then(async resp => {
            await this.setState({ membresias: resp.data })
        });
    }
    getRegionMemberships = async (region) => {
        await axios.get("/api/memberships/region/" + this.state.id, { params: { region: region } }).then(async resp => {
            await this.setState({ membresiasRegion: resp.data })
        });
    }
    getPromos = async (country) => {
        await axios.get("/api/memberships/promos/" + this.state.id, { params: { country: country } }).then(async resp => {
            await this.setState({ promos: resp.data })
        });
    }
    getCountries = async () => {
        await axios.get("/api/countries").then(async resp => {
            await this.setState({ countries: resp.data });
        })
    }
    getRegions = async () => {
        await axios.get("/api/regions/").then(async resp => {
            await this.setState({ regiones: resp.data })
        });
    }
    setUpdating = async (e) => {
        const membership = e.target.getAttribute("data-update");
        await this.setState({ updating: membership });
        const toUpdate = await this.getMembershipInfo(membership);
        await this.setState({ updatingMembership: toUpdate })
    }
    setPromoting = async (e) => {
        const membership = e.target.getAttribute("data-update");
        const currTrial = e.target.getAttribute("data-currTrial")
        await this.setState({ promoting: membership });
        const toPromote = await this.getMembershipInfo(membership);
        await this.setState({ promotingMembership: toPromote, currTrial: currTrial })
    }
    setActivating = async (e) => {
        const membership = e.target.getAttribute("data-update");
        await this.setState({ activating: membership });
        const toActivate = await this.getMembershipInfo(membership);
        await this.setState({ activeMembership: toActivate })
    }
    setDisabling = async (e) => {
        const membership = e.target.getAttribute("data-update");
        await this.setState({ disableMembership: membership });

    }
    setSetting = async (e) => {
        const membership = e.target.getAttribute("data-update");
        await this.setState({ settingMembership: membership });

    }
    updatePiCodeEdited = async (picode) => {

        let updatingMembership = this.state.updatingMembership;
        updatingMembership["picode"] = picode
    }
    getMembershipInfo = async function (membership) {
        return await axios.get("/api/membership/" + membership, { params: { country: this.state.currCountry } }).then(async resp => {
            return resp.data;
        });

    }
    disableTrial = async (e) => {
        const membership = e.target.getAttribute("data-update");
        await this.setState({ untrial: membership });
        const toUntiral = await this.getMembershipInfo(membership);
        await this.setState({ untrialMembership: toUntiral })
    }
    activateTrial = async (e) => {
        const membership = e.target.getAttribute("data-update");
        await this.setState({ toTrial: membership });
        const toTrial = await this.getMembershipInfo(membership);
        await this.setState({ toTrialMembership: toTrial })
    }
    deletePromo = async (e) => {
        const obj = e.target;
        const membership = obj.getAttribute("data-update");
        await this.setState({ unpromo: membership });
        const promo = obj.getAttribute("data-promo");
        await this.setState({ promoToUnpromo: promo });
        const toUnpromo = await this.getMembershipInfo(membership);
        await this.setState({ unpromoMembership: toUnpromo })
    }
    resetPromo = async (e) => {
        const obj = e.target;
        const membership = obj.getAttribute("data-update");
        await this.setState({ promo: membership });
        const promo = obj.getAttribute("data-promo");
        await this.setState({ promoToPromo: promo });
        const toUnpromo = await this.getMembershipInfo(membership);
        await this.setState({ promoMembership: toUnpromo })
    }
    async getAllDataModel() {
        await axios.get('/api/model/detail/' + this.state.id)
            .then(async resp => {
                if (!resp.data) {
                    window.location = "/";
                }
                else {
                    //console.log(resp.data);
                    await this.setState({
                        name: resp.data.name,
                        username: resp.data.username,
                        hostname: resp.data.hostname
                    })

                }
            });
    }
    searchByRegion = (e) => {
        this.setState({ searchByRegion: e.target.checked })
    }
    formatMoney = (n, c, d, t) => {
        c = isNaN(c = Math.abs(c)) ? 2 : c;
        d = d === undefined ? "." : d;
        t = t === undefined ? "," : t;
        if (isNaN(n)) {
            return n
        }
        let s = n < 0 ? "-" : "";
        let i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c)));
        let k = (i.length);
        let j = k > 3 ? k % 3 : 0;
        return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
    };
    pintarMembresiasActivas() {
        if (this.state.membresias.Activas.length === 0) {
            return (<tr><td colSpan={7} className="text-center">No hay membresias</td></tr>)
        }
        const membresias = [...this.state.membresias.Activas];
        return membresias.map((item, index) => {
            return (
                <tr key={index}>
                    <td>{item.id}</td>
                    <td>{item.pi_code}</td>
                    <td>${this.formatMoney(item.price)}</td>
                    <td>{item.currency}</td>
                    <td>{item.payment_period}</td>
                    <th>{item.paymentPro}</th>
                    <td>{(item.membership_active === 1) ? ((item.principal_membership === 1) ? "Principal" : "Activa") : "Inactiva"}</td>
                    <td>
                        <ul className="list-inline">
                            {(this.state.totalActivates > 1) ?
                                <li className="list-inline-item">
                                    <button className="btn btn-promo btn-sm" onClick={this.setDisabling} data-toggle="modal" data-target="#ModalDisableMembership" data-update={item.id}>
                                        Desactivar
                                    </button>
                                </li> : ""}
                            {(item.principal_membership === 0) ?
                                <li className="list-inline-item">
                                    <button className="btn btn-activte btn-sm" onClick={this.setSetting} data-toggle="modal" data-target="#ModalSetMainMembership" data-update={item.id}>
                                        Hacer Principal
                                    </button>
                                </li> : ""
                            }

                        </ul>
                    </td>
                </tr>
            )
        })
    }
    pintarTrialsActivos() {
        if (!this.state.membresias.Trials && this.state.membresias.Trials.length === 0) {
            return (<tr><td colSpan={7} className="text-center">No hay Trials</td></tr>)
        }
        const membresias = [...this.state.membresias.Trials];
        return membresias.map((item, index) => {
            return (
                <tr key={index}>
                    <td>{item.id}</td>
                    <td>{item.pi_code}</td>
                    <td>${this.formatMoney(item.price)}</td>
                    <td>{item.currency}</td>
                    <td>{item.payment_period}</td>
                    <th>{item.paymentPro}</th>
                    <td>{item.active === 1 ?
                        moment(item.start_time).format("LLLL") + " a " + moment(item.end_time).format("LLLL") : "disabled"
                    }</td>
                    <td>{item.picode_promo}  ${this.formatMoney(item.price_promo)} {item.currency_promo}</td>
                    <td>
                        <ul className="list-inline">
                            {item.active === 0 ?
                                <li className="list-inline-item">
                                    <button className="btn btn-success btn-sm" onClick={this.activateTrial}
                                        data-toggle="modal" data-target="#ModalActivateTrial"
                                        data-update={item.id}>
                                        Activar
                                    </button>
                                </li> :
                                <li className="list-inline-item">
                                    <button className="btn btn-danger btn-sm" onClick={this.disableTrial}
                                        data-toggle="modal" data-target="#ModalDeleteTrial"
                                        data-update={item.id}>
                                        Desactivar
                                    </button>
                                </li>
                            }
                        </ul>
                    </td>
                </tr>
            )
        })
    }
    pintarMembresias() {
        if (this.state.membresias.Historial.length === 0) {
            return (<tr><td colSpan={7} className="text-center">No hay membresias</td></tr>)
        }
        const membresias = [...this.state.membresias.Historial];
        // eslint-disable-next-line
        return membresias.map((item, index) => {
            if (item.membership_active === 0) {
                return (
                    <tr key={index}>
                        <td>{item.id}</td>
                        <td>{item.pi_code}</td>
                        <td>${this.formatMoney(item.price)}</td>
                        <td>{item.currency}</td>
                        <td>{item.payment_period}</td>
                        <th>{item.paymentPro}</th>
                        <td>{(item.membership_active === 1) ? ((item.principal_membership === 1) ? "Principal" : "Activa") : "Inactiva"}</td>
                        <td>
                            {(item.pi_code === null) ?
                                ((this.props.accessLevel["u"] !== undefined && this.props.accessLevel["u"] >= 3) ?
                                    <button className="btn btn-primary btn-sm" onClick={this.setUpdating} data-toggle="modal" data-target="#ModalUpdateMembership" data-update={item.id}>
                                        Enter PiCode
                                </button> : "") :
                                <ul className="list-inline">
                                    {(!item.trial_next) ?
                                        <li className="list-inline-item">
                                            <button className="btn btn-activte btn-sm" onClick={this.setActivating}
                                                data-toggle="modal" data-target="#ModalActiveMembership"
                                                data-update={item.id}>
                                                Activate
                                            </button>
                                        </li> : null
                                    }
                                    <li className="list-inline-item">
                                        <button className="btn btn-promo btn-sm" onClick={this.setPromoting} data-currTrial={item.trial_next} data-toggle="modal" data-target="#ModalSetPromo" data-update={item.id}>
                                            Promo / Trial
                                        </button>
                                    </li>
                                </ul>
                            }
                        </td>
                    </tr>
                )
            }
        })
    }
    pintarPromos() {
        if (this.state.promos.length === 0) {
            return (<tr><td colSpan={7} className="text-center">No hay membresias</td></tr>)
        }
        const membresias = [...this.state.promos];
        return membresias.map((item, index) => {
            return (
                <tr key={index}>
                    <td>{item.id}</td>
                    <td>{item.pi_code}</td>
                    <td>${this.formatMoney(item.price)}</td>
                    <td>{item.currency}</td>
                    <td>{item.payment_period}</td>
                    <th>{item.paymentPro}</th>
                    <td>{this.state.hostname}?promo={item.code}</td>
                    <td>{item.active === 1 ?
                        moment(item.start_date).format("LLLL") + " a " + moment(item.end_date).format("LLLL") : "disabled"
                    }</td>
                    <td>{item.picode_promo}  ${this.formatMoney(item.price_promo)}  {item.currency_promo}</td>                      
                    <td>
                        <ul className="list-inline">
                            <li className="list-inline-item">
                                {(item.active === 1) ?
                                    <button className="btn btn-danger btn-sm" onClick={this.deletePromo}
                                        data-toggle="modal" data-target="#ModalDeletePromo" data-update={item.id}
                                        data-promo={item.id_promo}>
                                        Desactivar
                                    </button> :
                                    <button className="btn btn-primary btn-sm" onClick={this.resetPromo}
                                        data-toggle="modal" data-target="#ModalResetPromo" data-update={item.id}
                                        data-promo={item.id_promo}>
                                        Activar
                                    </button>
                                }
                            </li>
                        </ul>
                    </td>
                </tr>
            )

        })
    }
    drawCoutries = () => {
        const paises = [...this.state.countries];
        return paises.map((item, index) => {
            return (
                <option key={index} value={item.code}>{item.name}</option>
            )
        })
    }
    drawRegions = () => {
        const regiones = [...this.state.regiones];
        return regiones.map((item, index) => {
            return (
                <option key={index} value={item.id}>{item.name}</option>
            )
        })
    }
    showRegionMembership = (e, id, picode) => {
        this.setState({ regionMembership: id, regionPicode: picode })
    }
    pintarRegionMemberships = () => {
        if (this.state.membresiasRegion.length === 0) {
            return (<tr><td colSpan={7} className="text-center">No hay membresias</td></tr>)
        }
        const membresias = [...this.state.membresiasRegion];
        return membresias.map((item, index) => {
            return (
                <tr key={index}>
                    <td>{item.id}</td>
                    <td>{item.pi_code}</td>
                    <td>${this.formatMoney(item.price)}</td>
                    <td>{item.currency}</td>
                    <td>{item.payment_period}</td>
                    <th>{item.paymentPro}</th>
                    <td>{(item.code) ? this.state.hostname + "?promo=" + item.code : ""}</td>
                    <td>                   
                        <ul className="list-inline">
                            <li className="list-inline-item">
                            {(item.pi_code === null) ?
                                ((this.props.accessLevel["u"] !== undefined && this.props.accessLevel["u"] >= 3) ?
                                    <button className="btn btn-primary btn-sm" onClick={this.setUpdating} data-toggle="modal" data-target="#ModalUpdateMembership" data-update={item.id}>
                                        Enter PiCode
                                </button> : "") : ""
                            }
                            </li>
                        </ul>
                    </td>
                    <td>
                        <ul className="list-inline">
                            <li className="list-inline-item">
                                <button className="btn btn-primary btn-sm" onClick={(e) => { this.showRegionMembership(e, item.id, item.pi_code) }}
                                    data-toggle="modal" data-target="#ModalShowRegionMembership">
                                    Ver Más
                                </button>
                            </li>
                        </ul>
                    </td>
                </tr>
            )

        })
    }
    render() {
        return (
            <div className="container">
                <Header username={this.props.username} path={this.state.path} view={this.state.view} />
                <div className="content">
                    <Sidebar permisos={this.props.permisos} view={this.props.view} />
                    <div className="content-padding main-content" id="page">
                        <div className="row head-padding">
                            <div className="col-12 col-sm-2 col-md-2 col-lg-2">
                                <h4>Membresias</h4>
                            </div>
                            <div className="col-12 col-sm-10 col-md-10 col-lg-10 alignButtons">
                                {(this.props.accessLevel["c"] !== undefined && this.props.accessLevel["c"] >= 3) ?
                                    <div className=" spacer">
                                        <button className="btn btn-reactivate extra-btn lastButton" data-toggle="modal" data-target="#ModalDisableMembershipAll">Desactivar Todas</button>
                                        <button className="btn btn-newMembership extra-btn" data-toggle="modal" data-target="#ModalNewMemberships">Nueva Membresia</button>
                                    </div>
                                    : ""}
                            </div>
                        </div>
                        <div className="row selectCountry">
                            <div className="col-12 col-sm-6 col-md-4 ">
                                <div className="text-left spacer">
                                    <div className="form-check" key="all">
                                        <input type="checkbox" className="form-check-input" name="searchByRegion" id="searchByRegion" value="1" checked={this.state.searchByRegion} onChange={(e) => this.searchByRegion(e)} />
                                        <label className="form-check-label" htmlFor="searchByRegion">Buscar por región</label>
                                    </div>
                                    {!this.state.searchByRegion ?
                                        <select name="country" id="country" className="form-control"
                                            value={this.state.currCountry} onChange={this.changeCountry}>
                                            <option value="0">Selecciona un País</option>
                                            {this.drawCoutries()}
                                        </select>
                                        :
                                        <select name="regionNew" id="regionNew" className="form-control"
                                            value={this.state.currRegion}
                                            onChange={(e) => this.changeRegion(e)}>
                                            <option value="0">Selecciona una Region</option>
                                            {this.drawRegions()}
                                        </select>
                                    }
                                </div>
                            </div>
                        </div>
                        {this.state.currRegion === 0 ?
                            <div className="row">
                                <div className="col-12">
                                    <div className="text-white head-table-membership">
                                        Activas
                                    </div>
                                    <div className="table-responsive-xl tableDiv tableScroll">
                                        <table className="table tableDataSection">
                                            <thead>
                                                <tr className="attr-table">
                                                    <th>ID</th>
                                                    <th>PiCode</th>
                                                    <th>Precio</th>
                                                    <th>Moneda</th>
                                                    <th>Periodo de Pago</th>
                                                    <th>Procesador de Pagos</th>
                                                    <th>Estatus</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.pintarMembresiasActivas()}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="text-white head-table-membership">
                                        Trials
                                    </div>
                                    <div className="table-responsive-xl tableDiv tableScroll">
                                        <table className="table tableDataSection">
                                            <thead>
                                                <tr className="attr-table">
                                                    <th>ID</th>
                                                    <th>PiCode</th>
                                                    <th>Precio</th>
                                                    <th>Moneda</th>
                                                    <th>Periodo de Pago</th>
                                                    <th>Procesador de Pagos</th>
                                                    <th>Estatus</th>
                                                    <th>Recurrente</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.pintarTrialsActivos()}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="text-white head-table-coin">
                                        Promociones
                                    </div>
                                    <div className="table-responsive-xl tableDiv tableScroll">
                                        <table className="table tableDataSection">
                                            <thead>
                                                <tr className="attr-table">
                                                    <th>ID</th>
                                                    <th>PiCode</th>
                                                    <th>Precio</th>
                                                    <th>Moneda</th>
                                                    <th>Periodo de Pago</th>
                                                    <th>Procesador de Pagos</th>
                                                    <th>URL</th>
                                                    <th>Estatus</th>
                                                    <th>Recurrente</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.pintarPromos()}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="head-table text-white">
                                        Inactivas
                                    </div>
                                    <div className="table-responsive-xl tableDiv tableScroll">
                                        <table className="table tableDataSection">
                                            <thead>
                                                <tr className="attr-table">
                                                    <th>ID</th>
                                                    <th>PiCode</th>
                                                    <th>Precio</th>
                                                    <th>Moneda</th>
                                                    <th>Periodo de Pago</th>
                                                    <th>Procesador de Pagos</th>
                                                    <th>Estatus</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.pintarMembresias()}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="row">
                                <div className="col-12">
                                    <div className="text-white head-table-membership">
                                        Membresias
                                    </div>
                                    <div className="table-responsive-xl tableDiv ">
                                        <table className="table tableDataSection">
                                            <thead>
                                                <tr className="attr-table">
                                                    <th>ID</th>
                                                    <th>PiCode</th>
                                                    <th>Precio</th>
                                                    <th>Moneda</th>
                                                    <th>Periodo de Pago</th>
                                                    <th>Procesador de Pagos</th>
                                                    <th>Promo Code</th>
                                                    <th></th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.pintarRegionMemberships()}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <Footer />
                <ModalWarning title={"Algo salio mal"} message={"No se pudo guardar la membresia"} />
                <ModalNewMemberships regiones={this.state.regiones} countries={this.state.countries} currCountry={this.state.currCountry} changeCountry={this.changeCountry} reloadMemberships={this.reloadMemberships} getRegionMemberships={this.getRegionMemberships} model={this.state.id} accessLevel={this.props.accessLevel}
                    searchByRegion={this.state.searchByRegion} currRegion={this.state.currRegion} />
                <ModalUpdateMembership currCountry={this.state.currCountry} reloadMemberships={this.reloadMemberships} model={this.state.id} membership={this.state.updatingMembership} updatePiCodeEdited={this.updatePiCodeEdited} searchByRegion={this.state.searchByRegion} getRegionMemberships={this.getRegionMemberships} currRegion={this.state.currRegion}/>
                <ModalActiveMembership currCountry={this.state.currCountry} reloadMemberships={this.reloadMemberships} model={this.state.id} membership={this.state.activeMembership} />
                <ModalDisableMembership currCountry={this.state.currCountry} reloadMemberships={this.reloadMemberships} model={this.state.id} membership={this.state.disableMembership} main={this.state.mainMembership} />
                <ModalSetMainMembership currCountry={this.state.currCountry} reloadMemberships={this.reloadMemberships} model={this.state.id} membership={this.state.settingMembership} />
                <ModalSetPromo currTrial={this.state.currTrial} currCountry={this.state.currCountry} activeMemberships={this.state.membresias.Historial} reloadMemberships={this.reloadMemberships} model={this.state.id} membership={this.state.promotingMembership} />
                <ModalDeleteTrial currCountry={this.state.currCountry} reloadMemberships={this.reloadMemberships} model={this.state.id} membership={this.state.untrialMembership} />
                <ModalActivateTrial currCountry={this.state.currCountry} reloadMemberships={this.reloadMemberships} model={this.state.id} membership={this.state.toTrialMembership} />
                <ModalDeletePromo currCountry={this.state.currCountry} reloadMemberships={this.reloadMemberships} model={this.state.id} membership={this.state.unpromoMembership} promo={this.state.promoToUnpromo} />
                <ModalResetPromo currCountry={this.state.currCountry} reloadMemberships={this.reloadMemberships} model={this.state.id} membership={this.state.promoMembership} promo={this.state.promoToPromo} />
                <ModalShowRegionMembership model={this.state.id} membership={this.state.regionMembership} picode={this.state.regionPicode} membresiasRegion={this.state.membresiasRegion} getRegionMemberships={this.getRegionMemberships} currRegion={this.state.currRegion} />
                <ModalDisableMembershipAll id={this.state.id} />
            </div>
        )
    }
}
export default Memberships;