import React, { Component } from 'react';
import axios from "axios";
import ModalSaving from "../../../ModalSaving";

class ModalDisableContentMedia extends Component {
    constructor(){
        super();
        this.state = {
            isActive : false,
            saved : false,
            error: false
        };
        this.disableContentMedia=this.disableContentMedia.bind(this);
    }
    disableContentMedia = async () => {
        await this.setState({saved : false, error: false});
        let send=true;
        if(send) {
            document.getElementById("modalDisableContentCancelBtn").click();
            document.getElementById("modalSaveDisableContentMedia").classList.add("show");
            const model = this.props.model;
            const module = this.props.updatingModule;
            const data = {
                module: module
            }          
            const saved = await axios.put('/api/contentmedia/disable/' + model, data).then(resp => {
                return resp.data;
            });
            if (saved) {
                await this.setState({saved : true});
                await this.props.reloadModules();
            } else {    
                await this.setState({error : true});
            }
        }
    }
    render(){
        return (
            <div>
                <div className="modal" id="ModalDisableContentMedia" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header-section">
                                <h5 className="modal-title sectionTitle">Desactivar Contenido Media</h5>
                                <button type="button" className="close modalClose" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body modelBody">
                                <p>¿Realmente desea desactivar este Contenido?</p>                               
                            </div>
                            <div className="modal-footer modalFooter">
                                <button type="button" className="btn btn-modalClose" id="modalDisableContentCancelBtn" data-dismiss="modal">Cancelar</button>
                                <button type="button" className="btn btn-modalSuccess" onClick={this.disableContentMedia}>Aceptar</button>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalSaving saved={this.state.saved} error={this.state.error} idModal="modalSaveDisableContentMedia"/>
            </div>
        )
    }
}
export default ModalDisableContentMedia;