import React, { Component } from 'react';
import axios from "axios";
import ModalSaving from "../../../ModalSaving";

class ModalNewBonus extends Component {
    constructor() {
        super();
        this.state = {
            saved: false,
            error: false
        }
    }
    newBonus = async () => {
        await this.setState({ saved: false, error: false });
        const regexInput = new RegExp(/[0-9]+$/);
        const percentage = document.getElementById("percentage").value;
        const dollarSpent = document.getElementById("dollarSpent").value;
        let send = true;
        if (percentage ==="" || isNaN(percentage) || percentage <= 0 || percentage >= 100 || !regexInput.test(percentage)) {
            document.getElementById("percentage").classList.add("is-invalid");
            send = false;
        }
        else {
            document.getElementById("percentage").classList.remove("is-invalid");
        }
        if (dollarSpent ==="" || isNaN(dollarSpent) || dollarSpent <= 0 || !regexInput.test(dollarSpent)
            || dollarSpent >= 16777215) {
            document.getElementById("dollarSpent").classList.add("is-invalid");
            send = false;
        }
        else {
            document.getElementById("dollarSpent").classList.remove("is-invalid");
        }
        if (send) {
            document.getElementById("ModalNewBonusCancelBtn").click();
            document.getElementById("ModalSaveNewBonus").classList.add("show");
            let data = "";
            let reloadBonusModel = false;
            if(this.props.allModels){
                data = {
                    "bonusCoins":[{
                        "new": {
                            "percentage": percentage,
                            "dollarSpent": dollarSpent
                        }
                    }],
                    "allModels": true
                }
            }else{
                data = {
                    "bonusCoins":[{
                        "model": this.props.model,
                        "new": {
                            "percentage": percentage,
                            "dollarSpent": dollarSpent
                        }
                    }]
                }
                reloadBonusModel = true;
            }
            const saved = await axios.put('/api/bonus/selfupdate', data)
                .then(async resp => {
                    return resp.data;
                });
            if (saved.Error === undefined) {
                await this.setState({ saved: true })
                if(reloadBonusModel){
                    await this.props.reloadBonus();
                }
            } else {
                await this.setState({ error: true });
            }
        }
    }
    render() {
        return (
            <div>
                <div className="modal" id="ModalNewBonus" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Crear Nuevo Bonus</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form id="newBonus">
                                    <div className="form-group">
                                        <input type="text" placeholder="Porcentaje" className="form-control" id="percentage" name="percentageBonus"/>
                                    </div>
                                    <div className="form-group">
                                        <input type="number" placeholder="Dolar Gastado" className="form-control" id="dollarSpent" name="dollar"/>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger" id="ModalNewBonusCancelBtn" data-dismiss="modal">Cancelar&nbsp;<i className="fas fa-times"></i></button>
                                <button type="button" className="btn btn-success" onClick={this.newBonus}>Guardar&nbsp;<i className="fas fa-save"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalSaving saved={this.state.saved} error={this.state.error} idModal="ModalSaveNewBonus" />
            </div>
        )
    }
}
export default ModalNewBonus;