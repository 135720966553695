import React, { Component } from 'react';
import axios from "axios";
import cookie from "js-cookie";
import "./header.css";

class Header extends Component {
    constructor(){
        super();
        this.logout=this.logout.bind(this);
        this.contextMenu=this.contextMenu.bind(this);
    }
    componentDidMount(){

    }
    async logout(){

        await axios.post('/api/user/logout').then(async res => {
            cookie.remove('username')
            cookie.remove('token')
            cookie.remove('permission');
            cookie.remove('email');
            window.location = '/';
        })

    }
    contextMenu(e){
        let sidebar=document.querySelector(".sidebar");
        let content=document.querySelector(".main-content");
        if(e.target.classList.contains('open')){
            sidebar.classList.remove("hide");
            content.classList.remove("all");
            e.target.classList.remove('open');
        }
        else{
            sidebar.classList.add("hide");
            content.classList.add("all");
            e.target.classList.add('open')
        }
    }
    render(){
        return (
            <div>
                <nav className="navbar">
                    <button className="btn btn-link btn-menuIcon" onClick={this.contextMenu}>
                        <i className="fas fa-bars"></i>
                    </button>
                    <a href='/' className="navbar-brand navbarBrand">DiSo</a>
                    <div className='sectionbar'>
                        {(this.props.path === undefined)?<span className="active">{"Home "}</span>:<div> {this.props.path} <span className="active"> {this.props.view} </span> </div>}
                    </div>
                    <div className="form-inline my-2 my-lg-0">
                        <button className="btn btn-link dropdown-toggle" href="#" id="navbarDropdownMenuLink"
                           data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {(this.props.username !== undefined) ? this.props.username : ""}
                        </button>
                        <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                            <button className="btn btn-link btn-block text-left" onClick={this.logout}>Logout</button>
                        </div>
                    </div>
                </nav>
            </div>
        )
    }
}
export default Header;