    import React, { Component } from 'react';
import axios from "axios";

class Secciones extends Component {
    constructor(){
        super();
        this.state = {
            allSections : [],
            section: []
        }
    }
    async componentDidMount(){
        await this.getSections();
        const secciones = [...this.props.section];
        let newSection = [...this.state.section];
        secciones.forEach(async (el,i) => {
            //og(el,i);
            if(this.props.accessLevel["u"] >= 3) {
                const element = document.getElementById("section" + el);
                element.checked = true;
            }
            newSection[el]=true;
        });
        if(secciones.length === 0 ){
            if(this.props.accessLevel["u"] >= 3) {
                let element = document.getElementById("section6");
                element.checked = true;
            }
        }
        await this.setState({
            section : newSection
        });
    }

    getSections = async () =>{
        await axios.get('/api/model/sections/')
            .then(async resp =>{
                await this.setState({allSections : resp.data});
            })
    }

    drawSections(){
        //console.log(this.props.allSections);
        if(this.state.allSections.length === 0){
            return (<div><p>No se encontraron secciones</p></div>)
        }
        const allSections = [...this.state.allSections];
        const secciones = [...this.props.section];
        return allSections.map((item,index) => {
            return (
                (this.props.accessLevel["u"] >= 3 || secciones.includes(String(item.id))) ?
                        <div key={index} className="contentIni">
                            {(this.props.accessLevel["u"] >= 1) ?
                            <div className="form-check">
                                {(this.props.accessLevel["u"] >= 3) ?
                                    <input 
                                        type="checkbox" 
                                        className="form-check-input"
                                        name={"sections"} 
                                        id={"section" + item.id} 
                                        value={item.id}
                                    />
                                : null }
                                <label 
                                    className="form-check-label contentIni" 
                                    htmlFor="exampleCheck1"
                                >
                                    {item.name}
                                </label>
                            </div> : (this.state.section[item.id] !== undefined && this.state.section[item.id] === true) ? <p>{item.name}</p> : "" }
                        </div>
                    : null
            )

        })
    }
    render(){
        return (
            <div className="modelContent">
                <div className="text-white head-table">
                    Secciones
                </div>
                <div className="container-fluid">
                    <div className="row divCard">
                        {this.drawSections()}
                    </div>
                </div>
            </div>
        )
    }
}
export default Secciones;
