import React, { Component } from 'react';
import axios from "axios";
import SetActiveMemberships from '../SetActiveMemberships';
import ModalSaving from "../../../ModalSaving";
//import ModalActiveMembership from "../ModalActiveMembership";

class ModalNewMemberships extends Component {
    constructor() {
        super();
        this.state = {
            picode: false,
            isActive: false,
            nuevaMembresia: {},
            saved: false,
            error: false,
            currRegion: 0,
            newRegion: false,
            currCountry: 0
        };
        this.saveMembership = this.saveMembership.bind(this);
        this.changePICode = this.changePICode.bind(this);
        this.changeInput = this.changeInput.bind(this);
        this.setActiveMembership = this.setActiveMembership.bind(this);
    }
    componentDidMount() {

    }
    componentDidUpdate(prevProps, prevState){
        if(this.props.searchByRegion !== prevProps.searchByRegion){
            this.setState({newRegion : this.props.searchByRegion})
        }
        if(this.props.currRegion !== prevProps.currRegion){
            this.setState({currRegion : this.props.currRegion})
        }
        if(this.props.currCountry !== prevProps.currCountry){
            this.setState({currCountry : this.props.currCountry})
        }
    }
    isNewRegion = async (e) => {
        this.setState({ newRegion: e.target.checked })
    }
    setActiveMembership = function (e) {
        if (e.target.checked) {
            this.setState({ isActive: true });
        }
        else {
            this.setState({ isActive: false });
        }
    }
    changePICode = function (e) {
        if (e.target.value !== "") {
            let picode = "";
            let picodeInput = document.getElementById("picode");
            if (picodeInput !== null) {
                picode = picodeInput.value;
            }
            const precio = document.getElementById("precio").value;
            const moneda = document.getElementById("moneda").value;
            const periodo = document.getElementById("periodo").value;
            const paymentPro = document.getElementById("paymentPro").value;
            const data = {
                picode: picode,
                precio: precio,
                moneda: moneda,
                periodo: periodo,
                paymentPro: paymentPro
            }
            this.setState({ picode: true, nuevaMembresia: data });
        }
        else {
            this.setState({ picode: false, isActive: false });
        }
    }
    changeInput = function (e) {
        let picode = "";
        let picodeInput = document.getElementById("picode");
        if (picodeInput !== null) {
            picode = picodeInput.value;
        }
        const precio = document.getElementById("precio").value;
        const moneda = document.getElementById("moneda").value;
        const periodo = document.getElementById("periodo").value;
        const paymentPro = document.getElementById("paymentPro").value;
        const data = {
            picode: picode,
            precio: precio,
            moneda: moneda,
            periodo: periodo,
            paymentPro: paymentPro
        };
        this.setState({ nuevaMembresia: data });
    }
    saveMembership = async () => {
        await this.setState({ saved: false, error: false });
        let send = true;
        let isRegion = document.getElementById("isForRegion").checked;
        let region = null;
        if (isRegion) {
            region = document.getElementById("regionNew").value;
            if (region === "0") {
                document.getElementById("regionNew").classList.add("is-invalid");
                send = false;
            }
            else {
                document.getElementById("regionNew").classList.remove("is-invalid");
            }
        }
        else {
            if (region === "0") {
                document.getElementById("countryNew").classList.add("is-invalid");
                send = false;
            }
            else {
                document.getElementById("countryNew").classList.remove("is-invalid");
            }
        }
        const precio = document.getElementById("precio").value;
        const moneda = document.getElementById("moneda").value;
        const periodo = document.getElementById("periodo").value;
        const paymentPro = document.getElementById("paymentPro").value;
        if (precio === "" || isNaN(precio) || precio <= 0) {
            document.getElementById("precio").classList.add("is-invalid");
            send = false;
        }
        else {
            document.getElementById("precio").classList.remove("is-invalid");
        }
        if (moneda === "0") {
            document.getElementById("moneda").classList.add("is-invalid");
            send = false;
        }
        else {
            document.getElementById("moneda").classList.remove("is-invalid");
        }
        if (periodo === "0") {
            document.getElementById("periodo").classList.add("is-invalid");
            send = false;
        }
        else {
            document.getElementById("periodo").classList.remove("is-invalid");
        }
        if (paymentPro === "0") {
            document.getElementById("paymentPro").classList.add("is-invalid");
            send = false;
        }
        else {
            document.getElementById("paymentPro").classList.remove("is-invalid");
        }
        if (send) {
            document.getElementById("ModalNewMembershipCancelBtn").click();
            document.getElementById("ModalSaving").classList.add("show");
            const formData = new FormData();
            const form = document.getElementById("newMembership");
            formData.append("idModel", this.props.model);
            for (let k = 0; k < form.length; k++) {
                let element = form[k];
                if (element.tagName.toUpperCase() === "INPUT") {
                    if (element.type === "checkbox" || element.type === "radio") {
                        if (element.checked)
                            formData.append(element.name, element.value);
                    }
                    else {

                        if (element.name !== "")
                            formData.append(element.name, element.value);

                    }
                }
                if (element.tagName.toUpperCase() === "SELECT") {
                    formData.append(element.name, element.value)
                }
            }
            const saved = await axios.post("/api/membership/", formData).then(resp => {
                return resp.data;
            });
            if (saved.Error === undefined) {
                await this.setState({ saved: true })
                await this.props.reloadMemberships();
                await this.props.getRegionMemberships(this.props.currRegion);
            } else {
                await this.setState({ error: true });
                let picodeInput = document.getElementById("picode");
                if (picodeInput !== null)
                    picodeInput.classList.add("is-invalid");
            }
        }
    }
    changeRegion = (e) => {
        this.setState({ currRegion: e.target.value });
    }
    changeCountryNew = (e) => {
        this.props.changeCountry(e)
    }
    drawCoutries = () => {
        const paises = [...this.props.countries];
        return paises.map((item, index) => {
            return (
                <option key={index} value={item.code}>{item.name}</option>
            )
        })
    }
    drawRegions = () => {
        const regiones = [...this.props.regiones];
        return regiones.map((item, index) => {
            return (
                <option key={index} value={item.id}>{item.name}</option>
            )
        })
    }
    render() {
        return (
            <div>
                <div className="modal" id="ModalNewMemberships" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header-section">
                                <h6 className="modal-title sectionTitle">Nueva Membresia</h6>
                                <button type="button" className="close modalClose" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body modelBody">
                                <form id="newMembership">
                                    <div className="form-check" key="all">
                                        <input type="checkbox" className="form-check-input" name="isForRegion" id="isForRegion" value="1" checked={this.state.newRegion} onChange={(e) => this.isNewRegion(e)} />
                                        <label className="form-check-label" htmlFor="isForRegion">Membresia para región</label>                                    
                                    </div>                                                                      
                                    {this.state.newRegion ?
                                        <div className="form-group">
                                            <select name="regionNew" id="regionNew" className="form-control"
                                                value={this.state.currRegion}
                                                onChange={(e) => this.changeRegion(e)}>
                                                <option value="0">Selecciona una Region</option>
                                                {this.drawRegions()}
                                            </select>
                                        </div>
                                        :
                                        <div className="form-group">
                                            <select name="countryNew" id="countryNew" className="form-control"
                                                value={this.props.currCountry}
                                                onChange={(e) => this.changeCountryNew(e)}>
                                                <option value="0">Selecciona un País</option>
                                                {this.drawCoutries()}
                                            </select>
                                        </div>
                                    }
                                    {(this.props.accessLevel["u"] !== undefined && this.props.accessLevel["u"] >= 3) ?
                                        <div className="form-group">
                                            <input type="text" placeholder="PiCode" className="form-control" id="picode" name="picode" onChange={this.changePICode} />
                                        </div> : ""}
                                    <div className="form-group">
                                        <input type="text" placeholder="Precio" className="form-control" id="precio" name="precio" onChange={this.changeInput} />
                                    </div>
                                    <div className="form-group">
                                        <select name="moneda" className="form-control" id="moneda" defaultValue={0} onChange={this.changeInput}>
                                            <option value="0" disabled>Moneda</option>
                                            <option value="USD">USD</option>
                                            <option value="MXN">MXN</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <select name="periodo" className="form-control" id="periodo" defaultValue={0} onChange={this.changeInput}>
                                            <option value="0" disabled>Periodo de Pago</option>
                                            <option value="day">Per day</option>
                                            <option value="30 day">Per 30 days</option>
                                            <option value="week">Per week</option>
                                            <option value="month">Per month</option>
                                            <option value="year">Per year</option>
                                        </select>

                                    </div>
                                    <div className="form-group">
                                        <select name="paymentPro" id="paymentPro" className="form-control" defaultValue={0} onChange={this.changeInput}>
                                            <option value="0">Procesador de Pagos</option>
                                            <option value="1">EPOCH</option>
                                            <option value="2">CCBILL</option>                                           
                                        </select>
                                    </div>
                                    {(this.state.picode && !this.state.newRegion) ?
                                        <div className="form-check" key="all">
                                            <input type="checkbox" className="form-check-input" onChange={this.setActiveMembership} name="isActive" id="isActive" value="1" />
                                            <label className="form-check-label" htmlFor="isActive">Activar esta membresia</label>
                                        </div> : ""
                                    }
                                    {(this.state.isActive && this.state.picode && !this.state.newRegion) ?
                                        <div>
                                            <hr />
                                            <h4>Membresias Activas</h4>
                                            <SetActiveMemberships currCountry={this.props.currCountry} model={this.props.model} new={this.state.nuevaMembresia} />
                                        </div> : ""

                                    }
                                    {(this.state.picode && this.state.newRegion) ?
                                        <div className="form-check" key="all">
                                            <input type="checkbox" className="form-check-input" name="isMainRegion" id="isMainRegion" value="1" />
                                            <label className="form-check-label" htmlFor="isMainRegion">Hacer principal para todos los paises</label>
                                        </div> : ""
                                    }
                                </form>
                            </div>
                            <div className="modal-footer modalFooter">
                                <button type="button" className="btn btn-modalClose" id="ModalNewMembershipCancelBtn" data-dismiss="modal">Cancelar</button>
                                <button type="button" className="btn btn-modalSuccess" onClick={this.saveMembership}>Guardar</button>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalSaving saved={this.state.saved} error={this.state.error} />
            </div>
        )
    }
}
export default ModalNewMemberships;