import React, { Component } from 'react';

class ModalError extends Component {

    closeModal(){
        document.getElementById('ModalError').classList.remove('show');
    }
    render(){
        return (
                <div className="modal fade ModalError" id="ModalError" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header-section">
                                <h6 className="modal-title sectionTitle">ERROR</h6>
                                <button type="button" className="close modalClose" data-dismiss="modal" aria-label="Close" onClick={this.closeModal}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body modelBody">
                                <p className='textModalDisable'>Necesita llenar el campo de <b>Hostname</b> con la URL del sitio web en la seccion de <b>Storage & Analytics</b> para activarlo.</p>
                            </div>
                            <div className="modal-footer modalFooter">
                                <button type="button" className="btn btn-modalClose" id="ModalErrorCancelBtn" data-dismiss="modal" onClick={this.closeModal}>Cerrar</button>
                            </div>
                        </div>
                    </div>
                </div>
        )
    }
}
export default ModalError;
