import React, { Component } from 'react';
import axios from "axios";

import Header from '../../../components/Layout/Header';
import Sidebar from '../../../components/Layout/Sidebar';
import Footer from '../../../components/Layout/Footer';
import ModalWarning from '../../../components/Layout/ModalWarning';
import ModalNewRegion from '../../../components/Configuration/ModalNewRegion';
import './style.css'

class Regions extends Component {
    constructor(){
        super();
        this.state={
            path: "Home / Configuración / ",
            view: "Regiones",
            regiones : [],
            paises : [],
            regionEditing : null,
            currentCountries : null,
            currentName : null
        };
        this.reloadRegions = this.reloadRegions.bind(this);
    }
    async componentDidMount() {
        //console.log("inicio");
        await this.props.isAbleToAccess(2,3,1);
        await this.getRegions();
        await this.getCountries();
        this.props.changeView("config");
    }
    reloadRegions = async () => {
        this.getRegions();
    }
    getRegions = async () => {
        await axios.get("/api/regions/").then(async resp => {
            await this.setState({regiones : resp.data})
        });
    };
    getCountries = async () => {
        await axios.get("/api/countries").then( async resp => {
            await this.setState({paises : resp.data});
        })
    }
    flag = (country) => {
        let flagOffset = 0x1F1E6;
        let asciiOffset = 0x41;
        let firstChar = country.codePointAt(0) - asciiOffset + flagOffset;
        let secondChar = country.codePointAt(1) - asciiOffset + flagOffset;

        let flag = String.fromCodePoint(firstChar, secondChar);
        return flag
    }
    editRegion = (e, region = null, currentCountries = [], currentName = "") => {
        this.setState({regionEditing : region, currentCountries : currentCountries, currentName : currentName})

    }
    pintarPaises(paises) {
        return paises.map((item,index) => {
            return (
                <span className="flag-icon">{this.flag(item)}</span>
            )
        })
    }
    pintarRegiones() {
        if(this.state.regiones.length === 0){
            return (<tr><td colSpan={5} className="text-center">No hay regiones</td></tr>)
        }
        const regiones = [...this.state.regiones];
        return regiones.map((item,index) => {
            return (
                <tr key={index}>
                    <td>{item.name}</td>
                    <td>{this.pintarPaises(item.countries)}</td>
                    <td>
                        <ul className="list-inline">
                            <li  className="list-inline-item">
                                <button className="btn btn-newModel btn-sm btn-icon" data-toggle="modal" data-target="#ModalNewRegion"
                                        onClick={(e) => this.editRegion(e, item.id, item.countries, item.name)}><span
                                    className="fas fa-edit"></span>
                                </button>
                            </li>
                        </ul>
                    </td>
                </tr>
            )
        })
    }
    render () {
        return(
            <div className="container">
                <Header username={this.props.username} path={this.state.path} view={this.state.view}/>
                <div className="content">
                    <Sidebar permisos={this.props.permisos} view={this.state.view}/>
                    <div className="content-padding main-content" id="page">
                        <div className="row head-padding">
                            <div className="col-6">
                                <h4>Regiones</h4>
                            </div>
                            <div className="col-6">
                                {(this.props.accessLevel["c"] !== undefined && this.props.accessLevel["c"] >= 3) ?
                                    <div className="text-right spacer">
                                        <button onClick={(e) => this.editRegion(e, null)} className="btn btn-newModel" data-toggle="modal" data-target="#ModalNewRegion">Nueva Regi&oacute;n</button>
                                    </div>
                                    : "" }
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="text-white head-table">
                                    Regiones
                                </div>
                                <div className="table-responsive tableDiv">
                                    <table className="table tableDataSection">
                                        <thead>
                                        <tr className="attr-table">
                                            <th>Nombre</th>
                                            <th>Paises</th>
                                            <th></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.pintarRegiones()}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
                {(this.props.accessLevel["c"] !== undefined && this.props.accessLevel["c"] >= 3) ?
                    <ModalNewRegion
                        currentCountries={this.state.currentCountries}
                        currentName = {this.state.currentName}
                        reloadRegions={this.reloadRegions}
                        paises={this.state.paises}
                        regionIndex={this.state.regionEditing}
                    /> : "" }
                <ModalWarning title={"Algo salio mal"} message={"No se pudo editar la region"} />
            </div>
        )
    }
}
export default Regions;