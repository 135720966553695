import React, { Component } from 'react';
import moment from 'moment';
import Carousel from "../../Carousel";
import DatePicker from "react-datepicker";

class GeneralNotification extends Component {
    constructor() {
        super();
        this.state = {
            startDate: false
        }
        this.handleChangeStart = this.handleChangeStart.bind(this);
    }

    handleChangeStart= async(date) =>{
        await this.setState({
            startDate: date
        });
    }

    render(){
        return (
            <div className="preview">
                <div className="text-white head-table">
                    General
                </div>
                <div 
                    id='GeneralNotificationDetail' 
                    className="container-fluid"
                >
                    <div className="divCard row info-card">
                        <div className="col-12 col-sm-12 col-lg-12 fontdetail">
                            <label>
                                Status
                            </label>
                            {this.props.status === 1 ? "Activo" : "Desactivo"}
                        </div>
                        <div className="col-12 col-sm-6 col-lg-6 fontdetail">
                            <label>
                                Fecha de creaci&oacute;n
                            </label>
                            {moment(this.props.created).format("YYYY-MM-DD HH:mm")}
                        </div>
                        <div className="col-12 col-sm-6 col-lg-6 fontdetail">
                            <label>
                                Fecha de expiraci&oacute;n
                            </label>
                            {this.props.status === 1 ?
                                    this.props.modifyDate?
                                    <div>
                                        <div className="exp-date-detail">
                                            <DatePicker 
                                                showTimeSelect
                                                timeIntervals={15}
                                                minDate={new Date()}
                                                dateFormat="MMMM d, yyyy HH:mm"
                                                onChange={this.handleChangeStart}
                                                className="form-control form-control-sm dateInput"
                                                selected={new Date(this.state.startDate ? this.state.startDate : moment(this.props.expiration).format("YYYY-MM-DD HH:mm"))}
                                            />
                                        </div>
                                        <button
                                            className="btn btn-newModel"
                                            onClick={(e) => this.props.changeExpiration(e,this.props.id, this.state.startDate ? this.state.startDate : moment(this.props.expiration).format("YYYY-MM-DD HH:mm"))}
                                        >
                                            Guardar
                                        </button>
                                    </div>
                                :
                                    <div>
                                        <div className="exp-date-detail">
                                            {moment(this.props.expiration).format("YYYY-MM-DD HH:mm")}
                                        </div>
                                        <button
                                            className="btn btn-newMembership"
                                            onClick={(e) => this.props.openModify(e)}
                                        >
                                            Modificar Fecha
                                        </button>
                                    </div>
                            : 
                                <div className="exp-date-detail">
                                    {moment(this.props.expiration).format("YYYY-MM-DD HH:mm")}
                                </div>
                            }
                        </div>
                        <div className="col-12 col-sm-6 col-lg-6 fontdetail">
                            <label>
                                T&iacute;tulo en espa&ntilde;ol
                            </label>
                            {this.props.esp.title}
                        </div>
                        <div className="col-12 col-sm-6 col-lg-6 fontdetail" >
                            <label>
                                Descripci&oacute;n en espa&ntilde;ol
                            </label>
                            {this.props.esp.description}
                        </div>   
                        <div className="col-12 col-sm-6 col-lg-6 fontdetail">
                            <label>
                                T&iacute;tulo en ingl&eacute;s
                            </label>
                            {this.props.eng.title}
                        </div>
                        <div className="col-12 col-sm-6 col-lg-6 fontdetail" >
                            <label>
                                Descripci&oacute;n en ingl&eacute;s
                            </label>
                            {this.props.eng.description}
                        </div>  
                        <div className="col-4"></div>
                        <div className="col-4">
                            {this.props.media ?
                                <Carousel
                                    autoPlay={false}
                                    media={this.props.media}
                                />  
                            : null }      
                        </div>            
                    </div>
                </div>
            </div>
        )
    }
}
export default GeneralNotification;
