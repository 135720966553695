import React, { Component } from 'react';
import axios from "axios";
import SetActiveMemberships from '../SetActiveMemberships';
import ModalSaving from "../../../ModalSaving";

class ModalUpdateMembership extends Component {
    constructor(){
        super();
        this.state = {
            picode : false,
            isActive : false,
            saved : false,
            error: false
        };
        this.updateMembership=this.updateMembership.bind(this);
        this.changePICode = this.changePICode.bind(this);
        this.setActiveMembership = this.setActiveMembership.bind(this);
    }
    componentDidMount(){
    }

    setActiveMembership = function(e){
        if(e.target.checked){
            this.setState({isActive : true});
        }
        else{
            this.setState({isActive : false});
        }
    }
    changePICode = function(e){
        if(e.target.value!==""){
            const picode = e.target.value;
            this.props.updatePiCodeEdited(picode);
            this.setState({picode : true});
        }
        else{
            this.setState({picode : false, isActive : false});
        }
    }
    updateMembership = async () => {
        const country =  this.props.currCountry;
        await this.setState({saved : false, error: false});
        let send=true;
        const picode = document.getElementById("picodeupdate").value;
        document.getElementById("picodeupdate").classList.remove("is-invalid");
        if(picode===""){
            document.getElementById("picodeupdate").classList.add("is-invalid");
            send = false;
        }
        if(send) {
            document.getElementById("ModalUpdateMembershipCancelBtn").click();
            document.getElementById("modalSaveUpdateMembership").classList.add("show");
            const formData = new FormData();
            const form = document.getElementById("updateMembership");
            formData.append("idModel",this.props.model);
            for( let k = 0 ; k<form.length; k++){
                let element=form[k];
                if(element.tagName.toUpperCase() === "INPUT") {
                    if(element.type === "checkbox" || element.type === "radio"){
                        if(element.checked)
                            formData.append(element.name, element.value);
                    }
                    else{

                        if(element.name !== "")
                            formData.append(element.name, element.value);

                    }
                }
                if(element.tagName.toUpperCase() === "SELECT"){
                    formData.append(element.name, element.value)
                }
            }
            const saved = await axios.put("/api/membership/"+this.props.membership.id, formData, {params : { country : country }}).then(resp => {
                return resp.data;
            });
            if (saved.Error === undefined) {
                await this.setState({saved : true})
                await this.props.reloadMemberships();
                await this.props.getRegionMemberships(this.props.currRegion);
            } else {
                await this.setState({error : true});
                document.getElementById("picodeupdate").classList.add("is-invalid");
            }
        }
    }
    render(){
        return (
            <div>
                <div className="modal" id="ModalUpdateMembership" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header-section">
                                <h6 className="modal-title sectionTitle">Actualizar Membresia</h6>
                                <button type="button" className="close modalClose" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body modelBody">
                                <form id="updateMembership">
                                    <div className="form-group">
                                        <input type="text" placeholder="PiCode" className="form-control" id="picodeupdate" name="picode" onChange={this.changePICode}/>
                                    </div>

                                    {(this.state.picode && !this.props.searchByRegion) ?
                                        <div className="form-check" key="all">
                                            <input type="checkbox" className="form-check-input" onChange={this.setActiveMembership} name="isActive" id="isActive" value="1"/>
                                            <label className="form-check-label" htmlFor="exampleCheck1">Activar esta membresia</label>
                                        </div> : ""
                                    }
                                    {(this.state.isActive && this.state.picode) ?
                                        <div>
                                            <hr/>
                                            <h4>Membresias Activas</h4>
                                            <SetActiveMemberships currCountry={this.props.currCountry} model={this.props.model} new={this.props.membership} />
                                        </div> : ""
                                    }
                                </form>
                            </div>
                            <div className="modal-footer modalFooter">
                                <button type="button" className="btn btn-modalClose" id="ModalUpdateMembershipCancelBtn" data-dismiss="modal">Cancelar</button>
                                <button type="button" className="btn btn-modalSuccess" onClick={this.updateMembership}>Guardar</button>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalSaving saved={this.state.saved} error={this.state.error} idModal="modalSaveUpdateMembership"/>
            </div>
        )
    }
}
export default ModalUpdateMembership;