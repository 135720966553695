import React, {Component} from 'react';
import axios from "axios";
import ModalSaving from '../../ModalSaving';

class ModalUpdateReferral extends Component{
    constructor(){
        super();
        this.state={
            saved : false,
            error : false
        }; 
        this.saveReferral = this.saveReferral.bind(this);
    }
    componentDidMount(){
        document.getElementById("currentNameInput").value=this.props.name;
        document.getElementsByName("currentMailInput").value= this.props.email;
    }
    saveReferral = async ()=> {
        await this.setState({saved: false, error: true});
        let send = true;
        const nombre = document.getElementById("currentNameInput").value;
        const email = document.getElementById("currentMailInput").value;
        if(!nombre === "" || !email === ""){
            send = true;
        }
        if(send) {
            document.getElementById("ModalUpdateReferralCancelBtn").click();
            document.getElementById("ModalSaving").classList.add("show");
            const data = {
                name: nombre,
                email: email
            }
            const saved = await axios.put("/api/referral/" + this.props.referral, data).then(resp => {
                return resp.data;
            });
            await this.props.reloadReferral();
            if (saved) {
                await this.setState({saved : true})
            } else {
                await this.setState({error : true})
            }
        }
    }
    render(){
        return(
            <div>
                <div className="modal" id="ModalUpdateReferral" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header-section">
                                <h5 className="modal-title sectionTitle">Actualizar Referido</h5>
                                <button type="button" className="close modalClose modal-closeSection" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body referralBody">
                                <form id="newModel">
                                    <div className="form-group">
                                        <input type="text" placeholder="Nombre" className="form-control" id="currentNameInput"/>
                                    </div>
                                    <div className="form-group">
                                        <input type="text" placeholder="Correo Electrónico" className="form-control" id="currentMailInput"/>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer modalFooter">
                                <button type="button" className="btn btn-danger" id="ModalUpdateReferralCancelBtn" data-dismiss="modal">Cancelar&nbsp;<i className="fas fa-times"></i></button>
                                <button type="button" className="btn btn-success" onClick={this.saveReferral}>Guardar&nbsp;<i className="fas fa-save"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalSaving saved={this.state.saved} error={this.state.error}/>
            </div>
        )
    }
}
export default ModalUpdateReferral;
