import React, { Component } from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import CarouselElement from "./CarouselElement";
import './style.css'

class CarouselObj extends Component{
    constructor(props) {
        super();
        this.clickVideo = [];
        this.viewerKeys=[];
    }

    playVideo = async (e) => {
        let select = e.target;
        let parent = e.target.parentNode;
        let video= (parent.tagName === "g") 
            ? parent.parentNode.parentNode.previousSibling
            : (select.tagName === "svg") 
                ? parent.previousSibling
                : select;
        if(video.paused) {
            video.play();
            video.nextSibling.firstChild.classList.add("playing");
        }
        else{
            video.pause();
            video.nextSibling.firstChild.classList.remove("playing");
        }
    }

    drawMedia(){
        let media = [...this.props.media];
        if(!media || !media.length) return ;
        let length = media.length;
        return media.map((item, index) => {
            this.clickVideo.push(index);
            if((!item.post_media_video) || media.length>1 ){
                this.viewerKeys.push(index);
            }
            return (
                <CarouselElement
                    key={media[index].order}
                    media={item}
                    index={index}
                    length={length}
                    config={this.props.config}
                    playVideo={this.playVideo}
                    clicVideo={this.clickVideo}
                />
            )
        });
    }

    render(){
        let media = [...this.props.media];
        return(
            (media.length > 1) ?
                <div>
                    <Carousel
                        interval={20000}
                        autoPlay={false}
                        showStatus={false}
                        showThumbs={false}
                        emulateTouch={true}
                        dynamicHeight={false}
                        selectedItem={this.props.selectedItem}
                    >
                        {this.drawMedia()}
                    </Carousel>
                </div>
            :
                <div className="only">
                    {this.drawMedia()}
                </div>
        )
    }
}

export default CarouselObj;
