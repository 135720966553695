import React, { Component } from 'react';
import ModalSaving from "../../../ModalSaving";
//import ModalActiveMembership from "../ModalActiveMembership";

class ModalNewPromo extends Component {
    constructor(){
        super();
        this.state = {
            picode : false,
            isActive : false,
            nuevaMembresia : {},
            saved : false,
            error : false
        };
    }
    componentDidMount(){

    }
    setActiveMembership = function(e){
        if(e.target.checked){
            this.setState({isActive : true});
        }
        else{
            this.setState({isActive : false});
        }
    }
    
    
    render(){
        return (
            <div>
                <div className="modal" id="ModalNewPromo" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header-section">
                                <h6 className="modal-title sectionTitle">Nueva Promoción</h6>
                                <button type="button" className="close modalClose" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body modelBody">
                                Se reiniciará a 0 el contador de usuarios a quienes se les envió la promoción anterior.
                            </div>
                            <div className="modal-footer modalFooter">
                                <button type="button" className="btn btn-modalClose" id="ModalNewMembershipCancelBtn" data-dismiss="modal">Cancelar</button>
                                <button type="button" className="btn btn-modalSuccess" onClick={this.saveMembership}>Aceptar</button>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalSaving saved={this.state.saved} error={this.state.error} idModal="ModalSaveNewPromo"/>
            </div>
        )
    }
}
export default ModalNewPromo;