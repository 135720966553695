import React, { Component } from 'react';
import axios from "axios";

class General extends Component {
    constructor(){
        super();
        this.state = {
            allLanguages : [],
            managers : [],
            rates: [],
            ratingOne: "Trajes de baño / lencería",
            ratingTwo: "Transparencias / lencería / semidesnudos",
            ratingThree: "Sexy toys / solo / bdsm / bondage / anal / lésbico",
            disable: true,
            checkedValue: false
        }
        this.handleCheck = this.handleCheck.bind(this);
    }
    async componentDidMount(){
        if(this.props.accessLevel["u"] >= 2) {
            await this.getLanguages();
            document.getElementById("TitleSite").value = (this.props.title === undefined) ? "" : this.props.title;
            document.getElementById("idioma").value = this.props.language;
        }
        if(this.props.accessLevel["u"] >= 3) {
            document.getElementById("EarningFee").value = (!this.props.fee) ? 0 : this.props.fee;
            document.getElementById("ManagerFee").value = (!this.props.managerfee) ? 0 : this.props.managerfee;
            document.getElementById("WireFee").value = (!this.props.wireFee) ? 0 : this.props.wireFee;
            await this.getManagers();
            if (this.props.manager !== null && this.props.accessLevel["u"] >= 3)
                document.getElementById("manager").value = this.props.manager;
            await this.getRates();
            if (this.props.rating !== null && this.props.accessLevel["u"] >= 3)
                document.getElementById("rate").value = this.props.rating;
            document.getElementById("DescriptionRating").value = (!this.props.descRating) 
            ? ((this.props.rating === 1)  
                ? this.state.ratingOne 
                : (this.props.rating === 2)  
                ? this.state.ratingTwo
                : (this.props.rating === 3)  
                ? this.state.ratingThree
                : "") 
            : this.props.descRating;
            const otherDescript = document.getElementById("otherDescription");
            if (this.props.descRating !== null) {
                otherDescript.value = this.props.otherDesc;
                otherDescript.checked = this.props.otherDesc;
                await this.setState({checkedValue: this.props.otherDesc, disable: false});
            }
            
        }
    }
    handleCheck = async (e) => {
        await this.setState({
            checkedValue: !this.state.checkedValue, 
            disable: !this.state.disable
        });
    }
    getLanguages = async () =>{
        await axios.get('/api/model/languages/')
            .then(async resp =>{
                await this.setState({allLanguages : resp.data});
            })
    }
    getRates = async () =>{
        await axios.get('/api/model/rating/')
            .then(async resp =>{
                await this.setState({rates : resp.data});
            })
    }
    getManagers = async () =>{
        await axios.get('/api/managers')
            .then(async resp =>{
                await this.setState({managers : resp.data});
            })
    }
    renderDictionary(){
        const allLanguages = [...this.state.allLanguages];
        return allLanguages.map((item,index) => {
            return (
                <option key={index} value={item.code}>{item.name}</option>
            )
        })
    }
    renderManagers(){
        const managers = [...this.state.managers];
        return managers.map((item,index) => {
            return (
                <option key={index} value={item.id}>{item.name}</option>
            )
        })
    }
    renderRating(){
        const rates = [...this.state.rates];
        return rates.map((item,index) => {
            return (
                <option key={index + 1} value={item.id}>{item.name}</option>
            )
        })
    }
    changeRateDescription = async (e) =>{
        let descRating = document.getElementById("DescriptionRating");
        var id = parseInt(e.target.value);
        if(id === 1){
            descRating.value = this.state.ratingOne;
        } else if (id === 2) {
            descRating.value = this.state.ratingTwo;
        } else if (id === 3) {
            descRating.value = this.state.ratingThree;
        }
    }
    render(){
        return (
            <div className="">
                <div className="text-white head-table">
                    General
                </div>
                <div id='generalModelDetail' className="container-fluid">
                    <div className="divCard row">
                        <div className="col-12 col-sm-6 col-lg-3">
                            <label>User</label>
                            {this.props.mail}
                        </div>
                        <div className="col-12 col-sm-6 col-lg-3">
                            <label>Mail</label>
                            {this.props.mail}
                        </div>
                        <div className="col-12 col-sm-6 col-lg-3">
                            <label>Alias</label>
                            { (this.props.alias === undefined) ? "" : this.props.alias }
                        </div>
                        <div className="col-12 col-sm-6 col-lg-3">
                            <label>Title Site</label>
                            {(this.props.accessLevel["u"] >= 2) ?
                                <input 
                                    name="TitleSite" 
                                    id="TitleSite" 
                                    className="form-control" 
                                    type="text" 
                                /> 
                            : (this.props.title === undefined) ? "" : this.props.title }
                        </div>
                        <div className="col-12 col-sm-6 col-lg-3">
                            <label>Language</label>
                            {(this.props.accessLevel["u"] >= 2) ?
                                <select 
                                    name="idioma" 
                                    id="idioma" 
                                    className="form-control"
                                >
                                    {this.renderDictionary()}
                                </select> 
                            : (this.props.languageName === undefined) 
                                ? "" 
                                : this.props.languageName 
                            }
                        </div>
                        <div className="col-12 col-sm-6 col-lg-3">
                            <label>Manager</label>
                            {(this.props.accessLevel["u"] >= 3) ?
                                <select 
                                    name="manager" 
                                    id="manager" 
                                    className="form-control"
                                >
                                    <option value="0">DiSo</option>
                                    {this.renderManagers()}
                                </select>
                            : (this.props.manager === null) 
                                ? "DiSo" 
                                : this.props.managerName 
                            }
                        </div>
                        {(this.props.accessLevel["u"] >= 3) ?
                            <div className="col-12 col-sm-6 col-lg-3">
                                <label>Model Fee</label>
                                    <input 
                                        name="EarningFee" 
                                        id="EarningFee" 
                                        className="form-control" 
                                        type="number" 
                                        min="0" 
                                        max="100" 
                                        step="0.1"
                                    />
                            </div>
                            : null }
                        {(this.props.accessLevel["u"] >= 3) ?
                            <div className="col-12 col-sm-6 col-lg-3">
                                <label>Manager Fee</label>
                                    <input 
                                        name="ManagerFee" 
                                        id="ManagerFee" 
                                        className="form-control" 
                                        type="number" 
                                        min="0" 
                                        max="100" 
                                        step="0.1"
                                    />
                            </div>
                            : null }
                        {(this.props.accessLevel["u"] >= 3) ?
                            <div className="col-12 col-sm-6 col-lg-3">
                                <label>Wire Fee</label>
                                <input 
                                    name="WireFee" 
                                    id="WireFee" 
                                    className="form-control" 
                                    type="number" 
                                    min="0" 
                                    step="1"
                                />
                            </div>
                            : null }
                        <div className="col-12 col-sm-6 col-lg-3">
                            <label>Content Rating</label>
                            {(this.props.accessLevel["u"] >= 3) ?
                                <select 
                                    name="rate" 
                                    id="rate" 
                                    className="form-control"
                                    onChange={(e) => this.changeRateDescription(e)}
                                >
                                    <option key={0} value={0}>Desactivado</option>
                                    {this.renderRating()}
                                </select> 
                            : (this.props.rating === null) 
                                ? "Desactivado" 
                                : this.props.ratingName 
                            }
                        </div>
                        <div className="col-12 col-sm-6 col-lg-3">
                            <label>Description Rating</label>
                            {(this.props.accessLevel["u"] >= 2) ?
                                <input 
                                    name="DescriptionRating" 
                                    id="DescriptionRating" 
                                    className="form-control" 
                                    type="text"
                                    disabled={this.state.disable}
                                /> 
                            : (!this.props.descRating) 
                                ? ""
                                : this.props.descRating
                            }
                        </div>
                        <div className="col-12 col-sm-6 col-lg-3">
                            {(this.props.accessLevel["u"] >= 2) ?
                                <div className="checkbox-desc-rate">
                                    <input 
                                        type="checkbox" 
                                        className="form-control" 
                                        name="otherDescription" 
                                        id="otherDescription" 
                                        value={this.state.checkedValue === true ? 1 : 0}
                                        defaultChecked={this.state.checkedValue === true ? true : false}
                                        onChange={this.handleCheck}
                                    />
                                    <label
                                        className="form-check-label" 
                                        htmlFor="otherDescription"
                                    >
                                        Other Description
                                    </label>
                                </div>
                            : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default General;
