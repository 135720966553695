import React, { Component } from 'react';
import axios from "axios";
import ModalSaving from "../../ModalSaving";

class ModalNewReferral extends Component{
    constructor(){
        super();
        this.state = {
            saved: true,
            error: false
        };
        this.saveReferral=this.saveReferral.bind(this);
    }
    componentDidMount(){

    }
    saveReferral = async () => {
        await this.setState({saved : false, error: false});
        let send=true;
        const regexMail= new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        const nombre = document.getElementById("newNameInput").value;
        const email = document.getElementById("newMailInput").value;
        const address = document.getElementById("newAddressInput").value;
        if(nombre===""){
            document.getElementById("newNameInput").classList.add("is-invalid");
            send = false;
        }
        else{
            document.getElementById("newNameInput").classList.remove("is-invalid");
        }
        if(email==="" || !regexMail.test(email)){
            document.getElementById("newMailInput").classList.add("is-invalid");
            send = false;
        }
        else{
            document.getElementById("newMailInput").classList.remove("is-invalid");
        }
        if(send) {
            document.getElementById("ModalNewReferralCancelBtn").click();
            document.getElementById("ModalSaving").classList.add("show");
            const data = {
                nombre: nombre,
                email: email,
                address: address
            }
            const saved = await axios.post("/api/referral/", data).then(resp => {
                return resp.data;
            });
            if (saved) {
                await this.setState({saved : true})
                await this.props.reloadReferrals();
            } else {
                await this.setState({error : true})
            }
        }
    }
    render(){
        return(
            <div>
                <div className="modal" id="ModalNewReferral" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header-section">
                                <h6 className="modal-title sectionTitle">Nuevo Referido</h6>
                                <button type="button" className="close modalClose modal-closeSection" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body referralBody">
                                <form id="newReferral">
                                    <div className="form-group">
                                        <input type="text" placeholder="Nombre" className="form-control" id="newNameInput"/>
                                    </div>
                                    <div className="form-group">
                                        <input type="text" placeholder="Correo Electr&oacute;nico" className="form-control" id="newMailInput"/>
                                    </div>
                                    <div className="form-group">
                                        <input type="text" placeholder="Direcci&oacute;n" className="form-control" id="newAddressInput"/>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer modalFooter">
                                <button type="button" className="btn btn-modalClose" id="ModalNewReferralCancelBtn" data-dismiss="modal">Cancelar</button>
                                <button type="button" className="btn btn-modalSuccess" onClick={this.saveReferral}>Guardar</button>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalSaving saved={this.state.saved} error={this.state.error}/>
            </div>
        )
    }
}
export default ModalNewReferral;