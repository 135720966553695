import React, { Component } from 'react';
import axios from "axios";
import ModalSaving from "../../ModalSaving";

class ModalResetCustomerPassword extends Component {
    constructor(){
        super();
        this.state = {
            saved : false,
            error: false,
            newPassword: null
        };
        this.resetPassword=this.resetPassword.bind(this);
    }
    // componentDidMount(){

    // }
    resetPassword = async () => {
        await this.setState({saved : false, error: false});
            document.getElementById("ModalResetCustomerPasswordCancelBtn").click();
            document.getElementById("modalSaveResetPwd").classList.add("show");
            const data ={
                id: this.props.id
            }
            const reset = await axios.post("/api/user/resetCustomerPassword/", data)
            .then(resp => {
                return resp.data;
            });
            if (reset) {
                await this.setState({ saved: true, newPassword: "Nueva contraseña: "+reset.password});

            } else {
                await this.setState({error : true});
            }
    }
    render(){
        return (
            <div>
                <div className="modal" id="ModalResetCustomerPassword" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header-section">
                                <h6 className="modal-title sectionTitle">Resetear Contrase&ntilde;a</h6>
                                <button type="button" className="close modalClose" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body modelBody">
                                <p className='textModalDisable'>¿Desea resetear la contrase&ntilde;a de este usuario?</p>
                            </div>
                            <div className="modal-footer modalFooter">
                                <button type="button" className="btn btn-modalClose" id="ModalResetCustomerPasswordCancelBtn" data-dismiss="modal">Cancelar</button>
                                <button type="button" className="btn btn-modalSuccess" onClick={this.resetPassword}>Aceptar</button>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalSaving
                    saved={this.state.saved}
                    error={this.state.error}
                    customMessage={this.state.newPassword || ''}
                    idModal="modalSaveResetPwd"
                />
            </div>
        )
    }
}
export default ModalResetCustomerPassword;
