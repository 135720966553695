import React, { Component } from 'react';
import axios from "axios";

import Header from '../../../components/Layout/Header';
import Sidebar from '../../../components/Layout/Sidebar';
import Footer from '../../../components/Layout/Footer';
import ModalWarning from '../../../components/Layout/ModalWarning';
import ModalNewUser from '../../../components/Configuration/ModalNewUser';
class Users extends Component {
    constructor(){
        super();
        this.state={
            usuarios: [],
            path: "Home / Configuración / ",
            view: "Usuarios"

        };
        this.reloadUsers = this.reloadUsers.bind(this);
    }
    async componentDidMount() {
        //console.log("inicio");
        await this.props.isAbleToAccess(2,3,1);

        await this.getUsers();
        this.props.changeView("config");
    }
    reloadUsers = async () => {
        this.getUsers();
    }
    getUsers = async () => {
        await axios.get("/api/users/").then(async resp => {
            await this.setState({usuarios : resp.data})
        });
    };
    pintarUsuarios (){
        if(this.state.usuarios.length === 0){
            return (<tr><td colSpan={5} className="text-center">No hay usarios</td></tr>)
        }
        const usuarios = [...this.state.usuarios];
        return usuarios.map((item,index) => {
            return (
                <tr key={index}>
                    <td>{item.id}</td>
                    <td>{item.alias}</td>
                    <td>{item.username}</td>
                    <td>{item.email}</td>
                    <td>{item.access}</td>
                </tr>
            )
        })
    }

    render () {
        return(
            <div className="container">
                <Header username={this.props.username} path={this.state.path} view={this.state.view}/>
                <div className="content">
                    <Sidebar permisos={this.props.permisos} view={this.state.view}/>
                    <div className="content-padding main-content" id="page">
                        <div className="row head-padding">
                            <div className="col-6">
                                <h4>Usuarios</h4>
                            </div>
                            <div className="col-6">
                                {(this.props.accessLevel["c"] !== undefined && this.props.accessLevel["c"] >= 3) ?
                                    <div className="text-right spacer">
                                        <button className="btn btn-newModel" data-toggle="modal" data-target="#ModalNewUser">Nuevo Usuario</button>
                                    </div> 
                                : "" }
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="text-white head-table">
                                    Usuarios
                                </div>
                                <div className="table-responsive tableDiv">
                                    <table className="table tableDataSection">
                                        <thead>
                                        <tr className="attr-table">
                                            <th>ID</th>
                                            <th>Nombre</th>
                                            <th>Usuario</th>
                                            <th>Mail</th>
                                            <th>Acceso</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.pintarUsuarios()}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
                {(this.props.accessLevel["c"] !== undefined && this.props.accessLevel["c"] >= 3) ?
                    <ModalNewUser reloadUsers={this.reloadUsers}/> : "" }
                <ModalWarning title={"Algo salio mal"} message={"No se pudo guardar el usuario"} />
            </div>
        )
    }
}
export default Users;