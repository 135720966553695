import React from 'react';
const ModelsToActivate = (props) => {
    const {
        modelosPromo
    } = props;
    return modelosPromo.map((item,index) => {
        return (
            <li key={index}>
                {item}
            </li>
        )
    })
}
export default ModelsToActivate;