import React, { Component } from 'react';
import axios from "axios";
import Header from '../../components/Layout/Header';
import Sidebar from '../../components/Layout/Sidebar';
import Footer from '../../components/Layout/Footer';
import ModalWarning from '../../components/Layout/ModalWarning';
import ModalNewReferral from "../../components/Referral/ModalNewReferral";
import './Referrals.css';

class Referrals extends Component {
    constructor() {
        super();
        this.state = {
            referrals: [],
            path: "Home / ",
            view: "Referidos"
        };
        this.reloadReferrals = this.reloadReferrals.bind(this);
    }
    
    async componentDidMount() {
        await this.props.isAbleToAccess(6, 1, 1);
        await this.getReferrals();
        this.props.changeView("referral");
    }
    reloadReferrals = async () => {
        this.getReferrals();
    }
    getReferrals = async () => {
        await axios.get("/api/referrals/").then(async resp => {
            await this.setState({ referrals: resp.data })
        });
    };    
    openReferral(e, referral) {
        e.preventDefault();
        window.location.href = "/referrals/referral/" + referral;
    }
    showReferrals() {
        if (this.state.referrals.length === 0) {
            return (<tr><td colSpan={5} className="text-center">No hay referidos</td></tr>)
        }
        const referrals = [...this.state.referrals];
        // eslint-disable-next-line
        return referrals.map((item, index) => {
            if (item.referral_status === 1) {
                return (
                    <tr key={index}>
                        <td>{item.id}</td>
                        <td>{item.name}</td>
                        <td>{item.email}</td>
                        <td>{item.address}</td>
                        <td>
                            <ul className="list-inline">
                                <li className="list-inline-item">
                                    <button title="Editar" className="btn btn-newModel btn-sm btn-icon"
                                        onClick={(e) => this.openReferral(e, item.id)}><span
                                            className="fas fa-edit"></span>
                                    </button>
                                </li>                                
                            </ul>
                        </td>
                    </tr>
                )
            }
        })
    }

    showReferralsInactive (){
        if(this.state.referrals.length === 0){
            return (<tr><td colSpan={5} className="text-center">No hay referidos</td></tr>)
        }
        const referrals = [...this.state.referrals];
        // eslint-disable-next-line
        return referrals.map((item,index) => {
            if (item.referral_status === 0) {
                return (
                <tr key={index}>
                    <td>{item.id}</td>
                    <td>{item.name}</td>
                    <td>{item.email}</td>
                    <td>{item.address}</td>
                    <td>
                        <ul className="list-inline">
                            <li  className="list-inline-item">
                                <button title="Editar" className="btn btn-newModel btn-sm btn-icon"
                                        onClick={(e) => this.openReferral(e, item.id)}><span
                                    className="fas fa-edit"></span>
                                </button>
                            </li>                                                   
                        </ul>
                    </td>
                </tr>
            )
          }
        })
    }

    render() {
        return (
            <div className="container">
                <Header 
                    username={this.props.username} 
                    path={this.state.path} 
                    view={this.state.view}
                />
                <div className="content">
                    <Sidebar 
                        permisos={this.props.permisos} 
                        view={this.props.view}/>
                    <div className="content-padding main-content" id="page">
                        <div className="row head-padding">
                            <div className="col-6">
                                <h4>Referidos</h4>
                            </div>
                            <div className="col-6">
                                {(this.props.accessLevel["c"] !== undefined && this.props.accessLevel["c"] >= 3) ?
                                    <div className="text-right spacer">
                                        <button className="btn btn-newModel" data-toggle="modal" data-target="#ModalNewReferral">Nuevo Referido</button>
                                    </div>
                                    : ""}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="text-white referralColor head-table">
                                    Referidos
                                </div>
                                <div className="tableDiv table-responsive-xl">
                                    <table className="table tableDataSection">
                                        <thead>
                                            <tr className="table-primary">
                                                <th>ID</th>
                                                <th>Nombre</th>
                                                <th>Correo Electrónico</th>
                                                <th>Dirección</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td colSpan='5' className='attr-title'>Referidos Activos</td>
                                            </tr>
                                            {this.showReferrals()}
                                            <tr>
                                                <td colSpan='5' className='attr-title'>Referidos Inactivos</td>
                                            </tr>
                                            {this.showReferralsInactive()}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
                <ModalWarning title={"Algo salio mal"} message={"No se pudo guardar el referido"} />
                <ModalNewReferral reloadReferrals={this.reloadReferrals} />
            </div>
        )
    }
}
export default Referrals;