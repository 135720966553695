import React, { Component } from 'react';
import axios from "axios";
import ModalSaving from "../../../ModalSaving";

class ModalChangeValueCoin extends Component {
    constructor(){
        super();
        this.state = {
            picode : false,
            isActive : false,
            nuevaCoin : {},
            saved : false,
            error : false
        };
        this.changeValuePiCode=this.changeValuePiCode.bind(this);
    }
    componentDidMount(){

    }
    changeValuePiCode = async () => {
        await this.setState({saved : false, error: false});
        document.getElementById("ModalChangeValueCoinCancelBtn").click();
        document.getElementById("ModalSaveChangeValueCoin").classList.add("show");
        const coins = document.getElementById("coinsUpdate").value;
        let send = true;
        if(coins==="" || isNaN(coins) || coins<=0){
            document.getElementById("coinsUpdate").classList.add("is-invalid");
            send = false;
        }
        else{
            document.getElementById("coinsUpdate").classList.remove("is-invalid");
        }
        if(send) {
            const formData = new FormData();
            formData.append("idModel", this.props.model);
            const form = document.getElementById("updateCoin");
            for( let k = 0 ; k<form.length; k++){
                let element=form[k];
                if(element.tagName.toUpperCase() === "INPUT") {
                    if(element.type === "checkbox" || element.type === "radio"){
                        if(element.checked)
                            formData.append(element.name, element.value);
                    }
                    else{

                        if(element.name !== "")
                            formData.append(element.name, element.value);

                    }
                }
                if(element.tagName.toUpperCase() === "SELECT"){
                    formData.append(element.name, element.value)
                }
            }
            const extraCharge = this.props.updatingMembership;
            const saved = await axios.put('/api/model/coin/' + extraCharge, formData)
                .then(async resp => {
                    return resp.data;
                });
            if (saved.Error === undefined) {
                await this.setState({saved: true})
                await this.props.reloadCoins();
            } else {
                await this.setState({error: true});
            }
        }
    }
    render(){
        return (
            <div>
                <div className="modal" id="ModalChangeValueCoin" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Cambiar precio de moneda</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form id="updateCoin">
                                    <div className="form-group">
                                        <input type="number" placeholder="Monedas" className="form-control" id="coinsUpdate" name="coins" defaultValue={this.props.curMonedasToChange}/>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger" id="ModalChangeValueCoinCancelBtn" data-dismiss="modal">Cancelar&nbsp;<i className="fas fa-times"></i></button>
                                <button type="button" className="btn btn-success" onClick={this.changeValuePiCode}>Guardar&nbsp;<i className="fas fa-save"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalSaving saved={this.state.saved} error={this.state.error} idModal="ModalSaveChangeValueCoin"/>
            </div>
        )
    }
}
export default ModalChangeValueCoin;