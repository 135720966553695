import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';
import ModalSaving from "../../ModalSaving";
import DatePicker from "react-datepicker";
import pdfMake from 'pdfmake';
import pdfFonts from "pdfmake/build/vfs_fonts";
import "react-datepicker/dist/react-datepicker.css";
import HelpBox from "../../HelpBox";
import {isMobile} from 'react-device-detect';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
var BarChart = require("react-chartjs-2").Bar;
var options = {
    maxBarThickness: 4,
    responsive: true,
    maintainAspectRatio: true,
    scaleShowGridLines : false,
    scaleGridLineColor : "rgba(255,255,255,.09)",
    scaleGridLineWidth : .75,
    scaleShowHorizontalLines: true,
    scaleShowVerticalLines: false,
    pointDot : true,
    pointDotRadius : 1,
    pointDotStrokeWidth : 1,
    pointHitDetectionRadius : 10,
    datasetStroke : false,
    datasetStrokeWidth : 2,
    datasetFill : true,
    offsetGridLines : false,
    borderWidth: 0,
    tooltips: {
        mode: 'index',
        callbacks: {
            // Use the footer callback to display the sum of the items showing in the tooltip
            label: function(tooltipItem, data) {
                return data.datasets[tooltipItem.datasetIndex].label + ": $" + tooltipItem.yLabel.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
            },
        }
    },
    scales : {
        yAxes: [{
            ticks: {
                beginAtZero: true,
                callback: function(value, index, values) {
                    return "$ " + value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
                }
            }
        }]
    }
};
var optionsNotMoney = {
    maxBarThickness: 4,
    responsive: true,
    maintainAspectRatio: true,
    scaleShowGridLines : false,
    scaleGridLineColor : "rgba(255,255,255,.09)",
    scaleGridLineWidth : .75,
    scaleShowHorizontalLines: true,
    scaleShowVerticalLines: false,
    pointDot : true,
    pointDotRadius : 1,
    pointDotStrokeWidth : 1,
    pointHitDetectionRadius : 10,
    datasetStroke : false,
    datasetStrokeWidth : 2,
    datasetFill : true,
    offsetGridLines : false,
    borderWidth: 0,
    scales : {
        yAxes: [{
            ticks: {
                beginAtZero: true,
                callback: function(value, index, values) {
                    return value.toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,');
                }
            }
        }]
    }
}
class Analytics extends Component{
    constructor(){
        super();
        this.state={
            modelEarning: [],
            modelCurrent: [],
            analyticsUsersDays:15,
            currentUsersDays: [],
            currentUsersValues: [],
            currentUsersPromedio: 0,
            salesDays: [],
            salesPeriodDays: [],
            salesValues:[],
            salesValuesEarn:[],
            totalSales: [],
            totalPeriodSales: 0,
            totalSalesTwo : [],
            currsalesDays: [],
            currsalesValues: [],
            currtotalSales : [],
            totalCoins : [],
            currTotalCoins : [],
            totalBruto : [],
            currTotalBruto : [],
            startDate: new Date(),
            endDate: new Date(),
            periodCurrent : null,
            periodEarning : null,
            error : false,
            showHidden : false,
            modelos: [],
            totalModelos : 0,
            step : 1,
            range : 5,
            sortby: null,
            sortin: "ASC",
            gradiente:'',
            activeGraph: 0,
            radioSelected: 0,
            tableTotals : [0,0,0,0,0,0]
        }
        this.alterData = this.alterData.bind(this);
        this.handleChangeStart = this.handleChangeStart.bind(this);
        this.handleChangeEnd = this.handleChangeEnd.bind(this);
        this.changeStep = this.changeStep.bind(this);
        this.sortTable = this.sortTable.bind(this);
    }

    componentDidMount(){
        ////////////////////Earnings///////////////////////
        this.getModels();
        this.changeRange();
        const inputs = document.getElementsByClassName("dateInput");
        inputs[0].setAttribute("readonly",true);
        inputs[1].setAttribute("readonly",true);
        //////////////////////////////////////////////////
        if (isMobile) {
            this.setState({analyticsUsersDays: 7})
        }
    }

    async handleChangeStart(date) {
        await this.setState({
            startDate: date
        });
        let endDate = this.state.endDate;
        endDate = new Date(endDate);
        if(endDate.getTime() < date.getTime()){
            await this.setState({
                endDate: date
            });
        }
        await this.changeRange();
    }

    async handleChangeEnd(date) {
        await this.setState({
            endDate: date
        });
        await this.changeRange();
    }

    getModels = async () => {
        await axios.get("/api/models/").then(async resp => {
            await this.setState({modelos : resp.data, totalModelos : resp.data.length})
        });
    };

    getPeriod = async (inicio = null, fin = null) => {

        //const duration = fin.diff(inicio, 'days');
        let duration = 0;
        while(fin > inicio){
            duration++;
            fin.subtract(1, 'days');
        }
        fin.add(duration, 'days');
        //Obtener el siguiente jueves de la semana actual
        //const disoPay = moment(fin.subtract(2, 'weeks'));
        const lastInterval = moment(fin.subtract(13, 'days'))
            .format("YYYY-MM-DD");
        const firstInterval = moment(fin.subtract(duration, 'days'))
            .format("YYYY-MM-DD");
        return ({
            firstInterval : firstInterval,
            lastInterval: lastInterval
        })
    }
    getCurrEarnigs=async(period,model=0,range=5,step=1, sort=null, sortIn=null, onlyModel = 0)=>{
        period["model"]=model;
        period["range"] = range;
        period["step"] = step;
        period["sort"] = (sort === null) ? false : sort;
        period["sortIn"] = (sortIn === null) ? "ASC" : sortIn;
        period["onlyModel"] = onlyModel;
        const resp = await axios.get('/api/v2/analytics/earning/model', {
            params: period
        })
        .then(resp=>{
            this.setState({modelCurrent: resp.data});
            return true;
        });
        return resp;
    }
    getTotalEarnigs=async(period,model=0,range=5,step=1, sort=null, sortIn=null, onlyModel = 0)=>{
        period["model"]=model;
        period["range"] = range;
        period["step"] = step;
        period["sort"] = (sort === null) ? false : sort;
        period["sortIn"] = (sortIn === null) ? "ASC" : sortIn;
        period["onlyModel"] = onlyModel;
        const resp = await axios.get('/api/v2/analytics/earning/model', {
            params: period
        })
        .then(resp=>{
            this.setState({modelEarning: resp.data});
            return true;
        });
        return resp;
    }
    alterData = async () => {
        this.setState({
            step : 1
        })
        this.changeRange()
    }
    changeStep = async (e) => {
        const currStep = this.state.step;
        const step = parseInt(e.target.getAttribute("data-step"));
        if(step !== currStep){
            await this.setState({step : step});
            this.changeRange();
        }
    }
    changeRange = async () => {
        let onlyModel = 0;
        if(document.getElementById("onlyModelInfo1")) {
            onlyModel = document.querySelector('input[name="onlyModelInfo"]:checked').value;
        }
        const range = document.getElementById("displaySelect").value;
        await this.setState({range : parseInt(range)});
        const step = this.state.step;
        const sort = this.state.sortby;
        const sortIn = this.state.sortin;
        await this.setState({saved : false, error: false});
        document.getElementById("ModalSaving").classList.add("show");
        const modelo = document.getElementById("modelSelect").value;
        const periodo = document.getElementById("periodSelect").value;
        const inputs = document.getElementsByClassName("dateInput");
        let inicio = null;
        let fin = null;
        let inicio_general = null;
        let fin_general = null;
        switch (periodo) {
            case "0":
                this.setState({
                    showHidden : false
                });
                fin = moment();
                inicio = moment().subtract(30,"days");
                inicio_general = inicio;
                fin_general = fin;
                break;
            case "1":
                this.setState({
                    showHidden : false
                });
                fin = moment().subtract(1,"week");
                inicio = moment().subtract(1,"week").subtract(6,"days");
                inicio_general = inicio;
                fin_general = fin;
                break;
            case "2":
                this.setState({
                    showHidden : false
                });
                fin = moment().subtract(2,"week");
                inicio = moment().subtract(2,"week").subtract(6,"days");
                inicio_general = inicio;
                fin_general = fin;
                break;
            case "3":
                this.setState({
                    showHidden : false
                });
                fin = moment();
                inicio = moment().startOf("month");
                inicio_general = inicio;
                fin_general = fin;
                break;
            case "4":
                this.setState({
                    showHidden : false
                });
                fin = moment().subtract(1,"month").endOf("month");
                inicio = moment().subtract(1,"month").startOf("month");
                inicio_general = inicio;
                fin_general = fin;
                break;
            case "5":
                 this.setState({
                     showHidden: false,
                 });
                 fin = moment();
                 inicio = moment();
                 while (fin.day() !== 4) {
                     //4 = Jueves
                     //Si fin.day() > 4 entonces trae el jueves de la siguiente semana
                     fin.add(1, "days");
                 }
                 while (inicio.day() !== 4) {
                     //4 = Jueves
                     //Si inicio.day() > 4 entonces trae el jueves de la siguiente semana
                     inicio.subtract(1, "days");
                 }
                 if (fin.isSame(inicio, "day")) {
                     inicio.subtract(6, "days");
                 }
                 fin_general = moment();
                 inicio_general = moment().day(0);
                 break;
            case "6":
                this.setState({
                    showHidden : false
                });
                fin = moment();
                inicio = moment().subtract(90,"days");
                inicio_general = inicio;
                fin_general = fin;
                break;
            case "7":
                this.setState({
                    showHidden : false
                });
                fin = moment();
                inicio = moment().startOf("year");
                inicio_general = inicio;
                fin_general = fin;
                break;
            case "8":
                this.setState({
                    showHidden : true
                });
                inicio = moment(inputs[0].value);
                fin = moment(inputs[1].value);
                inicio_general = inicio;
                fin_general = fin;
                break;
            default:
                this.setState({
                    showHidden : false
                });
                fin = moment();
                inicio = moment();
                while(fin.day()!==4){//4 = Jueves
                    //Si fin.day() > 4 entonces trae el jueves de la siguiente semana
                    fin.add(1, 'days');
                }
                while(inicio.day()!==4){//4 = Jueves
                    //Si inicio.day() > 4 entonces trae el jueves de la siguiente semana
                    inicio.subtract(1, 'days');
                }
                if(fin.isSame(inicio,"day")){
                    inicio.subtract(6, "days");
                }
                inicio_general = moment();
                fin_general = moment().day(0);
                break;
        }
        if(modelo === "0"){
            this.setState({ totalModelos: this.state.modelos.length});
        } else{
            this.setState({ totalModelos: 1});
        }
        const periodCurrent = {
            firstInterval: inicio.format("YYYY-MM-DD"),
            lastInterval: fin.format("YYYY-MM-DD")
        };
        const periodCurrentGeneral = {
            firstInterval: inicio_general.format("YYYY-MM-DD"),
            lastInterval: fin_general.format("YYYY-MM-DD")

        }
        const p1 = new Promise(resolve => {
            return resolve(
                this.getTotalCurrEarnigsByDay(periodCurrent, modelo, range, step, onlyModel),
                this.getCurrEarnigs(periodCurrent,modelo,range,step, sort, sortIn, onlyModel),
                this.getTotalMembership(periodCurrentGeneral,modelo),
                this.getSellPerDay(periodCurrentGeneral,modelo)
            )
        });
        const period = await this.getPeriod(inicio, fin);
        await this.setState({periodCurrent : periodCurrent,periodEarning : period})
        const p2 = new Promise(resolve => {
            return resolve(
                this.getTotalEarnigs(period, modelo, range, step, sort, sortIn, onlyModel)
            )
        });
        const p3 = new Promise(resolve => {
            return resolve(
                this.getTotalEarnigsByDay(period, modelo, range, step, onlyModel)
            )
        });
        Promise.all([p1, p2, p3]).then(values => {
            document.getElementById("ModalSaving").classList.remove("show");
        });
    }
    getTotalEarnigsByDay=async(period,model=0,range=5,step=1,onlyModel = 0)=>{
        this.setState({salesDays: [], salesValuesEarn: [], totalSales : [], totalCoins : [], totalBruto : []})
        period["model"]=model;
        period["onlyModel"] = onlyModel;
        const resp = await axios.get('/api/v2/analytics/earning/model/byday', {
            params: period
        })
            .then(async res=>{
                const salesPerDay = res.data
                const dateSales = salesPerDay.map(day => day.date);
                const valueSales = salesPerDay.map(day => (day.totalEarning === null) ? 0 : 1*day.totalEarning.toFixed(2));
                const valueCoins = salesPerDay.map(day => (day.totalCoins === null) ? 0 : 1*day.totalCoins.toFixed(2));
                const totalUsersSales = salesPerDay.map(day => (day.supertotal === null) ? 0 : 1*day.supertotal.toFixed(2));
                const totalValueSales = salesPerDay.map(day => ( ((day.totalCoins === null) ? 0 : 1*day.totalEarning.toFixed(2)) + ((day.totalCoins === null) ? 0 : 1*day.totalCoins.toFixed(2))));
                await this.setState({salesDays: dateSales, salesValuesEarn: valueSales, totalSales : totalUsersSales, totalCoins : valueCoins, totalBruto : totalValueSales});
                return true;
            });
        return resp;
    }
    getTotalCurrEarnigsByDay=async(period,model=0,range=5,step=1,onlyModel = 0)=>{
        this.setState({currsalesDays: [], currsalesValues: [], currtotalSales : [], currTotalCoins : [], currTotalBruto : []})
        period["model"]=model;
        //console.log(onlyModel);
        period["onlyModel"] = onlyModel;
        const resp = await axios.get('/api/v2/analytics/earning/model/byday', {
            params: period
        })
            .then(async res=>{
                const salesPerDay = res.data
                const dateSales = salesPerDay.map(day => day.date);
                const valueSales = salesPerDay.map(day => (day.totalEarning === null) ? 0 : 1*day.totalEarning.toFixed(2));
                const valueCoins = salesPerDay.map(day => (day.totalCoins === null) ? 0 : 1*day.totalCoins.toFixed(2));
                const totalUsersSales = salesPerDay.map(day => (day.supertotal === null) ? 0 : 1*day.supertotal.toFixed(2));
                const totalValueSales = salesPerDay.map(day => ( ((day.totalCoins === null) ? 0 : 1*day.totalEarning.toFixed(2)) + ((day.totalCoins === null) ? 0 : 1*day.totalCoins.toFixed(2))));
                await this.setState({currsalesDays: dateSales, currsalesValues: valueSales, currtotalSales : totalUsersSales, currTotalCoins : valueCoins, currTotalBruto : totalValueSales});
                return true;
            });
        return resp;
    }
    formatMoney = (n, c, d, t) => {
        c = isNaN(c = Math.abs(c)) ? 2 : c;
        d = d === undefined ? "." : d;
        t = t === undefined ? "," : t;
        if(isNaN(n)){
            return n
        }
        let s = n < 0 ? "-" : "";
        let i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c)));
        let k = (i.length);
        let j = k > 3 ? k % 3 : 0;
        return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
    };
    sortTable = async (e) => {
        let sortables = document.getElementsByClassName("sortable");
        let actualSort = e.target.getAttribute("data-sortin");
        let nextSort = (actualSort === "") ? "ASC" : ((actualSort === "ASC") ? "DESC" : "");
        let classSort = (actualSort === "") ? "fa-sort-down" : ((actualSort === "ASC") ? "fa-sort-up" : "fa-sort");
        for( let i = 0 ; i < sortables.length ; i++ ){
            sortables[i].classList.remove("fa-sort-up");
            sortables[i].classList.remove("fa-sort-down");
            sortables[i].classList.add("fa-sort");
        }
        e.target.classList.add(classSort);
        e.target.setAttribute("data-sortin", nextSort);
        const sort = e.target.getAttribute("data-sort");
        await this.setState({
            sortby : sort,
            sortin : nextSort
        });
        this.changeRange();
    }
    renderCurrent(){
        if(this.state.modelCurrent.length===0) {
            return (<tr><td>No Data</td></tr>);
        }
        let copyEarning = [...this.state.modelCurrent];
        let total = this.state.tableTotals;
        total[0]=0;
        total[1]=0;
        total[2]=0;
        total[3]=0;
        total[4]=0;
        total[5]=0;
        total[6]=0;
        return copyEarning.map( (item, index) => {
            total[0] += (item.totalEarning === null) ? 0 : item.totalEarning;
            total[1] += (item.totalCoins === null) ? 0 : item.totalCoins;
            total[2] += (item.chargeback === null) ? 0 : item.chargeback
            total[3] += (item.totalRefunds === null) ? 0 : item.totalRefunds;
            total[4] += (item.subtotal === null) ? 0 : item.subtotal;
            total[5] += (item.processFee === null) ? 0 : item.processFee;
            total[6] += (item.supertotal === null) ? 0 : item.supertotal;

            return(
                <tr key={index}>
                    <th scope="row">
                        {item.username}
                    </th>
                    <td>
                        <p>${(item.totalEarning === null) ? "0.00" : this.formatMoney(item.totalEarning)}</p>
                        <p><span>{ (item.countEarning === null) ? 0 : item.countEarning }</span></p>
                    </td>
                    <td>
                        <p>${(item.totalCoins === null) ? "0.00" : this.formatMoney(item.totalCoins)}</p>
                        <p><span>{ (item.countCoins === null) ? 0 : item.countCoins }</span></p>
                    </td>
                    <td>
                        <p>${(item.chargeback === null) ? "0.00" : this.formatMoney(item.chargeback)}</p>
                        <p><span>{ (item.countChargeback === null) ? 0 : item.countChargeback }</span></p>
                    </td>
                    <td>
                        <p>${(item.totalRefunds === null) ? "0.00" : this.formatMoney(item.totalRefunds)}</p>
                        <p><span>{ (item.countRefunds === null) ? 0 : item.countRefunds }</span></p>
                    </td>
                    <td>
                        ${(item.subtotal === null) ? "0.00" : this.formatMoney(item.subtotal)}
                    </td>
                    <td>
                        <p>$-{(item.processFee === null) ? "0.00" : this.formatMoney(item.processFee)}</p>
                    </td>
                    <td>
                        ${(item.supertotal === null) ? "0.00" : this.formatMoney(item.supertotal)}
                    </td>
                </tr>
            )
        });
    }
    renderEarnings(){

        if(this.state.modelEarning.length===0) {
            return (<tr><td>No Data</td></tr>);
        }
        let copyEarning = [...this.state.modelEarning];
        let total = this.state.tableTotals;
        total[0]=0;
        total[1]=0;
        total[2]=0;
        total[3]=0;
        total[4]=0;
        total[5]=0;
        total[6]=0;
        return copyEarning.map( (item, index) => {
            total[0] += (item.totalEarning === null) ? 0 : item.totalEarning;
            total[1] += (item.totalCoins === null) ? 0 : item.totalCoins;
            total[2] += (item.chargeback === null) ? 0 : item.chargeback
            total[3] += (item.totalRefunds === null) ? 0 : item.totalRefunds;
            total[4] += (item.supertotal === null) ? 0 : item.subtotal;
            total[5] += (item.processFee === null) ? 0 : item.processFee;
            total[6] += (item.supertotal === null) ? 0 : item.supertotal;
            return(
                <tr key={index}>
                    <th scope="row">
                        {item.username}
                    </th>
                    <td>
                        <p>${(item.totalEarning === null) ? "0.00" : this.formatMoney(item.totalEarning)}</p>
                        <p><span>{ (item.countEarning === null) ? 0 : item.countEarning }</span></p>
                    </td>
                    <td>
                        <p>${(item.totalCoins === null) ? "0.00" : this.formatMoney(item.totalCoins)}</p>
                        <p><span>{ (item.countCoins === null) ? 0 : item.countCoins }</span></p>
                    </td>
                    <td>
                        <p>${(item.chargeback === null) ? "0.00" : this.formatMoney(item.chargeback)}</p>
                        <p><span>{ (item.countChargeback === null) ? 0 : item.countChargeback }</span></p>
                    </td>
                    <td>
                        <p>${(item.totalRefunds === null) ? "0.00" : this.formatMoney(item.totalRefunds)}</p>
                        <p><span>{ (item.countRefunds === null) ? 0 : item.countRefunds }</span></p>
                    </td>
                    <td>
                        ${(item.subtotal === null) ? "0.00" : this.formatMoney(item.subtotal)}
                    </td>
                    <td>
                        <p>$-{(item.processFee === null) ? "0.00" : this.formatMoney(item.processFee)}</p>
                    </td>
                    <td>
                        ${(item.supertotal === null) ? "0.00" : this.formatMoney(item.supertotal)}
                    </td>
                </tr>
            )
        });
    }
    pintarModelos (){
        if(this.state.modelos.length === 0){
            return ("No hay modelos")
        }
        const modelos = [...this.state.modelos];
        // eslint-disable-next-line
        return modelos.map((item,index) => {
            if(item.status_active === 1){
                return (
                    <option key={index} value={item.id_model}>{item.username}</option>
                )
            }
        })
    }
    getTotalMembership=async(period,modelo)=>{
        this.setState({currentUsersDays: [], currentUsersValues: [], currentUsersPromedio:0});
        period.model = modelo;
        axios.get('/api/v2/analytics/current/user/day/graph/',{
            params: period
        })
        .then(res=>{
            const inicio = moment(period.firstInterval);
            const fin = moment(period.lastInterval);
            const interval = inicio.diff(fin, "days") + 1;
            let dates = [];
            while (inicio.isSameOrBefore(fin)){
                dates.push(inicio.format("MM-DD"));
                inicio.add(1, "days");
            }
            const values = res.data.map(day => day.count);
            //const totalUsers = values.reduce((val1, val2) => val1+val2);
            const promedio = (interval).toFixed(0);
            this.setState({currentUsersDays: dates, currentUsersValues: values, currentUsersPromedio:promedio});
        });
    };

    getSellPerDay = async(period,modelo) =>{
        this.setState({salesPeriodDays: [], salesValues: [], totalPeriodSales : 0});
        period.model = modelo;
        axios.get('/api/v2/analytics/new/sales/graph/',{
            params: period
        })
        .then(res=>{
            const inicio = moment(period.firstInterval);
            const fin = moment(period.lastInterval);
            const salesPerDay = res.data.salesPerDay
            let dates = [];
            while (inicio.isSameOrBefore(fin)){
                dates.push(inicio.format("MM-DD"));
                inicio.add(1, "days");
            }
            const valueSales = salesPerDay.map(day => day.count);
            let totalUsersSales = 0;
            if(valueSales.length > 0)
                totalUsersSales = valueSales.reduce((val1, val2) => val1+val2);
            this.setState({salesPeriodDays: dates, salesValues: valueSales, totalPeriodSales : totalUsersSales});
        });
    }

    totalMember= async() =>{
        const menu = document.getElementById('earnsGraphMenu');
        menu.style.display='none';
        this.setState({activeGraph: 0});
    }
    newSales= async() =>{
        const menu = document.getElementById('earnsGraphMenu');
        var active = document.getElementById('firstG');
        menu.style.display='none';
        active.classList.remove("activeGraphOne");
        this.setState({activeGraph: 1});
    }
    EarnCut= async() =>{
        const menu = document.getElementById('earnsGraphMenu');
        var active = document.getElementById('firstG');
        document.getElementById('customRadio1').checked = false;
        document.getElementById('customRadio2').checked = true;
        active.classList.remove("activeGraphOne");
        menu.style.display='block';
        this.setState({activeGraph: 2,radioSelected: 1});
    }
    EarnMoney= async() =>{
        const menu = document.getElementById('earnsGraphMenu');
        var active = document.getElementById('firstG');
        active.classList.remove("activeGraphOne");
        menu.style.display='none';
        this.setState({activeGraph: 3,radioSelected: 2});
        document.getElementById('customRadio2').checked = false;
        document.getElementById('customRadio1').checked = true;
    }
    handleRadioCurrent= async() =>{
        this.setState({radioSelected: 0});
        document.getElementById('customRadio2').checked = false;
    }
    handleRadioCharge= async() =>{
        this.setState({radioSelected: 1});
        document.getElementById('customRadio1').checked = false;
    }
    handleModelCheck = async() => {
        this.changeRange();
    }

    render(){
        return(
            <div className="row">
                <div className="col-12 col-lg-12">
                    <div className="divCardGraphs card ">
                        <div className="text-white head-graph">
                            Membresias Total Activas - Nuevas Ventas - Corte de Ganancias
                        </div>
                        <div className="card-body body-graph">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className='col-12'>
                                        <div className='row graphEarns'>
                                            <div className="form-group col-12 col-sm-6 col-md-6 col-lg-6" style={{'display':'none'}}>
                                                <label>Display</label>
                                                <select onChange={this.alterData} name="display" id="displaySelect" className="form-control form-control-sm">
                                                    <option value="5">5</option>
                                                    <option value="10">10</option>
                                                    <option value="25">25</option>
                                                    <option value="50">50</option>
                                                    <option value="100">100</option>
                                                    <option value="0">Todos</option>
                                                </select>
                                            </div>
                                            <div className="form-group col-12 col-sm-6 col-md-6 col-lg-6">
                                                <label>Modelo</label>
                                                <select onChange={this.alterData} name="model" id="modelSelect" className="form-control form-control-sm">
                                                    <option value="0">Todos</option>
                                                    {this.pintarModelos()}
                                                </select>
                                            </div>
                                            <div className="form-group col-12 col-sm-6 col-md-6 col-lg-6">
                                                <label>Periodo</label>
                                                <select onChange={this.alterData} name="period" id="periodSelect" className="form-control form-control-sm">
                                                    <option value="0">&Uacute;ltimos 30 d&iacute;as</option>
                                                    <option value="1">Semana pasada</option>
                                                    <option value="2">Hace dos semanas</option>
                                                    <option value="3">Este mes</option>
                                                    <option value="4">Mes pasado</option>
                                                    <option value="5">Periodo Actual</option>
                                                    <option value="6">&Uacute;ltimos 90 d&iacute;as</option>
                                                    <option value="7">Este año</option>
                                                    <option value="8">Otro periodo</option>
                                                </select>
                                            </div>
                                            <div className={"form-group col-12 col-sm-6 col-lg-6 " + ((this.state.showHidden) ? "" : "hidden")}>
                                                <label>Inicio</label>
                                                <DatePicker className="form-control form-control-sm dateInput"
                                                            dateFormat="yyyy-MM-dd"
                                                            maxDate={new Date()}
                                                            selected={this.state.startDate}
                                                            onChange={this.handleChangeStart}
                                                />
                                            </div>
                                            <div className={"form-group col-12 col-sm-6 col-lg-6 " + ((this.state.showHidden) ? "" : "hidden")}>
                                                <label>Fin</label>
                                                <DatePicker className="form-control form-control-sm dateInput"
                                                            dateFormat="yyyy-MM-dd"
                                                            maxDate={new Date()}
                                                            minDate={this.state.startDate}
                                                            selected={this.state.endDate}
                                                            onChange={this.handleChangeEnd}
                                                />
                                            </div>

                                        </div>
                                        <div className='btnGraphs'>
                                            <button id='firstG' className='btn btn-graphOne activeGraphOne' onClick={this.totalMember}>Membresias Total Activas</button>
                                            <button className='btn btn-graphTwo' onClick={this.newSales}>Nuevas Ventas</button>
                                            <button className='btn btn-graphThree' onClick={this.EarnCut}>Corte de ganancias</button>
                                        </div>

                                        {this.state.currentUsersValues.length> 0 && this.state.activeGraph===0 ?
                                        <div>
                                            <i className="fas fa-calendar-week" style={{'color':'#fff','fontSize':14,'paddingRight':3}}></i>


                                            <BarChart height={isMobile ? 250 : 125}
                                                id='membershipChart'
                                                data={{
                                                labels: this.state.currentUsersDays,
                                                datasets: [
                                                    {
                                                        label: 'Users Memberships',
                                                        backgroundColor: '#75E994',
                                                        borderColor: '#BDF966',
                                                        pointBackgroundColor: '#BDF966',
                                                        pointBorderColor: '#75E994',
                                                        pointHoverBackgroundColor: '#75E994',
                                                        pointHoverBorderColor: 'BDF966',
                                                        data: this.state.currentUsersValues
                                                    }
                                                ]
                                            }} options={optionsNotMoney}/>
                                        </div>
                                        : null}

                                        {this.state.salesValues.length > 0 && this.state.activeGraph===1 ?
                                        <div>
                                            <i className="fas fa-calendar-week" style={{'color':'#fff','fontSize':14,'paddingRight':3}}></i>
                                            <BarChart height={isMobile ? 250 : 125}
                                                data={{

                                                labels: this.state.salesPeriodDays,
                                                datasets: [
                                                    {
                                                        label: 'Users Memberships',
                                                        backgroundColor: '#66E0ED',
                                                        borderColor: 'rgba(220,220,220,1)',
                                                        pointBackgroundColor: 'rgba(220,220,220,1)',
                                                        pointBorderColor: '#b0b0b0',
                                                        pointHoverBackgroundColor: '#b0b0b0',
                                                        pointHoverBorderColor: 'rgba(220,220,220,1)',
                                                        data: this.state.salesValues
                                                    }
                                                ]
                                            }} options={optionsNotMoney}/>
                                        </div>
                                        :null}
                                        <div id='earnsGraphMenu' style={{'display':'none'}}>
                                            {(this.props.accessLevel["r"] >= 2) ?
                                            <div className="checkboxGraph">
                                                <div className="custom-control custom-radio">
                                                    <input type="radio" id="onlyModelInfo1" name="onlyModelInfo" className="custom-control-input" onClick={this.handleModelCheck} defaultChecked value={0} />
                                                    <label className="custom-control-label" htmlFor="onlyModelInfo1" >Ganancias Totales</label>
                                                </div>
                                                <div className="custom-control custom-radio">
                                                    <input type="radio" id="onlyModelInfo2" name="onlyModelInfo" className="custom-control-input" onClick={this.handleModelCheck} value={1} />
                                                    <label className="custom-control-label" htmlFor="onlyModelInfo2" >Ganancias Manager</label>
                                                </div>
                                                <div className="custom-control custom-radio">
                                                    <input type="radio" id="onlyModelInfo3" name="onlyModelInfo" className="custom-control-input" onClick={this.handleModelCheck} value={2} />
                                                    <label className="custom-control-label" htmlFor="onlyModelInfo3" >Ganancias Modelo</label>
                                                </div>
                                            </div> : null }
                                            <div className='radioGraph'>
                                                <div className="custom-control custom-radio">
                                                    <input type="radio" id="customRadio2" name="customRadio2" className="custom-control-input" onClick={this.handleRadioCharge} defaultChecked/>
                                                    <label className="custom-control-label" htmlFor="customRadio2">Ganancias al corriente del periodo</label>
                                                    <span className="fas fa-question-circle question-span-graph"  data-toggle="modal" data-target="#modalHelpCurrent"></span>
                                                </div>
                                                <div className="custom-control custom-radio">
                                                    <input type="radio" id="customRadio1" name="customRadio1" className="custom-control-input" onClick={this.handleRadioCurrent}/>
                                                    <label className="custom-control-label" htmlFor="customRadio1" >Ganancias por cobrar del periodo</label>
                                                    <span className="fas fa-question-circle question-span-graph"  data-toggle="modal" data-target="#modalHelpPorCobrar"></span>
                                                </div>
                                            </div>
                                        </div>

                                        {this.state.salesValuesEarn.length > 0 && this.state.activeGraph===2 ?
                                            <BarChart height={isMobile ? 250 : 125}
                                                      data={{
                                                          labels: this.state.currsalesDays,
                                                          datasets: [
                                                              {
                                                                  label: 'Total Ganancias',
                                                                  backgroundColor: '#F44520',
                                                                  borderColor: '#F44520',
                                                                  pointBackgroundColor: '#F44520',
                                                                  pointBorderColor: '#b0b0ff',
                                                                  pointHoverBackgroundColor: '#b0b0ff',
                                                                  pointHoverBorderColor: 'rgba(220,220,220,1)',
                                                                  data: (this.state.radioSelected=== 1) ? this.state.currtotalSales : this.state.totalSales
                                                              },
                                                              {
                                                                  label: 'Ingreso Bruto',
                                                                  backgroundColor: '#66E0ED',
                                                                  borderColor: '#66E0ED',
                                                                  pointBackgroundColor: '#66E0ED',
                                                                  pointBorderColor: '#b0b0ff',
                                                                  pointHoverBackgroundColor: '#b0b0ff',
                                                                  pointHoverBorderColor: 'rgba(220,220,220,1)',
                                                                  data: (this.state.radioSelected=== 1) ? this.state.currTotalBruto : this.state.totalBruto
                                                              },
                                                              {
                                                                  label: 'Ingreso Bruto Membresias',
                                                                  backgroundColor: '#75E994',
                                                                  borderColor: '#75E994',
                                                                  pointBackgroundColor: '#75E994',
                                                                  pointBorderColor: '#b0b0b0',
                                                                  pointHoverBackgroundColor: '#b0b0b0',
                                                                  pointHoverBorderColor: 'rgba(220,220,220,1)',
                                                                  data: (this.state.radioSelected=== 1) ? this.state.currsalesValues : this.state.salesValuesEarn
                                                              },
                                                              {
                                                                  label: 'Ingreso Bruto Coins',
                                                                  backgroundColor: '#f3c70d',
                                                                  borderColor: '#f3c70d',
                                                                  pointBackgroundColor: '#FB0046',
                                                                  pointBorderColor: '#b0b0b0',
                                                                  pointHoverBackgroundColor: '#b0b0b0',
                                                                  pointHoverBorderColor: 'rgba(220,220,220,1)',
                                                                  data: (this.state.radioSelected=== 1) ? this.state.currTotalCoins : this.state.totalCoins
                                                              }
                                                          ]
                                                      }} options={options}/>


                                        :null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.activeGraph===2 ?
                    this.state.radioSelected=== 1 ?
                    <div className="col-12">
                        <div className="table-responsive tableDiv">
                            <div className='attr-title'>Ganancias al corriente del periodo</div>
                            <table className="table tableDataSection">
                                <thead className='headd'>
                                <tr className='attr-table'>
                                        <th scope="col">Model&nbsp;<span data-sortin="" onClick={this.sortTable} className="fas fa-sort sortable pointer" data-sort={1}></span></th>
                                        <th scope="col">Ingreso Bruto Membresias&nbsp;<span data-sortin="" onClick={this.sortTable} className="fas fa-sort sortable pointer" data-sort={2}></span></th>
                                        <th scope="col">Ingreso Bruto Coins&nbsp;<span data-sortin="" onClick={this.sortTable} className="fas fa-sort sortable pointer" data-sort={2}></span></th>
                                        <th scope="col">Devoluciones&nbsp;<span data-sortin="" onClick={this.sortTable} className="fas fa-sort sortable pointer" data-sort={3}></span></th>
                                        <th scope="col">Reembolsos&nbsp;<span data-sortin="" onClick={this.sortTable} className="fas fa-sort sortable pointer" data-sort={4}></span></th>
                                        <th scope="col">Subtotal&nbsp;<span data-sortin="" onClick={this.sortTable} className="fas fa-sort sortable pointer" data-sort={7}></span></th>
                                        <th scope="col">Cuota procesamiento&nbsp;<span data-sortin="" onClick={this.sortTable} className="fas fa-sort sortable pointer" data-sort={5}></span></th>
                                        <th scope="col">Ganancias&nbsp;<span data-sortin="" onClick={this.sortTable} className="fas fa-sort sortable pointer" data-sort={6}></span></th>
                                    </tr>
                                </thead>
                                <tbody className='tableScoll-earns'>
                                    {this.renderCurrent()}
                                </tbody>
                            </table>
                            <div>
                                <table className="table tableDataSection">
                                    <tbody>
                                    <tr>
                                        <th>Total</th>
                                        <th>${this.formatMoney(this.state.tableTotals[0].toFixed(2))}</th>
                                        <th>${this.formatMoney(this.state.tableTotals[1].toFixed(2))}</th>
                                        <th>${this.formatMoney(this.state.tableTotals[2].toFixed(2))}</th>
                                        <th>${this.formatMoney(this.state.tableTotals[3].toFixed(2))}</th>
                                        <th>${this.formatMoney(this.state.tableTotals[4].toFixed(2))}</th>
                                        <th>${this.formatMoney(this.state.tableTotals[5].toFixed(2))}</th>
                                        <th>${this.formatMoney(this.state.tableTotals[6].toFixed(2))}</th>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className='text-left spacer'>
                            <button onClick={(e)=>this.downloadEarning(e)} className='btn btn-newModel save'>Download</button>
                        </div>
                    </div>
                    : this.state.radioSelected=== 0 ?
                        <div className="col-12">
                            <div className="table-responsive tableDiv">
                                <div className='attr-title'>Ganancias por cobrar del periodo</div>
                                <table className="table tableDataSection">
                                    <thead className='headd'>
                                    <tr className='attr-table'>
                                            <th scope="col">Model&nbsp;<span data-sortin="" onClick={this.sortTable} className="fas fa-sort sortable pointer" data-sort={1}></span></th>
                                            <th scope="col">Ingreso Bruto Membresias&nbsp;<span data-sortin="" onClick={this.sortTable} className="fas fa-sort sortable pointer" data-sort={2}></span></th>
                                            <th scope="col">Ingreso Bruto Coins&nbsp;<span data-sortin="" onClick={this.sortTable} className="fas fa-sort sortable pointer" data-sort={2}></span></th>
                                            <th scope="col">Devoluciones&nbsp;<span data-sortin="" onClick={this.sortTable} className="fas fa-sort sortable pointer" data-sort={3}></span></th>
                                            <th scope="col">Reembolsos&nbsp;<span data-sortin="" onClick={this.sortTable} className="fas fa-sort sortable pointer" data-sort={4}></span></th>
                                            <th scope="col">Subtotal&nbsp;<span data-sortin="" onClick={this.sortTable} className="fas fa-sort sortable pointer" data-sort={7}></span></th>
                                            <th scope="col">Cuota procesamiento&nbsp;<span data-sortin="" onClick={this.sortTable} className="fas fa-sort sortable pointer" data-sort={5}></span></th>
                                            <th scope="col">Total estimado&nbsp;<span data-sortin="" onClick={this.sortTable} className="fas fa-sort sortable pointer" data-sort={6}></span></th>
                                        </tr>
                                    </thead>
                                    <tbody className='tableScoll-earns' >
                                        {this.renderEarnings()}

                                    </tbody>
                                </table>
                                <div>
                                    <table className="table tableDataSection">
                                        <tbody>
                                        <tr>
                                            <th>Total</th>
                                            <th>${this.formatMoney(this.state.tableTotals[0].toFixed(2))}</th>
                                            <th>${this.formatMoney(this.state.tableTotals[1].toFixed(2))}</th>
                                            <th>${this.formatMoney(this.state.tableTotals[2].toFixed(2))}</th>
                                            <th>${this.formatMoney(this.state.tableTotals[3].toFixed(2))}</th>
                                            <th>${this.formatMoney(this.state.tableTotals[4].toFixed(2))}</th>
                                            <th>${this.formatMoney(this.state.tableTotals[5].toFixed(2))}</th>
                                            <th>${this.formatMoney(this.state.tableTotals[6].toFixed(2))}</th>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className='text-left spacer'>
                                <button onClick={(e)=>this.downloadEarning(e)} className='btn btn-newModel save'>Download</button>
                            </div>
                        </div>
                        :null
                    :null}
                <ModalSaving saved={this.state.saved} error={this.state.error} message={"Cargando"}/>
                <HelpBox title="Ganancias por cobrar del periodo" idModal="modalHelpPorCobrar">Esta gráfica muestra las ganancias que la modelo cobrara o cobr&oacute; en el periodo indicado.</HelpBox>
                <HelpBox title="Ganancias al corriente del periodo" idModal="modalHelpCurrent">Esta gráfica muestra las ganancias que la modelo ha generado en el periodo indicado.</HelpBox>
            </div>
        )
    }
    async downloadEarning(e){
        e.preventDefault();
        const title = 'Earning period';
        const period = this.state.periodEarning.firstInterval+"  "+this.state.periodEarning.lastInterval;
        var table = {
            pageOrientation: 'landscape',
            content : [
                {
                    text: title ,
                    style: 'header',
                    alignment: 'center',
                    pageOrientation: 'landscape'
                },
                //'Tables support the same width definitions as standard columns:',
                {
                style: 'body',
                table: {
                    body: [
                        [{text : period, colSpan: 11, alignment: 'center'}, {}, {}, {}, {}, {}, {}, {}, {},{},{}],
                        [
                            'Model',
                            'Gross',
                            'Chargebacks',
                            'Refunds',
                            'Adjustments',
                            'Net',
                            'Processing Fees',
                            'Chargebacks Fees',
                            'Refunds Fees',
                            'Paid Affiliates',
                            'Estimate Total'
                        ]
                    ]
                    }
                }
            ],
            styles: {
                header: {
                    fontSize: 18,
                    bold: true,
                    margin: [0, 0, 0, 10]
                },
                body: {
                    alignment: 'center',
                    margin: [0, 5, 0, 15]
                }
            }
        };
        this.state.modelEarning.forEach((item)=>{
            table.content[1].table.body.push(
                [
                    item.username,
                    '$'+((item.totalEarning === null) ? "0.00" : this.formatMoney(item.totalEarning)),
                    '$'+((item.chargeback === null) ? "0.00" : this.formatMoney(item.chargeback)),
                    '$'+((item.totalRefunds === null) ? "0.00" : this.formatMoney(item.totalRefunds)),
                    '$'+((item.adjustments === null) ? "0.00" : this.formatMoney(item.adjustments)),
                    '$'+(this.formatMoney(item.totalEarning+ item.chargeback + item.totalRefunds + item.adjustments)),
                    '$'+((item.processFee === null) ? "0.00" : '-'+this.formatMoney(item.processFee)),
                    '$'+((item.chargeback === null) ? "0.00" : this.formatMoney(item.chargeback*.5)),
                    '$'+((item.countRefunds === null) ? "0.00" : this.formatMoney(item.countRefunds*-1)),
                    '$0.00',
                    '$'+this.formatMoney(item.supertotal)
                ]
            );
        });
        pdfMake.createPdf(table).open({}, window);
    }
}
export default Analytics;
