import React, { Component } from 'react';
import axios from 'axios';
import cookie from 'js-cookie';
import Footer from '../../components/Login/Footer';

class Login extends Component {
    constructor(){
        super();

        this.login=this.login.bind(this);
        this.state = {
            error : null
        }

    }
    componentDidMount(){
        cookie.set('type', this.props.type,{ expires: 30 });
        let userInput = document.getElementById("usernameInput");
        userInput.addEventListener("keyup",(e) => {
            this.sendLogin(e);
        });
        let pwdInput = document.getElementById("passwordInput");
        pwdInput.addEventListener("keyup",(e) => {
            this.sendLogin(e);
        })
        //console.log("inicio");
    }
    login(){
        const username=document.getElementById("usernameInput").value;
        const password=document.getElementById("passwordInput").value;
        if(username!==""&&password!==""){
            this.setState({error : null})
            axios.post('/api/user/login',{username : username, password : password})
            .then(resp=>{
                if(resp.data.valid.login){
                    cookie.set('username', resp.data.valid.username,{ expires: 30 });
                    cookie.set('token', resp.data.valid.token,{ expires: 30 })
                    cookie.set('permission', resp.data.valid.permission, { expires: 30 });
                    cookie.set('email', resp.data.valid.email,{ expires: 30 });
                    cookie.set('options', resp.data.valid.data,{ expires : 30 });
                    window.location = '/';
                }
                else{
                    this.setState({error : "Incorrect username or password"})
                }
            })
        }
        else{
            this.setState({error : "Plese enter username and password"})
        }
    }
    sendLogin = (e) => {
        if (e.keyCode === 13) {
            // Cancel the default action, if needed
            e.preventDefault();
            // Trigger the button element with a click
            this.login()
        }
    }
    render () {
        return(
            <div className="container">
                <div className="container login">
                    <div className="content login">
                        <div className="login-container">
                            <h4>
                                Login 
                            </h4>
                            <div className="form-group">
                                <input type="text" className="form-control" placeholder="Username / Email" id="usernameInput" />
                            </div>
                            <div className="form-group">
                                <input type="password" className="form-control" placeholder="Password" id="passwordInput" />
                            </div>
                            <div className="col-xs-12 text-center forgot-password">
                                <button className="btn btn-link">Forgot my Password</button>
                            </div>
                            <div className="col-xs-12 text-center">
                                <button className="btn btn-success login-btn" onClick={this.login} >Login</button>
                            </div>
                            {this.state.error !== null ?
                                <div className="col-xs-12 text-center text-danger error-login">
                                    {this.state.error}
                                </div>
                                : null}
                        </div>
                    </div>
                    <Footer/>
                </div>

            </div>
        )
    }
}
export default Login