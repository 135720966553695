import React, { Component } from 'react';

class Footer extends Component{
    // constructor(){
    //     super();

    // }

    render(){
        return(
            <div className="col-xs-12 text-center footer-login">
                <p>
                    2019 &copy; DiSo. All Rights Reserved.
                </p>
            </div>
        )
    }
}
export default Footer;