import React, { Component } from 'react';
import './style.css';

class Post extends Component {

    async componentDidMount(){

    }
    render(){
        return (
            <div
                className="screen-example screen-chat"
                id="exampleChat"
                style={{backgroundColor : this.props.config.color.navbar}}
            >
                <button
                    type="button"
                    className="menu-example-head left"
                    onClick={this.props.openSideBar}
                >
                    <i className=" fas fa-bars"></i>
                </button>
                <button
                    type="button"
                    className="menu-example-head right"
                >
                    <i className=" fas fa-user"></i>
                </button>
                <div
                    className="logo"
                >
                    {(this.props.config.logo) ?
                        <img
                            src={this.props.config.storageURL + this.props.config.logo}
                            alt="LOGO"
                        />
                        :
                        "LOGO"
                    }
                </div>
                <div
                    className="sidebar-example open"
                    style={{
                        backgroundColor : this.props.config.color.navbar
                    }}
                >
                    <button
                        type="button"
                        className="menu-example-head left"
                        onClick={this.props.closeSideBar}
                    >
                        <i className=" fas fa-bars"></i>
                    </button>
                    <ul>
                        <li
                            style={{
                                color : "hsla(0,0%,100%,.57)"
                            }}
                            data-window={0}
                            onClick={this.props.openExampleWindow}
                        >
                            INICIO
                        </li>
                        <li
                            style={{
                                color : "hsla(0,0%,100%,.57)"
                            }}
                            data-window={1}
                            onClick={this.props.openExampleWindow}
                        >
                            POSTS
                        </li>
                        <li
                            style={{
                                color : "hsla(0,0%,100%,.57)"
                            }}
                            data-window={4}
                            onClick={this.props.openExampleWindow}
                        >
                            MENU CHAT
                        </li>
                        <li
                            style={{
                                backgroundColor:this.props.config.color.secondary,
                                color: "hsla(0,0%,100%,.57)"
                            }}
                        >
                            CHAT
                        </li>
                        <li
                            style={{
                                color : "hsla(0,0%,100%,.57)"
                            }}
                            data-window={3}
                            onClick={this.props.openExampleWindow}
                        >
                            MODAL
                        </li>
                    </ul>
                </div>
                <div
                    className="screen-body"
                    style={{backgroundColor:this.props.config.color.principal}}
                >
                    <button
                        type="button"
                        className="back"
                        style={{
                            backgroundColor:this.props.config.color.secondary,
                            color : this.props.config.color.secondaryText
                        }}
                    >
                        <i className="fas fa-chevron-left"></i>
                    </button>
                    <img
                        className="user-image"
                        src={this.props.config.storageURL + this.props.mainImage}
                        alt=""
                    />
                    <p
                        className="user-name"
                        style={{color : this.props.config.color.principalText}}
                    >
                        Username
                    </p>
                    <div
                        className="chat-content"
                        style={{
                            borderColor : this.props.config.color.secondary
                        }}
                    >
                        <div className="globe from">
                            <p
                                style={{
                                    backgroundColor:this.props.config.color.chat.userChat,
                                    color:this.props.config.color.chat.userChatText
                                }}
                            >
                                At vero eos et accusamus et iusto odio dignissimos ducimus
                            </p>
                        </div>
                        <div className="globe to">
                            <p
                                style={{
                                    backgroundColor:this.props.config.color.chat.modelChat,
                                    color:this.props.config.color.chat.modelChatText
                                }}
                            >
                                Nam libero tempore, cum soluta nobis est eligendi. Ut enim ad minima veniam, quis nostrum
                            </p>
                        </div>
                        <div className="globe from">
                            <p
                                style={{
                                    backgroundColor:this.props.config.color.chat.userChat,
                                    color:this.props.config.color.chat.userChatText
                                }}
                            >
                                Itaque earum rerum
                            </p>
                        </div>
                        <div className="globe to">
                            <p
                                style={{
                                    backgroundColor:this.props.config.color.chat.modelChat,
                                    color:this.props.config.color.chat.modelChatText
                                }}
                            >
                                Nam libero tempore, cum soluta
                            </p>
                        </div>
                        <div className="globe to">
                            <p
                                style={{
                                    backgroundColor:this.props.config.color.chat.modelChat,
                                    color:this.props.config.color.chat.modelChatText
                                }}
                            >
                                Excepteur sint occaecat
                            </p>
                        </div>
                    </div>
                    <div className="chat-sender"
                         style={{
                             backgroundColor:this.props.config.color.chat.titleAndBotton
                         }}
                    >
                        <i className="fas fa-smile"
                           style={{
                               backgroundColor:this.props.config.color.secondary,
                               color:this.props.config.color.chat.background,
                               borderColor : this.props.config.color.secondary
                           }}
                        ></i>
                        <p style={{
                            color : this.props.config.color.chat.bottonText
                        }}
                        >
                            Lorem ipsum dolor sit amet
                        </p>
                        <i className="fas fa-paper-plane"
                           style={{
                               color:this.props.config.color.secondary
                           }}
                        ></i>
                    </div>
                    <span className="menu-chat"
                          style={{
                              backgroundColor:this.props.config.color.secondary
                          }}>
                        <i className="fas fa-angle-up"
                           style={{
                               color:this.props.config.color.secondaryText
                           }}
                        ></i>
                    </span>

                </div>
            </div>
        )
    }
}
export default Post;
