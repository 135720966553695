import React, { Component } from 'react';
import axios from "axios";
import ModalSaving from "../../../ModalSaving";

class ModalChangeModuleValue extends Component {
    constructor(){
        super();
        this.state = {
            picode : false,
            isActive : false,
            nuevaCoin : {},
            saved : false,
            error : false
        };
        this.changeValueModule=this.changeValueModule.bind(this);
    }
    componentDidMount(){

    }
    changeValueModule = async () => {
        await this.setState({saved : false, error: false});
        document.getElementById("ModalChangeModuleValueCancelBtn").click();
        document.getElementById("ModalSaveChangeModuleValue").classList.add("show");
        const coins = document.getElementById("coinsModule").value;
        let send = true;
        if(coins==="" || isNaN(coins) || coins<=0){
            document.getElementById("coinsModule").classList.add("is-invalid");
            send = false;
        }
        else{
            document.getElementById("coinsModule").classList.remove("is-invalid");
        }
        if(send) {
            const formData = new FormData();
            formData.append("idModel", this.props.model);
            const form = document.getElementById("updateModule");
            for( let k = 0 ; k<form.length; k++){
                let element=form[k];
                if(element.tagName.toUpperCase() === "INPUT") {
                    if(element.type === "checkbox" || element.type === "radio"){
                        if(element.checked)
                            formData.append(element.name, element.value);
                    }
                    else{

                        if(element.name !== "")
                            formData.append(element.name, element.value);

                    }
                }
                if(element.tagName.toUpperCase() === "SELECT"){
                    formData.append(element.name, element.value)
                }
            }
            const module = this.props.updatingModule;
            const saved = await axios.put('/api/model/coin/module/' + module, formData)
                .then(async resp => {
                    return resp.data;
                });
            if (saved.Error === undefined) {
                await this.setState({saved: true})
                await this.props.reloadModules();
            } else {
                await this.setState({error: true});
            }
        }
    }
    render(){
        return (
            <div>
                <div className="modal" id="ModalChangeModuleValue" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Cambiar precio de modulo</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form id="updateModule">
                                    <div className="form-group">
                                        <input type="number" placeholder="Monedas" className="form-control" id="coinsModule" name="coins" defaultValue={this.props.curMonedasToChangeModule}/>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger" id="ModalChangeModuleValueCancelBtn" data-dismiss="modal">Cancelar&nbsp;<i className="fas fa-times"></i></button>
                                <button type="button" className="btn btn-success" onClick={this.changeValueModule}>Guardar&nbsp;<i className="fas fa-save"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalSaving saved={this.state.saved} error={this.state.error} idModal="ModalSaveChangeModuleValue"/>
            </div>
        )
    }
}
export default ModalChangeModuleValue;