import React from 'react';

const CloseIcon= ({color, width, height, className = null, onClickFunction = null})=>{
    return(
        <svg   
            xmlns="http://www.w3.org/2000/svg" 
            width={width} 
            height={height}
            viewBox="0 0 452.000000 452.000000"
            className={className}
            preserveAspectRatio="xMidYMid meet"
        >
            <g 
                transform="translate(0.000000,452.000000) scale(0.100000,-0.100000)"
                fill={color} 
                stroke="none"
            >
                <path 
                    d="M2080 4059 c-703 -72 -1308 -554 -1531 -1219 -129 -385 -128 -776 1
                    -1162 172 -514 585 -935 1100 -1118 475 -170 991 -135 1440 96 476 245 816
                    688 934 1215 139 625 -66 1278 -541 1718 -374 347 -896 521 -1403 470z m-363
                    -1060 c15 -5 144 -126 286 -267 l257 -257 258 257 c141 141 267 261 279 267
                    12 6 41 11 65 11 106 0 176 -105 137 -207 -5 -15 -126 -144 -267 -285 l-256
                    -258 256 -257 c141 -142 262 -271 267 -286 48 -126 -70 -244 -196 -196 -15 5
                    -144 126 -285 267 l-258 256 -257 -256 c-142 -141 -271 -262 -286 -267 -102
                    -39 -207 31 -207 137 0 24 5 53 11 65 6 12 126 138 267 280 l257 257 -257 258
                    c-141 141 -262 270 -268 285 -47 127 71 244 197 196z"
                />
            </g>
        </svg>
    )
}
export default CloseIcon;
