import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';

class PromosActive extends Component {
    constructor() {
        super();
        this.state = {
            promosActive: [],
            startDateActive: new Date()
        }
        this.handleChangeStart = this.handleChangeStart.bind(this); 
    }
    componentDidMount(){
        this.promotionsActive();
    }
    promotionsActive = async () => {
        const resp = await axios.get('/api/promosActive/').then(async resp => {
            this.setState({ promosActive: resp.data });
            return true;
        });
        return resp;
    }
    async handleChangeStart(date) {
        this.setState({ startDateActive: date });
    }
    showPromosActive() {
        if (this.state.promosActive.length === 0) {
            return (<tr><td colSpan={5} className="text-center">No hay información</td></tr>)
        }
        const promosActive = [...this.state.promosActive];
        // eslint-disable-next-line
        return promosActive.map((item, index) => {
            let start = moment(item.startDate).format("DD/MMM/YYYY");
            let end = moment(item.endDate).format("DD/MMM/YYYY");
            return (
                <tr key={index}>
                    <td>{item.model}</td>
                    <td>{item.hostname}</td>
                    <td>{item.type}</td>
                    <td>${item.amount}</td>
                    {start && end === 'Invalid date' ?
                        <td>Sin registro</td> :
                        <td>{start} <span className="fas fa-long-arrow-alt-right"></span> {end}</td>
                    }
                </tr>
            )
        })
    }

    render() {
        return (
            <div className="">
                <div className="row">
                    <div className="col-12">
                        <div className="text-white head-table">
                            Promociones Activas
                        </div>
                        <div className="container-fluid ">
                            <div className="divCard row">
                                <div className="col-12 col-sm-6 col-lg-6">
                                    <label>Periodo: <input type="text" className="period-input" value={moment(this.state.startDateActive).add(2,'days').format("DD-MMM-YYYY")} disabled />
                                    </label>
                                </div>
                                <div className="table-responsive tableDiv tableScoll-lg">
                                    <caption className="table-responsive caption-coins">Historial de promociones activas</caption>
                                    <table className="table tableDataSection">
                                        <thead>
                                            <tr className="attr-table">
                                                <th>Modelo</th>
                                                <th>Hostname</th>
                                                <th>Periodo de pago</th>
                                                <th>Monto de Transacci&oacute;n</th>
                                                <th>Periodo Activo</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.showPromosActive()}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}
export default PromosActive;