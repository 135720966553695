import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import "./sidebar.css";

class Sidebar extends Component {
    constructor(){
        super();
        this.showSubmenu = this.showSubmenu.bind(this);
        this.showSubmenuUsers = this.showSubmenuUsers.bind(this);

    }
    showSubmenu = (e) => {
        let link = e.target;
        let span = link.nextSibling;
        if(link.classList.contains('collapsed')){
            span.classList.remove("fa-angle-up");
            span.setAttribute("class", "fas fa-angle-down");
        }
        else{
            span.classList.remove("fa-angle-down");
            span.setAttribute("class", "fas fa-angle-up");            
        }
    }

    showSubmenuUsers = (e) => {
        let link = e.target;
        let span = link.nextSibling;
        if(link.classList.contains('collapsed')){
            span.classList.remove("fa-angle-up");
            span.setAttribute("class", "fas fa-angle-down");
        }
        else{
            span.classList.remove("fa-angle-down");
            span.setAttribute("class", "fas fa-angle-up");            
        }
    }
    /*drawSidebar() {
        console.log(this.props.permisos);
        let permisos = [...this.props.permisos];
        return permisos.map( (el, index) =>{
            <li></li>
        })
    }*/

    render(){
        return(
            <div className="sidebar" id="sidebar">
                <ul>
                    <li className="borde">
                        <div className="menuM">Menu</div>
                    </li>
                    <li 
                        className={(this.props.view === "home") ? "active" : ""}
                    >
                        <Link to='/'>
                            <div className="classHome">Home</div>
                        </Link>
                    </li>
                    {this.props.permisos.Modelos ?
                        <li 
                            className={(this.props.view === "model") ? "active" : ""}
                        >
                            <Link to='/Models'>
                                <div className="classModelos">Modelos</div>
                            </Link>
                        </li>
                        : null
                    }
                    <li className={(this.props.view === "promotions") ? "active" : ""}>
                            <Link to='/Promotions'>
                                <div className="classPromotions">
                                    Promociones
                                </div>
                            </Link>
                    </li>
                    {this.props.permisos.Configuracion ?
                        <li>
                           {/*eslint-disable-next-line*/}
                            <a 
                                href='#' 
                                onClick={this.showSubmenu} 
                                data-toggle="collapse" 
                                data-target="#submenu1" 
                                aria-expanded="true" 
                                className="classConf"
                            >
                                Configuraci&oacute;n
                            </a>
                            <span className="fas fa-angle-up"></span>
                            <ul 
                                id="submenu1" 
                                role="menu" 
                                aria-labelledby="btn-1" 
                                className="nav collapse show"
                            >
                                {this.props.permisos.Configuracion.c >= 3 ?
                                    <li 
                                        className={(this.props.view === "Usuarios") ? "active" : ""}
                                    >
                                        <Link
                                            to='/Configuration/Users'
                                        >
                                            <div>Usuarios</div>
                                        </Link>
                                    </li>
                                : null }
                                {this.props.permisos.Configuracion.c >= 3 ?
                                    <li 
                                        className={(this.props.view === "Permisos") ? "active" : ""}
                                    >
                                        <Link
                                            to='/Configuration/Access'>
                                            <div>Permisos</div>
                                        </Link>
                                    </li>
                                : null }
                                {this.props.permisos.Configuracion.c >= 3 ?
                                    <li 
                                        className={(this.props.view === "Regiones") ? "active" : ""}
                                    >
                                        <Link
                                            to='/Configuration/Regions'>
                                            <div>Regiones</div>
                                        </Link>
                                    </li>
                                : null }
                            </ul>
                        </li>
                        : null
                    }
                    {this.props.permisos.Clients ?
                        <li>
                            {/*eslint-disable-next-line*/}
                            <a 
                                href='#' 
                                onClick={this.showSubmenuUsers} 
                                data-toggle="collapse" 
                                data-target="#submenu2"
                                aria-expanded="true" 
                                className="classConf"
                            >
                                Usuarios
                            </a>
                            <span className="fas fa-angle-up"></span>
                            <ul 
                                id="submenu2" 
                                role="menu" 
                                aria-labelledby="btn-1" 
                                className="nav collapse show"
                            >
                                {this.props.permisos.Clients.c >= 1 ?
                                    <li className={(this.props.view === "Clientes") ? "active" : ""}><Link
                                        to='/User/Customer'>
                                        <div>Clientes</div>
                                    </Link></li>
                                    : null
                                }
                            </ul>
                        </li>
                        : null
                    }
                    {this.props.permisos.Managers ?
                        <li 
                            className={(this.props.view === "manager") ? "active" : ""}>
                            <Link to='/Managers'>
                                <div className="classManagers">
                                    Managers
                                </div>
                            </Link>
                        </li>
                        :null
                    }
                    <li className={(this.props.view === "referral") ? "active" : ""}>
                            <Link to='/Referrals'>
                                <div className="classReferrals">
                                    Referidos
                                </div>
                            </Link>
                    </li>
                    {this.props.permisos.Notifications ?
                        <li className={(this.props.view === "notification") ? "active" : ""}>
                            <Link to='/Notifications'>
                                <div className="classNotify">
                                    Notificaciones
                                </div>
                            </Link>
                        </li>
                        :null
                    }
                </ul>
            </div>
        );
    }
}
export default Sidebar;