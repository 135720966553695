import React, { Component } from 'react';
import axios from "axios";
import ModalSaving from "../../../ModalSaving";
import DatePicker from "react-datepicker/es";
import moment from "moment";

class ModalSetPromo extends Component {
    constructor(){
        super();
        this.state = {
            picode : false,
            isActive : false,
            saved : false,
            error: false,
            startDate : moment().add(1, 'hours').utc().format("YYYY-MM-DDTHH:00"),
            endDate : moment().add(1, 'hours').add(1,'days').utc().format("YYYY-MM-DDTHH:00")
        };
        this.activatePromo=this.activatePromo.bind(this);
    }
    componentDidMount(){
    }
    setDate = async (date, end = false) => {
        if(end){
            this.setState({endDate : moment(date).format("YYYY-MM-DDTHH:mm")});
        }
        else{
            this.setState({startDate : moment(date).format("YYYY-MM-DDTHH:mm")});
            if(moment(this.state.endDate).isBefore(moment(date))){
                this.setState({endDate : moment(date).add(1, 'days').format("YYYY-MM-DDTHH:mm")});
            }
        }
    }
    activatePromo = async () => {
        //const country =  this.props.currCountry;
        await this.setState({saved : false, error: false});
        let send = true;
        let membership = this.props.currTrial
        if(!membership){
            membership = document.getElementById("membresiaPromo").value;
        }
        if(membership === "0" || membership === null){
            document.getElementById("membresiaPromo").classList.add("is-invalid");
            send = false
        }
        const checked = document.getElementById("isPromo").checked;
        if(send){
            document.getElementById("ModalSetPromoCandelBtn").click();
            document.getElementById("modalSavePromo").classList.add("show");
            const formData = new FormData();
            formData.append("idModel",this.props.model);
            formData.append("country",this.props.currCountry);
            formData.append("start", this.state.startDate);
            formData.append("end", this.state.endDate);
            formData.append("membresiaPromo", membership);
            formData.append("isPromo", checked);
            const saved = await axios.post("/api/membership/promo/"+this.props.membership.id, formData).then(resp => {
                return resp.data;
            });
            if (saved.Error === undefined) {
                await this.setState({saved : true})
                await this.props.reloadMemberships();
            } else {
                await this.setState({error : true});
            }
        }
    }
    drawActives = () => {
        const membresias = [...this.props.activeMemberships];
        // eslint-disable-next-line
        return membresias.map((item,index) => {
            if(item.membership_active === 1) {
                return (
                    <option key={index} value={item.id}>{item.pi_code} -
                        ${item.price} {item.currency} per {item.payment_period}</option>
                )
            }
        })
    }
    render(){
        return (
            <div>
                <div className="modal" id="ModalSetPromo" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header-section">
                                <h5 className="modal-title sectionTitle">Activar Promocion</h5>
                                <button type="button" className="close modalClose" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body modelBody">
                                <form id="setPromoForm">
                                    <div className="form-check form-group" key="all">
                                        <input type="checkbox" className="form-check-input" name="isPromo" id="isPromo" value="1"/>
                                        <label className="form-check-label" htmlFor="exampleCheck1">Deseo generar un código de promoción</label>
                                    </div>
                                    {(!this.props.currTrial) ?
                                        <div className="form-group">
                                            <select name="membresiaPromoSelect" id="membresiaPromo" className="form-control">
                                                <option value="0">Selecciona la Membresia</option>
                                                { this.drawActives() }
                                            </select>
                                        </div>
                                        : null
                                    }
                                    <p className="text-info text-sm"><i className="fa fa-info"></i>&nbsp;Recuerda insertar las horas en tiempo UTC</p>
                                    <div className="form-group">
                                        <label htmlFor="" className="control-label">
                                            Fecha de inicio
                                        </label>
                                        <DatePicker selected={new Date(this.state.startDate)}
                                                    onChange={date => this.setDate(date)}
                                                    showTimeSelect
                                                    timeFormat="HH:mm"
                                                    timeIntervals={15}
                                                    minDate={new Date()}
                                                    timeCaption="time"
                                                    dateFormat="MMMM d, yyyy h:mm aa"
                                                    className="form-control"/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="" className="control-label">
                                            Fecha de termino
                                        </label>
                                        <DatePicker selected={new Date(this.state.endDate)}
                                                    onChange={date => this.setDate(date, true)}
                                                    showTimeSelect
                                                    timeFormat="HH:mm"
                                                    timeIntervals={15}
                                                    minDate={new Date(this.state.startDate)}
                                                    timeCaption="time"
                                                    dateFormat="MMMM d, yyyy h:mm aa"
                                                    className="form-control"/>
                                    </div>

                                </form>
                            </div>
                            <div className="modal-footer modalFooter">
                                <button type="button" className="btn btn-modalClose" id="ModalSetPromoCandelBtn" data-dismiss="modal">Cancelar</button>
                                <button type="button" className="btn btn-modalSuccess" onClick={this.activatePromo}>Continuar</button>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalSaving saved={this.state.saved} error={this.state.error} idModal="modalSavePromo"/>
            </div>
        )
    }
}
export default ModalSetPromo;