import React, { Component } from 'react';
import axios from "axios";

class GeneralCustomer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            usermodels: [],
            modelSelected: null,
            userSelected: null
        };
        this.reloadUserModels = this.reloadUserModels.bind(this);
    }
    async componentDidMount() {
        await this.getUserModels();
        await this.showUserModels();
    }
    reloadUserModels = async () => {
        this.getUserModels();
    }
    getUserModels = async () => {
        await axios.get('/api/usermodels/' + this.props.username).then(async resp => {
            await this.setState({ usermodels : resp.data })
        });       
    };
    usermodel = async (user, model) => {
        this.setState({userSelected: user, modelSelected: model});
        this.props.searchUser();
    }
    showUserModels() {
        if (this.state.usermodels.length === 0) {
            return (<tr><td colSpan={5} className="text-center" disabled>No hay Usuario/Modelo</td></tr>)
        }
        const usermodels = [...this.state.usermodels];
        return usermodels.map((item, index) => {
                let user = item.userName;
                let model = item.idModel;
                return (
                    <tr key={index}>
                        <td>{item.idUser}</td>
                        <td>{item.userName}</td>
                        <td>{item.userMail}</td>
                        <td>{item.modelName}</td>
                        <td>
                            <ul className="list-inline">
                                <li className="list-inline-item">
                                    <button title="Ver" className="btn btn-sm btn-icon">
                                        <span onClick={() => this.props.usermodel(user, model)} 
                                            class="far fa-eye">                                          
                                        </span>
                                    </button>
                                </li>
                            </ul>
                        </td>
                    </tr>
                )
        })
    }
    render() {
        return (
            <div className="row">
                <div className="col-12">
                    <div className="text-white head-table-user">
                        Informaci&oacute;n del Usuario/Modelo
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="table-responsive tableDiv tableScoll-lg">
                                <table className="table tableDataSection">
                                    <thead>
                                        <tr className="attr-table">
                                            <th>ID</th>
                                            <th>Username</th>
                                            <th>Mail</th>
                                            <th>Modelo</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                        {this.showUserModels()}
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default GeneralCustomer;