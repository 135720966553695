import React, { Component } from 'react';
//import getCroppedImg from './cropImage';
import Cropper from 'react-easy-crop';

class Crop extends Component {
    constructor(){
        super();
        this.closeModal=this.closeModal.bind(this)
    }
    async componentDidMount(){

    }



    closeModal(){
        document.getElementById("cropperModal").style.display="none"
    }
    render(){
        return (
            <div className="modal" id="cropperModal" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header-section">
                            <h5 className="modal-title sectionTitle">Fix Image</h5>
                            <button type="button" className="close modalClose" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true" onClick={this.closeModal}>&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="col-12 col-sm-12">
                                <div className='slidecontainer'>
                                    <Cropper
                                        image={this.props.imageSrc}
                                        crop={this.props.crop}
                                        zoom={this.props.zoom}
                                        aspect={this.props.aspect}
                                        cropShape={this.props.shapeCrop}
                                        onCropChange={this.props.onCropChange}
                                        onCropComplete={this.props.onCropComplete}
                                        onZoomChange={this.props.onZoomChange}
                                    />
                                </div>
                            </div>
                            <div className="slideFix">
                                <input type="range" min="1" max="3" step="0.1" name='zoomScroll' value={this.props.zoom} className="slider" id="myRange" onChange={(e, zoom) => this.props.onZoomChangeBar(e,zoom)}/>
                            </div>
                        </div>
                        <div className="modal-footer modalFooter">
                            <button type="button" className="btn btn-modalClose" data-dismiss="modal" onClick={this.closeModal}>Cancelar&nbsp;<i className="fas fa-times"></i></button>
                            <button type="button" className="btn btn-modalSuccess" onClick={this.props.showCroppedImage}>Guardar&nbsp;<i className="fas fa-save"></i></button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default Crop;