import React, { Component } from 'react';
import axios from "axios";
import ModalSaving from "../../../ModalSaving";

class ModalDeletePromo extends Component {
    constructor(){
        super();
        this.state = {
            picode : false,
            isActive : false,
            saved : false,
            error: false,
        };
        this.deletePromo=this.deletePromo.bind(this);
    }
    componentDidMount(){
    }
    deletePromo = async () => {
        await this.setState({saved : false, error: false});
        let send = true;
        if(send){
            document.getElementById("ModalDeletePromoCancelBtn").click();
            document.getElementById("modalSaveDeletePromo").classList.add("show");
            const formData = new FormData();
            formData.append("idModel",this.props.model);
            formData.append("promo",this.props.promo);
            formData.append("country",this.props.currCountry);
            const saved = await axios.put("/api/membership/promo/disable/"+this.props.membership.id, formData).then(resp => {
                return resp.data;
            });
            if (saved.Error === undefined) {
                await this.setState({saved : true})
                await this.props.reloadMemberships();
            } else {
                await this.setState({error : true});
            }
        }
    }
    render(){
        return (
            <div>
                <div className="modal" id="ModalDeletePromo" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header-section">
                                <h5 className="modal-title sectionTitle">Desactivar Promocion</h5>
                                <button type="button" className="close modalClose" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body modelBody">
                                <p>¿Realmente quiere desactivar esta promoci&oacute;n?</p>
                            </div>
                            <div className="modal-footer modalFooter">
                                <button type="button" className="btn btn-modalClose" id="ModalDeletePromoCancelBtn" data-dismiss="modal">Cancelar</button>
                                <button type="button" className="btn btn-modalSuccess" onClick={this.deletePromo}>Continuar</button>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalSaving saved={this.state.saved} error={this.state.error} idModal="modalSaveDeletePromo"/>
            </div>
        )
    }
}
export default ModalDeletePromo;