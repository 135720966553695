import React, { Component } from 'react';
import Header from '../../components/Layout/Header';
import Sidebar from '../../components/Layout/Sidebar';
import Footer from '../../components/Layout/Footer';
import Analytics from '../../components/Dashboard/Analytics';
import Multimedia from '../../components/Dashboard/Multimedia';
import Actions from '../../components/Dashboard/Actions';

class Dashboard extends Component {
    constructor(props){
        super(props);
        this.state = {
            view: "home"
        }
    }
    async componentDidMount() {
        await this.props.isAbleToAccess(4,0,1);
    }

    render () {
        return(
            <div className="container">
                <Header username={this.props.username}/>
                <div className="content">
                    <Sidebar permisos={this.props.permisos} view={this.state.view}/>
                    <div className="content-padding main-content" id="page">
                        <div className="head-padding">
                            <h4>Home</h4>
                        </div>
                        {(this.props.accessLevel["r"] !== undefined && this.props.accessLevel["r"] >= 1) ?
                            <Analytics accessLevel={this.props.accessLevel}/>
                            :
                            <div>
                                <p>CMS MODELOS</p>
                            </div>
                        }
                        {(this.props.accessLevel["r"] !== undefined && this.props.accessLevel["r"] >= 1) ?
                            <Actions/>
                            : null
                        }
                        {(this.props.accessLevel["r"] !== undefined && this.props.accessLevel["r"] >= 1) ?
                            <Multimedia/>
                            : null
                        }
                    </div>
                </div>
                <Footer/>
            </div>
        )
    }
}
export default Dashboard
