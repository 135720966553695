import React, { Component } from 'react';
import axios from "axios";
import ModalSaving from "../../ModalSaving";

class ModalActivateManager extends Component {
    constructor(){
        super();
        this.state={
            saved : false,
            error : false
        };
        this.activateManager=this.activateManager.bind(this);
    }
    componentDidMount(){

    }
    activateManager = async () => {
        await this.setState({saved : false, error: false});
        document.getElementById("ModalActivateManagerCancelBtn").click();
        document.getElementById("modalSaveActivateManager").classList.add("show");
        const saved = await axios.put("/api/manager/activate/" + this.props.manager, {}).then(resp => {
            return resp.data;
        });
        if (saved) {
            await this.setState({saved : true})
            await this.props.reloadManager();
        } else {
            await this.setState({error : true})
        }

    }
    render(){
        return (
            <div>
                <div className="modal" id="ModalActivateManager" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Activar Manager</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>¿Seguro que desea activar a este manager?</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger" id="ModalActivateManagerCancelBtn" data-dismiss="modal">Cancelar&nbsp;<i className="fas fa-times"></i></button>
                                <button type="button" className="btn btn-success" onClick={this.activateManager}>Guardar&nbsp;<i className="fas fa-save"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalSaving saved={this.state.saved} error={this.state.error} idModal="modalSaveActivateManager"/>
            </div>
        )
    }
}
export default ModalActivateManager;