import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';
import DatePicker from "react-datepicker";
import ModalSaving from "../../ModalSaving";

class Multimedia extends Component{
    constructor(){
        super();
        this.state={
            modelos:[],
            totalModelos:'',
            usuarios: [],
            step : 1,
            startDate: new Date(),
            endDate: new Date(),
            showHidden : false,
            mensajes: []
        }
        this.alterData = this.alterData.bind(this);
        this.handleChangeStart = this.handleChangeStart.bind(this);
        this.handleChangeEnd = this.handleChangeEnd.bind(this);        
    }
    componentDidMount(){
        this.getModels();

        // this.changeRange();
    }
    getModels = async () => {
        await axios.get("/api/models/multimedia").then(async resp => {
            await this.setState({modelos : resp.data, totalModelos : resp.data.length})
        });
    };
    async handleChangeStart(date) {
        await this.setState({
            startDate: date
        });
        let endDate = this.state.endDate;
        endDate = new Date(endDate);
        if(endDate.getTime() < date.getTime()){
            await this.setState({
                endDate: date
            });
        }
        await this.changeRange();
    }
    async handleChangeEnd(date) {
        await this.setState({
            endDate: date
        });
        await this.changeRange();
    }
    alterData = async () => {
        this.setState({
            step : 1
        })
        this.changeRange()
    }
    changeRange = async () => {
        await this.setState({saved : false, error: false});
        document.getElementById("ModalSaving").classList.add("show");
        const periodo = document.getElementById("periodSelected").value;
        const inputs = document.getElementsByClassName("dateInputs");
        let inicio = null;
        let fin = null;
        switch (periodo) {
            case "0":
                this.setState({ showHidden : false });
                fin = moment();
                inicio = moment();
                while(fin.day()!==4){//4 = Jueves
                    fin.add(1, 'days');//Si fin.day() > 4 entonces trae el jueves de la siguiente semana
                }
                while(inicio.day()!==4){//4 = Jueves
                    inicio.subtract(1, 'days');//Si inicio.day() > 4 entonces trae el jueves de la siguiente semana
                }
                if(fin.isSame(inicio,"day")){
                    inicio.subtract(6, "days");
                }
                break;
            case "1":
                this.setState({ showHidden : false });
                fin = moment().subtract(1,"week");
                inicio = moment().subtract(1,"week").subtract(6,"days");
                break;
            case "2":
                this.setState({ showHidden : false });
                fin = moment().subtract(2,"week");
                inicio = moment().subtract(2,"week").subtract(6,"days");
                break;
            case "3":
                this.setState({ showHidden : false });
                fin = moment();
                inicio = moment().startOf("month");
                break;
            case "4":
                this.setState({ showHidden : false });
                fin = moment().subtract(1,"month").endOf("month");
                inicio = moment().subtract(1,"month").startOf("month");
                break;
            case "5":
                this.setState({ showHidden : false });
                fin = moment();
                inicio = moment().subtract(30,"days");
                break;
            case "6":
                this.setState({ showHidden : false });
                fin = moment();
                inicio = moment().subtract(90,"days");
                break;
            case "7":
                this.setState({ showHidden : false });
                fin = moment();
                inicio = moment().startOf("year");
                break;
            case "8":
                this.setState({
                    showHidden : true
                });
                inicio = moment(inputs[0].value);
                fin = moment(inputs[1].value);
                break;
            default:
                this.setState({ showHidden : false });
                fin = moment();
                inicio = moment();
                while(fin.day()!==4){//4 = Jueves
                    fin.add(1, 'days');//Si fin.day() > 4 entonces trae el jueves de la siguiente semana
                }
                while(inicio.day()!==4){//4 = Jueves
                    inicio.subtract(1, 'days');//Si inicio.day() > 4 entonces trae el jueves de la siguiente semana
                }
                if(fin.isSame(inicio,"day")){
                    inicio.subtract(6, "days");
                }
                break;
        }
        const periodCurrent = {
            firstInterval: inicio.format("YYYY-MM-DD"),
            lastInterval: fin.format("YYYY-MM-DD")
        };
        await this.setState({periodCurrent : periodCurrent})
        const p3 = new Promise(resolve => {
            return resolve(
                this.getCurrMessages(periodCurrent)
            )
        });
        Promise.all([p3]).then(values => {
            document.getElementById("ModalSaving").classList.remove("show");
        });
    }
    getCurrMessages=async(period,modelCount)=>{
        // period["modelCount"]=modelCount;
        const resp = await axios.get('/api/models/messages', {params: period})
        .then(async res=>{
            await this.setState({mensajes: res.data});
            return true;
        });
        return resp;
    }
    pintarUsuarios (){
        if(this.state.modelos.length === 0){
            return (<tr><td colSpan={5} className="text-center">No hay información</td></tr>)
        }
        const modelos = [...this.state.modelos];
        return modelos.map((item,index) => {
            return (
                <tr key={index}>
                    <td>{item.id_model}</td>
                    <td>{item.username}</td>
                    <td>{item.audio}</td>
                    <td>{item.image}</td>
                    <td>{item.video}</td>
                    <td>{item.total}</td>
                </tr>
            )
        })
    }
    pintarContador (){
        if(this.state.mensajes.length === 0){
            return (<tr><td colSpan={5} className="text-center">No hay información</td></tr>)
        }
        const mensajes = [...this.state.mensajes];
        return mensajes.map((item,index) => {
            return (
                <tr key={index}>
                    <td>{item.username}</td>
                    <td>{item.total}</td>
                </tr>
            )
        })
    }  
    
    render(){
        return(
            <div className="row">
                <div className="col-12">
                    <div className="text-white head-table">
                        Mensajes Multimedia Pendientes
                    </div>
                    <div className="table-responsive tableDiv tableScoll-lg">
                        <table className="table tableDataSection">
                            <thead>
                            <tr className="attr-table">
                                <th>ID</th>
                                <th>Modelo</th>
                                <th>Audio</th>
                                <th>Imagen</th>
                                <th>Video</th>
                                <th>Total</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.pintarUsuarios()}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="col-12">
                    <div className="text-white head-table">
                        Mensajes Totales Recibidos
                    </div>
                    <div className="container-fluid">
                        <div className="divCard row">
                            <div className="form-group col-12 col-sm-6 col-md-6 col-lg-6">
                                <label>Periodo</label>
                                <select onChange={this.alterData} name="period" id="periodSelected" className="form-control form-control-sm">
                                    <option value="">Seleccionar</option>
                                    <option value="0">Periodo Actual</option>
                                    <option value="1">Semana pasada</option>
                                    <option value="2">Hace dos semanas</option>
                                    <option value="3">Este mes</option>
                                    <option value="4">Mes pasado</option>
                                    <option value="5">&Uacute;ltimos 30 d&iacute;as</option>
                                    <option value="6">&Uacute;ltimos 90 d&iacute;as</option>
                                    <option value="7">Este año</option>
                                    <option value="8">Otro periodo</option>
                                </select>
                            </div>
                            <div className={"form-group col-12 col-sm-6 col-lg-6 " + ((this.state.showHidden) ? "" : "hidden")}>
                                <label>Inicio</label>
                                <DatePicker className="form-control form-control-sm dateInputs"
                                            dateFormat="yyyy-MM-dd"
                                            maxDate={new Date()}
                                            selected={this.state.startDate}
                                            onChange={this.handleChangeStart}
                                />
                            </div>
                            <div className={"form-group col-12 col-sm-6 col-lg-6 " + ((this.state.showHidden) ? "" : "hidden")}>
                                <label>Fin</label>
                                <DatePicker className="form-control form-control-sm dateInputs"
                                            dateFormat="yyyy-MM-dd"
                                            maxDate={new Date()}
                                            minDate={this.state.startDate}
                                            selected={this.state.endDate}
                                            onChange={this.handleChangeEnd}
                                />
                            </div>
                            <div className="table-responsive tableDiv tableScoll-lg" style={{background:'#fff'}}>
                                <table className="table tableDataSection">
                                    <thead>
                                    <tr className="attr-table">
                                        <th>Modelo</th>
                                        <th># Mensajes Recibidos</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.pintarContador()}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    
                </div>

                <ModalSaving saved={this.state.saved} error={this.state.error} message={"Cargando"}/>
                
            </div>
        )
    }
}
export default Multimedia;
