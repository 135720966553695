import React, {Component} from 'react';

class HelpBox extends Component{

    hideModal = () => {
        const id = (this.props.idModal !== undefined) ? this.props.idModal : "ModalHelp";
        document.getElementById(id).classList.remove("show");
    }
    
    render(){
        return (
            <div className="modal fade modalHelp" id={(this.props.idModal !== undefined) ? this.props.idModal : "ModalHelp"} role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header-section">
                            <h6 className="modal-title sectionTitle">{this.props.title}</h6>
                            <button type="button" className="close modalClose" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {this.props.children}
                        </div>
                        <div className="modal-footer modalFooter">
                            <button type="button" className="btn btn-newMembership btn-lg" data-dismiss="modal">Cerrar&nbsp;<i className="fas fa-times"></i></button>
                        </div>
                    </div>
                </div>
            </div>
        )
    };
}
export default HelpBox;