import React, { Component } from 'react';
import axios from "axios";
import ModalSaving from "../../ModalSaving";

class ModalNewManager extends Component {
    constructor(){
        super();
        this.state={
            saved : false,
            error : false
        };
        this.saveManager=this.saveManager.bind(this);
    }
    componentDidMount(){

    }
    saveManager = async () => {
        await this.setState({saved : false, error: false});
        let send=true;
        const regexMail= new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        const nombre = document.getElementById("newNameInput").value;
        const username = document.getElementById("newUsernameInput").value;
        const mail = document.getElementById("newMailInput").value;
        const pwd = document.getElementById("newPwdInput").value;
        const againPwd = document.getElementById("newAgainPwdInput").value;
        if(nombre===""){
            document.getElementById("newNameInput").classList.add("is-invalid");
            send = false;
        }
        else{
            document.getElementById("newNameInput").classList.remove("is-invalid");
        }
        if(username===""){
            document.getElementById("newUsernameInput").classList.add("is-invalid");
            send = false;
        }
        else{
            document.getElementById("newUsernameInput").classList.remove("is-invalid");
        }
        if(mail==="" || !regexMail.test(mail)){
            document.getElementById("newMailInput").classList.add("is-invalid");
            send = false;
        }
        else{
            document.getElementById("newMailInput").classList.remove("is-invalid");
        }
        if(pwd===""||pwd.length<8){
            document.getElementById("newPwdInput").classList.add("is-invalid");
            send = false;
        }
        else{
            document.getElementById("newPwdInput").classList.remove("is-invalid");
        }
        if(pwd!==againPwd) {
            document.getElementById("newAgainPwdInput").classList.add("is-invalid");
            send = false;
        }
        else{
            document.getElementById("newAgainPwdInput").classList.remove("is-invalid");
        }
        if(send) {
            document.getElementById("ModalNewManagerCancelBtn").click();
            document.getElementById("ModalSaving").classList.add("show");
            const data = {
                username: username,
                nombre: nombre,
                mail: mail,
                password: pwd
            }
            const saved = await axios.post("/api/manager/", data).then(resp => {
                return resp.data;
            });
            if (saved) {
                await this.setState({saved : true})
                await this.props.reloadManagers();
            } else {
                await this.setState({error : true})
            }
        }
    }
    render(){
        return (
            <div>
                <div className="modal" id="ModalNewManager" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header-section">
                                <h6 className="modal-title sectionTitle">Nuevo Manager</h6>
                                <button type="button" className="close modalClose modal-closeSection" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body modelBody">
                                <form id="newModel">
                                    <div className="form-group">
                                        <input type="text" placeholder="Nombre" className="form-control" id="newNameInput"/>
                                    </div>
                                    <div className="form-group">
                                        <input type="text" placeholder="Usuario" className="form-control" id="newUsernameInput"/>
                                    </div>
                                    <div className="form-group">
                                        <input type="text" placeholder="Correo Electr&oacute;nico" className="form-control" id="newMailInput"/>
                                    </div>
                                    <div className="form-group">
                                        <input type="password" placeholder="Contrase&ntilde;a (8 caracteres m&iacute;nimo)" className="form-control" id="newPwdInput"/>
                                    </div>
                                    <div className="form-group">
                                        <input type="password" placeholder="Repite Contrase&ntilde;a" className="form-control" id="newAgainPwdInput"/>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer modalFooter">
                                <button type="button" className="btn btn-modalClose" id="ModalNewManagerCancelBtn" data-dismiss="modal">Cancelar</button>
                                <button type="button" className="btn btn-modalSuccess" onClick={this.saveManager}>Guardar</button>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalSaving saved={this.state.saved} error={this.state.error}/>
            </div>
        )
    }
}
export default ModalNewManager;