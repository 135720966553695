import axios from "axios";
import Promise from "bluebird";
import cookie from "js-cookie";
import images from "../Images";

class UploadFiles {

    constructor() {
        let baseUrl = "https://socialtechapps.com/";
        let hostname = window.location.hostname;
        if(hostname.split(".")[0] === "beta" || hostname==="localhost"){
            baseUrl = "https://beta.socialtechapps.com/";
            //baseUrl = "http://localhost:5001/";
        }
        this.axiosInstance = axios.create({
            baseURL: baseUrl
        });
    }

    fileValidation = async (files, currFiles=[]) => {
        if (!files || (files && !files.length)) return false;
        await new Promise.map(files, async (file, i) => {
            let typeFormat = file ? images.validateFormat(file.type) : '';
            if (typeFormat === 'image') {
                if (file.type.match('image/jpeg.*')) {
                    let blob = await images.rotateImage(file, i);
                    currFiles.push({ 
                        file: blob, 
                        type: typeFormat, 
                        method: "upload", 
                        progress: 0 
                    });
                } else {
                    currFiles.push({ 
                        file: file, 
                        type: typeFormat, 
                        method: "upload", 
                        progress: 0
                    });
                }
            }
            else if (typeFormat === 'video') {
                currFiles.push({ 
                    file: file, 
                    type: typeFormat, 
                    method: "upload", 
                    progress: 0
                });
            }
        });
        return currFiles;
    }
    /**
     * This funtion will be use to upload all the files to the encoding server
     * @param {File} file
     * @param {Object} option
     * @param {Function} axiosMedia - callback to send the processing of the file into de component
     */
    uploadFiles = async (file, options = {}, axiosMedia) => {
        try {
            return new Promise( async resolve => {
                if(!file) return resolve(false);
                let typeFormat = images.validateFormat(file.type);
                let ext;
                if(typeFormat === "audio"){
                    ext = file.type.split("/")[1].split(";")[0];
                }
                else{
                    const lastDot = file.name.lastIndexOf('.');
                    ext = file.name.substring(lastDot + 1);
                }

                let data = {
                    format: ext,
                    type: file.type,
                    idModel: cookie.get('username'),
                    hostname: 'socialtechapps.com',
                    token: cookie.get('token')
                }
                let dataUrl = await this.axiosInstance.post('/api/v2/getUrl', data).then(async (resp) => {
                    if (resp.data.url) {
                        return resp.data
                    }
                }).catch((e) => {
                    return false;
                });
                if (!dataUrl) {
                    return resolve(false);
                }
                //axiosMedia works to send the processing of the file into the component
                let uploaded = await axiosMedia(dataUrl.url, file, options);
                if (uploaded) {
                    let type = file.type.split("/")[0];
                    let encodeData = {
                        type: type,
                        file: dataUrl.filename,
                        bucket: dataUrl.bucket,
                        folder: options.folder || "/",
                        idModel: cookie.get('username'),
                        hostname: 'socialtechapps.com',
                        token: cookie.get('token'),
                        options,
                        format: ext
                    };

                    let encoded = await this.encodeMedia(encodeData);
                    return resolve({
                        method: "saved",
                        file: encoded.file,
                        poster: encoded.poster,
                        image: encoded.image || null,
                        video: encoded.video || null,
                        audio: encoded.audio || null,
                        type: type,
                        videoNameEncoded: encoded.videoNameEncoded || null,
                        stored: encoded.stored || null,
                        screenshot: encoded.screenshot || null
                    });

                }
                return resolve(false);
            });
        }
        catch (e) {
            axios.post('/api/v2/error/message/admin', {
                error: e
            });
        }
    }
    encodeMedia = async (data) => {
        return new Promise(resolve => {
            this.axiosInstance.post('/api/v2/encoding/media', data).then(async (resp) => {
                if (resp.data.Success) {
                    return resolve(resp.data.files);
                }
            }).catch((e) => {
                return resolve(false);
            });
        });
    }
}

export default new UploadFiles();
