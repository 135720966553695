import React, {Component} from 'react';
import './Preloader.css';
class Preloader extends Component {
	render() {
		return (
			<div className="preloader">
			</div>
		);
	}

};
export default Preloader;