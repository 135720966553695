import React, { Component } from 'react';

class Metadata extends Component {

    async componentDidMount(){
        const noimage="https://storage.googleapis.com/private-social-media.appspot.com/cms/noimage.png";
        if(this.props.accessLevel["u"] >= 3) {
            document.getElementById("MetaName").value = (this.props.meta.name === undefined) ? "" : this.props.meta.name;
            document.getElementById("MetaDescription").value = (this.props.meta.description === undefined) ? "" : this.props.meta.description;
            document.getElementById("MetaShortName").value = (this.props.meta.short_name === undefined) ? "" : this.props.meta.short_name;
            document.getElementById("MetaTitle").value = (this.props.meta.title === undefined) ? "" : this.props.meta.title;
        }
        document.getElementById("faviconImg").setAttribute("src",(this.props.meta.favicon === undefined) ? noimage : this.props.meta.favicon);
        document.getElementById("iconImg").setAttribute("src",(this.props.meta.icon === undefined) ? noimage : this.props.meta.icon);
    }
    render(){
        return (
            <div className="">
                <div className="text-white head-table">
                    Metadata
                </div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 col-sm-6 col-lg-3 formMetadata">
                            <div className='labelMetadata inputTopPadd'>Nombre</div>
                            {(this.props.accessLevel["u"] >= 3) ?
                            <input name="MetaName" id="MetaName" className="form-control" type="text" /> : (this.props.meta.name === undefined) ? "" : this.props.meta.name }
                            <div className='labelMetadata inputBotPadd'>Descripci&oacute;n</div>
                            {(this.props.accessLevel["u"] >= 3) ?
                                <input name="MetaDescription" id="MetaDescription" className="form-control" type="text" /> : (this.props.meta.description === undefined) ? "" : this.props.meta.description }
                        </div>
                        <div className="cardPadd img-content col-12 col-sm-6 col-lg-3">
                            <div className="divImgCard card  mx-auto" style={{width: "100%"}}>
                                <img src="" className="imgMetadata card-img-top" alt="favicon" id="faviconImg"/>
                                <div className="metadataBody card-body">
                                    <div className="card-title imgTitle">Favicon</div>
                                    {(this.props.accessLevel["u"] >= 1) ?
                                    <div className="form-group" style={{marginBottom:'-2rem'}}>
                                        <div className="custom-file addFile-adjust">
                                            <input type="file" className="custom-file-input" onChange={this.props.getImage} name="favicon" id="favicon" accept=".png,.jpg,.jpeg,.bmp,.heif,.heic" data-arr="meta"/>
                                            <label className="custom-upload" htmlFor="favicon">
                                                <i className="fas fa-cloud-upload-alt"></i>&ensp;Add file
                                            </label>
                                        </div>
                                    </div> : "" }
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-3 formMetadata">
                            <div className='labelMetadata inputTopPadd'>Nombre Corto</div>
                            {(this.props.accessLevel["u"] >= 3) ?
                                <input name="MetaShortName" id="MetaShortName" className="form-control" type="text" /> : (this.props.meta.short_name === undefined) ? "" : this.props.meta.short_name }
                                <div className='labelMetadata inputBotPadd'>T&iacute;tulo</div>
                            {(this.props.accessLevel["u"] >= 3) ?
                                <input name="MetaTitle" id="MetaTitle" className="form-control" type="text" /> : (this.props.meta.title === undefined) ? "" : this.props.meta.title }
                        </div>
                        <div className="cardPadd img-content col-sm-6 col-lg-3">
                            <div className="divImgCard card  mx-auto" style={{width: "100%"}}>
                                <img src="" className="imgMetadata card-img-top" alt="icon" id="iconImg"/>
                                <div className="metadataBody card-body">
                                    <div className="card-title imgTitle">Icon</div>
                                    {(this.props.accessLevel["u"] >= 1) ?
                                    <div className="form-group" style={{marginBottom:'-2rem'}}>
                                        <div className="custom-file addFile-adjust">
                                            <input type="file" className="custom-file-input" onChange={this.props.getImage} name="icon" id="icon" accept=".png,.jpg,.jpeg,.bmp,.heif,.heic" data-arr="meta"/>
                                            <label className="custom-upload" htmlFor="icon">
                                                <i className="fas fa-cloud-upload-alt"></i>&ensp;Add file
                                            </label>
                                        </div>
                                    </div> : "" }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default Metadata;
