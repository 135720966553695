import React, { Component } from 'react';
import axios from "axios";
import ModalSaving from "../../../ModalSaving";

class ModalActiveMembershipRegion extends Component {
    constructor(){
        super();
        this.state = {
            saved : false,
            error: false
        };
        this.activeMembership=this.activeMembership.bind(this);
        this.disableMembership=this.disableMembership.bind(this);
    }
    componentDidMount(){
    }
    activeMembership = async () => {
        await this.setState({saved : false, error: false});
        let send=true;
        if(send) {
            document.getElementById("ModalActiveRegionMembershipCancelBtn").click();
            document.getElementById("modalSaveActiveRegionMembership").classList.add("show");
            const formData = new FormData();
            formData.append("idModel",this.props.model);
            if(this.props.type === 3){
                formData.append("main", true);
            }
            else{
                formData.append("main", document.getElementById("mainCheck").checked)
            }
            const saved = await axios.put("/api/membership/region/activate/"+this.props.membership, formData).then(resp => {
                return resp.data;
            });
            if (saved.Error === undefined) {
                await this.setState({saved : true})
                await this.props.reloadMembershipsByCountry();
            } else {
                await this.setState({error : true});
            }
        }
    }
    disableMembership = async () => {
        await this.setState({saved : false, error: false});
        let send=true;
        if(send) {
            document.getElementById("ModalActiveRegionMembershipCancelBtn").click();
            document.getElementById("modalSaveActiveRegionMembership").classList.add("show");
            const formData = new FormData();
            formData.append("idModel",this.props.model);
            const saved = await axios.put("/api/membership/region/disable/"+this.props.membership, formData).then(resp => {
                return resp.data;
            });
            if (saved.Error === undefined) {
                await this.setState({saved : true})
                await this.props.reloadMembershipsByCountry();
                await this.props.getRegionMemberships(this.props.currRegion);
            } else {
                await this.setState({error : true});
            }
        }
    }
    render(){
        return (
            <div>
                <div className="modal" id="ModalActiveMembershipRegion" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header-section">
                                <h6 className="modal-title sectionTitle">{(this.props.type === 1) ?
                                    "Activar Membresia"
                                    : (this.props.type === 2) ?
                                    "Desactivar Membresia"
                                    :
                                    "Hacer Principal"
                                }
                                </h6>
                                <button type="button" className="close modalClose" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body modelBody">
                                <form id="activeRegionMembership">
                                    {(this.props.type === 1) ?
                                        <p>Desea activar esta membresia en todos los paises?</p>
                                        : (this.props.type === 2) ?
                                            <p>Desea desactivar esta membresia en todos los paises</p>
                                            :
                                            <p>Desea hacer esta membresia principal en todos los paises</p>
                                    }
                                    {(this.props.type === 1) ?
                                        <div className="form-check">
                                            <input type="checkbox" className="form-check-input" id="mainCheck" name="mainCheck"/>
                                                <label className="form-check-label" htmlFor="mainCheck">Hacer la membresia principal</label>
                                        </div>
                                        : null

                                    }
                                </form>
                            </div>
                            <div className="modal-footer modalFooter">
                                <button type="button" className="btn btn-modalClose" id="ModalActiveRegionMembershipCancelBtn" data-dismiss="modal">Cancelar</button>
                                <button type="button" className="btn btn-modalSuccess" onClick={(this.props.type === 2) ? this.disableMembership : this.activeMembership}>Guardar</button>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalSaving saved={this.state.saved} error={this.state.error} idModal="modalSaveActiveRegionMembership"/>
            </div>
        )
    }
}
export default ModalActiveMembershipRegion;
