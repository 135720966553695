import React, { Component } from 'react';
import "./PromoMail.css";

class MailPromoTemplate extends Component {

    render(){
    	return(
    		<div>
    			<div className='subj'>Asunto del correo: <b style={{"font-size":'15px'}}>{this.props.subject}</b></div>
	    		<div className="content-promo" style={{'backgroundImage':'url('+this.props.storageUrlTest+ '/' + this.props.storageTest+ '/public/img/'+this.props.imgPromoTest+ ')'}}>
		            <div className="cuerpo-promo">
		                <div className="logo-promo" style={{'backgroundImage':'url('+this.props.storageUrlTest + this.props.logoTest+ ')'}}>
		                </div>
		                <ul className="title-list">
		                    <li>
		                        <a className="host-promo" href={this.props.hostTest}>{this.props.hostTest}</a>
		                    </li>
		                    <li className="titulo-promo">
		                        {this.props.titleMail}
		                    </li>
		                </ul>
		                <ul className="list">
		                    <li>
		                        {this.props.fisrtLine}
		                    </li>
		                    <li>
		                        {this.props.secondLine}
		                    </li>
		                    <li className='liFont'>
		                        {this.props.thirdLine}
		                    </li>
		                </ul>
		                <div className="boton-registro-promo">
		                    <a href={this.props.hostTest} className="boton-principal-promo">
		                        VER CONTENIDO
		                    </a>
		                </div>
		            </div>
		        </div>
	        </div>
    	)
    }
}

export default MailPromoTemplate;
