import React, { Component } from 'react';
import axios from "axios";
import Header from '../../components/Layout/Header';
import Sidebar from '../../components/Layout/Sidebar';
import Footer from '../../components/Layout/Footer';
import ModalWarning from '../../components/Layout/ModalWarning';
import ModalNewModel from '../../components/Model/ModalNewModel';
import AllMembershipDisable from '../../components/Model/Detail/AllMembershipDisable';
import ActivatePromo from '../../components/Model/Detail/ActivatePromo';
import ModalNewBonus from '../../components/Model/Detail/ModalNewBonus';
import ModalChangeBonus from '../../components/Model/Detail/ModalChangeBonus';

class Models extends Component {
    constructor(){
        super();
        this.state={
            modelos: [],
            path: "Home / ",
            view: "Modelos",
            modelosPromo: [],
            allModels: false
        };
        this.reloadModels = this.reloadModels.bind(this);
    }
    async componentDidMount() {
        //console.log("inicio");
        await this.props.isAbleToAccess(1,1,1);
        await this.getModels();
        this.props.changeView("model");
    }
    reloadModels = async () => {
        this.getModels();
    }
    getModels = async () => {
        await axios.get("/api/models/").then(async resp => {
            await this.setState({modelos : resp.data})
        });
    };
    openModel(e,model){
        e.preventDefault();
        window.location.href = "/models/model/"+model;
    }
    openModelMemberships(e,model){
        e.preventDefault();
        window.location.href = "/models/model/"+model+"/memberships";
    }
    openModelCoins(e,model){
        e.preventDefault();
        window.location.href = "/models/model/"+model+"/coins";
    }
    checkPromo=async(e)=>{
        const modelosPromo = [...this.state.modelosPromo];
        const modelPromo = modelosPromo.indexOf(e.target.value);
        if (modelPromo < 0){
            modelosPromo.push(e.target.value)
        }
        else{
            modelosPromo.splice(modelPromo, 1);
        }
        this.setState({
            modelosPromo: modelosPromo
        })
    }
    pintarModelos (){
        if(this.state.modelos.length === 0){
            return (<tr><td colSpan={5} className="text-center">No hay modelos</td></tr>)
        }
        const modelos = [...this.state.modelos];
        // eslint-disable-next-line
        return modelos.map((item,index) => {
            if (item.status_active === 1) {
                return (
                <tr key={index}>
                    <td>{item.id_model}</td>
                    <td>{item.alias}</td>
                    <td>{item.email}</td>
                    <td>{item.hostname}</td>
                    <td>
                        <div className="checkbox">
                            <label>
                                <input
                                    onChange={e => this.checkPromo(e)}
                                    type="checkbox"
                                    value={item.hostname}
                                />
                            </label>
                        </div>
                    </td>
                    <td>
                        <ul className="list-inline">
                            <li  className="list-inline-item">
                                <button className="btn btn-newModel btn-sm btn-icon"
                                        onClick={(e) => this.openModel(e, item.id_model)}><span
                                    className="fas fa-edit"></span>
                                </button>
                            </li>
                            <li  className="list-inline-item">
                                <button className="btn btn-newMembership btn-sm btn-icon"
                                        onClick={(e) => this.openModelMemberships(e, item.id_model)}><span className="fas fa-money-bill-alt"></span>
                                </button>
                            </li>
                            <li  className="list-inline-item">
                                <button className="btn btn-coins btn-sm btn-icon"
                                        onClick={(e) => this.openModelCoins(e, item.id_model)}><span className="fas fa-coins"></span>
                                </button>
                            </li>
                        </ul>
                    </td>
                </tr>
            )
          }
        })
    }
    pintarModelosInactivas (){
        if(this.state.modelos.length === 0){
            return (<tr><td colSpan={6} className="text-center">No hay modelos</td></tr>)
        }
        const modelos = [...this.state.modelos];
        // eslint-disable-next-line
        return modelos.map((item,index) => {
            if (item.status_active === 0) {
                return (
                <tr key={index}>
                    <td>{item.id_model}</td>
                    <td>{item.alias}</td>
                    <td>{item.email}</td>
                    <td>{item.hostname}</td>
                    <td>
                        <ul className="list-inline">
                            <li  className="list-inline-item">
                                <button className="btn btn-newModel btn-sm btn-icon"
                                        onClick={(e) => this.openModel(e, item.id_model)}><span
                                    className="fas fa-edit"></span>
                                </button>
                            </li>
                            {this.props.permisos.Modelos.u >= 2 ?
                                <li className="list-inline-item">
                                    <button className="btn btn-newMembership btn-sm btn-icon"
                                            onClick={(e) => this.openModelMemberships(e, item.id_model)}><span
                                        className="fas fa-money-bill-alt"></span>
                                    </button>
                                </li>
                                : null
                            }
                            {this.props.permisos.Modelos.u >= 2 ?
                                <li className="list-inline-item">
                                    <button className="btn btn-coins btn-sm btn-icon"
                                            onClick={(e) => this.openModelCoins(e, item.id_model)}><span
                                        className="fas fa-coins"></span>
                                    </button>
                                </li>
                                : null
                            }
                        </ul>
                    </td>
                </tr>
            )
          }
        })
    }
    allModelsBonus = async (e) =>{
        if(e.target.click){
            await this.setState({allModels: true});
        }
    }
    render () {
        return(
            <div className="container">

                <Header username={this.props.username} path={this.state.path} view={this.state.view}/>
                <div className="content">
                    <Sidebar permisos={this.props.permisos} view={this.props.view}/>
                    <div className="content-padding main-content" id="page">
                        <div className="row head-padding">
                            <div className="col-6">
                                <h4>Modelos</h4>
                            </div>
                            <div className="col-6">
                                {(this.props.accessLevel["c"] !== undefined && this.props.accessLevel["c"] >= 2) ?
                                    <div className="text-right spacer">
                                        <button className="btn btn-newModel" data-toggle="modal" data-target="#modalNewModel">Nueva Modelo</button>
                                        <button className="btn btn-newBonus" onClick={(e)=> this.allModelsBonus(e)} data-toggle="modal" data-target="#ModalNewBonus">Nuevo Bonus</button>
                                        <button className="btn btn-changeBonus" onClick={(e)=> this.allModelsBonus(e)} data-toggle="modal" data-target="#ModalChangeBonus">Cambiar Cat&aacute;logo</button>
                                    </div>
                                : "" }
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="text-white head-table">
                                    Modelos
                                </div>
                                <div className="table-responsive tableDiv tableScoll-lg">
                                    <table className="table tableDataSection">
                                        <thead>
                                        <tr className="attr-table">
                                            <th>ID</th>
                                            <th>Nombre</th>
                                            <th>Mail</th>
                                            <th>Host</th>
                                            <th>Activar promo</th>
                                            <th></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td colSpan='6' className='attr-title'>Modelos Activas</td>
                                        </tr>
                                        {this.pintarModelos()}
                                        <tr>
                                            <td colSpan='5' className='attr-title'>Modelos Inactivas</td>
                                        </tr>
                                        {this.pintarModelosInactivas()}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="text-white head-table">
                                    Promos
                                </div>
                                <ActivatePromo modelosPromo={this.state.modelosPromo} />
                                <br/>
                                <h5>Desactivar Promos</h5>
                                <AllMembershipDisable />
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
                {(this.props.accessLevel["c"] !== undefined && this.props.accessLevel["c"] >= 2) ?
                <ModalNewModel reloadModels={this.reloadModels}/> : "" }
                <ModalWarning title={"Algo salio mal"} message={"No se pudo guardar la modelo"}/>
                <ModalNewBonus allModels={this.state.allModels}/>
                <ModalChangeBonus allModels={this.state.allModels}/>
            </div>
        )
    }
}
export default Models;