import React, { Component } from 'react';
import axios from "axios";
import ModalSaving from "../../ModalSaving";

class ModalStatusNotification extends Component {
    constructor(props) {
        super();
        this.state = {
            saved: false,
            error: false
        }
        this.statusNotification = this.statusNotification.bind(this);
    }


    statusNotification = async () => {
        await this.setState({ saved: false, error: false });
        document.getElementById("ModalStatusNotificationCancelBtn").click();
        document.getElementById("modalSaveActivate").classList.add("show");
        if (this.props.modelName && this.props.idModel && !this.props.statusModel) {
            const data = {
                id: this.props.idNews,
                idModel: this.props.idModel,
                status: this.props.logStatus
            };
            const saved = await axios.post("/api/notifications/detail/changeStatus/", data)
            .then(resp => {
                return resp.data;
            });
            if(saved){
                await this.setState({ saved: true })
                await this.props.reloadNews();
            } else {
                await this.setState({ error: true })
            }
        }else{
            const data = {
                id: this.props.idNews,
                status: this.props.statusModel
            };
            const saved = await axios.post("/api/notifications/changeStatus/", data)
            .then(resp => {
                return resp.data;
            });
            if(saved){
                await this.setState({ saved: true })
                await this.props.reloadNews();
            } else {
                await this.setState({ error: true })
            }
        }
    }

    render() {
        return (
            <div>
                <div 
                    className="modal" 
                    id="ModalStatusNotification" 
                    role="dialog"
                >
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">
                                    {(this.props.statusModel === 1  || (this.props.logStatus && this.props.logStatus !== 0))
                                        ? "Desactivar Notificación"
                                    : "Activar Notificación"}
                                </h5>
                                <button 
                                    type="button" 
                                    className="close" 
                                    data-dismiss="modal" 
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">
                                        &times;
                                    </span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>
                                    ¿Desea 
                                    {(this.props.statusModel === 1 || (this.props.logStatus && this.props.logStatus !== 0))
                                        ? " desactivar " 
                                    : " activar " }
                                    esta notificación
                                    {this.props.idModel 
                                        ? " para la modelo " + this.props.modelName
                                        : null
                                    }?
                                </p>
                            </div>
                            <div className="modal-footer">
                                <button 
                                    type="button" 
                                    className="btn btn-danger" 
                                    id="ModalStatusNotificationCancelBtn" 
                                    data-dismiss="modal"
                                >
                                    Cancelar
                                    <i className="fas fa-times icon-modal"></i>
                                </button>
                                <button 
                                    type="button" 
                                    className="btn btn-success" 
                                    onClick={this.statusNotification}
                                >
                                    Aceptar
                                    <i className="fas fa-save icon-modal"></i>
                                </button>
                            </div>
                        </div>
                    </div>                         
                </div>
                <ModalSaving 
                    saved={this.state.saved}
                    error={this.state.error}
                    idModal="modalSaveActivate"
                />
            </div>
        )
    }
}
export default ModalStatusNotification;
