import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
const ModalGeneralConfirm = (props) => {
    const {
        className,
        title,
        body,
        actionConfirm,
        buttonLabel,
        colorBtn
    } = props;
    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    return (
        <div>
            <Button color={ colorBtn ? colorBtn : 'primary' } onClick={toggle}>
                {buttonLabel}
            </Button>
            <Modal isOpen={modal} toggle={toggle} className={className}>
                <ModalHeader toggle={toggle}>{title}</ModalHeader>
                <ModalBody className='modelBody'>{body}</ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        onClick={e => actionConfirm(e, toggle)}>
                        Aceptar
                        </Button>{' '}
                    <Button color="secondary" onClick={toggle}>Cancelar</Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}
export default ModalGeneralConfirm;