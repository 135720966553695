import React, { Component } from 'react';
import axios from "axios";
import SetActiveMemberships from '../SetActiveMemberships';
import ModalSaving from "../../../ModalSaving";

class ModalActiveMembership extends Component {
    constructor(){
        super();
        this.state = {
            picode : false,
            isActive : false,
            saved : false,
            error: false
        };
        this.activeMembership=this.activeMembership.bind(this);
    }
    componentDidMount(){
    }

    changePICode = function(e){
        if(e.target.value!==""){
            const picode = e.target.value;
            this.props.updatePiCodeEdited(picode);
            this.setState({picode : true});
        }
        else{
            this.setState({picode : false, isActive : false});
        }
    }
    activeMembership = async () => {
        await this.setState({saved : false, error: false});
        let send=true;
        if(send) {
            document.getElementById("ModalActiveMembershipCancelBtn").click();
            document.getElementById("modalSaveActiveMembership").classList.add("show");
            const formData = new FormData();
            const form = document.getElementById("activeMembership");
            formData.append("idModel",this.props.model);
            for( let k = 0 ; k<form.length; k++){
                let element=form[k];
                if(element.tagName.toUpperCase() === "INPUT") {
                    if(element.type === "checkbox" || element.type === "radio"){
                        if(element.checked)
                            formData.append(element.name, element.value);
                    }
                    else{

                        if(element.name !== "")
                            formData.append(element.name, element.value);

                    }
                }
                if(element.tagName.toUpperCase() === "SELECT"){
                    formData.append(element.name, element.value)
                }
            }
            const saved = await axios.put("/api/membership/activate/"+this.props.membership.id, formData, {params : {country : this.props.currCountry}}).then(resp => {
                return resp.data;
            });
            if (saved.Error === undefined) {
                await this.setState({saved : true})
                await this.props.reloadMemberships();
            } else {
                await this.setState({error : true});
            }
        }
    }
    render(){
        return (
            <div>
                <div className="modal" id="ModalActiveMembership" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header-section">
                                <h6 className="modal-title sectionTitle">Activar Membresia</h6>
                                <button type="button" className="close modalClose" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body modelBody">
                                <form id="activeMembership">
                                    {(this.props.membership.id !== undefined) ?
                                        <div>
                                            <hr/>
                                            <h6>Membresias Activas</h6>
                                            <SetActiveMemberships currCountry={this.props.currCountry} model={this.props.model} new={this.props.membership}/>
                                        </div> : ""
                                    }
                                </form>
                            </div>
                            <div className="modal-footer modalFooter">
                                <button type="button" className="btn btn-modalClose" id="ModalActiveMembershipCancelBtn" data-dismiss="modal">Cancelar</button>
                                <button type="button" className="btn btn-modalSuccess" onClick={this.activeMembership}>Guardar</button>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalSaving saved={this.state.saved} error={this.state.error} idModal="modalSaveActiveMembership"/>
            </div>
        )
    }
}
export default ModalActiveMembership;
