import React, { Component } from 'react';
import axios from 'axios';
import Header from '../../../components/Layout/Header';
import Sidebar from '../../../components/Layout/Sidebar';
import Footer from '../../../components/Layout/Footer';
import moment from 'moment';
import DatePicker from "react-datepicker";
import Select from 'react-select';
import ModalSaving from "../../../components/ModalSaving";
import PromosActive from '../../../components/Promotions/PromosActive';

class PromosSoldConverted extends Component {
    constructor() {
        super();
        this.state = {
            promosSold: [],
            promosConverted: [],
            modelos: [],
            saved: false,
            error: false,
            startDateSold: new Date(),
            endDateSold: new Date(),
            startDateConverted: new Date(),
            endDateConverted: new Date(),
            period: "",
            modelSelected: [],
            modelSelectedConverted: [],
            modelsAlls: null,
            allModels: null,
            allModelsConv: null,
            model: null,
            modelSelectError: false,
            modelSelectErrConv: false,
            path: "Home / ",
            view: "Promociones"
        }
        this.handleChangeStartSold = this.handleChangeStartSold.bind(this);
        this.handleChangeEndSold = this.handleChangeEndSold.bind(this);
        this.handleChangeStartConverted = this.handleChangeStartConverted.bind(this);
        this.handleChangeEndConverted = this.handleChangeEndConverted.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.changeHandlerConverted = this.changeHandlerConverted.bind(this);
        this.historyPromosSold = this.historyPromosSold.bind(this);
        this.historyPromosConverted = this.historyPromosConverted.bind(this);
    }
    componentDidMount() {
        this.getModels();
        this.props.changeView("promotions");
    }
    getModels = async () => {
        await axios.get("/api/models/")
            .then(async resp => {
                let activeModelsCount = 0;
                let activeModels = [];
                for (var i = 0; i < resp.data.length; ++i) {
                    if (resp.data[i].status_active === 1) {
                        activeModelsCount = i + 1;
                        activeModels.push(resp.data[i]);
                    }
                }
                this.setState({
                    modelos: activeModels,
                    modelsAlls: activeModelsCount
                })
            });
    };
    changeHandler(value) {
        let getAll = null;
        let valueAll = null;
        for (var i = 0; i < value.length; ++i) {
            if (value[i].label === "Todos") {
                getAll = value[i].value
                valueAll = value[i];
                break;
            }
        }
        if (getAll) {
            this.setState({
                allModels: getAll,
                modelSelected: valueAll
            });
        } else {
            this.setState({
                modelSelected: value,
                allModels: null
            });
        }
    }
    changeHandlerConverted(value) {
        let getAll = null;
        let valueAll = null;
        for (var i = 0; i < value.length; ++i) {
            if (value[i].label === "Todos") {
                getAll = value[i].value
                valueAll = value[i];
                break;
            }
        }
        if (getAll) {
            this.setState({
                allModelsConv: getAll,
                modelSelectedConverted: valueAll
            });
        } else {
            this.setState({
                modelSelectedConverted: value,
                allModelsConv: null
            });
        }
    }
    historyPromosSold = async () => {
        this.setState({ saved: false, error: false, modelSelectError: false });
        let send = true;
        if (this.state.modelSelected.length === 0 && !this.state.allModels) {
            this.setState({
                modelSelectError: true
            });
            send = false;
        }
        if (send) {
            document.getElementById("ModalSaving").classList.add("show");
            let lapse = document.getElementsByClassName("dateSold");
            let start = moment(lapse[0].value);
            let end = moment(lapse[1].value);
            const period = {
                startDate: start.format("YYYY-MM-DD"),
                endDate: end.format("YYYY-MM-DD")
            };
            this.setState({ period: period });
            const showSold = new Promise(resolve => {
                return resolve(
                    this.promosSold(period)
                )
            });
            Promise.all([showSold]).then(values => {
                document.getElementById("ModalSaving").classList.remove("show");
            });
        }
    }
    historyPromosConverted = async () => {
        this.setState({ saved: false, error: false, modelSelectErrConv: false });
        let send = true;
        if (this.state.modelSelectedConverted.length === 0 && !this.state.allModelsConv) {
            this.setState({
                modelSelectErrConv: true
            });
            send = false;
        }
        if (send) {
            document.getElementById("ModalSaving").classList.add("show");
            let lapse = document.getElementsByClassName("dateConverted");
            let start = moment(lapse[0].value);
            let end = moment(lapse[1].value);
            const period = {
                startDate: start.format("YYYY-MM-DD"),
                endDate: end.format("YYYY-MM-DD")
            };
            this.setState({ period: period });
            const showSold = new Promise(resolve => {
                return resolve(
                    this.promosConverted(period)
                )
            });
            Promise.all([showSold]).then(values => {
                document.getElementById("ModalSaving").classList.remove("show");
            });
        }
    }
    promosSold = async (period) => {
        let type = 'T';
        let ccbillType = 'SS';
        const data = {
            allModels: this.state.allModels,
            modelSelected: this.state.modelSelected,
            period, ccbillType
        }
        const resp = await axios.get('/api/promosSoldConverted/' + type, { params: data }).then(async resp => {
            this.setState({ promosSold: resp.data });
            return true;
        });
        return resp;

    }
    promosConverted = async (period) => {
        let type = 'U';
        let ccbillType = 'WS';
        const data = {
            allModels: this.state.allModelsConv,
            modelSelected: this.state.modelSelectedConverted,
            period, ccbillType
        }
        const resp = await axios.get('/api/promosSoldConverted/' + type, { params: data }).then(async resp => {
            this.setState({ promosConverted: resp.data });
            return true;
        });
        return resp;
    }
    async handleChangeStartSold(date) {
        this.setState({ startDateSold: date });
        let endDateSold = this.state.endDateSold;
        endDateSold = new Date(endDateSold);
        if (endDateSold.getTime() < date.getTime()) {
            await this.setState({
                endDateSold: date
            });
        }
    }
    async handleChangeEndSold(date) {
        this.setState({
            endDateSold: date
        });
    }
    async handleChangeStartConverted(date) {
        this.setState({ startDateConverted: date });
        let endDateConverted = this.state.endDateConverted;
        endDateConverted = new Date(endDateConverted);
        if (endDateConverted.getTime() < date.getTime()) {
            await this.setState({
                endDateConverted: date
            });
        }
    }
    async handleChangeEndConverted(date) {
        this.setState({
            endDateConverted: date
        });
    }
    showPromosSold() {
        if (this.state.promosSold.length === 0) {
            return (<tr><td colSpan={5} className="text-center">No hay información</td></tr>)
        }
        const promosSold = [...this.state.promosSold];
        // eslint-disable-next-line
        return promosSold.map((item, index) => {
            let currentStart = moment(item.currentStart).format("DD/MMM/YYYY");
            let currentEnd = moment(item.currentEnd).format("DD/MMM/YYYY");
            return (
                <tr key={index}>
                    <td>{item.model}</td>
                    <td>{'$' + item.amountTransaction + ' USD'}</td>
                    <td>{item.region}</td>
                    <td>{item.total}</td>
                    {currentStart && currentEnd === 'Invalid date' ?
                        <td>Sin registro</td> :
                        <td>{currentStart} <span className="fas fa-long-arrow-alt-right"></span> {currentEnd}</td>
                    }
                </tr>
            )
        })
    }
    showPromosConverted() {
        if (this.state.promosConverted.length === 0) {
            return (<tr><td colSpan={5} className="text-center">No hay información</td></tr>)
        }
        const promosConverted = [...this.state.promosConverted];
        // eslint-disable-next-line
        return promosConverted.map((item, index) => {
            let currentStart = moment(item.currentStart).format("DD/MMM/YYYY");
            let currentEnd = moment(item.currentEnd).format("DD/MMM/YYYY");
            return (
                <tr key={index}>
                    <td>{item.model}</td>
                    <td>${item.amountTransaction} USD</td>
                    <td>{item.region}</td>
                    <td>{item.total}</td>
                    {currentStart && currentEnd === 'Invalid date' ?
                        <td>Sin registro</td> :
                        <td>{currentStart} <span className="fas fa-long-arrow-alt-right"></span> {currentEnd}</td>
                    }
                </tr>
            )
        })
    }
    render() {
        const all = {
            id_model: this.state.modelsAlls,
            username: 'Todos'
        };
        const allModels = [all, ...this.state.modelos];
        const modelos = allModels.map((item, index) => ({
            key: index,
            value: item.id_model,
            label: item.username
        }));

        return (
            <div className="container">
                <Header username={this.props.username} path={this.state.path} view={this.state.view} />
                <div className="content">
                    <Sidebar permisos={this.props.permisos} view={this.props.view} />
                    <div className="content-padding main-content" id="page">
                        <div className="row head-padding">
                            <div className="col-6">
                                <h4>Promociones</h4>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <PromosActive/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="text-white head-table">
                                    Promociones Vendidas / Convertidas a Membres&iacute;as
                                </div>
                                <div className="container-fluid ">
                                    <div className="divCard row">
                                        <div className="form-group col-12 col-sm-2 col-md-3 col-lg-3">
                                            <label>Inicio</label>
                                            <DatePicker className="form-control form-control-sm dateSold"
                                                dateFormat="yyyy-MM-dd"
                                                maxDate={new Date()}
                                                selected={this.state.startDateSold}
                                                onChange={this.handleChangeStartSold}
                                            />
                                        </div>
                                        <div className="form-group col-12 col-sm-2 col-md-3 col-lg-3">
                                            <label>Fin</label>
                                            <DatePicker className="form-control form-control-sm dateSold"
                                                dateFormat="yyyy-MM-dd"
                                                maxDate={new Date()}
                                                minDate={this.state.startDateSold}
                                                selected={this.state.endDateSold}
                                                onChange={this.handleChangeEndSold}
                                            />
                                        </div>
                                        <div className="form-group col-12 col-sm-6 col-md-4 col-lg-5">
                                            <label>Modelo</label>
                                            <Select
                                                id="modelS"
                                                closeMenuOnSelect={false}
                                                hideSelectedOptions={false}
                                                isMulti
                                                name="modelS"
                                                options={modelos}
                                                placeholder="Seleccionar"
                                                className={this.state.modelSelectError ?
                                                    "form-multiselect basic-multi-select select-modelPromos invalid" :
                                                    "form-multiselect basic-multi-select select-modelPromos"
                                                }
                                                classNamePrefix="selec"
                                                value={this.state.modelSelected}
                                                onChange={this.changeHandler}
                                            />
                                        </div>
                                        <div className="form-group col-12 col-sm-2 col-md-2 col-lg-1">
                                            <button type="button" id="sold" className="btn btn-searchPromos" onClick={this.historyPromosSold}>
                                                <i className="fas fa-search"></i>
                                            </button>
                                        </div>
                                        <div className="col-12 col-sm-6 col-lg-6">
                                            <label>Periodo: <input type="text" className="period-input" value={moment(this.state.startDateSold).format("DD-MMM-YYYY")} disabled />
                                                <span className="fas fa-long-arrow-alt-right"></span>
                                                <input type="text" className="period-input" value={moment(this.state.endDateSold).format("DD-MMM-YYYY")} disabled />
                                            </label>
                                        </div>
                                        <div className="table-responsive tableDiv tableScoll-lg">
                                            <caption className="table-responsive caption-coins">Historial de promociones vendidas</caption>
                                            <table className="table tableDataSection">
                                                <thead>
                                                    <tr className="attr-table">
                                                        <th>Modelo</th>
                                                        <th>Monto de Transacci&oacute;n</th>
                                                        <th>Regi&oacute;n</th>
                                                        <th>Total</th>
                                                        <th>Periodo Activo</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.showPromosSold()}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="form-group col-12 col-sm-2 col-md-3 col-lg-3">
                                            <label>Inicio</label>
                                            <DatePicker className="form-control form-control-sm dateConverted"
                                                dateFormat="yyyy-MM-dd"
                                                maxDate={new Date()}
                                                selected={this.state.startDateConverted}
                                                onChange={this.handleChangeStartConverted}
                                            />
                                        </div>
                                        <div className="form-group col-12 col-sm-2 col-md-3 col-lg-3">
                                            <label>Fin</label>
                                            <DatePicker className="form-control form-control-sm dateConverted"
                                                dateFormat="yyyy-MM-dd"
                                                maxDate={new Date()}
                                                minDate={this.state.startDateConverted}
                                                selected={this.state.endDateConverted}
                                                onChange={this.handleChangeEndConverted}
                                            />
                                        </div>
                                        <div className="form-group col-12 col-sm-6 col-md-4 col-lg-5">
                                            <label>Modelo</label>
                                            <Select
                                                name="modelSelec"
                                                id="modelSelec"
                                                closeMenuOnSelect={false}
                                                hideSelectedOptions={false}
                                                isMulti
                                                placeholder="Seleccionar"
                                                className={this.state.modelSelectErrConv ?
                                                    "form-multiselect basic-multi-select select-modelPromos invalid" :
                                                    "form-multiselect basic-multi-select select-modelPromos"
                                                }
                                                classNamePrefix="selec"
                                                value={this.state.modelSelectedConverted}
                                                onChange={this.changeHandlerConverted}
                                                isClearable={true}
                                                options={modelos}
                                            />
                                        </div>
                                        <div className="form-group col-12 col-sm-2 col-md-2 col-lg-1">
                                            <button type="button" id="converted" className="btn btn-searchPromos" onClick={this.historyPromosConverted}>
                                                <i className="fas fa-search"></i>
                                            </button>
                                        </div>
                                        <div className="col-12 col-sm-6 col-lg-6">
                                            <label>Periodo: <input type="text" className="period-input" value={moment(this.state.startDateConverted).format("DD-MMM-YYYY")} disabled />
                                                <span className="fas fa-long-arrow-alt-right"></span>
                                                <input type="text" className="period-input" value={moment(this.state.endDateConverted).format("DD-MMM-YYYY")} disabled />
                                            </label>
                                        </div>
                                        <div className="table-responsive tableDiv tableScoll-lg">
                                            <caption className="table-responsive caption-coins">Historial de promociones convertidas en membres&iacute;as</caption>
                                            <table className="table tableDataSection">
                                                <thead>
                                                    <tr className="attr-table">
                                                        <th>Modelo</th>
                                                        <th>Monto de Transacci&oacute;n</th>
                                                        <th>Regi&oacute;n</th>
                                                        <th>Total</th>
                                                        <th>Periodo Activo</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.showPromosConverted()}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
                <ModalSaving saved={this.state.saved} error={this.state.error} message={"Cargando"} />
            </div>
        )
    }
}
export default PromosSoldConverted;