import React, { Component } from 'react';
import axios from "axios";
import ModalSaving from "../../ModalSaving";

class ModalUpdateManager extends Component {
    constructor(){
        super();
        this.state={
            saved : false,
            error : false
        };
        this.saveManager=this.saveManager.bind(this);
    }
    componentDidMount(){
        document.getElementById("currentNameInput").value=this.props.name;
    }
    saveManager = async () => {
        await this.setState({saved : false, error: false});
        let send=true;
        const nombre = document.getElementById("currentNameInput").value;
        if(nombre===""){
            document.getElementById("currentNameInput").classList.add("is-invalid");
            send = false;
        }
        else{
            document.getElementById("currentNameInput").classList.remove("is-invalid");
        }

        if(send) {
            document.getElementById("ModalUpdateManagerCancelBtn").click();
            document.getElementById("ModalSaving").classList.add("show");
            const data = {
                name: nombre
            }
            const saved = await axios.put("/api/manager/" + this.props.manager, data).then(resp => {
                return resp.data;
            });
            if (saved) {
                await this.setState({saved : true})
                await this.props.reloadManager();
            } else {
                await this.setState({error : true})
            }
        }
    }
    render(){
        return (
            <div>
                <div className="modal" id="ModalUpdateManager" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Update Manager</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form id="newModel">
                                    <div className="form-group">
                                        <input type="text" placeholder="Nombre" className="form-control" id="currentNameInput"/>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger" id="ModalUpdateManagerCancelBtn" data-dismiss="modal">Cancelar&nbsp;<i className="fas fa-times"></i></button>
                                <button type="button" className="btn btn-success" onClick={this.saveManager}>Guardar&nbsp;<i className="fas fa-save"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalSaving saved={this.state.saved} error={this.state.error}/>
            </div>
        )
    }
}
export default ModalUpdateManager;