import React, { Component } from 'react';
import './style.css';

class Post extends Component {

    async componentDidMount(){

    }
    render(){
        return (
            <div
                className="screen-example screen-menu-chat"
                id="exampleMenuChat"
                style={{backgroundColor : this.props.config.color.navbar}}
            >
                <button
                    type="button"
                    className="menu-example-head left"
                    onClick={this.props.openSideBar}
                >
                    <i className=" fas fa-bars"></i>
                </button>
                <button
                    type="button"
                    className="menu-example-head right"
                >
                    <i className=" fas fa-user"></i>
                </button>
                <div
                    className="logo"
                >
                    {(this.props.config.logo) ?
                        <img
                            src={this.props.config.storageURL + this.props.config.logo}
                            alt="LOGO"
                        />
                        :
                        "LOGO"
                    }
                </div>
                <div
                    className="sidebar-example open"
                    style={{
                        backgroundColor : this.props.config.color.navbar
                    }}
                >
                    <button
                        type="button"
                        className="menu-example-head left"
                        onClick={this.props.closeSideBar}
                    >
                        <i className=" fas fa-bars"></i>
                    </button>
                    <ul>
                        <li
                            style={{
                                color : "hsla(0,0%,100%,.57)"
                            }}
                            data-window={0}
                            onClick={this.props.openExampleWindow}
                        >
                            INICIO
                        </li>
                        <li
                            style={{
                                color : "hsla(0,0%,100%,.57)"
                            }}
                            data-window={1}
                            onClick={this.props.openExampleWindow}
                        >
                            POSTS
                        </li>
                        <li
                            style={{
                                backgroundColor:this.props.config.color.secondary,
                                color: "#FFF"
                            }}
                        >
                            MENU CHAT
                        </li>
                        <li
                            style={{
                                color : "hsla(0,0%,100%,.57)"
                            }}
                            data-window={2}
                            onClick={this.props.openExampleWindow}
                        >
                            CHAT
                        </li>
                        <li
                            style={{
                                color : "hsla(0,0%,100%,.57)"
                            }}
                            data-window={3}
                            onClick={this.props.openExampleWindow}
                        >
                            MODAL
                        </li>
                    </ul>
                </div>
                <div
                    className="screen-body"
                    style={{backgroundColor:this.props.config.color.principal}}
                >
                    <button
                        className="massive-button"
                        style={{
                            backgroundColor:this.props.config.color.secondary,
                            color: this.props.config.color.secondaryText
                        }}
                    >
                        Public Message
                    </button>
                    <p
                        className="search"
                        style={{
                            backgroundColor : this.props.config.color.chat.background,
                            color : "#9e9e9e"
                        }}
                    >
                        <i className="fas fa-search"></i>
                        All chats
                    </p>
                    <ul
                        className="filter"
                    >
                        <li
                            className="active"
                            style={{
                                backgroundColor:this.props.config.color.secondary,
                                color: this.props.config.color.secondaryText,
                                borderColor : this.props.config.color.secondary,
                            }}
                        >
                            All
                        </li>
                        <li
                            style={{
                                borderColor : this.props.config.color.secondary,
                                backgroundColor : this.props.config.color.chat.background,
                                color: this.props.config.color.principalText
                            }}
                        >
                            Urgent
                        </li>
                        <li
                            style={{
                                borderColor : this.props.config.color.secondary,
                                backgroundColor : this.props.config.color.chat.background,
                                color: this.props.config.color.principalText
                            }}
                        >
                            No answered
                        </li>
                    </ul>
                    <ul
                        className="chats"
                        style={{
                            backgroundColor : this.props.config.color.chat.background
                        }}
                    >
                        <li
                            style={{
                                color: this.props.config.color.chat.bottonText,
                                borderColor: this.props.config.color.chat.bottonText
                            }}
                        >
                            <img
                                src={this.props.config.storageURL + this.props.mainImage}
                                alt=""
                            />
                            <p className="username-chat">Username</p>
                            <p className="content-chat">Lorem ipsum dolor sit </p>
                            <span
                                className="date"
                                style={{
                                    color: this.props.config.color.secondary
                                }}
                            >
                                Yesterday
                            </span>
                            <span
                                className="pendings"
                                style={{
                                    color: this.props.config.color.chat.modelChatText,
                                    backgroundColor : this.props.config.color.secondary
                                }}
                            >
                                3
                            </span>
                            <i
                                className="fas fa-angle-down"
                                style={{
                                    color: this.props.config.color.chat.modelChatText
                                }}
                            ></i>
                        </li>
                        <li
                            style={{
                                color: this.props.config.color.chat.bottonText
                            }}
                        >
                            <img
                                src={this.props.config.storageURL + this.props.mainImage}
                                alt=""
                            />
                            <p className="username-chat">Username</p>
                            <p className="content-chat">Lorem ipsum dolor sit </p>
                            <span
                                className="date"
                                style={{
                                    color: this.props.config.color.secondary
                                }}
                            >
                                22/06/2020
                            </span>
                            <span
                                className="pendings"
                                style={{
                                    color: this.props.config.color.chat.modelChatText,
                                    backgroundColor : this.props.config.color.secondary
                                }}
                            >
                                3
                            </span>
                            <i
                                className="fas fa-angle-down"
                                style={{
                                    color: this.props.config.color.chat.modelChatText
                                }}
                            ></i>
                        </li>
                        <li
                            style={{
                                color: this.props.config.color.chat.bottonText
                            }}
                        >
                            <img
                                src={this.props.config.storageURL + this.props.mainImage}
                                alt=""
                            />
                            <p className="username-chat">Username</p>
                            <p className="content-chat">Lorem ipsum dolor sit </p>
                            <span
                                className="date"
                                style={{
                                    color: this.props.config.color.secondary
                                }}
                            >
                                23/05/2020
                            </span>
                            <span
                                className="pendings"
                                style={{
                                    color: this.props.config.color.chat.modelChatText,
                                    backgroundColor : this.props.config.color.secondary
                                }}
                            >
                                3
                            </span>
                            <i
                                className="fas fa-angle-down"
                                style={{
                                    color: this.props.config.color.chat.modelChatText
                                }}
                            ></i>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }
}
export default Post;
