import React, { Component } from 'react';
import axios from "axios";
import moment from 'moment';
import Preloader from '../../components/Preloader';
import Header from '../../components/Layout/Header';
import Sidebar from '../../components/Layout/Sidebar';
import Footer from '../../components/Layout/Footer';
import ModalWarning from '../../components/Layout/ModalWarning';
import ModalNewNotification from '../../components/Notification/ModalNewNotification';
import ModalStatusNotification from '../../components/Notification/ModalStatusNotification';
import Icon from '../../iconos/Icon';
import './NotificationDetail/style.css';

class Notifications extends Component {
    constructor(){
        super();
        this.state={
            permisos: [],
            path: "Home / ",
            view: "Notificaciones",
            news: [],
            all: null,
            totalModels: 0,
            idNews: null,
            statusModel: null
        };
        this.getActiveNews = this.getActiveNews.bind(this);
        this.getNewsLogs = this.getNewsLogs.bind(this);
        this.changeStatus = this.changeStatus.bind(this);
        this.reloadNews = this.reloadNews.bind(this);
    }
    async componentDidMount() {
        await this.props.isAbleToAccess(7,3,1);
        this.props.changeView("notification");
        this.getActiveNews();
    }

    getActiveNews(){
        axios.get("/api/notifications/")
        .then(async res => {
            await this.setState({ 
                news: res.data[0], 
                totalModels: res.data[1] 
            })
        });;
    }; 

    getNewsLogs(e, notification){
        e.preventDefault();
        window.location.href = "/Notifications/" + notification;
    }

    reloadNews = async () => {
        this.getActiveNews();
    }

    changeStatus(e, id, status){
        this.setState({idNews: id , statusModel: status});
    }

    showNotifications() {
        if (this.state.news.length === 0) {
            return (<tr><td colSpan={7} className="text-center">No hay notificaciones</td></tr>)
        }
        const news = [...this.state.news];
        // eslint-disable-next-line
        return news.map((item, index) => {
            let title_esp= JSON.parse(item.esp);
            let title_eng= JSON.parse(item.eng);
            var modelsLeft = item.modelsCount - 1;
            if (item.status === 1) {
                return (
                    <tr key={index}>
                        <td>{item.id}</td>
                        <td>
                            {title_esp.title}
                            <div className="title-eng">{title_eng.title}</div>
                        </td>
                        <td>
                            {moment(item.created).format("YYYY-MM-DD")}
                        </td>
                        <td>
                            {(this.state.totalModels === item.modelsCount) 
                                ? "Todos" 
                                : (item.modelsCount > 1) 
                                    ? item.modelFirstName + " y " + modelsLeft + " más"
                                    : (item.modelsCount === 0) 
                                        ? "Ninguno"
                                        : item.modelFirstName
                            }
                        </td>
                        <td>
                            {moment(item.expiration).format("YYYY-MM-DD HH:mm")}
                        </td>
                        <td>
                            <button 
                                id="seeModels" 
                                className="btn btn-eye btn-alignIcon"
                                onClick={(e) => this.getNewsLogs(e, item.id)}
                            >
                                <Icon
                                    iconName="EyeIcon"
                                    color="#103182"
                                    width="30px"
                                    height="30px"
                                    className="eye-view"
                                />
                            </button>
                        </td>
                        <td>
                            <div className="checked-on onoff-style">
                                <button 
                                    id="diable" 
                                    className="btn btn-alignIcon"
                                    onClick={(e) => this.changeStatus(e, item.id, item.status)}
                                    data-toggle="modal" 
                                    data-target="#ModalStatusNotification"
                                >
                                    <i 
                                        className="fas fa-power-off icon-check-new"
                                    ></i>
                                </button>
                            </div>
                        </td>
                    </tr>
                )
            }
        });
    }

    showNotificationsInactive (){
         if (this.state.news.length === 0) {
            return (<tr><td colSpan={7} className="text-center">No hay notificaciones</td></tr>)
        }
        const news = [...this.state.news];
        // eslint-disable-next-line
        return news.map((item, index) => {
            let title_esp= JSON.parse(item.esp);
            let title_eng= JSON.parse(item.eng);
            var modelsLeft = item.modelsCount - 1;
            if (item.status === 0) {
                return (
                    <tr key={index}>
                        <td>{item.id}</td>
                        <td>
                            {title_esp.title}
                            <div className="title-eng">{title_eng.title}</div>
                        </td>
                        <td>{moment(item.created).format("YYYY-MM-DD")}</td>
                        <td>
                            {(this.state.totalModels === item.modelsCount) 
                                ? "Todos" 
                                : (item.modelsCount > 1) 
                                    ? item.modelFirstName + " y " + modelsLeft + " más"
                                    : (item.modelsCount === 0) 
                                        ? "Ninguno"
                                        : item.modelFirstName
                            }
                        </td>
                        <td>{moment(item.expiration).format("YYYY-MM-DD HH:mm")}</td>
                        <td>
                            <button 
                                id="seeModels" 
                                className="btn btn-eye btn-alignIcon"
                                onClick={(e) => this.getNewsLogs(e, item.id)}
                            >
                                <Icon
                                    iconName="EyeIcon"
                                    color="#103182"
                                    width="30px"
                                    height="30px"
                                    className="eye-view"
                                />
                            </button>
                        </td>
                        <td>
                            <div className="checked-off onoff-style">
                                <button 
                                    id="able" 
                                    className="btn btn-alignIcon"
                                    onClick={(e) => this.changeStatus(e, item.id, item.status)}
                                    data-toggle="modal" 
                                    data-target="#ModalStatusNotification"
                                >
                                    <i className="fas fa-power-off icon-desactive-new"
                                    ></i>
                                </button>
                            </div>
                        </td>
                    </tr>
                )
            }
        })
    }

    render () {
        if(!this.state.news) return <Preloader />;
        return(
            <div className="container">
                <Header 
                    username={this.props.username}
                    path={this.state.path} 
                    view={this.state.view}
                />
                <div className="content">
                    <Sidebar 
                        permisos={this.props.permisos} 
                        view={this.props.view}
                    />
                    <div 
                        className="content-padding main-content" 
                        id="page"
                    >
                        <div className="row head-padding">
                            <div className="col-6">
                                <h4>Notificaciones</h4>
                            </div>
                            <div className="col-6">
                                {(this.props.accessLevel["c"] !== undefined && this.props.accessLevel["c"] >= 3) ?
                                    <div className="text-right spacer">
                                        <button 
                                            className="btn btn-newModel" 
                                            data-toggle="modal" 
                                            data-target="#ModalNewNotification"
                                        >
                                            Nueva Notificaci&oacute;n
                                        </button>
                                    </div>
                                    : ""}
                            </div>
                        </div>
                        <div className="text-white referralColor head-table">
                            Notificaciones para Modelos
                        </div>
                        <div className="tableDiv table-responsive-xl">
                            <table className="table tableDataSection news-table">
                                <thead>
                                    <tr className="table-primary">
                                        <th>ID</th>
                                        <th>Noticia</th>
                                        <th>Fecha de creaci&oacute;n</th>
                                        <th>Enviado a</th>
                                        <th>Fecha de expiraci&oacute;n</th>
                                        <th>Ver</th>
                                        <th>Desactivar / Activar</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td 
                                            colSpan='7' 
                                            className='attr-title'
                                        >
                                            Notificaciones Activas
                                        </td>
                                    </tr>
                                    {this.showNotifications()}
                                    <tr>
                                        <td 
                                            colSpan='7' 
                                            className='attr-title'
                                        >
                                            Notificaciones Inactivas
                                        </td>
                                    </tr>
                                    {this.showNotificationsInactive()}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <Footer />
                <ModalWarning 
                    title={"Algo salio mal"} 
                    message={"No se pudo guardar la noticia. Por favor intente de nuevo"}
                />
                <ModalNewNotification 
                    reloadNews = {this.reloadNews}
                />
                <ModalStatusNotification
                    idNews={this.state.idNews}
                    statusModel={this.state.statusModel}
                    reloadNews = {this.reloadNews}
                />
            </div>
        )
    }
}
export default Notifications;