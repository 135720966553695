import React, { Component } from 'react';
import axios from "axios";
import moment from 'moment';
import Header from '../../../components/Layout/Header';
import Sidebar from '../../../components/Layout/Sidebar';
import Footer from '../../../components/Layout/Footer';
import ModalSaving from "../../../components/ModalSaving";
import ModalWarning from '../../../components/Layout/ModalWarning';
import NewsList from '../../../components/Notification/Detail/NewsList';
import GeneralNotification from '../../../components/Notification/Detail/GeneralNotification';
import ModalNewModelNotification from '../../../components/Notification/Detail/ModalNewModelNotification';

class NotificationDetail extends Component {
    constructor() {
        super();
        this.state = {
            id: null,
            media: [],
            modelos: [],
            username: "",
            saved: false,
            idNews: null,
            addedModels: [],
            modifyDate: false,
            realoadNewList: false,
            modifyModelDate: false,
            totalCountModels: null,
            path: "Home / Notificaciones / "
        }
        this.reloadNews = this.reloadNews.bind(this);
        this.openModify = this.openModify.bind(this);
        this.reloadModels = this.reloadModels.bind(this);
        this.openModelModify = this.openModelModify.bind(this);
        this.changeExpiration = this.changeExpiration.bind(this);
    }

    async componentDidMount() {
        await this.props.isAbleToAccess(7, 1, 1);
        await this.setState({ id: this.props.notification });
        if (this.state.id === "") {
            window.location = "/";
        }
        await this.getAllDataNews();
        this.getModels();
        this.props.changeView("notification");
        const id = this.state.id;
        this.setState({ view: id });
    }

    getAllDataNews = async () => {
        await axios.get('/api/notifications/detail/' + this.state.id)
        .then(async resp => {
            if (!resp.data) {
                window.location = "/";
            }
            else {
                let res = resp.data[0];
                await this.setState({
                    id: res.id,
                    status: res.status,
                    created: res.created,
                    esp: JSON.parse(res.esp),
                    eng: JSON.parse(res.eng),
                    expiration: res.expiration,
                    media: JSON.parse(res.media)
                })
            }
        });
    }

    getModels = async () => {
        const data = {
            idNews: this.state.id
        }
        await axios.post("/api/notifications/detail/models/", data)
        .then(async resp => {
            let activeModelsCount = 0;
            for(var i = 0; i < resp.data.length; ++i){
                activeModelsCount = i + 1;
            }
            await this.setState({
                addedModels : resp.data,
                totalCountModels : activeModelsCount
            })
        });
    };

    openModify(e){
        e.preventDefault();
        this.setState({
            modifyDate: true
        });
    }

    openModelModify(e, index){
        e.preventDefault();
        this.setState({
            modifyModelDate: true,
            selectedRow: index
        });
    }

    changeExpiration(e,id, date, perModel = null){
        e.preventDefault();
        if(!date){
            return false;
        }
        this.setState({
            saved: false, 
            error: false
        });
        document.getElementById("ModalSaving").classList.add("show"); 
        if(!perModel){
            const data={
                id: id,
                date: moment(date).format("YYYY-MM-DD HH:mm:ss")
            }
            axios.post('/api/notifications/modifyDate/', data)
            .then(resp =>{
                this.setState({
                    expiration: date,
                    saved: true,
                    modifyDate: false,
                    realoadNewList: true
                });
            }).catch(err => {
                this.setState({
                    error: true
                });
                return false;
            });
        } else{
            const data={
                id: id,
                date: moment(date).format("YYYY-MM-DD HH:mm:ss"),
                model: perModel
            }
            axios.post('/api/notifications/modifyModelDate/', data)
            .then(resp =>{
                this.setState({
                    saved: true,
                    modifyModelDate: false,
                    realoadNewList: true
                });
            }).catch(err => {
                this.setState({
                    error: true
                });
                return false;
            });
        }
    }
    async cancelChanges(e) {
        this.getAllDataNews();
    }
    reloadNews = async () => {
        this.getAllDataNews();
    }

    reloadModels = async () => {
        this.getNewsList();
    }

    getNewsList = async () => {
        await axios.post("/api/notifications/details/", {id: this.state.id})
        .then(async resp => {
            await this.setState({
                modelos: resp.data
            });
        });
    };

    render() {
        return (
            <div className="container">
                <Header 
                    path={this.state.path} 
                    view={this.state.view}
                    username={this.props.username}
                />
                <div className="content">
                    <Sidebar
                        view={this.props.view}
                        permisos={this.props.permisos}
                    />
                    <div
                        id="page"
                        className="content-padding main-content"
                    >
                        <div className="row head-padding">
                            <div 
                                className="col-12 col-sm-2 col-md-2 col-lg-2"
                            >
                                <h4>Notificaci&oacute;n</h4>
                            </div>
                            <div className="col-12 col-sm-10 col-md-10 col-lg-10 alignButtons">
                                <div className="inline-buttons">
                                    <ul style={{
                                            paddingLeft: 0,
                                            marginBottom: 0
                                        }}
                                    >
                                        {(this.props.accessLevel["c"] !== undefined && this.props.accessLevel["u"] >= 1) ?
                                            <li className="list-inline-item extra-btn">
                                                <button
                                                    type="button"
                                                    data-toggle="modal"
                                                    className="btn btn-newModel"
                                                    data-target="#ModalNewModelNotification"
                                                    disabled={this.state.addedModels.length === 0 ? true : false}
                                                >
                                                    Agregar Modelo
                                                </button>
                                            </li> 
                                        : null }
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <form 
                                    id="DataNews" 
                                    method="post"
                                    encType="multipart/form-data"
                                >
                                    {this.state.esp ?
                                        <GeneralNotification
                                            id={this.state.id}
                                            esp={this.state.esp}
                                            eng={this.state.eng}
                                            media={this.state.media}
                                            status={this.state.status}
                                            created={this.state.created}
                                            openModify={this.openModify}
                                            expiration={this.state.expiration}
                                            modifyDate={this.state.modifyDate}
                                            accessLevel={this.props.accessLevel}
                                            changeExpiration={this.changeExpiration}
                                        />
                                    : null }
                                    {this.state.modelos ? 
                                    <NewsList 
                                        id={this.state.id}
                                        modelos={this.state.modelos}
                                        getNewsList={this.getNewsList}
                                        reloadModels={this.reloadModels}
                                        openModify={this.openModelModify}
                                        accessLevel={this.props.accessLevel} 
                                        selectedRow={this.state.selectedRow}
                                        changeExpiration={this.changeExpiration}
                                        realoadNewList={this.state.realoadNewList}
                                        modifyModelDate={this.state.modifyModelDate}
                                    />
                                    : null }
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
                <ModalWarning 
                    title={"Algo salió mal"}
                    message={"No se pudo guardar la notificación"}
                    />
                {this.state.id ?
                    <ModalNewModelNotification 
                        idNews={this.state.id}
                        reloadNews={this.reloadModels}
                        addedModels={this.state.addedModels}
                        totalCountModels={this.state.totalCountModels}
                    />
                : null }
                <ModalSaving 
                    saved={this.state.saved}
                    error={this.state.error}
                />
            </div>
        )
    }
}
export default NotificationDetail;
