import React, { Component } from 'react';
import axios from "axios";

import Header from '../../../../components/Layout/Header';
import Sidebar from '../../../../components/Layout/Sidebar';
import Footer from '../../../../components/Layout/Footer';
import ModalNewCoin from "../../../../components/Model/Detail/ModalNewCoin";
import ModalDisablePiCodeCoin from "../../../../components/Model/Detail/ModalDisablePiCodeCoin";
import ModalEnablePiCodeCoin from "../../../../components/Model/Detail/ModalEnablePiCodeCoin";
import ModalChangeValueCoin from "../../../../components/Model/Detail/ModalChangeValueCoin"
import ModalChangeModuleValue from "../../../../components/Model/Detail/ModalChangeModuleValue";
import ModalDisableContentMedia from "../../../../components/Model/Detail/ModalDisableContentMedia";
import ModalNewBonus from "../../../../components/Model/Detail/ModalNewBonus";
import ModalDisableBonus from "../../../../components/Model/Detail/ModalDisableBonus";
import ModalChangeBonus from "../../../../components/Model/Detail/ModalChangeBonus";
import ModalSaving from '../../../../components/ModalSaving';
import moment from 'moment';

class Coins extends Component {
    constructor() {
        super();
        this.state = {
            id: null,
            membresias: { Activas: [], Historial: [] },
            name: null,
            username: null,
            hostname: null,
            currCountry: 0,
            coins: [],
            charges: [],
            disableMembership: null,
            enableMembership: null,
            updatingMembership: null,
            curMonedasToChange: 0,
            updatingModule: null,
            curMonedasToChangeModule: 0,
            bonus: [],
            disableBonus: null
        }
        this.reloadCoins = this.reloadCoins.bind(this);
        this.setDisabling = this.setDisabling.bind(this);
        this.setEnabled = this.setEnabled.bind(this);
        this.setPiCodeToChange = this.setPiCodeToChange.bind(this);
        this.setChangePriceModule = this.setChangePriceModule.bind(this);
        this.disableContentModule = this.disableContentModule.bind(this);
    }
    async componentDidMount() {
        await this.props.isAbleToAccess(1, 2, 3);
        await this.setState({ id: this.props.model });
        if (this.state.id === null) {
            window.location = "/";
        }
        await this.getAllDataModel();
        await this.getCoinsHistory();
        await this.getExtraCharges();
        await this.getBonusModel();
    }
    async getAllDataModel() {
        await axios.get('/api/model/detail/' + this.state.id)
            .then(async resp => {
                if (!resp.data) {
                    window.location = "/";
                }
                else {
                    //console.log(resp.data);
                    await this.setState({
                        name: resp.data.name,
                        username: resp.data.username,
                        hostname: resp.data.hostname
                    })

                }
            });
    }
    getCoinsHistory = async () => {
        await axios.get('/api/model/coin/history/' + this.state.id)
            .then(async resp => {
                await this.setState({ coins: resp.data })
            })
    }
    getExtraCharges = async () => {
        await axios.get('/api/model/coin/charges/' + this.state.id)
            .then(async resp => {
                await this.setState({ charges: resp.data })
            })
    }
    reloadCoins = async () => {
        await this.getCoinsHistory();
    }
    reloadModules = async () => {
        await this.getExtraCharges();
    }
    reloadBonus = async () => {
        await this.getBonusModel();
    }
    setDisabling = async (e, index) => {
        await this.setState({ disableMembership: index });

    }
    setEnabled = async (e, index) => {
        //console.log(index);
        await this.setState({ enableMembership: index });
    }
    setDisableBonus = async (e, index) => {
        await this.setState({ disableBonus: index });
    }
    setPiCodeToChange = async (e, index) => {
        const curValue = e.target.getAttribute("data-value");
        //console.log(curValue);
        await this.setState({ updatingMembership: index, curMonedasToChange: curValue });

    }
    setChangePriceModule = async (e, module) => {
        const curValue = e.target.getAttribute("data-value");
        //console.log(curValue);
        await this.setState({ updatingModule: module, curMonedasToChangeModule: curValue });
    }
    disableContentModule = async (e, module) => {
        await this.setState({ updatingModule: module });
    }
    formatMoney = (n, c, d, t) => {
        c = isNaN(c = Math.abs(c)) ? 2 : c;
        d = d === undefined ? "." : d;
        t = t === undefined ? "," : t;
        if (isNaN(n)) {
            return n
        }
        let s = n < 0 ? "-" : "";
        let i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c)));
        let k = (i.length);
        let j = k > 3 ? k % 3 : 0;
        return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
    };
    getBonusModel = async () => {
        await axios.get('/api/model/bonusmodel/' + this.state.id)
            .then(async resp => {
                await this.setState({ bonus: resp.data })
            })
    }
    savePercentageDollar = async (e, index) => {
        await this.setState({ saved: false, error: false });
        let send = true;
        let model = this.state.id;
        let id = this.state.bonus[index].id;
        let percentage = this.state.bonus[index].percentage;
        let dollarSpent = this.state.bonus[index].dollarSpent;
        if (this.state.bonus[index].percentage) {
            percentage = this.state.bonus[index].percentage;
        }
        if (this.state.bonus[index].dollarSpent) {
            dollarSpent = this.state.bonus[index].dollarSpent;
        }
        if (isNaN(percentage) || percentage <= 0) {
            send = false;
        }
        else {
            document.getElementById("percentageInput").classList.remove("is-invalid");
        }
        if (isNaN(dollarSpent) || dollarSpent <= 0) {
            send = false;
        }
        else {
            document.getElementById("dollarSpentInput").classList.remove("is-invalid");
        }
        if (send) {
            document.getElementById("modalSavingBonus").classList.add("show");
            const data = {
                "bonusCoins": [{
                    "model": model,
                    "new": {
                        "percentage": percentage,
                        "dollarSpent": dollarSpent
                    },
                    "old": {
                        "id": id
                    }
                }]
            }
            const saved = await axios.put('/api/bonus/selfupdate', data).then(resp => {
                return resp.data;
            });
            if (saved === false) {
                await this.setState({ saved: false, error: true })
            } else {
                await this.setState({ error: false, saved: true })
            }
        }
    }
    changeHandlerPercentage(e, index) {
        let value = e.target.value;
        let bonus = [...this.state.bonus];
        bonus[index].percentage = value;
        this.setState({ bonus: bonus });
    }
    changeHandlerDollarSpent(e, index) {
        let value = e.target.value;
        let bonus = [...this.state.bonus];
        bonus[index].dollarSpent = value;
        this.setState({ bonus: bonus });
    }
    drawCoinsHistory() {
        if (this.state.coins.length === 0) {
            return (<tr><td colSpan={4} className="text-center">No hay historial</td></tr>)
        }
        const coins = [...this.state.coins];
        return coins.map((item, index) => {
            return (
                <tr key={index}>
                    <td>{item.picode}</td>
                    <td>${this.formatMoney(item.precio)}</td>
                    <td>{item.moneda}</td>
                    <td>{item.coins}</td>
                    <td>{item.paymentPro}</td>
                    <td>
                        <ul className="list-inline">
                            {(item.status) ?
                                <li className="list-inline-item">
                                    <button className="btn btn-danger btn-sm" onClick={(e) => this.setDisabling(e, item.id)} data-toggle="modal" data-target="#ModalDisablePiCodeCoin">
                                        Desactivar
                                    </button>
                                </li> :
                                <li className="list-inline-item">
                                    <button className="btn btn-success btn-sm" onClick={(e) => this.setEnabled(e, item.id)} data-toggle="modal" data-target="#ModalEnablePiCodeCoin">
                                        Activar
                                    </button>
                                </li>
                            }
                            <li className="list-inline-item">
                                <button className="btn btn-primary btn-sm" onClick={(e) => this.setPiCodeToChange(e, item.id)} data-toggle="modal" data-target="#ModalChangeValueCoin" data-value={item.coins}>
                                    Cambiar Valor
                                </button>
                            </li>
                        </ul>
                    </td>
                </tr>
            )

        })
    }
    drawExtraCharges() {
        if (this.state.charges.length === 0) {
            return (<tr><td colSpan={5} className="text-center">No hay cargos</td></tr>)
        }
        const charges = [...this.state.charges];
        return charges.map((item, index) => {
            return (
                <tr key={index}>
                    <td>{item.module_name}</td>
                    <td>{item.coins}</td>
                    <td>
                        <ul className="list-inline">
                            <li className="list-inline-item">
                                <button className="btn btn-primary btn-sm" onClick={(e) => this.setChangePriceModule(e, item.id)} data-toggle="modal" data-target="#ModalChangeModuleValue" data-value={item.coins}>
                                    Cambiar
                                </button>
                            </li>
                            {(item.status === 1) ?
                                <li className="list-inline-item">
                                    <button className="btn btn-secondary btn-sm" onClick={(e) => this.disableContentModule(e, item.id)} data-toggle="modal" data-target="#ModalDisableContentMedia">
                                        Desactivar
                                    </button>
                                </li>
                                : ""}
                        </ul>
                    </td>
                </tr>
            )

        })
    }
    showBonusCoins() {
        if (this.state.bonus.length === 0) {
            return (<tr><td colSpan={4} className="text-center">No hay historial de Bonus</td></tr>)
        }
        const bonus = [...this.state.bonus];
        return bonus.map((item, index) => {
            let date = moment(item.date).format("DD/MMM/YYYY HH:mm:ss");
            return (
                <tr key={index}>
                    {(bonus[index].percentage === "" || isNaN(bonus[index].percentage) || bonus[index].percentage <= 0)
                        ? <td><input id="percentageInput" value={item.percentage} onChange={(e) => this.changeHandlerPercentage(e, index)} placeholder="Porcentaje" className="form-control" type="number" step="0.1" />
                            <span className="text-danger text-small"> Inválido</span>
                        </td>
                        :
                        <td><input id="percentageInput" value={item.percentage} onChange={(e) => this.changeHandlerPercentage(e, index)} placeholder="Porcentaje" className="form-control" type="number" step="0.1" /></td>
                    }
                    {(bonus[index].dollarSpent === "" || isNaN(bonus[index].dollarSpent) || bonus[index].dollarSpent <= 0)
                        ? <td><input id="dollarSpentInput" value={item.dollarSpent} onChange={(e) => this.changeHandlerDollarSpent(e, index)} placeholder="Dolar Gastado" className="form-control" type="number" step="0.1" />
                            <span className="text-danger text-small"> Inválido</span>
                        </td>
                        :
                        <td><input id="dollarSpentInput" value={item.dollarSpent} onChange={(e) => this.changeHandlerDollarSpent(e, index)} placeholder="Dolar Gastado" className="form-control" type="number" step="0.1" /></td>
                    }
                    <td>{date}</td>
                    <td>
                        <ul className="list-inline">
                            <li className="list-inline-item">
                                <button type="button" className="btn btn-newModel btn-sm btn-icon" onClick={(e) => this.savePercentageDollar(e, index)}>Guardar <span
                                    className="fas fa-save"></span>
                                </button>
                            </li>
                            {(item.status) ?
                                <li className="list-inline-item">
                                    <button className="btn btn-danger btn-sm" onClick={(e) => this.setDisableBonus(e, item.id)} data-toggle="modal" data-target="#ModalDisableBonus">
                                        Desactivar
                                    </button>
                                </li> : ""
                            }
                        </ul>
                    </td>
                </tr>
            )

        })
    }
    render() {
        return (
            <div className="container">
                <Header username={this.props.username} />
                <div className="content">
                    <Sidebar permisos={this.props.permisos} view={this.props.view} />
                    <div className="content-padding main-content" id="page">
                        <div className="row head-padding">
                            <div className="col-6">
                                <h4>Monedas</h4>
                            </div>
                            <div className="col-6">
                                {(this.props.accessLevel["c"] !== undefined && this.props.accessLevel["c"] >= 2) ?
                                    <div className="text-right spacer">
                                        <button className="btn btn-newModel" data-toggle="modal" data-target="#ModalNewCoin">Nuevo Precio</button>
                                    </div>
                                    : ""}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="text-white head-table-membership">
                                    Historial
                                </div>
                                <div className="table-responsive-xl tableDiv tableScroll">
                                    <table className="table tableDataSection">
                                        <thead>
                                            <tr className="attr-table">
                                                <th>PiCode</th>
                                                <th>Precio</th>
                                                <th>Currency</th>
                                                <th>Valor (Coins)</th>
                                                <th>Procesador de Pagos</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.drawCoinsHistory()}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="text-white head-table">
                                    Cargos Extra
                                </div>
                                <div className="table-responsive-xl">
                                    <table className="table tableDiv tableScroll">
                                        <thead>
                                            <tr className="attr-table">
                                                <th>Modulo</th>
                                                <th>Monedas</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.drawExtraCharges()}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="text-white head-table-bonus">
                                    Bonus
                                </div>
                                <div className="table-responsive-xl">
                                    <div className="text-right spacer">
                                        <li className="list-inline-item">
                                            <button className="btn btn-newBonus btn-sm" data-toggle="modal" data-target="#ModalNewBonus">
                                                Nuevo Bonus
                                            </button>
                                            <button className="btn btn-changeBonus btn-sm" data-toggle="modal" data-target="#ModalChangeBonus">
                                                Cambiar Cat&aacute;logo
                                            </button>
                                        </li>
                                    </div>
                                    <table className="table tableDiv tableScroll">
                                        <thead>
                                            <tr className="attr-table">
                                                <th>Porcentaje</th>
                                                <th>por cada Dolares Gastados</th>
                                                <th>Fecha de Creación</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.showBonusCoins()}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
                <ModalNewCoin reloadCoins={this.reloadCoins} model={this.state.id} accessLevel={this.props.accessLevel} />
                <ModalDisablePiCodeCoin reloadCoins={this.reloadCoins} model={this.state.id} accessLevel={this.props.accessLevel} disableMembership={this.state.disableMembership} />
                <ModalEnablePiCodeCoin reloadCoins={this.reloadCoins} model={this.state.id} accessLevel={this.props.accessLevel} enableMembership={this.state.enableMembership} />
                <ModalChangeValueCoin reloadCoins={this.reloadCoins} model={this.state.id} accessLevel={this.props.accessLevel} updatingMembership={this.state.updatingMembership} curMonedasToChange={this.state.curMonedasToChange} />
                <ModalChangeModuleValue reloadModules={this.reloadModules} model={this.state.id} accessLevel={this.props.accessLevel} updatingModule={this.state.updatingModule} curMonedasToChangeModule={this.state.curMonedasToChangeModule} />
                <ModalDisableContentMedia reloadModules={this.reloadModules} model={this.state.id} updatingModule={this.state.updatingModule} />
                <ModalNewBonus model={this.state.id} reloadBonus={this.reloadBonus} />
                <ModalDisableBonus disableBonus={this.state.disableBonus} model={this.state.id} reloadBonus={this.reloadBonus} />
                <ModalChangeBonus model={this.state.id} reloadBonus={this.reloadBonus}/>
                <ModalSaving idModal="modalSavingBonus" saved={this.state.saved} error={this.state.error} />
            </div>
        )
    }
}
export default Coins;