import React, { Component } from 'react';
import axios from "axios";
import ModalSaving from "../../../ModalSaving";
import DatePicker from "react-datepicker/es";
import moment from "moment";

class ModalTrialRegionMembership extends Component {
    constructor(){
        super();
        this.state = {
            saved : false,
            error: false,
            startDate : moment().add(1, 'hours').utc().format("YYYY-MM-DDTHH:00"),
            endDate : moment().add(1, 'hours').add(1,'days').utc().format("YYYY-MM-DDTHH:00")
        };

        this.activeTrial=this.activeTrial.bind(this);
    }
    setDate = async (date, end = false) => {
        console.log(date.UTC, moment(date));
        if(end){
            this.setState({endDate : moment(date).format("YYYY-MM-DDTHH:mm")});
        }
        else{
            this.setState({startDate : moment(date).format("YYYY-MM-DDTHH:mm")});
            if(moment(this.state.endDate).isBefore(moment(date))){
                this.setState({endDate : moment(date).add(1, 'days').format("YYYY-MM-DDTHH:mm")});
            }
        }
    }
    activeTrial = async () => {
        await this.setState({saved : false, error: false});
        let send=true;
        let trial = this.props.currTrial;
        console.log(trial, send);
        if(!trial){
            trial = document.getElementById("trialNextRegion2").value;
        }
        if(trial === "0" || trial === null){
            send = false
        }
        console.log(trial, send);
        if(send) {
            document.getElementById("ModalTrialRegionMembershipCancelBtn").click();
            document.getElementById("modalSaveTrialRegionMembership").classList.add("show");
            const formData = new FormData();
            formData.append("idModel",this.props.model);
            formData.append("trial", trial);
            formData.append("start", this.state.startDate);
            formData.append("end", this.state.endDate);
            const saved = await axios.put("/api/membership/region/trial/activate/"+this.props.membership, formData).then(resp => {
                return resp.data;
            });
            if (saved.Error === undefined) {
                await this.setState({saved : true})
                await this.props.reloadMembershipsByCountry();
                await this.props.getRegionMemberships(this.props.currRegion);
            } else {
                await this.setState({error : true});
            }
        }
    }
    disableTrial = async () => {
        await this.setState({saved : false, error: false});
        let send=true;
        if(send) {
            document.getElementById("ModalTrialRegionMembershipCancelBtn").click();
            document.getElementById("modalSaveTrialRegionMembership").classList.add("show");
            const formData = new FormData();
            formData.append("idModel",this.props.model);
            const saved = await axios.put("/api/membership/region/trial/disable/"+this.props.membership, formData).then(resp => {
                return resp.data;
            });
            if (saved.Error === undefined) {
                await this.setState({saved : true})
                await this.props.reloadMembershipsByCountry();
                await this.props.getRegionMemberships(this.props.currRegion);
            } else {
                await this.setState({error : true});
            }
        }
    }
    drawRegions = () => {
        const membresias = [...this.props.membresiasRegion];
        // eslint-disable-next-line
        return membresias.map((item,index) => {
            if(item.pi_code !== this.props.picode) {
                return (
                    <option key={index} value={item.id}>{item.pi_code}</option>
                )
            }
        })
    }
    render(){
        return (
            <div>
                <div className="modal" id="ModalTrialRegionMembership" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header-section">
                                <h6 className="modal-title sectionTitle">{(this.props.type === 1) ?
                                    "Activar Prueba"
                                    :
                                    "Desactivar Prueba"
                                }
                                </h6>
                                <button type="button" className="close modalClose" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body modelBody">
                                {(this.props.type === 2) ?
                                    <p>Desea desactivar esta prueba en todos los paises?</p>
                                    :
                                    <p>Desea activar esta prueba en todos los paises?</p>
                                }
                                {(this.props.type === 1) ?
                                    <form id="activeRegionMembership">
                                        {(!this.props.currTrial) ?
                                            <div className="form-group">
                                                <select name="trialNextRegion" id="trialNextRegion2"
                                                        className="form-control">
                                                    <option value="0" key={-1}>Selecciona un picode</option>
                                                    {this.drawRegions()}
                                                </select>
                                            </div> :
                                            null
                                        }
                                        <p className="text-info text-sm"><i className="fa fa-info"></i>&nbsp;Recuerda insertar las horas en tiempo UTC</p>
                                        <div className="form-group">
                                            <label htmlFor="" className="control-label">
                                                Fecha de inicio
                                            </label>
                                            <DatePicker selected={new Date(this.state.startDate)}
                                                        onChange={date => this.setDate(date)}
                                                        showTimeSelect
                                                        timeFormat="HH:mm"
                                                        timeIntervals={15}
                                                        minDate={new Date()}
                                                        timeCaption="time"
                                                        dateFormat="MMMM d, yyyy h:mm aa"
                                                        className="form-control"/>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="" className="control-label">
                                                Fecha de termino
                                            </label>
                                            <DatePicker selected={new Date(this.state.endDate)}
                                                        onChange={date => this.setDate(date, true)}
                                                        showTimeSelect
                                                        timeFormat="HH:mm"
                                                        timeIntervals={15}
                                                        minDate={new Date(this.state.startDate)}
                                                        timeCaption="time"
                                                        dateFormat="MMMM d, yyyy h:mm aa"
                                                        className="form-control"/>
                                        </div>
                                    </form>
                                    :
                                    null
                                }
                            </div>
                            <div className="modal-footer modalFooter">
                                <button type="button" className="btn btn-modalClose" id="ModalTrialRegionMembershipCancelBtn" data-dismiss="modal">Cancelar</button>
                                <button type="button" className="btn btn-modalSuccess" onClick={(this.props.type === 2) ? this.disableTrial : this.activeTrial}>Guardar</button>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalSaving saved={this.state.saved} error={this.state.error} idModal="modalSaveTrialRegionMembership"/>
            </div>
        )
    }
}
export default ModalTrialRegionMembership;
