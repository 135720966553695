import React, { Component } from 'react';
import axios from 'axios';
import cookie from 'js-cookie';
import {
    BrowserRouter as Router,
    Route, Switch
} from 'react-router-dom';
import Login from './containers/Login';
import Dashboard from './containers/Dashboard';
import Models from './containers/Models';
import ModelDetail from './containers/Models/ModelDetail';
import Memberships from './containers/Models/ModelDetail/Memberships';
import Coins from './containers/Models/ModelDetail/Coins';
import Managers from './containers/Managers';
import Users from './containers/Configuration/Users';
import Access from './containers/Configuration/Access';
import Regions from './containers/Configuration/Regions';
import Customer from './containers/User/Customer';
import Preloader from './components/Preloader';
import ManagerDetail from "./containers/Managers/ManagerDetail/ManagerDetail";
import Referrals from './containers/Referrals';
import ReferralDetail from './containers/Referrals/ReferralDetail';
import Notifications from './containers/Notifications';
import NotificationDetail from './containers/Notifications/NotificationDetail';
import PromosSoldConverted from './containers/Promotions/PromosSoldConverted';
import './App.css';

class App extends Component {
    constructor(){
        super();
        this.state = {
            auth : false,
            model : false,
            view : "home",
            accessLevel : 0,
            username : cookie.get("username"),
            loading: true,
            permisos : []
        };
        this.changeView = this.changeView.bind(this);
        this.isAbleToAccess = this.isAbleToAccess.bind(this);
    }

    async changeView(site){
        await this.setState({view : site});
    }
    componentDidMount(){
        //const pathArray = window.location.pathname.split('/');
        axios.get('/api/user/authenticated').then(res =>{
            if(res.data.auth){
                this.setState({
                    auth: true,
                    model: res.data.model,
                    loading:false,
                    permisos : res.data.permisos
                })
            }
            else{
                this.setState({
                    auth: false,
                    model: false,
                    loading:false
                })
            }
        }).catch(err => {
            this.setState({
                auth: false,
                model: false,
                loading:false
            })
        })

    }
    isAbleToAccess = async (access,min = 1, type = 1) => {
        let next = false;
        if(!this.state.auth){
            return false;
        }
        await axios.post('/api/user/acessToModule', {access : access, type : type}).then(res => {
            this.setState({
                accessLevel : res.data.access
            });
            switch (type) {
                case 1:
                    next = (min <= res.data.access["r"]);
                    break;
                case 2:
                    next = (min <= res.data.access["c"]);
                    break;
                case 3:
                    next = (min <= res.data.access["u"]);
                    break;
                case 4:
                    next = (min <= res.data.access["d"]);
                    break;
                default:
                    next = (min <= res.data.access["r"]);
                    break;
            }
        });

        if(!next){
            window.location = "/";
        }
    }
    render() {
        let typeUser = (cookie.get("type") === undefined || cookie.get("type") === "") 
            ? "Admin" 
            : cookie.get("type");
        if(this.state.loading) return <Preloader />;
        return (
            <Router>
                <Switch>
                    <Route
                        exact
                        path="/"
                        render={
                            props => (this.state.auth) ?
                                <Dashboard 
                                    permisos={this.state.permisos} 
                                    view={this.state.view} 
                                    changeView={this.changeView} 
                                    isAbleToAccess={this.isAbleToAccess} 
                                    accessLevel={this.state.accessLevel} 
                                    username={this.state.username}
                                /> :
                                <Login 
                                    type={typeUser}
                                />
                        }
                    />
                    <Route
                        exact
                        path="/home"
                        render={
                            props => (this.state.auth) ?
                                <Dashboard 
                                    permisos={this.state.permisos}  
                                    view={this.state.view} 
                                    changeView={this.changeView} 
                                    isAbleToAccess={this.isAbleToAccess} 
                                    accessLevel={this.state.accessLevel} 
                                    username={this.state.username}
                                /> :
                                <Login 
                                    type={typeUser}
                                />
                        }
                    />
                    <Route
                        exact
                        path="/models"
                        render={
                            props => (this.state.auth) ?
                                <Models 
                                    permisos={this.state.permisos}  
                                    view={this.state.view} 
                                    changeView={this.changeView} 
                                    isAbleToAccess={this.isAbleToAccess} 
                                    accessLevel={this.state.accessLevel} 
                                    username={this.state.username}
                                /> :
                                <Login 
                                    type={typeUser}
                                />
                        }
                    />
                    <Route
                        exact
                        path="/models/model/:model"
                        render={
                            props => (this.state.auth) ?
                                <ModelDetail 
                                    permisos={this.state.permisos}  
                                    model={props.match.params.model} 
                                    view={this.state.view} 
                                    changeView={this.changeView} 
                                    isAbleToAccess={this.isAbleToAccess} 
                                    accessLevel={this.state.accessLevel} 
                                    username={this.state.username}
                                /> :
                                <Login 
                                    type={typeUser}
                                />
                        }
                    />
                    <Route
                        exact
                        path="/promotions"
                        render={
                            props => (this.state.auth) ?
                                <PromosSoldConverted
                                    permisos={this.state.permisos}  
                                    view={this.state.view} 
                                    changeView={this.changeView} 
                                    isAbleToAccess={this.isAbleToAccess} 
                                    accessLevel={this.state.accessLevel} 
                                    username={this.state.username}
                                /> :
                                <Login 
                                    type={typeUser}
                                />
                        }
                    />
                    <Route
                        exact
                        path="/managers"
                        render={
                            props => (this.state.auth) ?
                                <Managers 
                                    permisos={this.state.permisos}  
                                    view={this.state.view} 
                                    changeView={this.changeView} 
                                    isAbleToAccess={this.isAbleToAccess} 
                                    accessLevel={this.state.accessLevel} 
                                    username={this.state.username}
                                /> :
                                <Login 
                                    type={typeUser}
                                />
                        }
                    />
                    <Route
                        exact
                        path="/managers/manager/:manager"
                        render={
                            props => (this.state.auth) ?
                                <ManagerDetail 
                                    permisos={this.state.permisos}  
                                    manager={props.match.params.manager} 
                                    view={this.state.view} 
                                    changeView={this.changeView} 
                                    isAbleToAccess={this.isAbleToAccess} 
                                    accessLevel={this.state.accessLevel} 
                                    username={this.state.username}
                                /> :
                                <Login 
                                    type={typeUser}
                                    />
                        }
                    />
                    <Route
                        exact
                        path="/models/model/:model/memberships"
                        render={
                            props => (this.state.auth) ?
                                <Memberships 
                                    permisos={this.state.permisos}  
                                    model={props.match.params.model} 
                                    view={this.state.view} 
                                    changeView={this.changeView} 
                                    isAbleToAccess={this.isAbleToAccess} 
                                    accessLevel={this.state.accessLevel} 
                                    username={this.state.username}
                                /> :
                                <Login 
                                    type={typeUser}
                                />
                        }
                    />
                    <Route
                        exact
                        path="/models/model/:model/coins"
                        render={
                            props => (this.state.auth) ?
                                <Coins 
                                    permisos={this.state.permisos}  
                                    model={props.match.params.model} 
                                    view={this.state.view} 
                                    changeView={this.changeView} 
                                    isAbleToAccess={this.isAbleToAccess} 
                                    accessLevel={this.state.accessLevel} 
                                    username={this.state.username}
                                /> :
                                <Login 
                                    type={typeUser}
                                />
                        }
                    />
                    <Route
                        exact
                        path="/Referrals"
                        render={
                            props => (this.state.auth) ?
                                <Referrals 
                                    permisos={this.state.permisos}  
                                    view={this.state.view} 
                                    changeView={this.changeView} 
                                    isAbleToAccess={this.isAbleToAccess} 
                                    accessLevel={this.state.accessLevel} 
                                    username={this.state.username}
                                /> :
                                <Login 
                                    type={typeUser}
                                />
                        }
                    />
                    <Route
                        exact
                        path="/referrals/referral/:referral"
                        render={
                            props => (this.state.auth) ?
                                <ReferralDetail 
                                    permisos={this.state.permisos} 
                                    referral={props.match.params.referral} 
                                    view={this.state.view} 
                                    changeView={this.changeView} 
                                    isAbleToAccess={this.isAbleToAccess} 
                                    accessLevel={this.state.accessLevel} 
                                    name={this.state.name}
                                /> :
                                <Login 
                                    type={typeUser}
                                />
                        }
                    />
                    <Route
                        exact
                        path="/Configuration/Users"
                        render={
                            props => (this.state.auth) ?
                                <Users 
                                    permisos={this.state.permisos}  
                                    view={this.state.view} 
                                    changeView={this.changeView} 
                                    isAbleToAccess={this.isAbleToAccess} 
                                    accessLevel={this.state.accessLevel} 
                                    username={this.state.username}
                                /> :
                                <Login type={typeUser}/>
                        }
                    />
                    <Route
                        exact
                        path="/Configuration/Access"
                        render={
                            props => (this.state.auth) ?
                                <Access 
                                    permisos={this.state.permisos}  
                                    view={this.state.view} 
                                    changeView={this.changeView} 
                                    isAbleToAccess={this.isAbleToAccess} 
                                    accessLevel={this.state.accessLevel} 
                                    username={this.state.username}
                                /> :
                                <Login type={typeUser}/>
                        }
                    />
                    <Route
                        exact
                        path="/Configuration/Regions"
                        render={
                            props => (this.state.auth) ?
                                <Regions 
                                    permisos={this.state.permisos}  
                                    view={this.state.view} 
                                    changeView={this.changeView} 
                                    isAbleToAccess={this.isAbleToAccess} 
                                    accessLevel={this.state.accessLevel} 
                                    username={this.state.username}
                                /> :
                                <Login 
                                    type={typeUser}
                                />
                        }
                    />
                    <Route
                        exact
                        path="/User/Customer"
                        render={
                            props => (this.state.auth) ?
                                <Customer 
                                    permisos={this.state.permisos}  
                                    view={this.state.view} 
                                    changeView={this.changeView} 
                                    isAbleToAccess={this.isAbleToAccess} 
                                    accessLevel={this.state.accessLevel} 
                                    username={this.state.username}
                                /> :
                                <Login 
                                    type={typeUser}
                                />
                        }
                    />
                    <Route
                        exact
                        path="/Notifications"
                        render={
                            props => (this.state.auth) ?
                                <Notifications 
                                    permisos={this.state.permisos}  
                                    view={this.state.view} 
                                    changeView={this.changeView} 
                                    isAbleToAccess={this.isAbleToAccess} 
                                    accessLevel={this.state.accessLevel} 
                                    username={this.state.username}
                                /> :
                                <Login 
                                    type={typeUser}
                                />
                        }
                    />
                    <Route
                        exact
                        path="/Notifications/:notification"
                        render={
                            props => (this.state.auth) ?
                                <NotificationDetail 
                                    permisos={this.state.permisos} 
                                    notification={props.match.params.notification} 
                                    view={this.state.view} 
                                    changeView={this.changeView} 
                                    isAbleToAccess={this.isAbleToAccess} 
                                    accessLevel={this.state.accessLevel} 
                                    username={this.state.username}
                                /> :
                                <Login 
                                    type={typeUser}
                                />
                        }
                    />
                    <Route
                        exact
                        render={
                            props => (this.state.auth) ?
                                <Dashboard 
                                    permisos={this.state.permisos}  
                                    view={this.state.view} 
                                    changeView={this.changeView} 
                                    isAbleToAccess={this.isAbleToAccess} 
                                    accessLevel={this.state.accessLevel} 
                                    username={this.state.username}
                                /> :
                                <Login 
                                    type={typeUser}
                                />
                        }
                    />
                </Switch>
            </Router>
        );
    }
}

export default App;
