import React, { Component } from 'react';
import axios from "axios";
import moment from "moment";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ModalSaving from '../../../ModalSaving';

class ModalNewModelNotification extends Component{
    constructor(){
        super();
        this.state = {
            messageError: null,
            saved: true,
            error: false,
            modelsSelected: [],
            allModels: null,
            startDate: moment().add(1, 'days').utc().format("YYYY-MM-DDTHH:00")
        };
        this.saveModelNotification = this.saveModelNotification.bind(this);
        this.handleChangeStart = this.handleChangeStart.bind(this);
        this.changeHandlerMultiple = this.changeHandlerMultiple.bind(this);
    }

    handleChangeStart= async(date) =>{
        await this.setState({
            startDate: date
        });
    }

    changeHandlerMultiple(value){
        let getAll = null;
        let valueAll = null;
        for (var i = 0; i < value.length; ++i) {
            if(value[i].label === "Todos"){
                getAll = value[i].value
                valueAll = value[i];
                break;
            }
        }
        if(getAll){
            this.setState({
                allModels: getAll,
                modelsSelected: valueAll
            });
        } else {
            this.setState({
                modelsSelected: value,
                allModels: null
            });
        }
    }

    saveModelNotification = async () => {  
        await this.setState({saved : false, error: false});
        let send = true;
        let idNews = this.props.idNews;
        const date = document.getElementById("dateInput").value;
        let modelsStelect = document.getElementById("modelsStelect");
        if(this.state.modelsSelected.length === 0 && !this.state.allModels){
            modelsStelect.classList.add("error-input");
            send = false;
        }else{
            modelsStelect.classList.remove("error-input");
        }
        if(date===""){
            document.getElementById("dateInput").classList.add("is-invalid");
            send = false;
        }else{
            document.getElementById("dateInput").classList.remove("is-invalid");
        }
        if(send){
            document.getElementById("ModalNewReferralCancelBtn").click();
            document.getElementById("ModalSaving").classList.add("show");
            const data = {
                idNews: idNews,
                modelsSelected: this.state.modelsSelected,
                allModels: this.state.allModels,
                getAllModels: this.state.allModels ? this.props.addedModels : null,
                date: moment(date).format("YYYY-MM-DD HH:mm:ss")
            }
            const saved = await axios.post("/api/notifications/detail/modelsInsert", data)
            .then(resp => {
                return resp.data;
            });
            if (saved) {
                await this.setState({
                    saved : true,
                    error : false
                });
                await this.props.reloadNews();
            } else {
                await this.setState({
                    error : true,
                    saved : false,
                    messageError: `Hubo un problema, por favor inténtelo nuevamente.`
                });
            }
        }
    }
    render(){
        const all = {
            id_model: this.props.totalCountModels,
            username: 'Todos'
        };
        const allModels = [all, ...this.props.addedModels];
        const modelos = allModels.map((item, index) => ({
            key: index ,
            value: item.id_model,
            label: item.username
        }));
        return(
            <div>
                <div 
                    className="modal" 
                    id="ModalNewModelNotification" 
                    role="dialog"
                >
                    <div 
                        className="modal-dialog" 
                        role="document"
                    >
                        <div className="modal-content">
                            <div className="modal-header-section">
                                <h6 
                                    className="modal-title sectionTitle"
                                >
                                    Agregar Modelo
                                </h6>
                                <button 
                                    type="button" 
                                    className="close modalClose modal-closeSection" 
                                    data-dismiss="modal" 
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body referralBody">
                                <form id="newModelNotification">
                                    <div className="form-group">
                                        <label>Enviar a</label>
                                        <Select
                                            id="modelsStelect"
                                            closeMenuOnSelect={false}
                                            hideSelectedOptions={false}
                                            isMulti
                                            name="modelsStelect"
                                            options={modelos}
                                            className="form-multiselect basic-multi-select"
                                            classNamePrefix="selec"
                                            value={this.state.modelsSelected}
                                            onChange={this.changeHandlerMultiple}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Fecha de expiraci&oacute;n</label>
                                        <DatePicker 
                                            id="dateInput"
                                            minDate={new Date()}
                                            className="form-control form-control-sm dateInput"
                                            showTimeSelect
                                            timeFormat="HH:mm"
                                            dateFormat="MMMM d, yyyy h:mm aa"
                                            timeIntervals={15}
                                            selected={new Date(this.state.startDate)}
                                            onChange={this.handleChangeStart}
                                        />
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer modalFooter">
                                <button 
                                    type="button" 
                                    className="btn btn-modalClose" 
                                    id="ModalNewReferralCancelBtn" 
                                    data-dismiss="modal"
                                >
                                    Cancelar
                                </button>
                                <button 
                                    type="button" 
                                    className="btn btn-modalSuccess" 
                                    onClick={this.saveModelNotification}
                                >
                                    Confirmar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalSaving 
                    saved={this.state.saved} 
                    error={this.state.error} 
                    messageError={this.state.messageError}
                />               
            </div>
        )
    }
}
export default ModalNewModelNotification;