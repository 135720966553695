import React, { Component } from 'react';
import axios from "axios";
import Promise from 'bluebird';
import ModalSaving from "../../../ModalSaving";
import ModalActiveMembershipRegion from "../ModalActiveMembershipRegion";
import ModalPromoRegionMembership from "../ModalPromoRegionMembership";
import ModalTrialRegionMembership from "../ModalTrialRegionMembership";

class ModalShowRegionMembership extends Component {
    constructor(){
        super();
        this.state = {
            countries : [],
            active : 1,
            promo : 1,
            trial : 1,
            currTrial : null
        };
    }
    componentDidMount(){
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.membership !== this.props.membership){
            this.setState({currTrial : null});
            this.getMembershipByCountry();
        }
        else if(this.props.membership === null){
            this.setState({countries : []});
        }
    }
    activateAll = (disable = false, main = false) => {
        this.setState({active : (disable) ? 2 : (main) ? 3 : 1});
    }
    promoAll = (disable = false) => {
        this.setState({promo : (disable) ? 2 : 1});
    }
    trialAll = (disable = false) => {
        this.setState({trial : (disable) ? 2 : 1});
    }
    getMembershipByCountry = async () => {
        await axios.get('/api/memberships/countries/' + this.props.membership).then( async (resp) => {
            this.setState({countries : resp.data});
            const countries = resp.data;
            Promise.map(countries, async (item,index) => {
                if(item.trial !== 0){
                    this.setState({currTrial : item.trial});
                }
            });
        })
    }

    flag = (country) => {
        let flagOffset = 0x1F1E6;
        let asciiOffset = 0x41;
        let firstChar = country.codePointAt(0) - asciiOffset + flagOffset;
        let secondChar = country.codePointAt(1) - asciiOffset + flagOffset;

        let flag = String.fromCodePoint(firstChar, secondChar);
        return flag
    }
    drawByCountry = () => {
        const countries = [...this.state.countries];
        return countries.map((item,index) => {
            return(
                <tr>
                    <td>
                        <span className="flag-icon">{this.flag(item.country)}</span>
                    </td>
                    <td>
                        {item.active ?
                            <i className="fa fa-check text-success"></i>
                            :
                            <i className="fa fa-times text-danger"></i>
                        }
                    </td>
                    <td>
                        {item.main ?
                            <i className="fa fa-check text-success"></i>
                            :
                            <i className="fa fa-times text-danger"></i>
                        }
                    </td>
                    <td>
                        {item.promo ?
                            <i className="fa fa-check text-success"></i>
                            :
                            <i className="fa fa-times text-danger"></i>
                        }
                    </td>
                    <td>
                        {item.trial ?
                            <i className="fa fa-check text-success"></i>
                            :
                            <i className="fa fa-times text-danger"></i>
                        }
                    </td>
                </tr>
            )
        })
    }
    render(){
        return (
            <div>
                <div className="modal" id="ModalShowRegionMembership" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header-section">
                                <h5 className="modal-title sectionTitle">Resumen</h5>
                                <button type="button" className="close modalClose" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body modelBody modal-overflow">
                                <h6>Picode: {this.props.picode}</h6>
                                <table className="table table-sm table-bordered table-centered">
                                    <thead>
                                    <tr className="">
                                        <th>País</th>
                                        <th>Activa</th>
                                        <th>Principal</th>
                                        <th>Promo</th>
                                        <th>Trial</th>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <th>
                                            <ul className="list-inline no-margin">
                                                <li className="list-inline-item">
                                                    <button className="btn btn-xsm btn-success" data-toggle="modal" onClick={(e) => this.activateAll()} data-target="#ModalActiveMembershipRegion"> <i className="fa fa-check"></i> </button>
                                                </li>
                                                <li className="list-inline-item">
                                                    <button className="btn btn-xsm btn-danger" data-toggle="modal" onClick={(e) => this.activateAll(true)} data-target="#ModalActiveMembershipRegion"> <i className="fa fa-times"></i> </button>
                                                </li>
                                            </ul>
                                        </th>
                                        <th>
                                            <ul className="list-inline no-margin">
                                                <li className="list-inline-item">
                                                    <button className="btn btn-xsm btn-success" data-toggle="modal" onClick={(e) => this.activateAll(false, true)} data-target="#ModalActiveMembershipRegion"> <i className="fa fa-check"></i> </button>
                                                </li>
                                            </ul>
                                        </th>
                                        <th>
                                            <ul className="list-inline no-margin">
                                                <li className="list-inline-item">
                                                    <button className="btn btn-xsm btn-success" data-toggle="modal" onClick={(e) => this.promoAll()} data-target="#ModalPromoRegionMembership"> <i className="fa fa-check"></i> </button>
                                                </li>
                                                <li className="list-inline-item">
                                                    <button className="btn btn-xsm btn-danger" data-toggle="modal" onClick={(e) => this.promoAll(true)} data-target="#ModalPromoRegionMembership"> <i className="fa fa-times"></i> </button>
                                                </li>
                                            </ul>
                                        </th>
                                        <th>
                                            <ul className="list-inline no-margin">
                                                <li className="list-inline-item">
                                                    <button className="btn btn-xsm btn-success" data-toggle="modal" onClick={(e) => this.trialAll()} data-target="#ModalTrialRegionMembership"> <i className="fa fa-check"></i> </button>
                                                </li>
                                                <li className="list-inline-item">
                                                    <button className="btn btn-xsm btn-danger" data-toggle="modal" onClick={(e) => this.trialAll(true)} data-target="#ModalTrialRegionMembership"> <i className="fa fa-times"></i> </button>
                                                </li>
                                            </ul>
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.drawByCountry()}
                                    </tbody>
                                </table>
                            </div>
                            <div className="modal-footer modalFooter">
                                <button type="button" className="btn btn-modalClose" data-dismiss="modal">Cerrar</button>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalActiveMembershipRegion currRegion={this.props.currRegion} getRegionMemberships={this.props.getRegionMemberships} reloadMembershipsByCountry={this.getMembershipByCountry} membership={this.props.membership} model={this.props.model} type={this.state.active}/>
                <ModalPromoRegionMembership currRegion={this.props.currRegion} getRegionMemberships={this.props.getRegionMemberships} reloadMembershipsByCountry={this.getMembershipByCountry} membership={this.props.membership} model={this.props.model} type={this.state.promo} membresiasRegion={this.props.membresiasRegion} picode={this.props.picode} currTrial={this.state.currTrial}/>
                <ModalTrialRegionMembership currRegion={this.props.currRegion} getRegionMemberships={this.props.getRegionMemberships} reloadMembershipsByCountry={this.getMembershipByCountry} membership={this.props.membership} model={this.props.model} type={this.state.trial} membresiasRegion={this.props.membresiasRegion} picode={this.props.picode} currTrial={this.state.currTrial}/>
                <ModalSaving saved={this.state.saved} error={this.state.error} idModal="modalSavePromo"/>
            </div>
        )
    }
}
export default ModalShowRegionMembership;