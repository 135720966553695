import React, { Component } from 'react';
import './ModalSaving.css'
class ModalSaving extends Component {
    constructor() {
        super();
        this.hideModal = this.hideModal.bind(this);
    }
    hideModal = () => {
        const id = (this.props.idModal !== undefined) ? this.props.idModal : "ModalSaving";
        document.getElementById(id).classList.remove("show");
    }
    render() {
        return (
            <div className="modal fade ModalSaving" id={(this.props.idModal !== undefined) ? this.props.idModal : "ModalSaving"} role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            {(this.props.saved) ?
                                <span className="fas fa-check fa-4x text-success"></span>
                                : (this.props.error) ?
                                    <span className="fas fa-times fa-4x text-danger"></span>
                                    : <span className="fas fa-spinner fa-spin fa-4x text-primary"></span>
                            }
                            {(this.props.saved) ?
                                <h5 className="text-success">{this.props.customMessage || "Guardado con Exito."}</h5>
                                : (this.props.error)
                                    ? <h5 className="text-danger">
                                        {
                                            this.props.messageError ? this.props.messageError : "Ocurrio un error"
                                        }
                                      </h5>
                                    : <h5>{(this.props.message === undefined) ? "Guardando . . ." : this.props.message}</h5>
                            }
                            {(this.props.saved) ?
                                <button type="button" className="btn btn-newModel btn-lg" onClick={this.hideModal} data-dismiss="modal">Cerrar&nbsp;<i className="fas fa-times"></i></button>
                                : (this.props.error) ?
                                    <button type="button" className="btn btn-modalClose btn-lg" onClick={this.hideModal} data-dismiss="modal">Cerrar&nbsp;<i className="fas fa-times"></i></button>
                                    : ""
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }

};
export default ModalSaving;