import React, { Component } from 'react';

class Footer extends Component{
    
    render(){
        let year = new Date().getFullYear();
        return(
            <div className="footer">
                <p className="text-center">DiSo {year} &reg;</p>
            </div>
        )
    }
}
export default Footer;